import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { debounce } from "lodash";
import { IProductItems } from "../../CfPriceList/src/PriceListViewController.web";
import { navigateTo, sortCondition } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
import { updateMultiSelectData } from "../../../components/src/common";
export interface ISnackbar {
  open: boolean,
  message: string,
  error?:boolean,
}

export interface IUpchargeInitialValue {
  upcharge_name: string,
  upcharge_second_name: string,
  section: (string | number)[],
  service: (string | number)[],
  product: IProductItems[],
  price: number,
  icon: string | number,
  type: number,
}

export interface IUpchargeInitialValue1 {
  upcharge_name: string,
  upcharge_second_name: string,
  section: (string | number)[],
  service: (string | number)[],
  product: (string | number)[],
  price: number,
  icon: string | number,
  type: string,
}

export interface IUpchargeBody {
  name: string,
  second_name: string,
  section_ids: (string | number)[],
  catalogue_ids: (string | number)[],
  service_ids: (string | number)[],
  upcharge_type: string | number,
  icon_id: string | number,
  price: number | string
}

export interface IOptionList {
  id: number| string,
  name?: string,
  option: string
}

export interface UpchargeList {
  data: {
    id: string;
    type: string;
    attributes: UpchargeListAttributes;
  };
}

export interface UpchargeListAttributes {
  name: string;
  second_name: string;
  price: number;
  upcharge_type: string;
  active: boolean;
  order_number: number;
  icon: IIconData;
  sections: ISectionData;
  services: IServiceData;
  catalogues: {
    data: IProductItems[];
  };
  name_translation: string;
}

export interface GalleryIcon {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      image: string;
      name: string;
      second_name: string;
      image_type: string;
      name_translation: string;
    };
  }[];
}

export interface IIconData {
  id: number;
  name: string;
  second_name: string;
  image_type: string;
  image: string;
  name_translation: string;
}

export interface ISectionData {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      section_name: string;
      section_second_name: string;
      active: boolean;
      online: boolean;
      order_number: number;
      created_at: string;
      updated_at: string;
      model_name: string;
      icon: {
        data: {
          id: string;
          type: string;
          attributes: {
            id: number;
            image: string;
            name: string;
            second_name: string;
            image_type: string;
            name_translation: string;
          };
        };
      };
      preference: {
        data: {
          id: string;
          type: string;
          attributes: {
            id: number;
            preference_first_name: string;
            icon_id: number;
            gallery_icon: {
              id: number;
              image: string;
              name_translation: string;
            };
            name: string;
          };
        };
      };
      name: string;
    };
  }[];
}

export interface IServiceData {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      second_name: string;
      short_name: string;
      icon_id: number;
      active: boolean;
      online_order: boolean;
      order_number: number;
      icon: {
        data: {
          id: string;
          type: string;
          attributes: {
            id: number;
            image: string;
            name: string;
            second_name: string;
            image_type: string;
            name_translation: string;
          };
        };
      };
      sections: ISectionData;
      name_translation: string;
    };
  }[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  isSaveLoading: boolean;
  selectedIcon: { id: string | number, option: string, image: string };
  isEdit: boolean;
  addUpchargeInitialvalues: IUpchargeInitialValue;
  dropdownOptions: {
    section: IOptionList[],
    service: IOptionList[],
    product: IProductItems[],
    icon: {id: string | number, name?: string,option:string,image: string}[],
    type: {id: string | number, name: string}[]
  };
  upchargeId: number;
  snackbar: ISnackbar;
  isSectionSelectAll: boolean;
  sectionSelectedData: IOptionList[];
  selectedSectionsForList: { id: string | number, option: string }[];
  isServiceSelectAll: boolean;
  serviceSelectedData: IOptionList[];
  selectedServicesForList: { id: string | number, option: string }[];
  isProductSelectAll: boolean;
  productSelectedData: IOptionList[];
  selectUpchargeProductForAdd: {id: string | number, option: string}[];
  upchargeSelectedOptionForList: {id: string | number, option: string}[];
  upchargeProductListDropdown: IProductItems[];
  autoCopmpleteValue: string;
  isSelectedAll: boolean;
  productNextPage: number;
  currentProductData: IProductItems[];
  searchQuery: boolean;
  selectedProductWhileEdit: IProductItems[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddUpchargeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpchargeDetailApiId: string = ""
  getSectionListApiId: string = ""
  getServiceListApiId: string = ""
  getProductListApiId: string = ""
  getGalleryIconsListApiId: string = ""
  createUpchargeApiId: string = ""
  editUpchargeApiId: string = ""
  upchargeType = [
    { id: 0, name: 'SAR' },
    { id: 1, name: "%" }
  ]

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isEdit: false,
      selectedIcon: {} as { id: string | number, option: string, image: string },
      addUpchargeInitialvalues: {
        upcharge_name: '',
        upcharge_second_name: '',
        section: [],
        service: [],
        product: [],
        price: 0,
        icon: '',
        type: 0,
      },
      isLoading: false,
      isSaveLoading: false,
      dropdownOptions: {
        section: [],
        service: [],
        product: [],
        icon: [],
        type: this.upchargeType,
      },
      upchargeId: -1,
      snackbar: { open: false, message: "", error:false },
      isSectionSelectAll: false,
      sectionSelectedData: [],
      selectedSectionsForList:[],
      isServiceSelectAll: false,
      serviceSelectedData: [],
      selectedServicesForList:[],
      isProductSelectAll: false,
      productSelectedData: [],
      selectUpchargeProductForAdd: [],
      upchargeSelectedOptionForList: [],
      upchargeProductListDropdown: [],
      autoCopmpleteValue: '',
      isSelectedAll: false,
      productNextPage: 1,
      currentProductData: [],
      searchQuery: false,
      selectedProductWhileEdit: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleResponse(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const upchargeId = this.props.navigation.getParam('navigationBarTitleText')
    this.getGalleryIconsList()
    this.getSectionList()
    if (upchargeId) {
      this.setState({ isEdit: true, upchargeId: upchargeId }, () => {
        this.getUpchargeDetails(upchargeId)
      })
    } else {
    }
  }

  handleResponse = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson && !responseJson?.errors && !responseJson?.error) {
      switch (apiRequestCallId) {
        case this.getUpchargeDetailApiId:
          const { data } = responseJson as UpchargeList;
          const { attributes }: { attributes: UpchargeListAttributes }  = data;
          const initValues: IUpchargeInitialValue = {
            upcharge_name: attributes?.name,
            upcharge_second_name: attributes?.second_name,
            section: attributes?.sections?.data?.map((section) => (parseInt(section.id))),
            service: attributes?.services?.data?.map((service) => (parseInt(service.id))),
            product: attributes?.catalogues?.data,
            price: attributes?.price,
            icon: attributes?.icon?.id || "",
            type: attributes?.upcharge_type === 'SAR' ? 0 : 1
          };          
          const initialDropdownSelected: { section: IOptionList[], service: IOptionList[], product: IOptionList[] } = {
            section: attributes?.sections?.data?.map((section) => { return { id: parseInt(section.id), option: section.attributes?.section_name } }),
            service: attributes?.services?.data?.map((service) => { return { id: parseInt(service.id), option: service.attributes?.name } }),
            product: attributes?.catalogues?.data?.map((catalogue) => { return { id: parseInt(catalogue.id), option: catalogue.attributes?.product_name } }),
          }
          const iconDetail = { id:attributes.icon?.id , option: attributes.icon?.name, image: attributes.icon?.image }
          const selectedData = initValues.product.map((item) => {return {id: item.id, option: item.attributes.product_name}});
          this.setState({ selectUpchargeProductForAdd: selectedData, upchargeSelectedOptionForList: selectedData, selectedProductWhileEdit: initValues.product, addUpchargeInitialvalues: initValues, selectedSectionsForList: initialDropdownSelected.section, sectionSelectedData: initialDropdownSelected.section, selectedServicesForList: initialDropdownSelected.service, serviceSelectedData: initialDropdownSelected.service, productSelectedData: initialDropdownSelected.product, selectedIcon:iconDetail }, () => {
            this.getProductList("")
            this.getServiceList()
            this.stopLoader()
          })
          break;
        case this.getSectionListApiId:
          const sectionData = (responseJson as ISectionData).data.map((section) => ({ id: parseInt(section.id), name: section.attributes.section_name, option: section.attributes.section_name }))
          this.setState({ dropdownOptions: { ...this.state.dropdownOptions, section: sectionData } })
          break;
        case this.getServiceListApiId:
          const serviceData = (responseJson as IServiceData).data.map((service) => ({ id: parseInt(service.id), name: service.attributes.name, option: service.attributes.name }))
          this.setState({ isLoading: false, dropdownOptions: { ...this.state.dropdownOptions, service: serviceData } })
          break;
        case this.getProductListApiId:
          this.handleProductListApiResponse(responseJson.data)
          break;
        case this.getGalleryIconsListApiId:
          const iconData = (responseJson as GalleryIcon)?.data.map((icon) => ({ id: parseInt(icon.id), option: icon.attributes.name, image: icon.attributes.image }))
          this.setState({
            dropdownOptions: { ...this.state.dropdownOptions, icon: iconData },
            addUpchargeInitialvalues: this.state.addUpchargeInitialvalues
          })
          break;
        case this.createUpchargeApiId:
          this.handleCreateUpchargeError(responseJson.data.attributes.errors)   
          break;
        case this.editUpchargeApiId:
          this.setState({isSaveLoading:false,snackbar: { open: true, message: "Upcharge Updated Successfully." } })
          setTimeout(() => {
            navigateTo({props: this.props, screenName: 'UpchargeList'})
          }, 1000);
          break;
      }
    } else if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.setState({ isLoading: false,isSaveLoading:false })
    }
  }

  stopLoader = () => {
    this.setState({ isLoading: false})
  }

  handleProductListApiResponse = (productList:IProductItems[]) => {
    if(this.state.upchargeProductListDropdown.length === 0) {
      let responseOptionList = [...this.state.selectedProductWhileEdit, ...productList.filter((item: IProductItems) => {
        return this.handleProductListResponse(item)
      })];

      const existingData = this.state.upchargeProductListDropdown;
      this.setState({ upchargeProductListDropdown: this.handleProductListResponseAsPerQuery(existingData, responseOptionList), currentProductData: responseOptionList }, () => {
        this.handleSelectAllFunctionality(responseOptionList)
      })
    } else {
      let responseOptionList = productList.filter((item: IProductItems) => {
        return this.handleProductListResponse(item)
      });
      const existingData = this.state.upchargeProductListDropdown;
      this.setState({ upchargeProductListDropdown: this.handleProductListResponseAsPerQuery(existingData, responseOptionList), currentProductData: responseOptionList }, () => {
        this.handleSelectAllFunctionality(responseOptionList)
      })
    }
  }

  handleSelectAllFunctionality(responseOptionList: IProductItems[]) {
    if (this.state.isSelectedAll) {
      this.setState({
        upchargeSelectedOptionForList: [...this.state.upchargeSelectedOptionForList, ...responseOptionList.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })],
      })
    } 
  }

  handleProductListResponse(item: IProductItems) {
    if (!this.state.selectedProductWhileEdit.some((innerItem: { id?: string | number, option?: string }) => innerItem.id === item.id)) {
      return item
    }
  }

  handleProductListResponseAsPerQuery(existingData: IProductItems[], responseOptionList: IProductItems[]) {
    if(this.state.searchQuery) {
      return existingData.concat(responseOptionList)
    }
    return responseOptionList
  }
 handleCreateUpchargeError = (errorsData:Record<string, string[]>) => {
  if(errorsData && Object.keys(errorsData).length){
    const gotError = Object.values(errorsData)[0]
    const fieldName = Object.keys(errorsData)[0].replace(/_/g, ' ')
    this.setState({isSaveLoading:false, snackbar: { open: true, message: `${fieldName} ${gotError[0]}` ,error:true} })
  }
  else {
    this.setState({isSaveLoading:false, snackbar: { open: true, message: "Upcharge Created Successfully." } })
            setTimeout(() => {
              navigateTo({ props: this.props, screenName: 'UpchargeList' })
            }, 1000);
  }
 }
  handleBackTotListing = () => {
    navigateTo({props: this.props, screenName: 'UpchargeList'})
  }

  getFormValue = (values: {upcharge_name: string, upcharge_second_name: string, section: (string | number)[], product: (string | number)[], service: (string | number)[], type: number ,icon: string | number, price: string | number}) => {
    return {
      "data": {
        "attributes": {
          "name": values?.upcharge_name,
          "second_name": values?.upcharge_second_name,
          "section_ids": values?.section,
          "catalogue_ids": values?.product,
          "service_ids": values?.service,
          "upcharge_type": values?.type,
          "icon_id": values?.icon,
          "price": values?.price
        }
      }
    }
  }

  handleSubmit = (values: IUpchargeInitialValue) => {
    this.setState({isSaveLoading:true ,snackbar: { open: false, message: "",error:false }})
    const getFormValues = {
      ...values, 
      product: this.state.isSelectedAll ? ['All'] : this.state.selectUpchargeProductForAdd.map((item) => item.id),
      service: this.state.isServiceSelectAll ? ['All'] : this.state.serviceSelectedData.map((item) => Number(item.id)),
      section: this.state.isSectionSelectAll ? ['All'] : this.state.sectionSelectedData.map(item => Number(item.id)),

    }
    const bodyData: { data: { attributes: IUpchargeBody } } = this.getFormValue(getFormValues)
    this.state.isEdit
      ? this.editUpcharge(bodyData)
      : this.createUpcharge(bodyData)
  }

  handleCloseSnackbar = () => {
    this.setState({ snackbar: { open: false, message: "" } })
  }


  // API

  createUpcharge = (bodyData: { data: { attributes: IUpchargeBody; }; }) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      "token": localStorage.getItem('token')
    };

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createUpchargeApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUpcharge
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(message.id, message);
  }

  editUpcharge = (bodyData: { data: { attributes: IUpchargeBody; }; }) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      "token": localStorage.getItem('token')
    };
    const apiUrl = `${configJSON.editUpcharge}${this.state.upchargeId}`

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUpchargeApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(message.id, message);
  }

  getUpchargeDetails = (idUpcharge: number) => {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem('token'),
    };
    const apiUrl = configJSON.getUpchargeDetailEndpoint + idUpcharge

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    this.getUpchargeDetailApiId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getSectionList = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem('token'),
    };

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSectionListApiId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServiceCategoryListDropDownEndpoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(message.id, message);
  }

  getServiceList = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem('token'),
    };

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceListApiId = message.messageId;

    const sectionSelectedIds = 
      this.state.sectionSelectedData?.length ? 
        this.state.sectionSelectedData?.map((selectedSection) => selectedSection.id) : 
        [];

    let endpoint = 
      sectionSelectedIds ?
      configJSON.getServiceListDropDownEndpoint + `&filter_by[section_ids][]=${sortCondition(sectionSelectedIds[0] == -1,this.state.dropdownOptions.section.map(item => item.id).join(','),sectionSelectedIds)}` :
      configJSON.getServiceListDropDownEndpoint

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(message.id, message);
  }
  getProductList = (searchQuery: string) => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem('token'),
    };

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductListApiId = message.messageId;
    
    const sectionSelectedIds = 
      this.state.sectionSelectedData?.length ? 
        this.state.sectionSelectedData?.map((selectedSection) => selectedSection.id) : 
        [];

    let filterBySectionIds = 
      sectionSelectedIds ? `&filter_by[section_ids][]=${sortCondition(sectionSelectedIds[0] == -1,this.state.dropdownOptions.section.map(item => item.id).join(','),sectionSelectedIds)}` : "";

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductListDropDownEndpoint + `&page_no=${this.state.productNextPage}&filter_by[query]=${searchQuery}${filterBySectionIds}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(message.id, message);
  }

  getGalleryIconsList = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGalleryIconsListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGalleryIconsListDropDownEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  checkSelectAllText = (getValue: {id: string | number, option: string}[]) => {
    this.setState({ selectUpchargeProductForAdd: getValue })
  };

  handleUpchargeClickOnSelectAll = () => {
    this.setState({isSelectedAll: true, selectUpchargeProductForAdd: []})
  };

  handleUpchargeAutoCompleteChange = (getValue: string) => {
    this.setState({ autoCopmpleteValue: getValue, productNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      this.getProductList(getValue)
    }
  }

  debouncedFunction = debounce(
    (newInputValue: string) =>
      this.handleUpchargeAutoCompleteChange(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleUpchargeEmptyAutoSelectValue = () => {
    this.setState({ autoCopmpleteValue: "" })
  };

  handleUpchargeProductScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentProductData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.getProductList("")
      })
    }
  };

  handleUpchargeSelectOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    if (option.id == "-1") {
      if(this.state.isSelectedAll) {
        this.setState({ isSelectedAll: false, selectUpchargeProductForAdd: [], upchargeSelectedOptionForList: [] });
      } else {
        this.setState({
          isSelectedAll: true,
          selectUpchargeProductForAdd: [{ id: "-1", option: "Select All" }],
          upchargeSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.upchargeProductListDropdown.map((item) => { return { id: item.id, option: item.attributes.product_name } })]
        });
      }
      return;
    }

    const isContained = value.some((item) => item.id == option.id)
    
    if (isContained) {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ selectUpchargeProductForAdd: value.filter((item) => item.id != option.id), upchargeSelectedOptionForList: value.filter((item) => item.id != option.id), isSelectedAll: false }, () => setFieldValue('product', value.filter((item) => item.id != option.id).map((item) => item.id)))
    } else {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ selectUpchargeProductForAdd: [...value, option], upchargeSelectedOptionForList: [...value, option], isSelectedAll: false }, () => setFieldValue('product', [...value, option].map((item) => item.id)))
    }
  };
  handleSelectServiceOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {}
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isServiceSelectAll ?
        {
          ...updateStateData,
          isServiceSelectAll: false,
          serviceSelectedData: [],
          selectedServicesForList: []
        }
        :
        {
          ...updateStateData,
          isServiceSelectAll: true,
          serviceSelectedData: [{ id: "-1", option: "Select All" }],
          selectedServicesForList: [{ id: "-1", option: "Select All" }, ...this.state.dropdownOptions.service]
        }
      fieldValue = updateStateData.isServiceSelectAll ? ["All"] : []
    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(newItems, this.state.dropdownOptions.service, 'isServiceSelectAll', 'serviceSelectedData', 'selectedServicesForList')
      fieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("service", fieldValue)
    })
  };
  handleSelectSectionOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {
    }
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { ...updateStateData, isSectionSelectAll: false, sectionSelectedData: [], selectedSectionsForList: [] }
        :
        {
          ...updateStateData,
          isSectionSelectAll: true,
          sectionSelectedData: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.dropdownOptions.section]
        };  
      fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(newItems, this.state.dropdownOptions.section, 'isSectionSelectAll', 'sectionSelectedData', 'selectedSectionsForList')
      fieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      this.setState({
        isServiceSelectAll:false,serviceSelectedData:[],selectedServicesForList:[],
        isSelectedAll: false, selectUpchargeProductForAdd:[],upchargeSelectedOptionForList:[]})
      setFieldValue("section", fieldValue)
      this.getServiceList()
      this.getProductList("")
    })
  };
  // Customizable Area End
}
