import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { makeApiMessage as MakeApiMessage } from "../../../components/src/common";
import { ISearchResult } from "../../advancedsearch/src/AdvancedSearchController";
import { getStorageData } from "../../../framework/src/Utilities";
import { SingleOrder } from "./utils";
import moment from "moment";
import { FormikErrors } from "formik";
import { ICleaningOrder } from "./HomeCleaningOrdersController.web";
import { OrderItemPreferences } from "../../../components/src/Interface";
export interface IPaid {
  id: string;
  name: string;
  currency: string;
  amount: number;
}
export interface IChargeListItem {
  attributes: {
    id: number;
    name: string;
    price: number;
    upcharge_type: string;
    order_price: string;
    name_translation?: string;
    icon: {
      attributes: {
        image: null;
        name: string;
        second_name: string;
        image_type: string;
      };
    };
  };
}

export interface Icatalouge {
  id?: string;
  name?: string;
  product_name: string;
  product_second_name?: string;
  measurement_value: string;
}
export interface IItemData {
  id?: string;
  attributes: {
    id?: number;
    quantity: number;
    unit_price: string;
    price: number | null;
    total_price: number | null;
    category_id: number;
    no_of_pieces: number;
    category_name: string;
    catalogue_id: number;
    upcharge_price: string;
    catalogue_variant_id: number;
    order_management_order_id: number;
    parent_order_qty: number | null;
    parent_catalogue_id: number | null;
    specifications?: {
      [field: string]: string;
    };
    order_statuses: null;
    catalogue: Icatalouge;
    product_image: null;
    preference_id?: number | null;
    preference?: {
      attributes: {
        active: boolean;
        preference_first_name: string;
        preference_second_name: string;
        gallery_icon: {
          id: number;
          image: string;
        };
      };
    };
    service: {
      id?: string;
      attributes: {
        name: string;
        active: boolean;
        second_name: string;
        order_number: number;
        online_order: boolean;
        icon: {
          data: {
            attributes: {
              id: number;
              name: string;
              image: string;
              second_name: string;
              image_type: string;
            };
          };
        };
      };
    };
    upcharge_list_ids: Array<IChargeListItem | never>;
    height?: string;
    width?: string;
    weight?: string;
    order_item_preferences: Array<OrderItemPreferences>;
  };
  isDestroy?: boolean;
  isNew?: boolean;
}

export interface ICarpAndFurnItemData {
  id?: string;
  type:string;
  attributes: {
    id: number;
    home_cleaning_order_id: number;
    quantity: number;
    home_cleaning_catalogue_id: number;
    home_cleaning_catalogue_type_id: number;
    notes: string;
    height: string;
    width: string;
    weight: number | null;
    updated_by_driver: boolean;
    unit_price: string;
    total_price: string;
    catalogue: {
        id: number;
        name: string;
        second_name: string;
        measurement_type: string[];
        name_translation: string;
        product_image: string;
        product_name: string;
        product_second_name: string;
    };
    product_type: {
        id: number;
        name: string;
        second_name: string;
        price: string;
        name_translation: string;
    };
  };
}

interface ICarpFurnPaid {
  amount: string;
  id: number;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  name_translation: string;
  attributes: {
      icon: {
          data: {
              id: number;
              type: string;
              attributes: {
                  id: number;
                  name: string;
                  second_name: string;
                  image_type: string;
                  name_translation: string;
                  image: string;
              };
          };
      };
  };
}


export interface ICarpAndFurnMetaData {
  total_items: number;
  paid: ICarpFurnPaid[];
  total: string;
}
export interface IOrderData {
  id: string;
  type?: string;
  checked?: boolean;
  attributes: {
    id: number;
    order_number: string;
    account_id: number;
    notes: string;
    created_at: string;
    internal_status: string;
    credit_note: boolean;
    credit_order_at: string;
    total_tax: string
    sub_total: string;
    tax_percentage: string
    total_items: number;
    total_pieces: number;
    paid_at: Date | null;
    customer_complaint: string | null;
    is_quick_drop: boolean;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      customer_profile?: {
        street_address: null;
        customer_id: string;
        business_label: null;
        full_address: string;
      };
    };
    invoice_receipt_pdf_url: string | null;
    send_to_zatca?: boolean;
    paid: Array<IPaid>;
    order_transactions: Array<IPaid>;
    currency: string;
    total: string;
    is_mobile?: boolean;
    status_plant?: string | null;
    status?: string;
    adjustment_reason?: null;
    adjustment_note?: null;
    store_management_id?: number;
    adjustment_request?: null;
    adjustment_order?: boolean;
    placed_at?: string;
    applied_discount?: string;
    order_items: {
      name: string;
      qty: number;
      parent_item_qty?: number;
      second_name?: string;
      service_short_name?: string;
      unit_price?: string;
      total_price?: string;
    }[];
    store_name?: string;
    next_plant_status?: {
      display_name: string;
      status: string;
    };
    plant_status_display_name?: null;
    rewash_order: boolean;
    promo_code?: null;
    parent_order_id: number | null;
    price_list_name?: null;
    parent_order: {
      created_at: string;
      order_number: string;
    } | null;
  };
}

export interface ICatalogueItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    product_name: string;
    product_second_name: string;
  };
}

export interface IServiceItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    icon: string;
    name: string;
    name_translation: string;
  };
}

export interface IPreferenceItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

export interface ICategoryItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    section_name: string;
  };
}
export interface IOrderMeta {
  total: string;
  total_items: number;
  total_pieces: number;
  paid: Array<IPaid | never>;
  credit_order_number: number | null;
}

interface IProductAddId {
  id: number | string;
  messageId: number | string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  storeId?: string;
  onClose: Function;
  onMoveToStore?: Function;
  onRefetchOrderList?: Function;
  orderData: IOrderData | ISearchResult | ICleaningOrder;
  isNewOrderDetailsModal?:boolean
  isEditPermission?: boolean;
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  isEdit: boolean;
  isEdited: boolean;
  errorMessage: string;
  hideReceived: boolean;
  successMessage: string;
  openSuccessPopup: boolean;
  orderMeta: IOrderMeta;
  categoryItemList: Array<ICategoryItem>;
  orderItemsList: Array<IItemData | never>;
  carpAndFurnOrderItemsList: Array<ICarpAndFurnItemData | never>;
  carpAndFurnOrderMeta: ICarpAndFurnMetaData;
  selectedOrderItems: Array<number | string>;
  serviceItemList: Array<IServiceItem | never>;
  preferenceItemList: Array<IPreferenceItem | never>;
  isQtyError: boolean;
  qtyErrorMsg: string;
  // Customizable Area End
}
interface SS {}

// Customizable Area Start

export const configJSON = require("./config.js");
const ApiUrls = configJSON.DetailsPage.ApiUrls;

// Customizable Area End

class OrderDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  editOrderMessageId: string = "";
  serviceListMessageId: string = "";
  preferenceListMessageId: string = "";
  orderDetailsMessageId: string = "";
  carpetAndFurnorderDetailsMessageId: string = "";
  categoryListMessageId: string = "";
  catalogueListMessageId: string = "";
  receivedAddProductCallCount: number = 0;
  orderDetailsReceivedMessageId: string = "";
  addProductApiCallId: Array<IProductAddId | never> = [];

  // Customizable Area End

  state = {
    // Customizable Area Start
    isEdited: false,
    isEdit: false,
    errorMessage: "",
    successMessage: "",
    hideReceived: false,
    openSuccessPopup: false,
    orderItemsList: [],
    serviceItemList: [],
    categoryItemList: [],
    selectedOrderItems: [],
    preferenceItemList: [],
    orderMeta: {
      paid: [],
      total: "",
      total_items: 0,
      total_pieces: 0,
      credit_order_number: null
    },
    isQtyError: false,
    qtyErrorMsg: "",
    carpAndFurnOrderItemsList: [],
    carpAndFurnOrderMeta: {
      paid: [],
      total: "",
      total_items: 0,
    },
    // Customizable Area End
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start

      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    const requestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    // Customizable Area Start
    this.handleServiceListResponse(requestId, successResponse);
    this.handlePreferenceListResponse(requestId, successResponse);
    this.handleOrderUpdateResponse(requestId, successResponse);
    this.handelAddProductResponse(requestId, successResponse);
    this.handleOrderItemsResponse(requestId, successResponse);
    this.handleCarpAndFurnOrderItemsResponse(requestId, successResponse);
    this.handleCategoryResponse(requestId, successResponse);
    this.handleReceiveResponse(requestId, successResponse);
    // Customizable Area End
  }

  componentDidMount(): any {
    super.componentDidMount();
    // Customizable Area Start
    this.getOrderItemDetailsApi();
    this.getCategoryList();
    this.setState({ isEdited: false });
    if(this.props.isNewOrderDetailsModal){
      this.getCarpAndFurnOrderItemDetailsApi();
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleCategoryResponse(
    requestId: string,
    response: { data?: Array<ICategoryItem> }
  ) {
    if (requestId === this.categoryListMessageId) {
      if (response?.data) {
        this.setState({ categoryItemList: response.data });
      }
    }
  }

  handleOrderUpdateResponse(
    requestId: string,
    response: {
      data: SingleOrder;
      errors: string;
    }
  ) {
    if (requestId === this.editOrderMessageId) {
      if (response.data) {
        this.onReset();
        this.getOrderItemDetailsApi();
        this.setState({ openSuccessPopup: true, isEdited: true });
        this.props.onRefetchOrderList && this.props.onRefetchOrderList();
      } else {
        this.setState({ errorMessage: response.errors });
      }
    }
  }

  handleServiceListResponse(
    requestId: string,
    response: { data: Array<IServiceItem | never> }
  ) {
    if (this.serviceListMessageId === requestId) {
      this.setState({ serviceItemList: response.data });
    }
  }

  handlePreferenceListResponse(
    requestId: string,
    response: { data: Array<IPreferenceItem | never> }
  ) {
    if (this.preferenceListMessageId === requestId) {
      this.setState({ preferenceItemList: response.data });
    }
  }

  handleOrderItemsResponse(
    requestId: string,
    response: { data: Array<IItemData | never>; meta: IOrderMeta }
  ) {
    if (this.orderDetailsMessageId === requestId) {
      if (response.data) {
        this.setState({
          orderItemsList: response.data.map((item) => {
            item.isNew = false;
            return item;
          }),
          orderMeta: response?.meta,
        });
      }
    }
  }

  handleCarpAndFurnOrderItemsResponse (
    requestId: string,
    response: { data: Array<ICarpAndFurnItemData | never>; meta: ICarpAndFurnMetaData }
  ) {
    if (this.carpetAndFurnorderDetailsMessageId === requestId) {
      if (response.data) {
        this.setState({
          carpAndFurnOrderItemsList: response.data.map((item) => {
            return item;
          }),
          carpAndFurnOrderMeta: response?.meta,
        });
      }
    }
  }

  handleReceiveResponse(requestId: string, response: { status?: number }) {
    if (this.orderDetailsReceivedMessageId === requestId) {
      if (response?.status === 200) {
        this.setState({ hideReceived: true });
        this.props.onRefetchOrderList && this.props.onRefetchOrderList();
        this.props.onMoveToStore && this.props.onMoveToStore();
        this.props.onClose && this.props.onClose();
      }
    }
  }

  handelAddProductResponse(
    requestId: string,
    response: {
      data: {
        id: string;
        attributes: { catalogue_variants: {data :[{ id: number }] }};
      };
    }
  ) {
    const elements = this.addProductApiCallId.find(
      (item: IProductAddId) => item.messageId === requestId
    );
    if (elements) {
      const list = this.state.orderItemsList.map((item: IItemData) => {
        if (item.id == elements?.id) {
          this.receivedAddProductCallCount++;
          item.id = undefined;
          item.attributes.id = undefined;
          item.attributes.catalogue_id = Number(response.data.id);
          item.attributes.catalogue_variant_id =
            response.data.attributes.catalogue_variants.data[0].id;
        }
        return item;
      });
      this.setState({ orderItemsList: list }, () => {
        this.addProductApiCallId.length === this.receivedAddProductCallCount &&
          this.onUpdateOrder();
      });
    }
  }

  getOrderItemDetailsApi() {
    const requestMessage = MakeApiMessage({
      url: ApiUrls.getOrderItemsList + this.props.orderData.id,
      method: "GET",
    });
    this.orderDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCarpAndFurnOrderItemDetailsApi () {
    const requestMessage = MakeApiMessage({
      url: ApiUrls.getHomeCleaningOrderItemList + this.props.orderData.id,
      method: "GET",
    });
    this.carpetAndFurnorderDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  updateOrderToReceived() {
    if(!Number(this.props.storeId)) {
      this.setState({ errorMessage: "Please select a store" });
      return;
    }

    const requestMessage = MakeApiMessage({
      url: ApiUrls.updateToReceived,
      method: "PATCH",
      body: JSON.stringify({
        order_ids: [this.props.orderData.id],
        store_id: this.props.storeId,
      }),
    });
    this.orderDetailsReceivedMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getType(index: number, totalCells: number = 0) {
    const { isEdit } = this.state;
    if (index === 0) {
      return isEdit ? "none" : "left";
    } else if (index === 1 && isEdit) {
      return "left";
    } else if (index === totalCells + (isEdit ? 1 : 0)) {
      return "right";
    } else {
      return "middle";
    }
  }

  getColumns(columns: number, haveSpecs: boolean, header: boolean = false) {
    const { isEdit } = this.state;
    const screenWidth = window.innerWidth;
  
    let text, headerColumns;
    if (screenWidth <= 1024) {
      text = Array.from({ length: columns - 1 }, (_blank, item) => item + 1).map(
        () => "120px"
      );
      text[1] = "60px";
      text[2] = "60px";
      headerColumns = header ? " 160px" : " 144px";
    } else {
      text = Array.from({ length: columns - 1 }, (_blank, item) => item + 1).map(
        () => "150px"
      );
      text[1] = "80px";
      text[2] = "80px";
      headerColumns = header ? " 200px" : " 184px";
    }
  
    const editText = isEdit ? "34px " : "";
    const suffix = haveSpecs ? headerColumns : " 1fr";
    return editText + text.join(" ") + suffix;
  }

  onEdit() {
    this.setState({ isEdit: true });
    this.getServiceDropDownApi();
    this.getPreferencesDropDownApi();
  }

  onAddProduct(array: Array<IItemData>, setValue: Function) {
    const list = JSON.parse(JSON.stringify(array));
    let idBasedOnTime = new Date().getTime();

    const emptyItem: IItemData = {
      id: String(idBasedOnTime),
      attributes: {
        id: idBasedOnTime,
        quantity: 0,
        unit_price: "",
        total_price: null,
        price: null,
        upcharge_price: "",
        catalogue_id: 1,
        category_name: "",
        catalogue_variant_id: 1,
        order_management_order_id: 1,
        parent_order_qty: null,
        parent_catalogue_id: null,
        no_of_pieces: 0,
        specifications: {},
        order_statuses: null,
        category_id: -1,
        catalogue: {
          product_name: "",
          measurement_value: "",
        },
        product_image: null,
        preference: {
          attributes: {
            active: false,
            preference_first_name: "",
            preference_second_name: "",
            gallery_icon: {
              id: 1,
              image: "",
            },
          },
        },
        service: {
          attributes: {
            name: "",
            active: false,
            second_name: "",
            order_number: 1,
            online_order: false,
            icon: {
              data: {
                attributes: {
                  id: 1,
                  name: "",
                  image: "",
                  second_name: "",
                  image_type: "",
                },
              },
            },
          },
        },
        upcharge_list_ids: [],
        order_item_preferences: []
      },
      isNew: true,
    };

    list.push(emptyItem);
    setValue(list, false);
  }

  async onChangeQuantityOrPcs(
    values: { items: IItemData[] },
    itemId: string,
    quantity: number | null,
    pcsValue: number | null,
    setValue: Function
  ) {
    const { items } = values;
    const isRewashOrder = (this.props.orderData as IOrderData).attributes
      .rewash_order;

    const array = items.map((item: IItemData) => {
      if (item.id == itemId) {
        if (
          isRewashOrder &&
          quantity &&
          quantity > (item.attributes.parent_order_qty as number)
        ) {
          this.setState({
            isQtyError: true,
            qtyErrorMsg: "Please enter less or equal then parent order",
          });
        } else {
          quantity && (item.attributes.quantity = quantity);
          pcsValue && (item.attributes.no_of_pieces = pcsValue);
          this.setState({
            isQtyError: false,
            qtyErrorMsg: "",
          });
        }
      }
      return item;
    });

    setValue(array);
  }

  getServiceDropDownApi() {
    const message = MakeApiMessage({
      url: ApiUrls.getServiceList,
      method: "GET",
    });
    this.serviceListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getPreferencesDropDownApi() {
    const message = MakeApiMessage({
      url: ApiUrls.getPreferencesList,
      method: "GET",
    });
    this.preferenceListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  async onChangeCatalogue(
    values: IItemData[],
    itemId: string,
    value: string,
    setValue: Function
  ) {
    const array = values.map((item: IItemData) => {
      if (item.id == itemId) {
        item.attributes.catalogue = {
          measurement_value: "",
          product_name: value,
        };
      }
      return item;
    });

    setValue(array);
  }

  onChangeService(
    values: IItemData[],
    itemId: string,
    value: string,
    setValue: Function
  ) {
    const option: IServiceItem = this.state.serviceItemList.filter(
      (item: IServiceItem) => item.id === value
    )[0];

    const array = values.map((item) => {
      if (item.id == itemId) {
        item.attributes.service = {
          id: option.id,
          attributes: {
            name: option.attributes.name,
            active: false,
            second_name: option.attributes.name_translation,
            order_number: -1,
            online_order: false,
            icon: {
              data: {
                attributes: {
                  id: -1,
                  name: "",
                  image: option.attributes.icon,
                  second_name: "",
                  image_type: "",
                },
              },
            },
          },
        };
      }
      return item;
    });

    setValue(array);
  }

  onChangePreferences(
    values: IItemData[],
    itemId: string,
    value: string,
    setValue: Function
  ) {
    const option: IPreferenceItem = this.state.preferenceItemList.filter(
      (item: IPreferenceItem) => item.id === value
    )[0];

    const array = values.map((item) => {
      if (item.id == itemId) {
        item.attributes.preference_id = option.attributes.id
      }
      return item;
    });

    setValue(array);
  }

  onChangeCategory(
    values: IItemData[],
    itemId: string,
    value: string,
    setValue: Function
  ) {
    const option: ICategoryItem = this.state.categoryItemList.filter(
      (item: IServiceItem) => item.id == value
    )[0];

    const array = values.map((item: IItemData) => {
      item.id == itemId && (item.attributes.category_id = option.attributes.id);
      return item;
    });

    setValue(array);
  }

  onChangePrice(
    values: IItemData[],
    itemId: string,
    value: number,
    setValue: Function
  ) {
    const array = values.map((item: IItemData) => {
      if (item.id == itemId) {
        item.attributes.total_price = value;
      }
      return item;
    });

    setValue(array);
  }

  handelCloseSnackbar() {
    this.setState({ errorMessage: "" });
  }

  getOrderBody(deleteOrder: boolean = false) {
    const { orderItemsList } = this.state;

    const orderBody = {
      edit_order: true,
      data: {
        is_quick_drop: false,
        store_management_id: this.props.storeId || undefined,
        customer_id: this.props.orderData.attributes.customer.id,
        order_items_attributes: orderItemsList
          .filter((item: IItemData) => {
            return (
              item.attributes.catalogue_id &&
              item.attributes.catalogue_variant_id
            );
          })
          .map((item: IItemData) => {
            const other = deleteOrder
              ? {}
              : {
                  quantity: item.attributes.quantity,
                  service_id: item.attributes.service.id,
                  category_id: item.attributes.category_id,
                  catalogue_id: item.attributes.catalogue_id,
                  no_of_pieces: item.attributes.no_of_pieces,
                  catalogue_variant_id: item.attributes.catalogue_variant_id,
                  preference_id: item.attributes.preference_id
                };
            return {
              ...other,
              id: item.id,
              _destroy: item.isDestroy,
            };
          }),
      },
    };
    return orderBody;
  }

  onUpdateOrder(deleteOrder: boolean = false) {
    const message = MakeApiMessage({
      url: ApiUrls.updateOrder + this.props.orderData.attributes.id,
      body: JSON.stringify(this.getOrderBody(deleteOrder)),
      method: "PUT",
    });

    this.editOrderMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  onAddProductApi() {
    const list = this.state.orderItemsList.filter((item: IItemData) =>
      Boolean(item.isNew)
    );

    list.forEach((item: IItemData) => {
      const { catalogue } = item.attributes;
      const message = MakeApiMessage({
        method: "POST",
        url: ApiUrls.createProduct,
        body: JSON.stringify({
          data: {
            attributes: {
              product_name: catalogue.product_name,
              store_management_id: this.props.storeId,
              category_ids: [item.attributes.category_id],
              product_second_name: catalogue.product_name,
              customer_id: this.props.orderData.attributes.customer.id,
              catalogue_variants_attributes: [
                {
                  catalogues_services_attributes: [
                    {
                      price: item.attributes.total_price,
                      service_id: item.attributes.service.id,
                    },
                  ],
                },
              ],
            },
          },
        }),
      });

      this.addProductApiCallId.push({
        id: item.id as string,
        messageId: message.messageId,
      });
      runEngine.sendMessage(message.id, message);
    });
  }

  async getCategoryList() {
    let token = await getStorageData("token");
    const message = MakeApiMessage({
      url: ApiUrls.getCategoryList + token,
      method: "GET",
    });

    this.categoryListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getCategoryNameById(itemId: number) {
    let item: ICategoryItem | undefined = this.state.categoryItemList.find(
      (item: ICategoryItem) => item.id === String(itemId)
    );
    return item ? (item as ICategoryItem)?.attributes?.name : "--";
  }

  onSelectUnSelectAll(select: boolean) {
    let list: Array<number | string | never> = [];
    if (select) {
      list = this.state.orderItemsList
        .filter((item: IItemData) => !item.isNew)
        .map((item: IItemData) => item.id) as Array<number | string>;
    }
    this.setState({ selectedOrderItems: list });
  }

  getSelectableList() {
    return this.state.orderItemsList.filter((item: IItemData) => !item.isNew);
  }

  onSelectOrderItem(itemId: number | string) {
    const { selectedOrderItems } = this.state;
    let list = JSON.parse(JSON.stringify(selectedOrderItems));
    if (list.includes(itemId as never)) {
      list = list.filter((item: number) => item != itemId);
    } else {
      list.push(itemId);
    }
    this.setState({ selectedOrderItems: list });
  }

  onReset() {
    this.setState({
      isEdit: false,
      selectedOrderItems: [],
    });
  }

  showEditOrderButton(order: IOrderData) {
    switch (order.attributes.internal_status) {
      case "coming_to_store":
        return true;
      case "in_store":
        return true;
      default:
        return false;
    }
  }

  isEditEnable() {
    const isShowEditButton =
      this.showEditOrderButton(this.props.orderData as IOrderData) &&
      !(this.props.orderData as IOrderData | ISearchResult).attributes.send_to_zatca
    return isShowEditButton;
  }

  handelDelete() {
    const { selectedOrderItems, orderItemsList } = this.state;
    if (selectedOrderItems.length == 0) {
      this.setState({ errorMessage: "Please select order items!" });
      return;
    }

    if (selectedOrderItems.length == orderItemsList.length) {
      this.setState({ errorMessage: "You can't delete all items" });
      return;
    }

    let list = orderItemsList.map((item: IItemData) => {
      if (selectedOrderItems.includes(item.id as never)) {
        item.isDestroy = true;
      }
      return item;
    });
    this.setState(
      {
        orderItemsList: list,
        successMessage: "Order items are successfully deleted!",
      },
      () => this.onUpdateOrder(true)
    );
  }

  handleOrderDetailForm = (items: Array<IItemData | never>) => {
    this.setState(
      { orderItemsList: items },
      () => this.handelSave()
    )
  }

  handelSave() {
    const needsToAddProducts =
      this.state.orderItemsList.filter((item: IItemData) => Boolean(item.isNew))
        .length > 0;

    this.setState({ successMessage: "Order is updated successfully" });

    const priceError =
      this.state.orderItemsList
        .filter((item: IItemData) => Boolean(item.isNew))
        .filter((item: IItemData) => Number(item.attributes.total_price) <= 0)
        .length > 0;

    if (priceError) {
      this.setState({ errorMessage: "Price should be grater zero!" });
      return;
    }

    if (needsToAddProducts) {
      this.onAddProductApi();
    } else {
      this.onUpdateOrder();
    }
  }

  getReceiveButtonStatus() {
    const { internal_status } = this.props.orderData?.attributes || {};
    const { hideReceived } = this.state;
    return (
      internal_status === "coming_to_store" &&
      this.props.storeId &&
      !hideReceived
    );
  }

  onCloseSuccessPopup() {
    this.setState({ openSuccessPopup: false });
  }

  formatCreatedDate = (created_at: string) => {
    return moment(created_at).format("DD/MM/YYYY")
  }

  getFormattedString(label: string) {
    return label.toString().split("_").join(" ");
  }

  getTypeOfOrderItemTable(index: number) {
    if (index === 0) return "left";
    else if (index === 6) return "right";
    else return "middle";
  }  

  getUniqueSpecs() {
    const { orderItemsList } = this.state;
    const allKeys: string[] = [];
    orderItemsList.forEach((item: IItemData) => {
      item.attributes.order_item_preferences.forEach((innerItem) => {
        if (innerItem.attributes.specifications) {
          Object.keys(innerItem.attributes.specifications).forEach(
            (keyName) => {
              allKeys.push(keyName);
            }
          );
        }
      });
    });

    return [...new Set(allKeys)];
  }

  getStyleForTableHeaderForEdit(index: number) {
    const { isEdit } = this.state;
    return isEdit && index === 0 ? "plr-0" : "";
  }

  getTruncateValue(value: string) {
    let text = String(value);
    return text.length > 10 ? text.substring(0, 7) + "..." : text;
  }

  getSelectPlaceholder(
    value: string,
    placeholder: string,
    list: Array<ICatalogueItem | IServiceItem>
  ) {
    let text = String(
      list.find((item) => item.id === value)?.attributes?.name || placeholder
    );
    return this.getTruncateValue(text);
  }
  
  getConditionBased(
    condition: boolean,
    trueValue: unknown,
    falseValue: unknown
  ) {
    return condition ? trueValue : falseValue;
  }

  getErrors(errors: FormikErrors<IItemData>) {
    let messageSelect = [];
    let messageEnter = [];
    messageSelect.push(errors?.attributes?.category_id);
    messageSelect.push(errors?.attributes?.preference_id);
    messageSelect.push(errors?.attributes?.service?.attributes?.name);
    messageEnter.push(errors?.attributes?.catalogue?.product_name);
    messageEnter.push(errors?.attributes?.no_of_pieces);
    messageEnter.push(errors?.attributes?.total_price);
    messageEnter.push(errors?.attributes?.quantity);
    messageEnter = messageEnter.filter((item) => item);
    messageSelect = messageSelect.filter((item) => item);
    let textSelect = messageSelect.length > 0 ? messageSelect.join(", ") : "";
    let textEnter = messageEnter.length > 0 ? messageEnter.join(", ") : "";
    let error = "";
    if (textEnter && !textSelect) error = "Please enter " + textEnter;
    else if (textSelect && !textEnter) error = "Please select " + textSelect;
    else if (textSelect && textEnter)
      error = `Please enter valid ${textEnter} and select ${textSelect}`;

    return error;
  }
  // Customizable Area End
}

export default OrderDetailsController;
