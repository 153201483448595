import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import moment from "moment";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { makeApiMessage } from "../../../components/src/common";
import { IFilter } from "../../../components/src/FilterPopover";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { IMyUser,PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import {
  CartsItemsPreferencesData,
  CreatePreferencesData,
  IAdjustCartProduct,
  IadjustSpecification,
  IpreferenceData,
  SelectedService,
  StoreManagementDetails,
  UniqueCombination,
  UpdateOrderBody,
  productTypes,
} from "./utils";
import {
  CatalogueList as CatalogueDataList,
  Service as ServiceData,
  IItemData,
  IlaundryOrder,
  CustomerSpecification,
  OrderItemPreferences
} from "../../../components/src/Interface";
import {
  PlantPermissionStatus,
  checkForPlantPermissonStatus,
  customPermissionApiKey,
  debounce,
} from "../../../blocks/utilities/src/CustomBlockHelpers";

import { CustomEnums, getCustomEnumName } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { isNumber, toString } from "lodash"
import qzTray from "qz-tray";
import { IFormValues } from "../../../blocks/OrderCreation/src/OrderCreation2Controller.web";
import { SelectedPreferenceData } from "../../../blocks/ProductDescription/src/EditPreferenceController";

export interface SectionItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    section_name: string;
    name: string;
  };
}
export interface IPaid {
  amount: string;
  id: number;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  name_translation: string;
  attributes: {
    icon: {
      data: {
        id: number;
        attributes: {
          id: number;
          name: string;
          second_name: string;
          image_type: string;
          name_translation: string;
          image: string;
        };
      };
    };
  };
}

interface Meta {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number | undefined;
  pervious_page: number | undefined;
}
export interface ICustomer {
  id: number;
  full_phone_number: string;
  email: string;
  full_name: string;
  customer_profile: {
    street_address: string;
    customer_id: string;
    business_label: string;
    full_address: string;
  };
}

export interface IOrderItem {
  name: string;
  qty: number;
  second_name: string;
  service_short_name: string;
  id: string;
  height: string;
  width: string;
  weight: string;
  attributes: {
    catalogue: {
      id: number;
      name: string;
      product_name: string;
      product_second_name: string;
    };
    notes: string;
    quantity: number;
    no_of_pieces: number;
    unit_price: string;
    total_price: string;
    tag_numbers: string[];
    tag_image: {tag_no: string; id: number; url: string; label_string?: string}[];
    order_item_preferences: Array<OrderItemPreferences>;
  };
}
export interface IDataItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    adjustment_reason: string | null;
    adjustment_note: string | null;
    credit_order_at:null,
    notes: string;
    customer_complaint: string;
    total: string;
    paid_at: string;
    created_at: Date;
    placed_at: Date;
    currency: string;
    account_id: number;
    paid: Array<IPaid>;
    store_name: string;
    is_mobile: boolean;
    customer: ICustomer;
    total_items: number;
    order_number: string;
    total_pieces: number;
    no_of_items?: number;
    no_of_pieces?: number;
    is_quick_drop: boolean;
    internal_status: string;
    sub_total: string;
    total_tax: string;
    credit_note?: boolean;
    refund_amount: string;
    tax_percentage: string;
    applied_discount: string;
    order_items: Array<IOrderItem | never>;
    status: string;
    status_plant: string;
    parent_order: {
      created_at: string;
      order_number: string;
    } | null;
  };
}

export interface IAdjustOrderItem {
  id: string;
  attributes: {
    id: number;
    order_management_order_id: number;
    quantity: number;
    catalogue_id: number;
    catalogue_variant_id: number;
    customer_preference_id: null;
    preference_id: null;
    service_id: number;
    parent_catalogue_id: null;
    specifications: null;
    category_id: number;
    no_of_pieces: number;
    no_of_tags: number;
    tag_numbers: number[];
    notes: null;
    actual_price: null;
    actual_total_price: null;
    parent_order_item_id: null;
    unit_price: string;
    total_price: string;
    upcharge_price: string;
    catalogue: {
      id: number;
      name: string;
      product_name: string;
      product_second_name: string;
    };
    product_image: null;
    preference: null;
    service: {
      id: string;
      attributes: {
        id: number;
        name: string;
        icon_id: number;
        active: boolean;
        short_name: string;
        second_name: string;
        order_number: number;
        online_order: boolean;
        icon: {
          data: {
            attributes: {
              id: number;
              name: string;
              image: string;
              image_type: string;
              second_name: string;
              name_translation: string;
            };
          };
        };
        name_translation: string;
      };
    };
    parent_order_qty: null;
  };
}

export interface IAdjustOrder {
  id: number;
  account_id: number;
  order_number: string;
  sub_total: string;
  total: string;
  status: string;
  price_list_name: string;
  applied_discount: string;
  cancellation_reason: string | null;
  tax_charges: string;
  shipping_charge: string | null;
  shipping_discount: string | null;
  shipping_total: string | null;
  total_tax: string;
  save_for_future: boolean;
  notes: string;
  promo_code_id: string | null;
  store_management_id: number;
  is_quick_drop: boolean;
  address_id: number | null;
  is_mobile: boolean;
  paid_at: string;
  created_at: string;
  refund_amount: string;
  rewash_order: boolean;
  send_to_zatca: boolean;
  adjustment_note: string;
  internal_status: string;
  adjustment_reason: string;
  adjustment_order: boolean;
  parent_order_id: string | null;
  delivery_address: string | null;
  customer_complaint: string | null;
  adjustment_request: string | null;
  tax_included_in_product_price: boolean;
  order_items: Array<IAdjustOrderItem | never>;
  customer: {
    id: number;
    email: string;
    business: string;
    full_name: string;
    full_address: string;
    full_phone_number: string;
  };
  order_transactions: {
    id: string;
    attributes: {
      id: number;
      account_id: number;
      order_management_order_id: number;
      amount: string;
      payment_method: {
        id: number;
        name: string;
        active: boolean;
        second_name: string;
        name_translation: string;
        attributes: {
          icon: {
            data: {
              id: number;
              attributes: {
                id: number;
                name: string;
                image: string;
                image_type: string;
                second_name: string;
                name_translation: string;
              };
            };
          };
        };
      };
      currency: string;
    };
  }[];
  order_created_by: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
  };
  currency: string;
  store_name: string;
  no_of_items: number;
  no_of_pieces: number;
  tax_percentage: string;
  promo_code?: {
    id: number;
    currency: string;
    discount: string;
    expiry_date: null;
    promo_code: string;
    description: string;
    saved_amount: string;
    max_discount: string;
    promo_expires: boolean;
    min_order_amount: string;
  };
  translated_store_name: string;
  store_second_name: string | null;
  store_management: {
    data: {
      attributes: { id: number; store_name: string };
    };
  };
  invoice_pdf_url: string | null;
  total_upcharge_amount: string;
  driver_account: {
    id: number;
    email: string;
    full_name: string;
    full_phone_number: string;
  };
}

interface IFilterOmitValue extends Omit<IFilter, "value"> {
  value?: string | string[] | Date[] | null | [undefined, undefined];
}
export interface IFilterItem extends Omit<IFilterOmitValue, "apiKey"> {
  apiKey?: string | string[];
}
export interface IOption {
  id: number;
  label: string;
}
export interface IUpChargeItem {
  id: number;
  name: string;
  icon: string;
  price: string;
  checked: boolean;
  currency: string;
  isUpdated?: boolean;
  upcharge_type: string;
  name_translation: string;
}

export interface IPreference {
  id: number;
  name: string;
  icon: string;
  checked: boolean;
  name_translation: string;
}

export interface ISpecOption {
  id: number;
  label: string;
  selected: boolean;
}

export interface ISpecification {
  id: number;
  name: string;
  name_translation: string;
  options_attributes: ISpecOption[];
}

export interface ICustomerSpecs {
  id: string;
  attributes: {
    service: {
      id: string;
      attributes: {
        id: number;
        name: string;
        icon: string;
      };
    };
    product: {
      id: string;
      attributes: {
        id: 972;
        name: string;
      };
    };
    catalogue: {
      id: number;
      attributes: {
        id: number;
        name: string;
        product_name: string;
        product_second_name: string;
      };
    };
    preferences: Array<IPreference | never>;
    specifications: Array<ISpecification | never>;
    upcharge_lists: Array<IUpChargeItem | never>;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  plantSection: string | undefined;
  selectedDate: Date | null | string | undefined;
  searchQuery: string;
  searchTextQuery: string;
  sortingQuery: string;
  isLoadingOrderItems: boolean;
  isScanningOrder: boolean;
  isLoadingSharedFolder: boolean;
  orderItems: Array<IOrderItem | never>;
  modalOrderItems: Array<IOrderItem | never>;
  itemsToPrint: Array<IOrderItem>;
  orderData: IDataItem | null;
  selectedOrders: Array<number | never>;
  selectedOption: string | null;
  selectAllStoreId:
   {id:string,option:string} | undefined;
  orderList: Array<IDataItem | never>;
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
  };
  sortingData: ISortingData;
  snackbarMessage: string;
  selectedBranches: Array<number>;
  branchList: Array<IOption>;
  selectedTab: number;
  isPermittedPage: boolean;
  adjustmentList: Array<IOption | never>;
  filters: IFilterItem[];
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  suggestionFieldTitle: string;
  isPrintShow: boolean;
  showResendButton: boolean;
  selectedAdjustmentReason: string | undefined;
  adjustmentComment: string;
  adjustmentError: boolean;
  adjustMentErrorModal: boolean;
  isDrawerOpen: boolean;
  isPlantLoading: boolean;
  isOrderListLoading: boolean;
  changeProductModal: boolean;
  adjustmentOrderModal: boolean;
  adjustmentRequestType?: string;
  adjustmentRequestTypeSub: string;
  editOrderPopupFromAdjustment: boolean;
  reviewOrderPopupFromAdjustment: boolean;
  adjustOrder: IlaundryOrder | null;
  adjustOrderItems: IItemData[];
  isCreditNoteDialogOpen: boolean;
  adjustmentReasonId: string;
  taxAmount: string;
  adjustmentAmount: string;
  addNote: string;
  isConfirmationChecked: boolean;
  cartTotalOpen: boolean;
  adjustmentCancelOrderModal: boolean;
  adjustmentSelectUpChargeModal: boolean;
  adjustmentCancelOrderMessage: string;
  orignalOrderId: number | null;
  userData: IMyUser | null;
  cataloguesDataList: CatalogueDataList[];
  cataloguesListMeta: Meta;
  tabsId: number;
  regionId: number | null
  currentPage: number;
  adjustCartProduct: Array<IAdjustCartProduct>;
  preferenceData: IpreferenceData | null;
  preferenceModalVisible: boolean;
  selectedService: SelectedService | null;
  orderUpChargePreference: Array<ICustomerSpecs | never>;
  sameProductDifferentServiceModalOpen: boolean;
  isIncreaseBtnClicked: boolean;
  clickedProductCardInfo: IAdjustCartProduct | null;
  originalAdjustOrderId: string;
  subProductModal: boolean;
  clickedParentCatalougeId: number | null;
  creditNoteOrder?: IDataItem;
  creditNotePresent?: boolean;
  hidden: boolean;
  creditLoader: boolean;
  creditNoteReceipt: string;
  adjustmentOrignalOrderNumber: string;
  cartsItemsPreferencesData: CartsItemsPreferencesData[];
  storeManagementDetails: StoreManagementDetails | null;

  productPopup: boolean;
  carpetFurnitureProductPopupData: CatalogueDataList | null;
  selectedCatalogueData: CatalogueDataList | null;
  palntPermissionStatus: PlantPermissionStatus;
  isPopuDisabledWithOrderCreate: boolean;

  searchCatalogue: string;
  isCatalogueCardLoading: boolean;
  catalogueListEmptyFlag: boolean;
  customerPriceListId: number | null;
  snackBarSeverity: 'error' | 'success';
  sectionList: { attributes: { section_name: string; id: number } }[];
  creditNoteOrderNumber: number | null;
  openCrediNoteModalLoader: boolean;

  isAutoSend: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class Cfplantdashboard2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeListMessageId: string = "";
  plantOrderListMessageId: string = "";
  updateOrderListMessageId: string = "";
  getOrderItemsListMessageId: string = "";
  adjustmentReasonListMessageId: string = "";
  filterOrderSuggestionApiCallId: string = "";
  filterCustomerSuggestionApiCallId: string = "";
  getCreateNewOrderApiCallID: string = "";
  updateOrderApiCallID: string = "";
  getCatalogueListAPICallID: string = "";
  getStoreManagementDetailsAPICallID: string = "";
  getPreferencesDataApiCallId: string = "";
  createPreferencesApicallId: string = "";
  scanOrderApiCallId: string = "";
  sendOrderToClanApiCallId: string = "";
  getSectionListId: string = "";

  ADJUSTMENT_TYPES = {
    ADD_QUANTITY: configJSON.adjustmentPopup_add_quantity,
    MINUS_QUANTITY: configJSON.adjustmentPopup_minus_quantity,
    ADD_NEW_PRODUCT: configJSON.adjustmentPopup_add_new_product,
    CHANGE_PRODUCT: configJSON.adjustmentPopup_change_product,
    REMOVE_PRODUCT: configJSON.adjustmentPopup_remove_product,
    ADD_UPCHARGE: configJSON.adjustmentPopup_add_upcharge,
    CHANGE_UPCHARGE: configJSON.adjustmentPopup_change_upcharge,
    REMOVE_UPCHARGE: configJSON.adjustmentPopup_remove_upcharge,
    FULL_UNDONE: configJSON.adjustmentPopup_full_undone,
    PARTIAL_UNDONE: configJSON.adjustmentPopup_partial_undone,
    CANCEL_ORDER: configJSON.editOrderPopup_text_cancel,
  };

  getUpdateOrderMessageId = "";
  getAdjustOrderMessageId = "";
  getCreditNoteMessageId = "";
  adjustOrderConfirmMessageId = "";
  checkForOrderCanceledMessageId = "";
  getAdjustOrderItemMessageId = "";
  getCreateCreditNoteMessageId = "";
  getUpdateUpChargePreferenceMessageId = "";
  getAdjustmentCancelOrFullUndoneMessageId = "";
  getSharedFolderMessageId = "";
  getFilesForAutoSendMessageId = "";

  shouldPrintTag = false;
  disableToggleAutoSend = false;
  disableGetFiles = false;
  autoSendFiles: ReturnType<typeof setInterval> | undefined = undefined
  isMoreFilesToSend = false;
  filesWrittenByResend = new Set<string>();
  filesWrittenByAutosend = new Set<string>();
  updateInFilesAfterResendCallId = "";
  updateInFilesAfterAutoSendCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getCustomEnumName(CustomEnums.DataForRedirectionFromNotification),
      // Customizable Area End
    ];

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleScanOrderResponse(message);
    this.handleGetFilesForAutoSendResponse(message);
    this.handleGetSharedFolderDataResponse(message);
    this.handleUpdateInFilesResponse(message);
    this.handleUpChargeOrder(message);
    this.handelStoreListApiResponse(message);
    this.handleUpChargeForPreference(message);
    this.handlePlatOrderListApiResponse(message);
    this.handelAdjustmentListApiResponse(message);
    this.handleFilterOrderSuggestionResp(message);
    this.handleAdjustOrderGetApiResponse(message);
    this.handlePlatOrderUpdateApiResponse(message);
    this.handelGetOrderItemListApiResponse(message);
    this.handleFilterCustomerSuggestionResp(message);
    this.handleCreateNewOrderResp(message);
    this.handleCatalougeListResp(message);
    this.handlePreferencesListResp(message);
    this.handleUpdateOrderResp(message);
    this.handleConfirmOrderResponse(message);
    this.handleCheckCanceledOrderResponse(message);
    this.handleCreditNoteResponse(message);
    this.handleStoreManagementDetailsResponse(message);
    this.handleSendOrderToCleanResponse(message);
    this.handleSectionListAPIResponse(message);

    if (
      message.id === getCustomEnumName(CustomEnums.DataForRedirectionFromNotification)
    ) {
      const {
        order_number,
        store_management_id
      } = message.properties.orderData;
      this.setState({
        selectedTab: 1, // redirect to receiving_area when click on go to adjustment btn from notification
      }, () => {
        setTimeout(() => this.getOrderList("receiving_area", 1, [store_management_id], order_number), 1000);
      })
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (varUrl: string) => {
        this.setState({ sortingQuery: varUrl });
        this.getOrderList(
          this.getCurrentSelectedTab().apiKey,
          this.state.meta.current_page,
          this.state.selectedBranches,
          this.state.searchQuery,
          varUrl
        );
      },
      onChange: (data: ISortingData) => this.setState({ sortingData: data }),
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ isPlantLoading: true });
    this.setState({
      isDrawerOpen: (await getStorageData("isPinned")) == "true",
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (!this.state.isPlantLoading) {
      if (prevState.isPlantLoading) {
        if (this.isShortingAreaTab() && !this.autoSendFiles) {
          this.toggleAutoSendMode()
        }
      }
      if (prevState.selectedTab !== this.state.selectedTab) {
        if (
          this.isShortingAreaTab()
          || 
          (this.getTabList()[prevState.selectedTab].apiKey === "shorting_area" && prevState.isAutoSend)
        ) {
          this.toggleAutoSendMode()
        }
      }
    }
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    clearInterval(this.autoSendFiles)
  }

  handleFilterChange = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
      },
      () => this.getOrderList(this.getCurrentSelectedTab().apiKey, 1)
    );
  };

  handelAdjustmentListApiResponse(message: Message) {
    if (
      this.adjustmentReasonListMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (response?.data) {
        this.setState({ adjustmentList: response.data });
      }
    }
  }

  handelGetOrderItemListApiResponse(message: Message) {
    if (
      this.getOrderItemsListMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const newOrderItems = this.handleNoTagsOrdersItems(response.data)

      this.setState({
        orderItems: newOrderItems,
        modalOrderItems: response.data,
        isLoadingOrderItems: false
      });
      if (this.shouldPrintTag) {
        this.handlePrintTag(newOrderItems);
      }
      this.shouldPrintTag = false
    }
  }

  handelStoreListApiResponse(message: Message) {
    if (
      this.storeListMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        branchList: response.data
          .map((item: { attributes: unknown }) => item.attributes)
          .map(
            ({
              id: varId,
              store_name,
            }: {
              id: number;
              store_name: string;
            }) => ({
              id: varId,
              label: store_name,
            })
          ),
        selectedBranches: this.state.selectedBranches.length > 0 
        ? this.state.selectedBranches : 
        response.data
          .map((item: { attributes: unknown }) => item.attributes)
          .map(
            ({
              id: varId,
            }: {
              id: number;
            }) => Number(varId)
          ),
        selectAllStoreId: { id: "-1", option: "Select All" }

      },()=>  this.onUserChange());
    }
  }

  handlePlatOrderListApiResponse(message: Message) {
    if (
      this.plantOrderListMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      response?.data &&
        this.setState({
          orderList: response.data as Array<IDataItem | never>,
          meta: response.meta,
          isPlantLoading: false,
          isOrderListLoading: false
        });

      this.setState({
        selectedOption: null,
        selectedOrders: [],
        showResendButton: response?.data?.[0]?.attributes?.status_plant === "shorting_area"
      });
    }
  }

  handlePlatOrderUpdateApiResponse(message: Message) {
    if (
      this.updateOrderListMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (response.status === 200) {
        this.onCloseDetailsModal();
        this.getOrderList(this.getCurrentSelectedTab().apiKey)
      }
    }
  }

  getStoreList(regionId?:number) {
    const message = makeApiMessage({
      method: "GET",
      url: configJSON.getStoreListEndPoint + `&allow_access=true`,
    });

    this.storeListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getOrderList(
    status = "at_store",
    page = this.state.meta.current_page,
    branches = this.state.selectedBranches,
    query = this.state.searchTextQuery,
    sortingQuery = this.state.sortingQuery
  ) {
    this.setState({isOrderListLoading: true})
    const { plantSection } = this.state;
    const varSort = sortingQuery ? "&" + sortingQuery : "";
    const queryForSearch = query ? `&filter_by[query]=${query}` : "";

    const orderLabel = this.state.filters.find(
      (item: IFilterItem) => item.title === "Order ID"
    )?.value;
    const orderOptions = this.state.filters.find(
      (item: IFilterItem) => item.title === "Order ID"
    )?.options;
    const order = orderOptions?.find(
      (item: { label: string }) => item.label == orderLabel
    );
    const order_id = order ? order?.value : "";

    const customerLabel = this.state.filters.find(
      (item: IFilterItem) => item.title === "Customer"
    )?.value;
    const customerOptions = this.state.filters.find(
      (item: IFilterItem) => item.title === "Customer"
    )?.options;
    const customer = customerOptions?.find(
      (item: { label: string }) => item.label == customerLabel
    );
    const customer_id = customer ? customer?.value : "";

    const Date = this.state.filters.find(
      (item: { title: string }) => item.title === "Date"
    )?.value;

    let sectionQuery: string = "";
    const sectionFilter = this.state.filters.find(
      (item) => item.title === "Section"
    );
    if (sectionFilter && sectionFilter.value && sectionFilter.value.length) {
      sectionQuery = (sectionFilter.value as string[]).reduce(
        (param: string, varc: string) => param + "&filter_by[section_ids][]=" + varc,
        ""
      );
    }

    const plantListUrl =
      configJSON.plantOrderListEndPoint +
      (plantSection || status) +
      `&page_no=${page}&per_page=100&store_ids=${branches
        .filter((index) => index)
        .join(",")}${queryForSearch}${varSort}${sectionQuery}` +
      (order_id ? `&filter_by[order_id]=${order_id}` : "") +
      (customer_id ? `&filter_by[customer_id]=${customer_id}` : "") +
      (Date ? `&filter_by[order_date]=${Date}` : "");
    const message = makeApiMessage({
      method: "GET",
      url: plantListUrl,
    });

    this.plantOrderListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleSearchText = (query:string) => {
    if(!this.state.isPermittedPage){
      this.setState({searchTextQuery:query})
      this.getOrderList(
        this.getCurrentSelectedTab().apiKey,
        1,
        this.state.selectedBranches,
        query
      )
    }    
  }

  getOrderItemsList(varId: string) {
    const message = makeApiMessage({
      method: "GET",
      url: configJSON.getOrderItemsList + varId,
    });

    this.setState({
      orderItems: [],
      modalOrderItems: [],
      isLoadingOrderItems: true
    });
    this.getOrderItemsListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getAdjustmentReasonList() {
    const message = makeApiMessage({
      method: "GET",
      url: configJSON.getAdjustmentReasonList,
    });

    this.adjustmentReasonListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  onChangeMultiSelect(event:{ id: string, option: string }) {
    const { branchList, selectedBranches } = this.state;
    const isSelectAll = selectedBranches.length === branchList.length;
    const idLists1 = JSON.parse(JSON.stringify(String(event.id)));
    const idLists = JSON.parse(JSON.stringify((event.id)));
    const allIds = branchList.map((varItem: IOption) => varItem.id);
    let selectedArrayIds = [];
    if (idLists1.includes("-1")) {
      if (!isSelectAll) {
        selectedArrayIds = allIds
        this.setState({selectAllStoreId: event})
      }else{
        this.setState({selectAllStoreId: undefined})
      }
    } else {
      this.setState({selectAllStoreId: undefined})
      if(this.state.selectedBranches.includes(idLists)){
        selectedArrayIds = this.state.selectedBranches.filter(id => id !== idLists);
      }else{
        selectedArrayIds.push(...this.state.selectedBranches,idLists)
        if(selectedArrayIds.length == branchList.length){
          this.setState({selectAllStoreId: { id: "-1", option: "Select All" }})
        }
      }
    }

    this.setState({
      selectedBranches: selectedArrayIds,
    });

    this.setState({ selectedOption: null, selectedOrders: [] });
    this.getOrderList(this.getCurrentSelectedTab().apiKey, 1, selectedArrayIds);
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
        700    
    );

  getCurrentSelectedTab() {
    const { selectedTab } = this.state;
    return this.getTabList()[selectedTab];
  }

  getNextPlantActionTab() {
    const { selectedTab } = this.state;
    let tabId;
    if (selectedTab === 5 || selectedTab === 6) {
      tabId = 0;
    } else {
      tabId = selectedTab + 1;
    }
    return this.getTabList()[tabId];
  }

  getTabList(isTabs: boolean = false) {
    const { plantSection,palntPermissionStatus } = this.state;

    const sections = [
      {
        label: configJSON.stringsListPage_tab_store,
        apiKey: "at_store",
        tabId: configJSON.store_tab_id,
        dropId: 0,
        showDropItems: [],
        permitted: palntPermissionStatus.storeAccessPermission,
        actionPermitted: palntPermissionStatus.storeActionPermission
      },
      {
        label: configJSON.stringsListPage_tab_receivingArea,
        apiKey: "receiving_area",
        tabId: configJSON.receiving_area_tab_id,
        dropId: 1,
        showDropItems: [
          configJSON.store_tab_id,
          configJSON.shorting_area_tab_id,
          configJSON.loading_area_tab_id,
          configJSON.dispatched_tab_id,
          configJSON.adjustment_tab_id,
        ],
        permitted: palntPermissionStatus.receivingAreaAccessPermission,
        actionPermitted: palntPermissionStatus.receivingAreaActionPermission
      },      
      {
        label: configJSON.stringsListPage_tab_sortingArea,
        apiKey: "shorting_area",
        tabId: configJSON.shorting_area_tab_id,
        dropId: 2,
        showDropItems: [
          configJSON.store_tab_id,
          configJSON.receiving_area_tab_id,
          configJSON.loading_area_tab_id,
          configJSON.dispatched_tab_id
        ],
        permitted: palntPermissionStatus.shortingAreaAccessPermission,
        actionPermitted: palntPermissionStatus.shortingAreaActionPermission
      },
      {
        label: configJSON.stringsListPage_tab_loadingArea,
        apiKey: "loading_area",
        tabId: configJSON.loading_area_tab_id,
        dropId: 3,
        showDropItems: [
          configJSON.store_tab_id,
          configJSON.receiving_area_tab_id,
          configJSON.shorting_area_tab_id,
          configJSON.dispatched_tab_id
        ],
        permitted: palntPermissionStatus.loadingAreaAccessPermission,
        actionPermitted: palntPermissionStatus.loadingAreaActionPermission
      },
      {
        label: configJSON.stringsListPage_tab_dispatched,
        apiKey: "dispatched",
        tabId: configJSON.dispatched_tab_id,
        dropId: 4,
        showDropItems: [
          configJSON.store_tab_id,
          configJSON.receiving_area_tab_id,
          configJSON.shorting_area_tab_id,
          configJSON.loading_area_tab_id,
        ],
        permitted: palntPermissionStatus.dispatchedAccessPermission,
        actionPermitted: palntPermissionStatus.dispatchedActionPermission
      },
      {
        label: configJSON.stringsListPage_tab_adjustment,
        apiKey: "adjustment",
        tabId: configJSON.adjustment_tab_id,
        dropId: 5,
        showDropItems: [
          configJSON.receiving_area_tab_id
        ],
        permitted: palntPermissionStatus.adjustmentAccessPermission,
        actionPermitted: palntPermissionStatus.adjustmentActionPermission
      },
    ];

    return plantSection && isTabs
      ? sections.filter((item) => item.apiKey === plantSection)
      : sections;
  }

  onChangeTabs(index: number) {
    const { plantSection } = this.state;
    const tabId =
      plantSection == ""
        ? index
        : this.handlePlantSectionTab(plantSection as string);
    this.setState({
      selectedTab: tabId,
      selectedOption: null,
      selectedOrders: [],
    });
    this.getOrderList(this.getTabList()[index].apiKey);
  }

  isAdjustmentTabLoad() {
    return (
      this.getCurrentSelectedTab().apiKey == "adjustment" ||
      this.state.plantSection === "adjustment"
    );
  }

  isShortingAreaTab = () => this.getCurrentSelectedTab().apiKey === "shorting_area"

  onSelectAllOrders() {
    const { orderList, selectedOrders } = this.state;
    const isSelectedAll = orderList.length === selectedOrders.length;
    if (isSelectedAll) {
      this.setState({ selectedOrders: [] });
    } else {
      this.setState({
        selectedOrders: orderList.map((item: IDataItem) => item.attributes.id),
      });
    }
  }

  isShowNextLabel() {
    const isAdjustMentPlant = this.state.plantSection === "adjustment";
    if (
      this.getCurrentSelectedTab().tabId == 7 ||
      this.getCurrentSelectedTab().tabId == 6 ||
      isAdjustMentPlant
    ) {
      return false;
    } else {
      return true;
    }
  }

  onToggleOrderSelection(varId: number) {
    let array: number[] = JSON.parse(JSON.stringify(this.state.selectedOrders));
    if (array.includes(varId)) {
      array = array.filter((item: number) => item != varId);
    } else array.push(varId);

    this.setState({ selectedOrders: array });
  }

  onUpdateAdjustmentOrders() {
    const { selectedOrders, selectedOption } = this.state;

    const adjustmentBody = JSON.stringify({
      status: selectedOption,
      order_ids: selectedOrders,
      adjustment_reason: this.state.selectedAdjustmentReason,
      adjustment_note: this.state.adjustmentComment,
    });

    const message = makeApiMessage({
      url: configJSON.plantOrderUpdateEndPoint,
      method: "PATCH",
      body: adjustmentBody,
    });

    this.updateOrderListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleAdustmentOrderStatus() {
    const { selectedOrders } = this.state;

    if (selectedOrders.length > 1) {
      this.setState({ adjustMentErrorModal: true });
    } else {
      const order = this.state.orderList.find(
        (item: IDataItem) => item.attributes.id === selectedOrders[0]
      );
      this.setState({ orderData: order as IDataItem });
      this.getOrderItemsList(selectedOrders[0].toString());
    }
  }

  onUpdateOrders() {
    const { selectedOrders, selectedOption } = this.state;

    if(selectedOrders.length > 0) {
      const body = JSON.stringify({
        status: selectedOption,
        order_ids: selectedOrders,
      });
  
      const isAdjustment = selectedOption === "adjustment";
  
      if (isAdjustment) {
        this.handleAdustmentOrderStatus();
        this.getAdjustmentReasonList();
      } else {
        const message = makeApiMessage({
          url: configJSON.plantOrderUpdateEndPoint,
          method: "PATCH",
          body: body,
        });
  
        this.updateOrderListMessageId = message.messageId;
        runEngine.sendMessage(message.id, message);
      }
    }else{
      this.setState({snackbarMessage: "Please select the order",  snackBarSeverity: 'error'})
    }

  }

  onChangePage(page: number) {
    this.getOrderList(this.getCurrentSelectedTab().apiKey, page);
  }

  onCancel() {
    this.setState({ selectedOption: null, selectedOrders: [] });
  }

  onOpenOrderDetails(order: IDataItem) {
    this.setState({ orderData: order });
    this.getOrderItemsList(order.id);
    this.getAdjustmentReasonList();
  }

  onChangeSelectedOption(value: string) {
    this.setState({ selectedOption: value, selectedOrders: [] });
  }

  onChangeSelectedAdjustmentReason(value: string) {
    let label = this.state.adjustmentList.find(
      (reason: IOption) => reason.id === Number(value)
    )?.label;
    this.setState({
      selectedAdjustmentReason: label as string,
      adjustmentError: false,
    });
  }

  onUserChange(storeId?: number) {
        this.setState(
      {
        selectedOrders: [],
        selectedOption: null,
      },
      () => {
        const permittedSections =  this.getTabList().filter(section => section.permitted);
        console.log(this.getTabList(),permittedSections,"permittedSectionspermittedSections")
        if(permittedSections.length > 0){
          this.getOrderList(permittedSections[0].apiKey, 1, this.state.selectedBranches);
          this.setState({selectedTab: permittedSections[0].dropId})
        }else{
          this.setState({isPermittedPage: true})
        }
      }
    );
  }

  onCloseSnackBar() {
    this.setState({ snackbarMessage: "" });
  }

  onCloseDetailsModal() {
    this.setState({
      orderData: null,
      selectedAdjustmentReason: "",
      adjustmentComment: "",
      selectedOrders: [],
      selectedOption: "",
      isPrintShow: false,
      itemsToPrint: []
    });
  }

  onClickOptionSelect() {
    if (this.state.selectedBranches.length === 0)
      this.setState({
        snackbarMessage: configJSON.stringsListPage_pleaseSelectStores,
      });
  }

  getBorderSelectDisable() {
    return (
      this.state.selectedBranches.length === 0 ||
      this.state.orderList.length === 0
    );
  }

  getBorderSelectRenderValue(item: unknown) {
    return item && item !== "metal_progetti"
      ? this.getTabList().find(
          ({ apiKey }: { apiKey: string }) => apiKey === item
        )?.label
      : configJSON.stringsListPage_updateOrder;
  }

  getConditionBased(flag: boolean, trueValue: unknown, falseValue: unknown) {
    return flag ? trueValue : falseValue;
  }

  getRenderValueOfReason(item: unknown) {
    let label = this.state.adjustmentList.find(
      (reason: IOption) => reason.id === (item as number)
    )?.label;
    return item ? (label as string) : configJSON.stringsListPage_table_select;
  }

  onChangeAdjustmentComment(value: string) {
    this.setState({ adjustmentComment: value });
  }

  handleSubmitAdjustment(varId: number) {
    const { selectedAdjustmentReason } = this.state;
    if (selectedAdjustmentReason != "") {
      const array = [];
      array.push(varId);
      this.setState(
        { selectedOption: "adjustment", selectedOrders: array },
        () => this.onUpdateAdjustmentOrders()
      );
    } else {
      this.setState({ adjustmentError: true });
    }
  }

  moveToNextPlantStatus(varId: number) {
    const array = [];
    array.push(varId);
    this.setState(
      {
        selectedOption: this.getNextPlantActionTab().apiKey,
        selectedOrders: array,
        isPrintShow: false,
        itemsToPrint: []
      },
      () => this.onUpdateOrders()
    );
  }

  getTableRowColumns() {
    const screenWidth = window.innerWidth;
    const extra = this.state.selectedOption ? "45px" : "";
    const currentTabId = this.getCurrentSelectedTab().tabId;

    const mainBodyConfigs = [
      
      { maxWidth: 1024, config: "150px 150px 150px 210px 100px 100px" },
      { maxWidth: 1280, config: "minmax(180px,1.8fr) 180px 180px 250px 120px 120px" },
      { maxWidth: 1366, config: "minmax(200px,1.8fr) 200px 200px 270px 130px 130px" },
      { maxWidth: Infinity, config: "1fr 1fr 1fr 1fr 1fr 1fr" }
    ];

    const adjustmentTabConfigs = [
      { maxWidth: 1024, config: "150px 100px" },
      { maxWidth: 1280, config: "170px 120px" },
      { maxWidth: 1366, config: "180px 130px" },
      { maxWidth: Infinity, config: "190px 190px" }
    ];
    //@ts-ignore
    const getConfig = (configs) => configs.find(cfg => screenWidth <= cfg.maxWidth).config;

    const mainBody = getConfig(mainBodyConfigs);
    const isAdjustmentTab = (currentTabId === 6 || currentTabId === 7) ? getConfig(adjustmentTabConfigs) : "";

    return `${extra} ${mainBody} ${isAdjustmentTab}`.trim();
  }

  handleOpenedChange(open: boolean) {
    this.setState({ isDrawerOpen: open });
  }

  getTableRowClassesForOrderDetails = () => "tableRow" + (this.state.isPrintShow ? " extendedTableRow" : "")

  getTypeOfOrderItemTable(index: number) {
    if (index === 0) return "left";
    else if (index === 6) return "right";
    else return "middle";
  }

  getTypeOfAdjustmentOrderItemTable(index: number) {
    if (index === 0) return "left";
    else if (index === 7) return "right";
    else return "middle";
  }

  getTypeOfOrderTable(index: number) {
    if (
      this.getCurrentSelectedTab().tabId == 7 ||
      this.getCurrentSelectedTab().tabId == 6
    ) {
      return this.getTypeOfAdjustmentOrderItemTable(index);
    } else {
      return this.getTypeOfOrderItemTable(index);
    }
  }

  handlePlantFilterAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilterItem) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let ApiUrl;
    if (title === "Order ID") {
      ApiUrl = configJSON.plantOrderFilterSuggestionEndPoint;
    } else if (title === "Customer") {
      ApiUrl = configJSON.plantCustomerFilterSuggestionEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });

    if (title === "Order ID") {
      this.filterOrderSuggestionApiCallId = requestMessage.messageId;
    } else if (title === "Customer") {
      this.filterCustomerSuggestionApiCallId = requestMessage.messageId;
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setFilterSuggestionList(list: { label: string; value: string }[]) {
    const updatedFilters = this.state.filters.map((item: IFilterItem) => {
      if (item.value !== "") {
        if (item.title === this.state.suggestionFieldTitle) item.options = list;
        return item;
      } else {
        if (item.title === this.state.suggestionFieldTitle) item.options = [];
        return item;
      }
    });

    this.setState({ filters: updatedFilters });
  }

  handleFilterOrderSuggestionResp(message: Message) {
    if (
      this.filterOrderSuggestionApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const list =
        response?.filter_names?.map(
          (item: { order_number: string | number; id: string | number }) => {
            return {
              label: item.order_number,
              value: item.id,
            };
          }
        ) || [];
      this.setFilterSuggestionList(list);
    }
  }

  handleFilterCustomerSuggestionResp(message: Message) {
    if (
      this.filterCustomerSuggestionApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const list =
        response?.filter_names?.map(
          (item: { name: string; id: string | number }) => {
            return {
              label: item.name,
              value: item.id,
            };
          }
        ) || [];
      this.setFilterSuggestionList(list);
    }
  }

  handleCreateNewOrderResp(message: Message) {
    if (
      this.getCreateNewOrderApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      {
        const response = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response) {
          this.setState(
            {
              adjustmentOrderModal: false,
              editOrderPopupFromAdjustment: true,
              orignalOrderId: this.state.adjustOrder?.id as number,
              adjustmentOrignalOrderNumber: this.state.adjustOrder
                ?.order_number as string,
              adjustOrder: response.data.attributes,
              adjustOrderItems: response.data.attributes.order_items.sort(
                (a: IItemData, b: IItemData) => Number(a.id) - Number(b.id)
              ),
              adjustCartProduct: this.handleAdjustCartProduct(
                response.data.attributes.order_items
              ),
              customerPriceListId: response.data.attributes.price_list_id
            },
            () => this.getStoreManagementBaseOnOrder()
          );
        }
      }
    }
  }

  handleUpdateOrderResp(message: Message) {
    if (
      this.updateOrderApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      {
        const response = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (
          response &&
          !response?.errors &&
          !response?.error
        ) {
          this.setState({
            adjustOrder: response.data.attributes,
            adjustOrderItems: response.data.attributes.order_items.sort(
              (a: IItemData, b: IItemData) => Number(a.id) - Number(b.id)
            ),
            adjustCartProduct: this.handleAdjustCartProduct(
              response.data.attributes.order_items
            ),
          });
        } else if(typeof response?.errors == "string") {
          alert(response.errors);
          // when getting error while adding product into cart then remove cartitem item with id undefined
          const filterAdjustCartProducts = this.state.adjustCartProduct.filter((cartProduct) => cartProduct.id !== undefined)
          this.setState({
            adjustCartProduct: filterAdjustCartProducts,
            cartsItemsPreferencesData: []
          });
        }
      }
    }
  }

  handleCatalougeListResp(message: Message) {
    if (
      this.getCatalogueListAPICallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      {
        const response = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && !response.error) {
          this.setState((prevState) => ({
            cataloguesDataList: this.state.currentPage > 1
            ? [...prevState.cataloguesDataList, ...response.data]
            : [...response.data],
            catalogueListEmptyFlag: response.data.length ? false : true,
            cataloguesListMeta: response.meta,
            isCatalogueCardLoading: false,
          }));
        }
      }
    }
  }

  handlePreferencesListResp(message: Message) {
    if (
      this.getPreferencesDataApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      {
        const response = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response) {
          this.setState({
            preferenceData: response.data,
            cartsItemsPreferencesData: [
              ...this.state.cartsItemsPreferencesData,
              {
                catalogue_variant_id:
                  response.data.attributes.catalogue_variant_id,
                catalogue_preference_id: response.data.attributes.id,
                service_id: response.data.attributes.service.id,
              },
            ],
          }, () => this.handleDisanledPopupAPIcall());
        }
      }
    }
  };

  handleDisanledPopupAPIcall = () => {
    const { isPopuDisabledWithOrderCreate } = this.state;
    if(isPopuDisabledWithOrderCreate) {
      this.handleUpdateOrderAPIcall(null)
    }
  };

  handlePlantSectionTab(plantSection: string) {
    const currentTab = this.getTabList().filter(
      (item) => item.apiKey === plantSection
    );
    return currentTab[0].dropId;
  }

  handlePlantUser(plantSection: string) {
    this.setState(
      {
        plantSection: plantSection,
        selectedTab: this.handlePlantSectionTab(plantSection),
      },
      () => {
        this.getOrderList();
      }
    );
  }

  isSelectAllToPrint = () => {
    const {itemsToPrint, orderItems} = this.state
    return itemsToPrint.length > 0 && itemsToPrint.length === orderItems.filter(orderItem => orderItem.attributes.tag_image.length).length
  }

  handleClickPrint(isContinue?: boolean) {
    if (isContinue && this.state.isPrintShow) {
      this.handlePrintTag(this.state.itemsToPrint)
      return;
    }
    this.setState(({ isPrintShow: Boolean(isContinue), itemsToPrint: [] }));
  }

  handleSelectItemToPrint = (orderItem: IOrderItem) => {
    this.setState((prev) => ({itemsToPrint: 
      prev.itemsToPrint.some(printItem => printItem === orderItem) ?
      prev.itemsToPrint.filter(printItem => printItem !== orderItem)
      :
      [...prev.itemsToPrint, orderItem]
    }))
  }

  handleSelectAllItemsToPrint = () => {
    this.setState((prev) => {
      const allTagItems = prev.orderItems.filter(orderItem => orderItem.attributes.tag_image.length)
      return ({
          itemsToPrint: prev.itemsToPrint.length === allTagItems.length ? [] : allTagItems
      })})
  }

  hanldeClickPrintCreditNote = () => {
    const {creditNoteReceipt} = this.state
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: "pdf",
      data: creditNoteReceipt
    })
    this.send(printMessage)
  }

  handleCancelAdjustOrderPopup() {
    this.setState({ adjustMentErrorModal: false });
  }

  handleFilterAnchor(event?: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.setState({ 
      filterAnchor: event?.currentTarget 
    }, () => {
      if(this.state.sectionList.length === 0) {
        this.getSectionList();
      }
    });
  }

  handleAutocompleteFilterChange(title: string, value: string) {
    this.setState({ suggestionFieldTitle: title });
    this.handlePlantFilterAutoComplete(title, value);
  }

  handleClearFilter() {
    let updated = this.state.filters.map((item: IFilterItem) => {
      if (item.title === "Date") item.value = null;
      else if (item.title === "Order ID" || item.title === "Customer") {
        item.options = [];
        item.value = "";
      } else item.value = "";
      return item;
    });
    this.setState({
      filters: updated,
      selectedDate: null,
    });
    setTimeout(() => this.handleFilterChange(updated as IFilter[]), 1000);
  }

  clearFilter() {
    const updated = this.state.filters.map((item: IFilterItem) => {
      if (item.title === "Date") item.value = "";
      else if (item.title === "Order ID") item.options = [];
      else if (item.title === "Customer") item.options = [];
      return item;
    });
    this.setState({ filters: updated, selectedDate: null });
  }

  handleDateInput(varDate: Date) {
    const updated = this.state.filters.map((item: IFilterItem) => {
      if (item.title === "Date")
        item.value = moment(varDate).format("YYYY/MM/DD");
      return item;
    });
    this.setState({
      filters: updated,
      selectedDate: moment(varDate).format("YYYY/MM/DD"),
    });
  }

  getMomentFormat(dateStr: Date | string) {
    return moment(dateStr).format("DD/MM/YYYY");
  }

  handleOnUserChange(userContext: IUserContext) {
    const apiKey = customPermissionApiKey.plantManagement;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForPlantPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);

    this.setState({
      userData: userContext?.user as IMyUser,
      palntPermissionStatus: value
    });

    const storeId = (userContext.user as IMyUser).attributes.employee_proifle.data.attributes.store_management.store_id as number;

    const storeIds = (userContext.user as IMyUser).attributes.employee_proifle.data.attributes.store_management_ids;

    this.setState({
      tabsId: userContext?.user?.attributes?.employee_proifle?.data?.attributes
        ?.store_management?.attributes?.sections?.[0]?.id as number,
      selectedBranches: storeIds,
      selectAllStoreId: storeIds.length > 0 ? { id: "-1", option: "Select All" } : undefined
    },()=> this.getStoreList());
  }

  getAdjustmentRequestType() {
    const { adjustmentRequestTypeSub, adjustmentRequestType } = this.state;
    const changeProduct =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.CHANGE_PRODUCT;
    const removeProduct =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.REMOVE_PRODUCT;
    const increaseQuantity =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.ADD_QUANTITY;
    const decreaseQuantity =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.MINUS_QUANTITY;
    const addProduct =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.ADD_NEW_PRODUCT;
    const addUpcharge =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.ADD_UPCHARGE;
    const removeUpcharge =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.REMOVE_UPCHARGE;
    const changeUpcharge =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.CHANGE_UPCHARGE;
    const fullUndone =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.FULL_UNDONE;
    const partialUndone =
      adjustmentRequestTypeSub == this.ADJUSTMENT_TYPES.PARTIAL_UNDONE;
    const cancelOrder =
      this.ADJUSTMENT_TYPES.CANCEL_ORDER == adjustmentRequestType;

    return {
      addProduct,
      fullUndone,
      cancelOrder,
      addUpcharge,
      changeProduct,
      removeProduct,
      partialUndone,
      removeUpcharge,
      changeUpcharge,
      increaseQuantity,
      decreaseQuantity,
    };
  }

  getAdjustEditOrderTitle() {
    const { adjustmentRequestType, adjustmentRequestTypeSub } = this.state;

    if (adjustmentRequestType == this.ADJUSTMENT_TYPES.CANCEL_ORDER)
      return configJSON.editOrderPopup_title_cancel_order;

    switch (adjustmentRequestTypeSub) {
      case this.ADJUSTMENT_TYPES.ADD_QUANTITY:
        return configJSON.editOrderPopup_title_increase_quantity;
      case this.ADJUSTMENT_TYPES.MINUS_QUANTITY:
        return configJSON.editOrderPopup_title_decrease_quantity;
      case this.ADJUSTMENT_TYPES.ADD_NEW_PRODUCT:
        return configJSON.editOrderPopup_title_add_new_product;
      case this.ADJUSTMENT_TYPES.CHANGE_PRODUCT:
        return configJSON.editOrderPopup_title_change_product;
      case this.ADJUSTMENT_TYPES.REMOVE_PRODUCT:
        return configJSON.editOrderPopup_title_remove_product;
      case this.ADJUSTMENT_TYPES.ADD_UPCHARGE:
        return configJSON.editOrderPopup_title_add_upcharge;
      case this.ADJUSTMENT_TYPES.CHANGE_UPCHARGE:
        return configJSON.editOrderPopup_title_edit_upcharge;
      case this.ADJUSTMENT_TYPES.REMOVE_UPCHARGE:
        return configJSON.editOrderPopup_title_remove_upcharge;
      case this.ADJUSTMENT_TYPES.FULL_UNDONE:
        return configJSON.editOrderPopup_title_full_undone;
      case this.ADJUSTMENT_TYPES.PARTIAL_UNDONE:
        return configJSON.editOrderPopup_title_partial_undone;
      default:
        return configJSON.editOrderPopup_title_edit_order;
    }
  }

  getUpChargeButtonTitle() {
    const { addUpcharge, changeUpcharge, removeUpcharge } =
      this.getAdjustmentRequestType();

    if (addUpcharge) return configJSON.editOrderPopup_button_add;
    else if (removeUpcharge) return configJSON.editOrderPopup_button_remove;
    else if (changeUpcharge) return configJSON.editOrderPopup_button_change;
    else return configJSON.editOrderPopup_button_edit;
  }

  handleChangeAdjustmentRequestType(value: string) {
    this.setState({
      adjustmentRequestType: value,
      adjustmentRequestTypeSub: "",
    });
  }

  handleChangeAdjustmentRequestTypeSub(value: string) {
    this.setState({ adjustmentRequestTypeSub: value });
  }

  handleAdjustOrderPopupFlag(flag: boolean) {
    const { adjustmentSelectUpChargeModal } = this.state;
    if (!adjustmentSelectUpChargeModal) {
      this.setState({
        adjustmentRequestType: "",
        adjustmentOrderModal: flag,
        adjustmentRequestTypeSub: "",
      });
    } else {
      this.setState({
        editOrderPopupFromAdjustment: true,
        adjustmentSelectUpChargeModal: false,
      });
    }
  }

  handleCloseReviewOrder() {
    this.setState({
      editOrderPopupFromAdjustment: true,
      reviewOrderPopupFromAdjustment: false,
    });
  }

  handleCloseCancelOrderPopup() {
    this.setState({
      adjustmentCancelOrderMessage: "",
      adjustmentCancelOrderModal: false,
    });
  }

  handleCreateNewDummyOrder() {
    const {
      originalAdjustOrderId,
      adjustmentRequestType,
      adjustmentRequestTypeSub,
    } = this.state;
    const body = {
      order_id: originalAdjustOrderId,
      adjustment_reason: adjustmentRequestTypeSub,
      adjustment_request: adjustmentRequestType,
    };
    let message = makeApiMessage({
      url: configJSON.getCreateNewOrderEndpoint,
      body: JSON.stringify(body),
      method: "POST",
    });
    this.getCreateNewOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleContinueAdjustOrder() {
    const { fullUndone, cancelOrder } = this.getAdjustmentRequestType();
    if (fullUndone || cancelOrder) {
      this.setState({
        adjustmentOrderModal: false,
        editOrderPopupFromAdjustment: true,
      });
    } else {
      this.handleCreateNewDummyOrder();
    }
  }

  handleBackEditOrder() {
    this.setState({
      adjustmentOrderModal: true,
      editOrderPopupFromAdjustment: false,
    });
  }

  handleContinueEditOrder() {
    const { fullUndone, cancelOrder } = this.getAdjustmentRequestType();

    if (fullUndone || cancelOrder) {
      const body = cancelOrder
        ? {
            request: "Cancel",
            reason: "Cancel",
          }
        : { request: "Full Undone", reason: "Undone" };

      this.updateAdjustOrder(body);
      this.setState({ editOrderPopupFromAdjustment: false });
    } else {
      this.handleCanceledOrderApiCall();
    }
  }

  handleChangeItemInEditOrder() {
    this.setState({
      changeProductModal: true,
      editOrderPopupFromAdjustment: false,
    });
  }

  handleBackInChangeItemModal() {
    this.setState({
      changeProductModal: false,
      editOrderPopupFromAdjustment: true,
    });
  }

  handleAddNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ addNote: event.target.value });
  };

  handleConfirmationCheckBox = () => {
    if (!this.state.creditNotePresent)
      this.setState({
        isConfirmationChecked: !this.state.isConfirmationChecked,
      });
  };

  toggleCreditNoteDialog = (item?: IDataItem) => {
    this.setState({
      creditNoteOrder: item,
      creditNotePresent: false,
      creditNoteReceipt: "",
      isCreditNoteDialogOpen: !this.state.isCreditNoteDialogOpen,
      hidden: true,
    });
    item && this.getCreditNoteApi(item.id);
  };

  getOrderById(varId: string) {
    let message = makeApiMessage({
      url: configJSON.getOrderByIdEndpoint + varId,
      method: "GET",
    });
    this.getAdjustOrderMessageId = message.messageId;
    this.send(message);

    message = makeApiMessage({
      url: configJSON.getOrderItemsByIdEndpoint + varId,
      method: "GET",
    });

    this.getAdjustOrderItemMessageId = message.messageId;
    this.send(message);
  }

  handleAdjustOrderGetApiResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!response) return;

    if (this.getAdjustOrderMessageId == requestId) {
      this.setState({ adjustOrder: response.data.attributes }, () => {
        this.handleAdjustOrderPopupFlag(true);
      });
    }

    if (this.getAdjustOrderItemMessageId == requestId)
      this.setState({
        adjustOrderItems: response?.data || [],
      });

    if (this.getAdjustmentCancelOrFullUndoneMessageId == requestId) {
      const { adjustOrder, plantSection } = this.state;
      const { fullUndone, cancelOrder } = this.getAdjustmentRequestType();
      const { order_number } = adjustOrder || { order_number: "" };
      const popupMessage = [
        {
          flag: fullUndone || cancelOrder,
          message: String(configJSON.cancelOrderPopup_message_text).replace(
            "$$$",
            order_number
          ),
        },
      ].find((item) => item.flag);

      this.setState({
        adjustmentCancelOrderModal: true,
        adjustmentCancelOrderMessage: popupMessage?.message || "",
      });

      if (plantSection) this.getOrderList(this.getCurrentSelectedTab().apiKey);
      else this.onChangeTabs(5);
    }
  }

  handleClickAdjustOrder(varId: string) {
    this.setState({ originalAdjustOrderId: varId });
    this.getOrderById(varId);
  }

  handleToggleCartTotal() {
    this.setState((prev) => ({ cartTotalOpen: !prev.cartTotalOpen }));
  }

  getEditOrderPopupConfirmButtonText() {
    const { fullUndone } = this.getAdjustmentRequestType();
    if (fullUndone) return configJSON.editOrderPopup_btn_undone;

    return configJSON.editOrderPopup_btn_continue;
  }

  updateAdjustOrder({ request, reason }: { request: string; reason: string }) {
    const { adjustOrder } = this.state;

    const message = makeApiMessage({
      url: configJSON.cancelOrUndoneAdjustOrderEndpoint,
      method: "PUT",
      body: JSON.stringify({
        adjustment_reason: reason,
        adjustment_request: request,
        order_id: adjustOrder?.id,
      }),
    });

    this.getAdjustmentCancelOrFullUndoneMessageId = message.messageId;
    this.send(message);
  }

  getUpdateOrderBody = (params: UpdateOrderBody | null) => {
    const { adjustOrder, adjustCartProduct, cartsItemsPreferencesData, selectedService } =
      this.state;

    let preferencesParamsData: {
      specifications: unknown;
      upcharge_list_ids: number[];
    };
    if (params) {
      preferencesParamsData = {
        specifications: params.specifications,
        upcharge_list_ids: params.upcharge_list_ids,
      };
    }

    const updateBody = {
      data: {
        customer_id: adjustOrder?.customer.id,
        store_management_id: adjustOrder?.store_management_id,
        order_items_attributes: adjustCartProduct
          .filter((item) => item.id === undefined &&
            item.service_id !== -1 &&
            item.service_id == selectedService?.id
          )
          .map((item) => {
            const isCarpetAndFurnitureProduct = item.isCarpetAndFurnitureProduct;

              let carpetAndFurnitureProductBody: 
              { notes: string | undefined; height: number; width: number; weight: number; } | undefined = undefined;
              if(isCarpetAndFurnitureProduct) {
                carpetAndFurnitureProductBody = {
                  notes: item.notes,
                  weight: Number(item.weight),
                  height: Number(item.height),
                  width: Number(item.width),
                }
              } 
            return {
              catalogue_id: item.catalogue_id,
              catalogue_variant_id: item.catalogue_variant_id,
              category_id: item.category_id,
              quantity: item.quantity,
              customer_preference_id: cartsItemsPreferencesData.find(
                (preferenceitem) => {
                  return (
                    preferenceitem.catalogue_variant_id ==
                      item.catalogue_variant_id &&
                    preferenceitem.service_id == item.service_id
                  );
                }
              )?.catalogue_preference_id,
              service_id: item.service_id,
              preference_id: params!== null ? params.preference_id : item.preference_id,
              ...preferencesParamsData,
              ...carpetAndFurnitureProductBody
            };
          }),
      },
    };

    return updateBody;
  };

  handleUpdateOrderAPIcall(params: UpdateOrderBody | null) {
    const { adjustOrder } = this.state;

    let message = makeApiMessage({
      url: configJSON.getOrderByIdEndpoint + adjustOrder?.id,
      body: JSON.stringify(this.getUpdateOrderBody(params)),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  updateQtyApicall(cartItem: IAdjustCartProduct) {
    const { adjustOrder } = this.state;
    const putBody = {
      data: {
        customer_id: adjustOrder?.customer.id,
        store_management_id: adjustOrder?.store_management_id,
        order_items_attributes: [
          {
            id: cartItem.id,
            quantity: cartItem.quantity,
            _destroy: cartItem.quantity == 0 ? true : false,
          },
        ],
      },
    };

    let message = makeApiMessage({
      url: configJSON.getOrderByIdEndpoint + adjustOrder?.id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getCatalogueList = (page: number = this.state.currentPage) => {
    const { tabsId, customerPriceListId } = this.state;
    this.setState({isCatalogueCardLoading: true});

    const searchQuery = this.state.searchCatalogue
      ? `&filter_by[query]=${this.state.searchCatalogue}`
      : "";

    const apiUrl =
      configJSON.getCatalogueListApi +
      `?price_list_id=${customerPriceListId}&category_id=${tabsId}${searchQuery}&per_page=30&page_no=${page}&allow_access=true&pos=true`;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getCatalogueListAPICallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  getStoreManagementBaseOnOrder = () => {
    const { adjustOrder } = this.state;
    const apiUrl =
      configJSON.getStoreManagementDetails +
      `/${adjustOrder?.store_management_id}?allow_access=true`;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getStoreManagementDetailsAPICallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  tabSectionChangeHandler = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ tabsId: newValue, currentPage: 1 }, () => {
      this.getCatalogueList(1);
    });
  };

  handleAdjustCartProduct(orderItems: IItemData[]) {
    const tempCart = orderItems.map((item) => {
      return {
        id: item.attributes.id,
        catalogue_id: item.attributes.catalogue_id,
        catalogue_variant_id: item.attributes.catalogue_variant_id,
        name: item.attributes.catalogue.name as string,
        product_name: item.attributes.catalogue.product_name,
        product_second_name: item.attributes.catalogue
          .product_second_name as string,
        unit_price: Number(item.attributes.unit_price),
        image: item.attributes.product_image as unknown as string,
        quantity: item.attributes.quantity,
        service_name: item.attributes.service.attributes.name,
        service_id: Number(item.attributes.service.id),
        parent_Product_id: item.attributes.parent_catalogue_id,
        category_id: item.attributes.category_id,
        upcharge_list_ids: item.attributes.upcharge_list_ids,
        preference_id: item.attributes.preference?.attributes.id || 0,
      };
    });

    return tempCart;
  }

  qtyIncreaseHandler = (params: { catalogue: CatalogueDataList }) => {
    const catalogue = params.catalogue;

    if (catalogue.attributes.product_type_id === productTypes.PARENT) {
      this.parentProductClickHandler(catalogue.attributes.id);
      return;
    }

    const { tabsId, adjustCartProduct, clickedParentCatalougeId } = this.state;
    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId
    );
    if (selectedProductIndex == -1) {
      const newProductInCart = {
        id: undefined,
        catalogue_id: catalogue.attributes.id,
        catalogue_variant_id:
          catalogue.attributes.catalogue_variants?.data[0].attributes.id,
        name: catalogue.attributes.name,
        product_name: catalogue.attributes.product_name,
        preference_id: catalogue.attributes.preference_id,
        product_second_name: catalogue.attributes.product_second_name,
        unit_price: 0,
        image: catalogue.attributes.catalogue_variants.data[0].attributes.image?.image,
        quantity: 1,
        service_name: "",
        service_id: -1,
        parent_Product_id: clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
      };
      tempCartProduct.push(newProductInCart);
    } else {
      const activeServices =
        catalogue.attributes.catalogue_variants.data[0].attributes.catalogue_variants_services.filter(
          (item) => item.service.active
        );
      if (
        tempCartProduct[selectedProductIndex].service_id !== -1 &&
        activeServices.length > 1
      ) {
        this.setState({
          clickedProductCardInfo: tempCartProduct[selectedProductIndex],
          selectedCatalogueData: catalogue
        });
        this.handleSameProductDifferentService();
        return;
      } else if (
        tempCartProduct[selectedProductIndex].service_id !== -1 &&
        activeServices.length == 1
      ) {
        tempCartProduct[selectedProductIndex].quantity =
          tempCartProduct[selectedProductIndex].quantity + 1;
        this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
        return;
      }
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
    }
    this.setState({
      adjustCartProduct: tempCartProduct,
    });
  }

  qtyDecreaseHandler = (params: { catalogue: CatalogueDataList }) => {
    const catalogue = params.catalogue;

    if (catalogue.attributes.product_type_id === productTypes.PARENT) {
      this.parentProductClickHandler(catalogue.attributes.id);
      return;
    }
    const { tabsId, adjustCartProduct } = this.state;
    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId
    );
    if (tempCartProduct[selectedProductIndex].quantity == 0) {
      return;
    } else {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity - 1;
      if (tempCartProduct[selectedProductIndex].service_id !== -1) {
        this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      }
    }
    this.setState({
      adjustCartProduct: tempCartProduct,
    });
  };

  checkForServicesWhenPopupDisabled = (
    clickedCatalougeItem: CatalogueDataList,
    clickedService: ServiceData
  ) => {
    const { tabsId, adjustCartProduct, clickedParentCatalougeId } = this.state;

    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === clickedCatalougeItem.attributes.id &&
        product.service_id === clickedService.id &&
        product.category_id === tabsId
    );
    if (selectedProductIndex == -1) {
      const newProductInCart = {
        id: undefined,
        catalogue_id: clickedCatalougeItem.attributes.id,
        catalogue_variant_id:
          clickedCatalougeItem.attributes.catalogue_variants.data[0].attributes
            .id,
        name: clickedCatalougeItem.attributes.name,
        product_name: clickedCatalougeItem.attributes.product_name,
        product_second_name:
          clickedCatalougeItem.attributes.product_second_name,
        unit_price: 0,
        image:
          clickedCatalougeItem.attributes.catalogue_variants.data[0].attributes
            .image?.image,
        quantity: 1,
        service_name: "",
        service_id: clickedService.id,
        parent_Product_id: clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
        preference_id: clickedCatalougeItem.attributes.preference_id,
      };
      tempCartProduct.push(newProductInCart);
      this.setState(
        {
          isPopuDisabledWithOrderCreate: true,
        },
        () => {
          this.getProductPreferencesCall(
            clickedCatalougeItem.attributes.catalogue_variants.data[0].attributes
              .id,
            clickedService.id
          );
        }
      );
      this.setState(
        {
          adjustCartProduct: tempCartProduct,
          isIncreaseBtnClicked: false,
          selectedService: {
            name: clickedService.name,
            id: clickedService.id,
          },
        }
      );
    } else {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
      this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      this.setState({
        adjustCartProduct: tempCartProduct,
        isIncreaseBtnClicked: false,
        selectedService: {
          name: clickedService.name,
          id: clickedService.id,
        },
      });
    }
    return true;
  };

  handleCheckPreferencesPopup = (
    clickedCatalouge: CatalogueDataList,
    service: ServiceData,
    cartProduct: IAdjustCartProduct
  ) => {
    const { storeManagementDetails, isIncreaseBtnClicked } = this.state;
    const isPreferencesPopupDisabled =
      storeManagementDetails?.data.attributes.store_configuration.attributes
        .item_preference_popup;

    if (!isPreferencesPopupDisabled) {
      if (isIncreaseBtnClicked) {
        this.checkForServicesWhenPopupDisabled(clickedCatalouge, service);
        return true;
      };

      this.setState(
        {
          selectedService: {
            name: service.name,
            id: service.id,
          },
          isPopuDisabledWithOrderCreate: true
        },
        () => {
          this.getProductPreferencesCall(
            clickedCatalouge.attributes.catalogue_variants.data[0].attributes.id,
            service.id
          );
          cartProduct.service_id = service.id;
        }
      );
      return true;
    }
    return false;
  };

  mainServiceHandler = (
    tempCartProduct: IAdjustCartProduct[],
    selectedCatalogueIndex: number,
    catalogue: CatalogueDataList,
    service: ServiceData
  ) => {
    const { isIncreaseBtnClicked } = this.state;
    if (tempCartProduct[selectedCatalogueIndex].service_id !== -1) {
      if (isIncreaseBtnClicked) {
        if (this.checkForServices(catalogue, service)) return 1;
      } else {
        return 1;
      }
    }
  };

  serviceClickHandler = (params: {
    catalogue: CatalogueDataList;
    service: ServiceData;
  }) => {
    const { catalogue, service } = params;
    
    const { tabsId, adjustCartProduct } = this.state;

    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === catalogue.attributes.id &&
        product.category_id === tabsId
    );
    if (selectedProductIndex < 0) {
      return;
    } else {

      // if product measurement is SQM or weight then display popup
      const carpetFurnitureMeasurementTypes = ["SQM", "Weight"];
      let isCarpetFurnitureProductType = catalogue.attributes.catalogue_variants.data[0].attributes?.measurement_type?.some(type => carpetFurnitureMeasurementTypes.includes(type) );
      
      if(isCarpetFurnitureProductType) {
        this.getProductPreferencesCall(
          catalogue.attributes.catalogue_variants.data[0].attributes.id,
            service.id
        );
        this.setState({
          productPopup: true,
          carpetFurnitureProductPopupData: catalogue,
          selectedService: {
            name: service.name,
            id: service.id,
          },
        });
        return;
      }

      if (
        this.handleCheckPreferencesPopup(
          catalogue,
          service,
          tempCartProduct[selectedProductIndex]
        )
      ) {
        return;
      }

      const value = this.mainServiceHandler(
        tempCartProduct,
        selectedProductIndex,
        catalogue,
        service
      );
      if (value === 1) {
        return;
      }
      tempCartProduct[selectedProductIndex].service_id = service.id;
    }

    this.getProductPreferencesCall(
      catalogue.attributes.catalogue_variants.data[0].attributes.id,
      service.id
    );

    this.setState({
      selectedService: {
        name: service.name,
        id: service.id,
      },
      adjustCartProduct: tempCartProduct,
      preferenceModalVisible: true,
    });
  }

  checkForServices = (
    clickedCatalouge: CatalogueDataList,
    clickedService: ServiceData
  ) => {
    const { tabsId, adjustCartProduct, clickedParentCatalougeId } = this.state;
    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === clickedCatalouge.attributes.id &&
        product.category_id === tabsId &&
        product.service_id === clickedService.id
    );
    if (selectedProductIndex == -1) {
      const newProductInCart = {
        id: undefined,
        catalogue_id: clickedCatalouge.attributes.id,
        catalogue_variant_id:
          clickedCatalouge.attributes.catalogue_variants.data[0].attributes.id,
        name: clickedCatalouge.attributes.name,
        product_name: clickedCatalouge.attributes.product_name,
        product_second_name: clickedCatalouge.attributes.product_second_name,
        unit_price: 0,
        image: 
          clickedCatalouge.attributes.catalogue_variants.data[0].attributes.image?.image,
        quantity: 1,
        service_name: "",
        service_id: clickedService.id,
        parent_Product_id: clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
        preference_id: clickedCatalouge.attributes.preference_id,
      };
      tempCartProduct.push(newProductInCart);
      this.getProductPreferencesCall(
        clickedCatalouge.attributes.catalogue_variants.data[0].attributes.id,
        clickedService.id
      );
      this.setState({
        adjustCartProduct: tempCartProduct,
        isIncreaseBtnClicked: false,
        selectedService: {
          name: clickedService.name,
          id: clickedService.id,
        },
        preferenceModalVisible: true,
      });
    } else {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
      this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      this.setState({
        adjustCartProduct: tempCartProduct,
        isIncreaseBtnClicked: false,
        selectedService: {
          name: clickedService.name,
          id: clickedService.id,
        },
      });
    }
    return true;
  };

  getProductPreferencesCall = (
    catalogue_variant_id: number,
    service_id: number
  ) => {
    const { adjustOrder } = this.state;

    const apiUrl =
      configJSON.getProductPreferences +
      `?customer_id=${adjustOrder?.customer.id}&catalogue_variant_id=${catalogue_variant_id}&service_id=${service_id}&allow_access=true&store_id=${adjustOrder?.store_management_id}`;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getPreferencesDataApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleClosePreference = () => {
    this.setState({ preferenceModalVisible: false });
    this.handleUpdateOrderAPIcall(null);
  };

  handleCartOnCarpetAndFunrnitureProduct = async (carpetFurniturebodyParams: IFormValues[]) => {
    const { tabsId, adjustCartProduct, selectedService } = this.state;
    const productPopupData = this.state.carpetFurnitureProductPopupData as CatalogueDataList;
    const tempCartProduct = [...adjustCartProduct];
    carpetFurniturebodyParams.forEach((param) => {
      const newProductInCart = {
        id: undefined,
        catalogue_id: productPopupData.attributes.id || 0,
        catalogue_variant_id:
          productPopupData.attributes.catalogue_variants?.data[0].attributes.id || 0,
        name: productPopupData.attributes.name,
        product_name: productPopupData?.attributes.product_name,
        product_second_name: productPopupData?.attributes.product_second_name,
        unit_price: 0,
        image: 
          productPopupData?.attributes.catalogue_variants.data[0].attributes.image
            .image,
        quantity: param.quantity,
        service_name: selectedService?.name || "",
        service_id: selectedService?.id || -1,
        parent_Product_id: this.state.clickedParentCatalougeId,
        category_id: tabsId,
        upcharge_list_ids: [],
        preference_id: productPopupData.attributes.preference_id,
        isCarpetAndFurnitureProduct: true,
        notes: param.notes,
        height: Number(param.height),
        width: Number(param.width),
        weight: Number(param.weight),
      };
      tempCartProduct.push(newProductInCart);
    });

    const filterCartProducts = tempCartProduct.filter((product) => product.service_id !== -1);
    this.setState({
      adjustCartProduct: filterCartProducts,
    });
  }

  async handleSubmitCarpetAndFunrnitureProduct(items: IFormValues[]) {
    const filteredItems = items.map((item) => {
      if (item.isNew) item.id = undefined
      return item
    })
    await this.handleCartOnCarpetAndFunrnitureProduct(filteredItems)
    if (this.state.adjustOrder?.id) {
      this.handleUpdateOrderAPIcall(null);
    } 

    this.setState({
      subProductModal: false,
      clickedParentCatalougeId: null,
      productPopup: false, 
      carpetFurnitureProductPopupData: null
    })
  }

  onCloseCareptAndFurnitureProductPopup = () => {
    this.setState({productPopup: false, carpetFurnitureProductPopupData: null})
  }

  handleCreateAdjustOrderPreference = (data: CreatePreferencesData) => {
    this.setState({
      preferenceModalVisible: false
    });
    const { adjustOrder, preferenceData } = this.state;

    const selectedSpecifications = data.selectedOptions
      ?.filter((element) => element.option_id)
      .map((item) => ({
        specification_option_id: Number(item.option_id),
      }));

    const bodyData = {
      data: {
        attributes: {
          customer_id: adjustOrder?.customer.id,
          preferences_attributes: [
            {
              id: Number((preferenceData as IpreferenceData).attributes?.id),
              catalogue_variant_id: Number(data.id),
              preference_id: data.preferenceId,
              upcharge_list_ids: data.upchargeListIds,
              service_id: data.serviceId,
              notes: data.notes,
              save_for_future: data.saveForFuture,
              pref_spec_options_attributes: selectedSpecifications,
            },
          ],
        },
      },
    };

    if (adjustOrder?.id) {
      const spesificationList: IadjustSpecification[] =
        (preferenceData as IpreferenceData).attributes?.specifications ?? [];

      const spesificationData: { [key: string]: string | undefined } = {};

      selectedSpecifications.forEach((element) => {
        const optionId = element.specification_option_id;
        const spesification = spesificationList.find((elem) =>
          elem.options_attributes.map((elem2) => elem2.id).includes(optionId)
        );
        if (spesification) {
          spesificationData[spesification.name] =
            spesification.options_attributes.find(
              (element) => element.id === optionId
            )?.label;
        }
      });

      const updateOrderData = {
        specifications: spesificationData,
        preference_id: data.preferenceId,
        upcharge_list_ids: data.upchargeListIds,
        catalogue_variant_id: Number(data.id),
      };

      this.handleUpdateOrderAPIcall(updateOrderData);
    }

    let message = makeApiMessage({
      url: configJSON.createPreferenceApiEndPoint,
      body: JSON.stringify(bodyData),
      method: "PUT",
    });
    this.createPreferencesApicallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  qtyHandlerInCart = (data: IItemData, actionKey: string) => {
    const { adjustCartProduct } = this.state;
    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.id === Number(data.attributes.id)
    );
    if (actionKey === "plus") {
      tempCartProduct[selectedProductIndex].quantity =
        tempCartProduct[selectedProductIndex].quantity + 1;
      this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
    } else {
      if (tempCartProduct[selectedProductIndex].quantity == 0) {
        return;
      } else {
        tempCartProduct[selectedProductIndex].quantity =
          tempCartProduct[selectedProductIndex].quantity - 1;
        this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
      }
    }
    this.setState({
      adjustCartProduct: tempCartProduct,
    });
  };

  getUpchargeListForProduct() {
    this.setState({
      adjustmentSelectUpChargeModal: true
    });
  }

  handleToggleUpchargeTick(itemId: string, upchargeId: number) {
    const list = this.state.orderUpChargePreference.map(
      (item: ICustomerSpecs) => {
        if (item.id == itemId) {
          const list = item.attributes.upcharge_lists.map(
            (upcharge: IUpChargeItem) => {
              if (upcharge.id == upchargeId) {
                upcharge.checked = !upcharge.checked;
                upcharge.isUpdated = true;
              }
              return upcharge;
            }
          );
          item.attributes.upcharge_lists = list;
        }
        return item;
      }
    );

    this.setState({ orderUpChargePreference: list });
  }

  handleCloseUpchargePopup() {
    this.setState({
      editOrderPopupFromAdjustment: true,
      adjustmentSelectUpChargeModal: false,
    });
  }

  updateOrderForUpChargesApi(body: unknown) {
    const message = makeApiMessage({
      url: configJSON.updateOrderEndPoint + this.state.adjustOrder?.id,
      method: "PUT",
      body: JSON.stringify(body as {}),
    });
    this.getUpdateOrderMessageId = message.messageId;
    this.send(message);
  }

  handleUpChargeOrder(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId != this.getUpdateOrderMessageId || !response) return;

    this.getOrderById(response.data.id);
    this.getOrderItemsList(response.data.id);
  }

  handleScanOrderResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId !== this.scanOrderApiCallId) return;
    const order = response?.data
    this.setState({isScanningOrder: false})
    if (order && order?.attributes) {
      if (order.attributes.is_quick_drop) return this.setState({snackbarMessage: "this is a quick drop order"})
      const currentSection = this.state.plantSection || this.getCurrentSelectedTab().apiKey
      if (order.attributes.status_plant !== currentSection) {
        this.setState({snackbarMessage: "this order does not belong to current section"})
        return;
      }
      this.shouldPrintTag = order.attributes.status_plant === "receiving_area"
      this.onOpenOrderDetails(order)
    }else {
      this.setState({snackbarMessage: "unable to find the order in plant"})
    }    
    
  }

  handleGetSharedFolderDataResponse = (message: Message) => {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId !== this.getSharedFolderMessageId) return;
    let errorMessage = "";
    if (response?.data?.length) {
      const inputFiles = response.data as {file_name: string; file_content: string; share_folders: string[]}[]
      const payload = inputFiles.flatMap(inputFile => 
        inputFile.share_folders.map(sharedFolder => ({
          file_path: sharedFolder + "\\" + inputFile.file_name,
          file_content: inputFile.file_content
        })))
      this.writeFiles(payload)
    } else {
      errorMessage = "No input file available for the orders"
    }
    this.setState((prev) => ({
      isLoadingSharedFolder: false, 
      selectedOption: "", selectedOrders: [], 
      snackbarMessage: errorMessage, 
      snackBarSeverity: errorMessage ? "error" : prev.snackBarSeverity
    }))
  }

  handleGetFilesForAutoSendResponse = (message: Message) => {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId !== this.getFilesForAutoSendMessageId) return;
    if (response?.data?.length) {
      const inputFiles = response.data as {file_name: string; file_content: string; share_folders: string[]}[]
      const payload = inputFiles.flatMap(inputFile => 
        inputFile.share_folders.map(sharedFolder => ({
          file_path: sharedFolder + "\\" + inputFile.file_name,
          file_content: inputFile.file_content
        })))
      this.writeFiles(payload, true, response.is_more)
    } else {
      this.disableGetFiles = false
    }
  }

  updatePreferenceForUpChargesApi(body: unknown) {
    const message = makeApiMessage({
      method: "PUT",
      body: JSON.stringify(body as {}),
      url: configJSON.updateCustomerPreferenceEndpoint,
    });
    this.getUpdateUpChargePreferenceMessageId = message.messageId;
    this.send(message);
  }

  handleUpChargeForPreference(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId != this.getUpdateUpChargePreferenceMessageId || !response)
      return;
  }

  handleDropdownUpChargeChange(varIds: number[], varId: string) {
    const { orderUpChargePreference } = this.state;

    const list = orderUpChargePreference.map((item) => {
      if (item.id == varId) {
        const list = item?.attributes?.upcharge_lists;
        const modifiedList = list.slice(6).map((item) => {
          item.checked = varIds.includes(item.id);
          return item;
        });
        const finalArray = [...list.slice(0, 6), ...modifiedList];
        item.attributes.upcharge_lists = finalArray;
      }
      return item;
    });

    this.setState({ orderUpChargePreference: list });
  }

  handleSameProductDifferentService = () => {
    this.setState({ sameProductDifferentServiceModalOpen: true });
  };

  sameProductDiffServiceModalClose = () => {
    // if product measurement is SQM and weight type then display carpet and funiture popup again don't increase quantity
    const carpetFurnitureMeasurementTypes = ["SQM", "Weight"];
    let isCarpetFurnitureProductType = this.state.selectedCatalogueData?.attributes.catalogue_variants.data[0].attributes?.measurement_type?.some(type => carpetFurnitureMeasurementTypes.includes(type) );
    
    if(isCarpetFurnitureProductType) {
      this.setState({
        productPopup: true,
        carpetFurnitureProductPopupData: this.state.selectedCatalogueData,
        sameProductDifferentServiceModalOpen: false,
        clickedProductCardInfo: null,
      });
      return;
    }
    const { adjustCartProduct, clickedProductCardInfo, tabsId } = this.state;
    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (item) =>
        item.catalogue_id === clickedProductCardInfo?.catalogue_id &&
        item.category_id === tabsId &&
        item.service_id === clickedProductCardInfo.service_id
    );
    tempCartProduct[selectedProductIndex].quantity =
      tempCartProduct[selectedProductIndex].quantity + 1;
    this.updateQtyApicall(tempCartProduct[selectedProductIndex]);
    this.setState({
      sameProductDifferentServiceModalOpen: false,
      adjustCartProduct: tempCartProduct,
    });
  };

  sameProductDiffServiceConfirmClick = () => {
    this.setState({
      sameProductDifferentServiceModalOpen: false,
      isIncreaseBtnClicked: true,
    });
  };

  handleTogglePreferenceTick(itemId: string, preferenceId: number) {
    const list = this.state.orderUpChargePreference.map(
      (item: ICustomerSpecs) => {
        if (item.id == itemId) {
          item.attributes.preferences = item.attributes.preferences.map(
            (preference: IPreference) => {
              if (preference.id == preferenceId)
                preference.checked = !preference.checked;
              else preference.checked = false;
              return preference;
            }
          );
        }
        return item;
      }
    );

    this.setState({ orderUpChargePreference: list });
  }

  handleToggleSpecificationTick(
    itemId: string,
    specsId: number,
    optionId: number
  ) {
    const list = this.state.orderUpChargePreference.map(
      (item: ICustomerSpecs) => {
        if (item.id == itemId) {
          const list = item.attributes.specifications.map(
            (item: ISpecification) => {
              if (item.id == specsId) {
                item.options_attributes = item.options_attributes.map(
                  (item: ISpecOption) => {
                    item.selected = item.id == optionId;
                    return item;
                  }
                );
              }
              return item;
            }
          );
          item.attributes.specifications = list;
        }
        return item;
      }
    );

    this.setState({ orderUpChargePreference: list });
  }

  handleCanceledOrderApiCall() {
    const { adjustOrder } = this.state;

    const message = makeApiMessage({
      method: "GET",
      url: configJSON.checkCanceledOrderEndPoint + adjustOrder?.id,
    });
    this.checkForOrderCanceledMessageId = message.messageId;
    this.send(message);
  }

  handleReviewCreateOrderClick() {
    const message = makeApiMessage({
      method: "POST",
      url: configJSON.confirmAdjustOrderEndPoint,
      body: JSON.stringify({ order_id: this.state.adjustOrder?.id }),
    });
    this.adjustOrderConfirmMessageId = message.messageId;
    this.send(message);
  }

  handleConfirmOrderResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId == this.adjustOrderConfirmMessageId) {
      if(response && !response.errors) {
        this.setState({
          reviewOrderPopupFromAdjustment: false,
          editOrderPopupFromAdjustment: false
        });
  
        if (this.state.plantSection)
          this.getOrderList(this.getCurrentSelectedTab().apiKey);
        else this.onChangeTabs(1);
      } else {
        alert(response.errors);
      }
    }
  }

  handleCheckCanceledOrderResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId == this.checkForOrderCanceledMessageId && response) {
      if (response.cancel_order) {
        const order_number = this.state.adjustmentOrignalOrderNumber;
        const popupMessage = String(
          configJSON.cancelOrderPopup_message_text
        ).replace("$$$", order_number);

        this.setState({
          adjustmentCancelOrderMessage:
            popupMessage + configJSON.cancelOrderPopup_message_text2,
          adjustmentCancelOrderModal: true,
        });
      }
      this.handleReviewCreateOrderClick()
    }
  }

  getEnableDisableForAdjustOrder() {
    const { adjustmentRequestType, adjustmentRequestTypeSub } = this.state;

    if (adjustmentRequestType == "Cancel") return true;
    else if (!adjustmentRequestType || !adjustmentRequestTypeSub) return false;
    else return true;
  }

  createCreditNoteApi() {
    const { addNote, creditNoteOrder } = this.state;
    this.setState({creditLoader:true})
    const message = makeApiMessage({
      url: configJSON.createCreditNoteEndPoint,
      method: "POST",
      body: JSON.stringify({
        notes: addNote,
        order_id: creditNoteOrder?.id,
      }),
    });
    this.getCreateCreditNoteMessageId = message.messageId;
    this.send(message);
  }

  getCreditNoteApi(orderId?: string) {
    this.setState({
      openCrediNoteModalLoader: true,
    });
    const message = makeApiMessage({
      url: configJSON.getCreditNoteEndpoint + orderId,
      method: "GET",
    });
    this.getCreditNoteMessageId = message.messageId;
    this.send(message);
  }

  handleCreditNoteResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId == this.getCreateCreditNoteMessageId && response) {
      if(response?.errors?.length) {
        this.setState({
          creditLoader: false,
        })
        alert(response.errors)
      } else {
        this.setState({
          snackbarMessage: "Credit note created successfully.",
          snackBarSeverity: "success",
          creditLoader:false,
          creditNoteOrder: undefined,
          isCreditNoteDialogOpen: false,
        });
        this.getOrderList(this.getCurrentSelectedTab().apiKey);
      }
    } else if (requestId == this.getCreditNoteMessageId && response?.data) {
      const note = response.data.attributes?.notes || "";
      this.setState({
        addNote: note,
        creditNoteOrderNumber: response.data.attributes.order_number,
        creditNotePresent: true,
        creditNoteReceipt:  toString(response.data.attributes.credit_note_receipt),
        openCrediNoteModalLoader: false
      });
    } 
    
    if (requestId == this.getCreditNoteMessageId && response?.errors) {
      this.setState({ hidden: false, openCrediNoteModalLoader: false });
    }
  }

  handleStoreManagementDetailsResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId == this.getStoreManagementDetailsAPICallID) {
      this.setState(
        {
          storeManagementDetails: response,
          tabsId:
            response.data.attributes.store_configuration.attributes.sections[0]
              .id,
          currentPage: 1
        },
        () => this.getCatalogueList(1)
      );
    }
  }

  getCombinationList() {
    const { adjustOrderItems } = this.state;
    const newArry = Array.from(
      new Set(
        adjustOrderItems.map((item) => {
          return {
            service_id: Number(item.attributes.service.id),
            category_id: item.attributes.category_id,
          };
        })
      )
    );
    return newArry;
  }

  getUniqueCombinationList(combinationList: UniqueCombination[]) {
    const newArray = combinationList.filter(
      (filterEle, index) =>
        index ===
        combinationList.findIndex(
          (element) =>
            element.category_id === filterEle.category_id &&
            element.service_id === filterEle.service_id
        )
    );
    return newArray;
  }

  findItemFromUnique(uniqueItem: UniqueCombination) {
    const { adjustOrderItems } = this.state;

    const uniqueSingleItem = adjustOrderItems?.find(
      (item: IItemData) =>
        Number(item.attributes.service.id) == uniqueItem.service_id &&
        item.attributes.category_id === uniqueItem.category_id
    );

    return uniqueSingleItem;
  }

  findSubItemsList(uniqueItem: UniqueCombination) {
    const { adjustOrderItems } = this.state;

    const subItemList = adjustOrderItems?.filter(
      (item: IItemData) =>
        Number(item.attributes.service.id) == uniqueItem.service_id &&
        item.attributes.category_id === uniqueItem.category_id
    );

    return subItemList;
  }

  deleteOrderItemHandler(data: IItemData) {
    const { adjustCartProduct } = this.state;
    const tempCartProduct = [...adjustCartProduct];
    const selectedProductIndex = tempCartProduct.findIndex(
      (product) =>
        product.catalogue_id === data.attributes.catalogue_id &&
        product.service_id === Number(data.attributes.service.id)
    );
    this.deleteOrderItemApicall(tempCartProduct[selectedProductIndex]);
  }

  deleteOrderItemApicall(cartItem: IAdjustCartProduct) {
    const { adjustOrder } = this.state;
    const putBody = {
      data: {
        customer_id: adjustOrder?.customer.id,
        store_management_id: adjustOrder?.store_management_id,
        order_items_attributes: [
          {
            id: cartItem.id,
            _destroy: true,
          },
        ],
      },
    };

    let message = makeApiMessage({
      url: configJSON.getOrderByIdEndpoint + adjustOrder?.id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  parentProductClickHandler = (catalogueId: number) => {
    this.setState({
      subProductModal: true,
      clickedParentCatalougeId: catalogueId,
    });
  };

  subProductPopupModalHandler = () => {
    this.setState({
      subProductModal: false,
    });
  };

  handleUpdatedLabels(items: unknown) {
    const { branchList } = this.state;

    const array: Array<number | string> = JSON.parse(JSON.stringify(items));

    function findLabelById(labelId: unknown) {
      let option = branchList.find((item: IOption) => item.id === labelId);
      return option && option.label;
    }

    const labels = array
      .map((arrayId: unknown) => findLabelById(arrayId))
      .filter((item) => item)
      .join(", ");

    const newLabel =
      labels.length > 20 ? labels.substring(0, 20) + "..." : labels;

    return newLabel;
  }

  handleStatusScroll = (isDrawerOpen: boolean) => {
    const width = window.innerWidth;
    if (width <= 1366 || (width <= 1560 && isDrawerOpen)) {
        return 'scrollable';
    } else {
        return 'fullWidth';
    }
  };

  handleNoTagsOrdersItems = (orderItems: IOrderItem[]) => {
    const newArray = orderItems.flatMap((Iorder) => {
      if(Iorder.attributes.tag_numbers.length > 0) {
        return Iorder.attributes.tag_numbers.map((tagNum) => ({
          ...Iorder,
          attributes: {
            ...Iorder.attributes,
            tag_numbers: [tagNum],
            tag_image: Iorder.attributes.tag_image?.filter(image => image.tag_no === tagNum) || []
          },
        }));
      } else {
        return Iorder;
      }
    });
    return newArray;
  };

  handleSearchCatalogue = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({currentPage: 1, searchCatalogue: event.target.value as string},
      () => this.getCatalogueList()
    )
  }

  handleCatalogueListScroll = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLElement;
    const position = target.scrollTop + target.clientHeight;
    if (
      !this.state.isCatalogueCardLoading &&
      target.scrollHeight - position <= 1.3 &&
      !this.state.catalogueListEmptyFlag
    ) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () =>
        this.getCatalogueList(this.state.currentPage)
      );
    }
  };
  scanOrder = (orderNumber: string) => {
    const endPoint = configJSON.getOrderByIdEndpoint + `${encodeURIComponent(orderNumber)}?for_barcode=true`
    this.setState({isScanningOrder: true})
    const message = makeApiMessage({
      method: "GET",
      url: endPoint
    });
    this.scanOrderApiCallId = message.messageId
    this.send(message)
  }

  handlePrintTag = (items: IOrderItem[]) => {
    const tags = items.flatMap(item => item.attributes.tag_image.map(image => image.label_string))
    const tagsToPrint = tags.filter(Boolean)
    if (tagsToPrint.length) {
      const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
      printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_TAGS")
      printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), tagsToPrint)
      this.send(printMessage)
    }
  }

  printIconClasses = (isDisabled: boolean) => "printIcon" + (isDisabled ? " disabled": "")

  handleCloseEditPreferencesPopup = () => {
    this.setState({
      adjustmentSelectUpChargeModal: false,
    });
  };

  getSpecificatonBodyObj = (specificationList: CustomerSpecification[]) => {
    let selectedOptionList: { [key: string]: string } = {};
    specificationList.forEach((specficItem) => {
      specficItem.options_attributes.forEach((specificOption) => {
        if (specificOption.selected) {
          selectedOptionList[specficItem.name] = specificOption.label;
        }
      });
    });
    return selectedOptionList;
  };

  getUpdatePreferenceBody = (
    updatedPreferencesData: SelectedPreferenceData[]
  ) => {
    const { adjustOrder } = this.state;

    const editedPreference = updatedPreferencesData.map((updatePreference) => {
      return {
        id: updatePreference.order_items_preference_id,
        order_item_id: updatePreference.order_items_id,
        preference_id: updatePreference.selected_preference,
        upcharge_list_ids: updatePreference.selected_upcharges,
        specifications: this.getSpecificatonBodyObj(
          updatePreference.selected_specification
        ),
      };
    });

    const updateBody = {
      id: adjustOrder?.id,
      order_item_preferences: editedPreference,
    };

    return updateBody;
  };

  handleSavePreference = (updatedPreferencesData: SelectedPreferenceData[]) => {
    this.setState({
      adjustmentSelectUpChargeModal: false,
    });

    let message = makeApiMessage({
      url: configJSON.orderItemPreferenceUpdateApiEndPoint,
      body: JSON.stringify(this.getUpdatePreferenceBody(updatedPreferencesData)),
      method: "PATCH",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  getSharedFolderInput = (orderIds?: string) => {
    let queryString = `?order_ids=${orderIds}`
    if (!orderIds) {
      const { selectedBranches, searchQuery, filters } = this.state;
      const queryForSearch = searchQuery ? `&filter_by[query]=${searchQuery}` : "";

      const orderFilter = filters.find(
        (item: IFilterItem) => item.title === "Order ID"
      )

      const customerFilter = this.state.filters.find(
        (item: IFilterItem) => item.title === "Customer"
      )

      const orderLabel = orderFilter?.value;
      const orderOptions = orderFilter?.options;
      const order = orderOptions?.find(
        (item: { label: string }) => item.label == orderLabel
      );
      const order_id = order ? order?.value : "";

      const customerLabel = customerFilter?.value;
      const customerOptions = customerFilter?.options;
      const customer_id = customerOptions?.find(
        (item: { label: string }) => item.label == customerLabel
      )?.value || "";

      const Date = this.state.filters.find(
        (item: { title: string }) => item.title === "Date"
      )?.value;

      queryString =
        `?filter_by[status_plant]=shorting_area` +
        `&store_ids=${selectedBranches.filter(isNumber).join(",")}${queryForSearch}` +
        (order_id ? `&order_ids=${order_id}` : "&order_ids=all") +
        (customer_id ? `&filter_by[customer_id]=${customer_id}` : "") +
        (Date ? `&filter_by[order_date]=${Date}` : "");
    }
    const message = makeApiMessage({
      method: configJSON.httpGetMethod,
      url: configJSON.metalProgettiSolutionEndPoint + queryString,
    });

    this.getSharedFolderMessageId = message.messageId
    this.send(message)
  }

  sendOrderToClean(order_id: string) {
    const body = {
      order_ids: [order_id]
    };

    let message = makeApiMessage({
      url: configJSON.orderSendToCleanEndPoint,
      body: JSON.stringify(body),
      method: "POST",
    });
    
    this.sendOrderToClanApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleSendOrderToCleanResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(requestId == this.sendOrderToClanApiCallId) {
      if(response) {
        if(!response.errors) {
          this.setState({
            snackbarMessage: response.message,
            snackBarSeverity: 'success'
          }, () => this.onChangeTabs(this.state.selectedTab));
        } else {
          this.setState({
            snackbarMessage: response.errors,
            snackBarSeverity: 'error'
          });
        }
      }
    }
  }

  shouldShowResendButton = () => {
    const {showResendButton, selectedOption} = this.state
    if (
      !showResendButton 
      || 
      (selectedOption && selectedOption !== "metal_progetti") 
      || this.getCurrentSelectedTab().apiKey !== "shorting_area"
    ) return false
    return true
  }

  handleResendOrders = (isAll?: boolean) => {
    const { selectedOrders } = this.state
    if (!isAll && !selectedOrders.length) return;
    this.setState({isLoadingSharedFolder: true})
    this.getSharedFolderInput(isAll ? undefined : selectedOrders.join(","))
  }

  getPaginationClasses = () => this.shouldShowResendButton() ? "my-10" : undefined

  shouldShowActionButtons = () => Boolean(this.state.selectedOption) && this.state.selectedOption !== "metal_progetti"

  shouldShowLoader = () => this.state.isScanningOrder || this.state.isLoadingSharedFolder

  isLoadingOrders = () => this.state.isPlantLoading || this.state.isOrderListLoading

  handleInputQty = (
    event: React.ChangeEvent<{ value: string }>,
    orderItem: IItemData
  ) => {
    let specials = /^\d*$/;
    if(specials.test(event.target.value)) {
      const { adjustOrderItems } = this.state;
      let tempOrderItems = JSON.parse(JSON.stringify(adjustOrderItems)) as IItemData[];
      const findChangeItem = tempOrderItems.find(
        (order_item) => order_item.id == orderItem.id
      );
      if (findChangeItem) {
        findChangeItem.attributes.quantity = Number(event.target.value);
        this.debouncedFetchSearchResults(findChangeItem);
      }

      this.setState({ adjustOrderItems: tempOrderItems });
    } else {
      alert("Please enter number only");
    }
  };

  handleCustomChangeQty = (findChangeItem: IItemData) => {
    const { adjustOrder } = this.state;
    const putBody = {
      data: {
        customer_id: adjustOrder?.customer.id,
        store_management_id: adjustOrder?.store_management_id,
        order_items_attributes: [
          {
            id: findChangeItem.id,
            quantity: findChangeItem.attributes.quantity,
            _destroy: findChangeItem.attributes.quantity == 0 ? true : false,
          },
        ],
      },
    };

    let message = makeApiMessage({
      url: configJSON.getOrderByIdEndpoint + adjustOrder?.id,
      body: JSON.stringify(putBody),
      method: "PUT",
    });
    this.updateOrderApiCallID = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  debouncedFetchSearchResults = debounce(this.handleCustomChangeQty, 500);

  returnDetailsTableRowNotes = (notes: string) => {
    if(notes) {
      return notes;
    } else {
      return "---";
    }
  };

  getSectionList = () => {
    const message = makeApiMessage({
      method: "GET",
      url: configJSON.gerSectionListEndPoint + `&allow_access=true`,
    });

    this.getSectionListId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleSectionListAPIResponse = (message: Message) => {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(requestId == this.getSectionListId) {
      if(response) {
        this.setState({ sectionList: response.data });
        const existingFilter = this.state.filters.find(
          (item) => item.title === "Section"
        );
        if (!existingFilter) return;
        existingFilter.options = response.data.map((item: SectionItem) => {
          return {
            value: item.id,
            label: item.attributes.section_name,
          };
        });
        this.setState({ filters: [...this.state.filters] });
      }
    }
  };

  renderNotesInDetails = (notes: string | undefined) => notes !== null && notes !== "";

  renderCustomerComplaint = (customer_complaint: string | undefined) => 
    customer_complaint !== null && customer_complaint !== "";

  renderCreditOrderNumber = (order_number: string | undefined) => {
    const { hidden, creditNoteOrderNumber, openCrediNoteModalLoader } = this.state;
    if(openCrediNoteModalLoader) {
      return "";
    } else {
      if(!hidden) {
        return order_number;
      } else {
        return creditNoteOrderNumber;
      }
    }
  };

  toggleAutoSendMode = async () => {
    if (this.disableToggleAutoSend) return;
    this.disableToggleAutoSend = true
    const { isAutoSend } = this.state
    const updateState : Partial<S> = {} 
    if (!isAutoSend) {
      try {
        if (!qzTray.websocket.isActive()) {
          await qzTray.websocket.connect()
        }
        this.disableGetFiles = false
        this.getFilesForAutoSend()
        this.autoSendFiles = setInterval(() => this.getFilesForAutoSend(), 60000)
        updateState.isAutoSend = true
      }catch(error) {
        updateState.snackbarMessage = configJSON.connectionErrorMessage
        updateState.snackBarSeverity = "error"
      }
    } else {
      clearInterval(this.autoSendFiles)
      updateState.isAutoSend = false
    }
    this.setState(updateState as Pick<S, keyof S>, () => this.disableToggleAutoSend = false)
  }

  getFilesForAutoSend = () => {
    if (this.disableGetFiles) return;
    this.disableGetFiles = true;
    const apiMessage = makeApiMessage({
      method: "GET",
      url: configJSON.metalProgettiSolutionEndPoint + "?is_automatic=true"
    })
    this.getFilesForAutoSendMessageId = apiMessage.messageId
    this.send(apiMessage)
  }

  writeFile = async (filePath: string, fileContent: string) => {
    try {
      await qzTray.file.write(filePath, { sandbox: false, shared: true, data: fileContent})
      const fileName = filePath.substring(filePath.lastIndexOf('\\') + 1)
      return fileName
    } catch (error) {
      runEngine.debugLog("Error writing file " + filePath)
      console.error(error)
      return ""
    }
  }

  writeFiles = async (payload: { file_path: string, file_content: string }[], isAutoSend = false ,is_more = false) => {
    try {
      if (!qzTray.websocket.isActive()) {
        await qzTray.websocket.connect()
      }
      const writtenFilePaths =  await Promise.all(payload.map(fileItem => 
        this.writeFile(fileItem.file_path, fileItem.file_content)
      ))
      const successfulFiles = writtenFilePaths.filter(Boolean)
      this.setState({
        snackbarMessage: `${successfulFiles.length} files have been written to shared folders`,
        snackBarSeverity: successfulFiles.length ? "success": "error"
      })
      if (successfulFiles.length) {
        this.isMoreFilesToSend = is_more
        const setKey = isAutoSend ? "filesWrittenByAutosend" : "filesWrittenByResend"
        successfulFiles.forEach(fileName => this[setKey].add(fileName))
        this.updateInFiles(isAutoSend)
      }
    } catch (error) {
      console.error(error)
      this.disableGetFiles = false
      this.setState({snackbarMessage: configJSON.connectionErrorMessage, snackBarSeverity: "error", isAutoSend: false})
    }
  }

  updateInFiles = (isAutoSend: boolean) => {
    const in_file_names = Array.from(isAutoSend ? this.filesWrittenByAutosend : this.filesWrittenByResend)
    const callId = isAutoSend ? "updateInFilesAfterAutoSendCallId" : "updateInFilesAfterResendCallId"
    const apiMessage = makeApiMessage({
      url: configJSON.updateOutFileApi,
      method: "POST",
      body: JSON.stringify({
        in_file_names
      })
    })
    this[callId] = apiMessage.messageId
    this.send(apiMessage)
  }

  handleUpdateInFilesResponse = (message: Message) => {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (![this.updateInFilesAfterAutoSendCallId, this.updateInFilesAfterResendCallId].includes(requestId)) return;
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId === this.updateInFilesAfterAutoSendCallId) {
      this.disableGetFiles = false
      if (responseJson.errors === "No file data uploaded") {
        this.filesWrittenByAutosend.clear()
        if (this.isMoreFilesToSend && this.state.isAutoSend) {
          this.getFilesForAutoSend()
        }
      }
    }
    if (requestId === this.updateInFilesAfterResendCallId) {
      if (responseJson.errors === "No file data uploaded") {
        this.filesWrittenByResend.clear()
      }
    }
  }

  getToggleButtonClass = () => this.state.isAutoSend ? undefined : "off-btn"

  moveToNextButtonClasses = () => "add mw-186" + (!this.isShowNextLabel() ? " disable-btn" : "")
  // Customizable Area End
}
