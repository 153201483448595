// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {concat, uniqBy, toString, debounce} from "lodash"
import { PreferenceListData } from "./PreferenceListController.web";
import utils from "../src/util";

type IconOption = {
    id: string;
    option: string;
    image: string;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedValue: PreferenceListData | null,
    isDropDownOpen: boolean,
    isOnline: boolean,
    preferenceDetail: PreferenceListData[],
    defaultIcon: string
    first_input: string
    second_input: string
    showInputError: boolean
    showRadioSelectError: boolean
    showIconError: boolean
    isShowPopUp: boolean
    popUpMessage: string
    preferenceId: string | number,
    item: EditSectionData,
    itemId: string
    severity: string
    showErrorRes: boolean
    errorMessage: string
    isLoading: boolean
    allIconsList: IconOption[]
    selectedIcon: IconOption | null
    iconPage: number;
    iconSearchText: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;

    // Customizable Area End
}
// Customizable Area Start
interface EditSectionData {
    id: string
    attributes: {
        section_name: string,
        section_second_name: string
        active: boolean,
        online: boolean,
        icon: {
            data: { id:string; attributes: { id: string | number; name: string; image: string }}
        },
        preference: {
            data: {
                id: string
            }
        },
    }
}
// Customizable Area End

// Customizable Area Start
export default class EditSectionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    itemsPerPage = 10;
    preferenceDetailCallId: string = "";
    updateSectionListCallId: string = "";
    GetIconsListCallId: string = "";
    disableLoadMoreIcon = false;
    sectionId: string = ""
    getSectionByIdCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            item: {} as EditSectionData,
            selectedValue: null,
            isDropDownOpen: false,
            isOnline: false,
            first_input: "",
            second_input: "",
            preferenceDetail: [],
            showRadioSelectError: false,
            showIconError: false,
            defaultIcon: configJSON.defaultIcon,
            showInputError: false,
            isShowPopUp: false,
            popUpMessage: "",
            preferenceId: "",
            itemId: "",
            severity: "",
            showErrorRes: false,
            errorMessage: "",
            isLoading: false,
            allIconsList: [],
            selectedIcon: null,
            iconPage: 1,
            iconSearchText: ""
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        this.getPreferenceDetail()
        this.sectionId = this.props.navigation.getParam('id');
        this.getSectionById();
        
        // Customizable Area End
    }
    // Customizable Area Start
    getIconsList = () => {
        const headers = {
          "Content-Type": configJSON.categoryApiContentType,
          token: window.localStorage.getItem(configJSON.token)
        };
    
        const GetIconsList = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.GetIconsListCallId = GetIconsList.messageId;
        const searchQuery = this.state.iconSearchText ? `&filter_by[query]=${this.state.iconSearchText}` : ''
    
        GetIconsList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.iconsEndpoint}?page_no=${this.state.iconPage}&per_page=${this.itemsPerPage}${searchQuery}`
        );
    
        GetIconsList.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        GetIconsList.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
        runEngine.sendMessage(GetIconsList.id, GetIconsList);
      };

    selectIcon = (newIcon: IconOption) => {
        this.setState({
            selectedIcon: newIcon,
            showIconError: false
        });
    }
    handleSelected = () => {
        const { id, attributes } = this.state.item
        this.setState({
            first_input: attributes.section_name,
            second_input: attributes.section_second_name === null ? "" : attributes.section_second_name,
            preferenceId: attributes.preference.data?.id,
            isOnline: attributes.online,
            itemId: id,
            selectedIcon:  {
              id:  attributes?.icon?.data?.id,
              option: attributes?.icon?.data?.attributes?.name,
              image: toString(attributes?.icon?.data?.attributes?.image)
            }
        }, () => this.getIconsList())
    }

    toggleButton = () => {
        this.setState((prevState) => ({
            isDropDownOpen: !prevState.isDropDownOpen,
        }));
    };
    goToListing = () => {
        this.props.navigation.history.push("/Products-SectionList")
    }
    handleDropDownValue = (option: PreferenceListData) => {
        this.setState({
            preferenceId: option.id,
            selectedValue: option,
            isDropDownOpen: false,
            showRadioSelectError: false
        });

    };
    handleCheckboxChange = () => {
        this.setState(prevState => ({
            isOnline: !prevState.isOnline
        }));
    };
    handleFirstInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            first_input: event.target.value,
            showInputError: false,
            isDropDownOpen: false,
        });
    };
    handleClosePopUp = () => {
        this.setState({
            isShowPopUp: false
        })
    }
    handleSecondInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            second_input: event.target.value,
            showInputError: false,
            isDropDownOpen: false,
        });
    };

    checkFormErrors = () => {
      const { first_input, selectedValue } = this.state;
      return selectedValue !== null && first_input !== ''
    }

    handleSaveUpdate = () => {
        const { first_input, selectedValue } = this.state;
        if (first_input === '') {
            this.setState({ showInputError: true });
        } else {
            this.setState({ showInputError: false });
        }
        if (selectedValue === null) {
            this.setState({ showRadioSelectError: true });
        } else {
            this.setState({ showRadioSelectError: false });
        }
        if (this.checkFormErrors()) {
            this.setState({isLoading: true})
            this.updateListSection()

        }
    };
    handleGetIconsListResponse(from: string, message: Message) {
        if (this.GetIconsListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
            const {parsedDropdownIconss}  = utils.parsedAllIcon(response)
            let newIcons = this.state.iconPage === 1 ? parsedDropdownIconss : concat(this.state.allIconsList, parsedDropdownIconss)
            this.state.selectedIcon && newIcons.unshift(this.state.selectedIcon)
            this.setState({
                allIconsList: uniqBy(newIcons, "id"),
            })
            this.disableLoadMoreIcon = parsedDropdownIconss.length < this.itemsPerPage
        }
      }
      
    getPreferenceDetail = () => {
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };

        const preferenceDetailMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.preferenceDetailCallId = preferenceDetailMsg.messageId;

        preferenceDetailMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllPreferenceDropdownEndPoint
        );

        preferenceDetailMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        preferenceDetailMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(preferenceDetailMsg.id, preferenceDetailMsg);
    }
    updateListSection = () => {

        let headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };
        let httpBody = {}
        let dataBody = {
            attributes: {
                section_name: this.state.first_input,
                section_second_name: this.state.second_input,
                preference_id: this.state.preferenceId,
                online: this.state.isOnline,
                icon_id: Number(this.state.selectedIcon?.id)

            }
        }
        httpBody = {
            data: dataBody
        }
        const updateSectionMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateSectionListCallId = updateSectionMessage.messageId;

        updateSectionMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.sectionListApiEndPoint}/${this.state.itemId}`
        );

        updateSectionMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        updateSectionMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        updateSectionMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpUpdateMethodType
        );

        runEngine.sendMessage(updateSectionMessage.id, updateSectionMessage);

    }
    handleResponseForPreferenceDetail = (from: string, message: Message) => {
        if (this.preferenceDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse) {
                this.setState({
                    preferenceDetail: apiResponse.data,
                    selectedValue: apiResponse.data?.find((item: PreferenceListData) => item.id == this.state.preferenceId)
                })

            }
        }
    }
    handleResponseSectionListUpdate = (from: string, message: Message) => {
        if (this.updateSectionListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.data.type === configJSON.typeCategory) {
                this.setState({
                    isShowPopUp: true,
                    popUpMessage: configJSON.updatePopUpMessage,
                    severity: configJSON.severitySuccess
                })
                setTimeout(() => {
                    this.goToListing()
                }, 1000)

            } else if (apiResponse.data.type === configJSON.typeError) {
                this.setState({
                    showErrorRes: true,
                    isLoading: false,
                    errorMessage: apiResponse.data.attributes.errors.name[0]
                })
            } else {
                this.setState({
                    isLoading: false,
                    isShowPopUp: true,
                    popUpMessage: apiResponse?.errors[0]?.message,
                    severity: configJSON.typeError

                })
            }
        }
    }

    handleScrollIconDropdown = (event: React.SyntheticEvent) => {
        if (this.disableLoadMoreIcon) return;
        const checkListboxNode = event.currentTarget;
        const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
    
        if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
          this.setState((prevState) => ({ iconPage: prevState.iconPage + 1 }), () => {
            this.getIconsList()
          })
        }
      };
    debouncedFunction = debounce(
        (newInputValue: string) => this.handleIconAutoCompleteChange(newInputValue),
        700,
        { maxWait: 2000 }
    );
    handleIconAutoCompleteChange = (getValue: string) => {
        if (getValue === this.state.iconSearchText) return;
        this.setState({ iconSearchText: getValue, iconPage: 1 }, () => {
            if (getValue.length < 1 || getValue.length > 2) {
            this.getIconsList()
            }
        });
    }


    getSectionById = () => {
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: window.localStorage.getItem(configJSON.token)
        };

        const apiUrl =
            configJSON.sectionListEndPoint + `/${this.sectionId}`;
        const sectionByEditIDDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSectionByIdCallId = sectionByEditIDDataMessage.messageId;

        sectionByEditIDDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiUrl
        );

        sectionByEditIDDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        sectionByEditIDDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(sectionByEditIDDataMessage.id, sectionByEditIDDataMessage);
    }

    handleResponseForGetSectionById = (from: string, message: Message) => {
        if (this.getSectionByIdCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse) {
              this.setState({
                item: apiResponse.data,
              }, 
              () => this.handleSelected()
            )
            }
        }
      }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForPreferenceDetail(from, message)
        this.handleResponseSectionListUpdate(from, message)
        this.handleGetIconsListResponse(from,message);
        this.handleResponseForGetSectionById(from, message)
        // Customizable Area End
    }
    // Customizable Area End


}
// Customizable Area End
