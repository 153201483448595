import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";
import { IFilter } from "../../../components/src/FilterPopover";
import moment from "moment";
import { handleMomentDateFormat } from "../../cfcashier/src/utils";
import html2pdf from "html2pdf.js";
import { handleDisplayRegion } from "./utils";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface PieGraphData {
    male: number | string;
    female: number | string;
    total: number | string;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    regionIdQuery: string;
    storeIdQuery: string;
    groupName: string;
    groupIdQuery: string;
    groupId: string;
    customerCountsTop: PieGraphData;
    customerCountsNew: PieGraphData;
    customerCountsInactive: PieGraphData;
    customerCountsTotal: PieGraphData;
    customerWithDiscountCount: PieGraphData;
    customerWithPricelistCount: PieGraphData;
    customerCountsRepeated: PieGraphData;
    filterAnchor: HTMLDivElement | undefined;
    filters: IFilter[];
    regionName: string;
    storeNames: string;
    amountSpendValue: string;
    openExportModal: boolean;
    permissionStatus: DashboardPermissionStatus | null;
    storeId: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomerDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    customerCountTopCallId: string = "customerCountTopCallId";
    customerCountTotalCallId: string = "customerCountTotalCallId";
    customerCountInactiveCallId: string = "customerCountInactiveCallId";
    customerCountNewCallId: string = "customerCountNewCallId";
    customerCountRepeatedCallId: string = "customerCountRepeatedCallId";
    customerCountWithDiscountCallId: string = "customerCountWithDiscountCallId";
    customerCountWithPricelistCallId: string = "customerCountWithPricelistCallId";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.BroadcastNavbarDataMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            regionIdQuery: "",
            storeIdQuery: "",
            groupIdQuery: "",
            groupName: "",
            groupId:"",
            storeId: "",
            customerCountsTop: { male: "", female: "", total: "" },
            customerCountsNew: { male: "", female: "", total: "" },
            customerCountsInactive: { male: "", female: "", total: "" },
            customerCountsTotal: { male: "", female: "", total: "" },
            customerCountsRepeated: { male: "", female: "", total: "" },
            customerWithDiscountCount: { male: "", female: "", total: "" },
            customerWithPricelistCount: { male: "", female: "", total: "" },
            filterAnchor: undefined,
            filters: [
                {
                    title: "Date",
                    value: "today",
                    type: "dateselect",
                    datevalue: { from: "", to: "" },
                    options: [
                        {
                            label: "Today",
                            value: "today",
                        },
                        {
                            label: "Last 7 days",
                            value: "last7",
                        },
                        {
                            label: "Last 30 days",
                            value: "last30",
                        },
                        {
                            label: "Specific Dates",
                            value: "specific",
                        },
                    ]
                }
            ],
            regionName: "",
            storeNames: "",
            amountSpendValue: "",
            openExportModal: false,
            permissionStatus: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.receiveDataFromTopBar(message)
        this.handleResForCustomerCountsTop(from, message)
        this.handleResForCustomerCountsNew(from, message)
        this.handleResForCustomerCountsInactive(from, message)
        this.handleResForCustomerCountsTotal(from, message)
        this.handleResForCustomerCountsRepeated(from, message)
        this.handleResForCustomerWithDiscount(from, message)
        this.handleResForCustomerCountsWithPricelist(from, message)
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    handleExportConfirm = async (methodName: string) => {
        if (methodName === "pdf") {
            const wrapperDomElement = document.getElementById("print-wrapper") as HTMLElement
            await html2pdf(wrapperDomElement, {
                pagebreak: { mode: ['css'] },
                filename: "Customer-dashboard.pdf",
                margin: 24,
                jsPDF: {
                    format: [1440, 1440],
                    unit: "px"
                }
            })
            this.setState({ openExportModal: false })
        }
    }

    receiveDataFromTopBar = (message: Message) => {
        if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
            const recievedData = message.getData(
                getName(MessageEnum.BroadcastNavbarData)
            );
            if (recievedData.storeId || recievedData.regionMultiId) {
                this.setState({ storeId: recievedData.storeId, groupId:recievedData.groupId, regionIdQuery: `&region_ids=${recievedData.regionMultiId}`, storeIdQuery: `&store_ids=${recievedData.storeId}`, groupName:recievedData.groupNames ,regionName: handleDisplayRegion(recievedData.regionMultiId, recievedData.regionNames, recievedData.regionSelectAll).textDisplayed, storeNames: recievedData.storeNames}, () => this.getFlagCustomerCounts())
            }
        }
    }

    getFlagCustomerCounts() {
        this.getCustomerCountsTop()
        this.getCustomerCountsNew()
        this.getCustomerCountsInactive()
        this.getCustomerCountsTotal()
        this.getCustomerCountsRepeated()
        this.getCustomerCountsWithDiscount()
        this.getCustomerCountsWithPricelist()
    }

    handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({amountSpendValue:event.target.value},()=>this.getCustomerCountsTop())
    }

    getCustomerCountsTop = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=top&minimum_amount=${this.state.amountSpendValue}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountTopCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCustomerCountsNew = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=new${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountNewCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCustomerCountsInactive = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=inactive${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountInactiveCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCustomerCountsTotal = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=total${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountTotalCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCustomerCountsRepeated = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=repeated${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountRepeatedCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCustomerCountsWithDiscount = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=customer_with_discount${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountWithDiscountCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCustomerCountsWithPricelist = async () => {
        const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
        let headers = {
            token: await getStorageData('token'),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = apiCall({
            httpBody: {},
            header: headers,
            url: `${configJSON.customerCountEndpoint}?flag=customer_with_pricelist${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.groupIdQuery}${dateFilterQuery}`,
            httpMethod: configJSON.dashboarApiMethodType,
        });

        this.customerCountWithPricelistCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleResForCustomerCountsTop = async (from: string, message: Message) => {
        if (this.customerCountTopCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerCountsTop: responseJson.data, amountSpendValue: responseJson.top_customer_amount })
            }
        }
    }

    handleResForCustomerCountsNew = async (from: string, message: Message) => {
        if (this.customerCountNewCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerCountsNew: responseJson.data })
            }
        }
    }

    handleResForCustomerCountsInactive = async (from: string, message: Message) => {
        if (this.customerCountInactiveCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerCountsInactive: responseJson.data })
            }
        }
    }

    handleResForCustomerCountsTotal = async (from: string, message: Message) => {
        if (this.customerCountTotalCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerCountsTotal: responseJson.data })
            }
        }
    }

    handleResForCustomerCountsRepeated = async (from: string, message: Message) => {
        if (this.customerCountRepeatedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerCountsRepeated: responseJson.data })
            }
        }
    }

    handleResForCustomerWithDiscount = async (from: string, message: Message) => {
        if (this.customerCountWithDiscountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerWithDiscountCount: responseJson.data })
            }
        }
    }

    handleResForCustomerCountsWithPricelist = async (from: string, message: Message) => {
        if (this.customerCountWithPricelistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status == 200) {
                this.setState({ customerWithPricelistCount: responseJson.data })
            }
        }
    }

    handleCloseFilterPopover = () => {
        this.setState({ filterAnchor: undefined })
    }

    handleFilterChangeAccount = (filters: IFilter[]) => {
        this.setState({ filters }, () => {
            this.getFlagCustomerCounts()
        });
    };

    handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({ filterAnchor: event.currentTarget })
    }

    handleDateFilterParam = () => {
        const today = moment().format('YYYY-MM-DD');
        let comomStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
        let commonEndDate = today;
        let startDate, endDate;
        switch (dateFilter) {
            case 'last7':
                endDate = commonEndDate;
                startDate = comomStartDate;
                break;
            case 'specific':
                const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
                if (dateFilter?.from && dateFilter?.to) {
                    endDate = moment(dateFilter.to).format('YYYY-MM-DD');
                    startDate = moment(dateFilter.from).format('YYYY-MM-DD');
                }
                break;
            case 'last30':
                startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
                endDate = today;
                break;
            case 'today':
                startDate = today;
                endDate = today;
                break;
            default:
                endDate = today;
                startDate = today;
        }
        const startDateText = startDate ? handleMomentDateFormat(startDate, 'DD-MM-YYYY') : moment().subtract(6, 'days').format('DD-MM-YYYY')
        const endDateText = endDate ? handleMomentDateFormat(endDate, 'DD-MM-YYYY') : moment().format('DD-MM-YYYY')

        const dateFilterQuery = startDate && endDate
            ? `&date_type=specific_dates&start_date=${startDate}&end_date=${endDate}`
            : '';

        return { startDateText, dateFilterQuery, endDateText }
    };

    handleExportModal = () => {
        this.setState(prevState => ({
            openExportModal: !prevState.openExportModal
        }));
    }

    handleUserChange = (context: IUserContext) => {
        const apiKey = customPermissionApiKey.dashboardPermission;
        const userData = context.user?.attributes.permission_groups;
        const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
        this.setState({
            permissionStatus: value
        });
    }

        // Customizable Area End
}
