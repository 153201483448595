Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getAllIconsApi = "bx_block_categories/gallery_icons/list_all_icons";
exports.getAllImagesApi = "bx_block_categories/gallery_icons/list_all_images";
exports.createIconsApi = "bx_block_categories/gallery_icons";
exports.editIconsApi = "bx_block_categories/gallery_icons/";
exports.deleteIconImageApi = 'bx_block_categories/gallery_icons/images'
exports.gallerySuggestionApi = 'bx_block_categories/gallery_icons/gallery_icon_filter'
exports.getServiceListApiEndPoint = '/bx_block_categories/services';
exports.applicationJsonContentType = "application/json";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypePost = "POST";
exports.apiMethidTypeDelete = "DELETE";
exports.deleteModalMessage = "Are you sure you want to delete icon/image?"
exports.deleteModalTitle = "Confirm Delete"
exports.noRecordFound = "No records found!!"

exports.getCatalogueListApi = "/bx_block_catalogue/catalogues";
exports.updateEmployeeCurrentStoreApi =
  "/bx_block_store_management/store_managements/update_current_store";
exports.getStoreListApi = "/bx_block_store_management/store_managements";
exports.getPaymentMethodsListApi = "/bx_block_settings/payment_methods";
exports.calculateCartItemsTotal = "/bx_block_order_management/orders/calculate_cart_items_total";
exports.createNewOrder = "/bx_block_order_management/orders/";
exports.getProductPreferences = "/bx_block_profile_bio/customer_preferences/product_preferences"
exports.createPreferenceApiEndPoint = "/bx_block_profile_bio/customer_preferences/update_preferences"
exports.getOrderListApi = "/bx_block_order_management/orders/customer_orders";
exports.getTotalRewardPoints = "account_block/customers/total_reward_points";
exports.redeemPoints = "account_block/customers/redeem_points";
exports.customerSearch = "account_block/customers/auto_complete"
exports.importGalleryCsvApiurl = "/bx_block_categories/gallery_icons/excel_import"
exports.exportGalleryCsvApiurl = "/bx_block_categories/gallery_icons/excel_export"
exports.exportTemplateGalleryCsvApiurl = "/bx_block_categories/gallery_icons/excel_template"
exports.exportimportText = "Export/Import";
exports.successCsvMessage = "CSV file has been exported successfully";
exports.successExcelMessage = "Excel file has been exported successfully";
exports.buttonMap = [
  {
    title: "Cancel",
    cssProperties: {
      backgroundColor: "#F1F5F9",
      color: "#64748B",
      width: "126px",
      height: "56px",
      textTransform: "none",
      border: "0",
    },
  },
  {
    title: "Add",
    cssProperties: {
      backgroundColor: "#204B9C",
      color: "#ffff",
      width: "128px",
      height: "56px",
      marginLeft: "32px",
      textTransform: "none",
      border: "0",
    },
  },
];
exports.test1 = "Test1";
exports.isCourrpt = "is corrupted";
exports.dimensionError = "Image dimensions exceed the maximum allowed size";
exports.selectOneTypeText = "Please select one type";
exports.uploadErrorMessage = "Please upload";
exports.typeDropdownValues = ["Icon", "Image"];
exports.imageNamePlaceholder = "Enter Name";
exports.imageArabicNamePlaceholder = "Enter Arabic Name";
exports.threeDotOptions = ["Upload", "Delete"];
exports.anchorOptions = { horizontal:'center', vertical:'top'};
exports.uploadInputAccepts = "image/*";
exports.autoHideDuration = 3000;
exports.iconTitle = "Icons";
exports.imageTitle = "Images";
exports.ResolutionImage = "The resolution of the image size should be 129 x 129";
exports.cancel = "Cancel";
exports.add = "Add";
exports.addIcon = "Add Icon/Image";
exports.addIconBtn = "Add Icon/image";
exports.galleryText = "Gallery";
exports.errorMsg = "something went wrong";
exports.editIcon = "Edit Icon/Image";
exports.token = "token";
exports.selectAllText = "Select All"

exports.noOrderMsg = "Order not found."

exports.searchCustomerApiEndPoint = "/account_block/web_customers/search_customer_account";
exports.searchCustomerApiMethod = "GET";
exports.test2="test002"
exports.addImageTest="addImageTest"

exports.Strings = {
  homeCleaningString: {
    homeCleaning:  "Home Cleaning Product",
    addProduct: "Add Product",
    productName: "Product Name",
    productSecondName: "Product Second Name",
    productType: "Product Type",
    pricePerSqureMeter: "Price Per Square Meter",
    view: "View",
    edit: "Edit",
    delete: "Delete",
    back: "Back",
    addNewProduct: "Add New Product",
    productNamePlaceholder: "Enter Product Name",
    productSecondNamePlaceholder: "Enter Product Second Name",
    measurementType: "Measurement Type",
    cleaningNotes: "Cleaning Process Notes",
    image: "Image",
    cleaningExpDates: "Cleaning Expected Duration (in days)",
    expectedDurationPlaceholder: "Enter Expected Duration",
    selectGallaryImg: "Select Image From Gallery",
    selectMeasurementType: "Select Measurement Type",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    CreateProductSuccessMsg: "Product created successfully.",
    editProductSuccessMsg: "Product edited successfully.",
    productTypeAndSqMeter: "ProductType (Price Per Square Meter)",
    status: "Status",
    deactive: "Deactive",
    deactivated: "Deactivated",
    active: "Active",
    homeCleanProduct: "Home Clean Product",
    availableFor: "Available For",
    selectAvaibility: "Select Availability",
    conformDeactiveText: "Confirm Deactivate",
    productDeactivateMessage: "Product Deactivated",
    confirmButtonText: "Back to Listing",
    productActivateSuccessMessage: "Product activate successfully.",
    productDeactivatedSuccessMessage: "Product deactivate successfully.",
    saved: "Saved"
  }
}

exports.ApiUrls = {
  getImagesListApiEndPoint: '/bx_block_categories/gallery_icons/list_all_images',
  getHomeCleanProductApiEndPoint: '/bx_block_catalogue/home_cleaning_catalogues'
}
exports.test = "testequaltest"
exports.marginTitle = "marginTitleAddImage"
exports.snackbarContainer = "snackbarContainer"
exports.searchGallery = "Search Gallery"
exports.galleryTest = "test2"
// Customizable Area End