import React from "react";
// Customizable Area Start
import { styled, Grid } from "@material-ui/core";

import AddRegionController, { Props, configJSON } from "./AddRegionController.web";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";

export default class RegionView extends AddRegionController {
  constructor(props: Props) {
    super(props);
  }

  renderTextInput = (title: string, value: string) => {
    return (
      <Grid item xs={12} md={6} >
        {title && (
          <>
            <div className="txtHeader">{title.charAt(0).toUpperCase() + title.slice(1)}</div>
            <div className="fieldValue">{value || "--"}</div>
          </>
        )}
      </Grid>
    );
  };

  renderSelectInput = (
    title: string,
    selectOptions: string[] | { text: string; value: string }[],
    value: string | undefined
  ) => {
    let fixedSelectOptions: { text: string; value: string }[] = [];
    if (typeof selectOptions[0] === "string") {
      selectOptions = selectOptions as string[];
      fixedSelectOptions = selectOptions.map((option: string) => ({ text: option, value: option }));
    } else {
      fixedSelectOptions = selectOptions as { text: string; value: string }[];
    }

    return (
      <Grid item xs={12} md={6}>
        {title && (
          <>
            <div className="txtHeader">{title.charAt(0).toUpperCase() + title.slice(1)}</div>
            <div className="fieldValue">
              {fixedSelectOptions.find((option) => option.value === value)?.text || "--"}
            </div>
          </>
        )}
      </Grid>
    );
  };

  renderOthersColumnRegion = (
    columnKey: string,
    columnVal: string | { isSelected: boolean; title: string }[]
  ) =>
    Array.isArray(columnVal)
      ? this.renderSelectInput(
          columnKey,
          columnVal.map((item) => item.title),
          columnVal.find((item) => item.isSelected)?.title
        )
      : this.renderTextInput(columnKey, columnVal);

  renderOtherFields = () => {
    const otherFieldKeys = Object.keys(this.state.regionInfo.attributes.others);
    const returnElement = [];

    for (let i = 0; i < otherFieldKeys.length; i += 2) {
      const key1 = otherFieldKeys[i];
      const key2 = otherFieldKeys[i + 1];
      const value1 = this.state.regionInfo.attributes.others[key1];
      const value2 = this.state.regionInfo.attributes.others[key2];
      returnElement.push(
        <>
          {this.renderOthersColumnRegion(key1, value1)}
          {key2 ? this.renderOthersColumnRegion(key2, value2) : this.renderTextInput("", "")}
        </>
      );
    }

    return returnElement;
  };

  render() {
    return (
        <PageContainer
          navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
           <CustomLoader loading={this.state.isLoading} />
          <StyledWrapper>
            <div className="headerWrapper">
              <div className="pageTitleView">{configJSON.regionViewHeader}</div>
              {renderBaseOnConditions(
                this.state.permissionStatus.editPermission,
                <div
                  className='buttonView editBtn'
                  data-test-id='addCompany'
                  onClick={this.handleEdit}
                >
                  {configJSON.edit}
                </div>,
                <></>
              )}
            </div>
              <Grid container spacing={4} className="formContainerView">
                {this.renderTextInput(
                  configJSON.regionName,
                  this.state.regionInfo.attributes.branch_name
                )}
                {this.renderTextInput(
                  configJSON.company,
                  this.state.regionInfo.attributes.company.attributes.name
                )}
                {this.renderTextInput(
                  configJSON.regionShortName,
                  this.state.regionInfo.attributes.branch_short_name
                )}
                {this.renderTextInput(
                  configJSON.regionId,
                  this.state.regionInfo.attributes.branch_id
                )}
                {this.renderTextInput(configJSON.email, this.state.regionInfo.attributes.email)}
                {this.renderTextInput(configJSON.address, this.state.regionInfo.attributes.address)}
                {this.renderTextInput(
                  configJSON.telephone,
                  this.state.regionInfo.attributes.telephone
                )}
                {this.renderOtherFields()}
              </Grid>
              <div className="backBtnContainer">
                <div
                  data-test-id='backButton'
                  onClick={this.handleBack}
                  style={{
                    marginRight: 32,
                  }}
                  className='buttonView'
                >
                  {configJSON.back}
                </div>
              </div>
            </StyledWrapper>
        </PageContainer>
    );
  }
}

const StyledWrapper = styled('div')({
  "& .headerWrapper": {
    display: "flex",
    marginBottom: "32px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .backBtnContainer": {
    display: "flex",
    gap: "32px",
    padding: "32px 0"
  },
  "& .columnContainer": {
    width: "100%",
  },
  "& .rowContainer": {
    gap: "32px",
    display: "flex",
    marginBottom: "32px",
  },
  "& .txtHeader":{
    fontStyle: "normal",
    "fontFamily": "Montserrat, sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    color: "#4D4D4D",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important"
    },
  },
  "& .pageTitleView": {
    "fontSize": "24px",
    "marginTop": "32px",
    "fontWeight": 600,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      "marginTop": "16px",
    },
  },
  "& .editBtn": {
    marginTop: 32,
    "@media only screen and (max-width: 1024px)": {
      "marginTop": "16px",
    },
  },
  "& .fieldValue": {
    fontSize: 16,
    fontWeight: 500,
    color: "#1A1A1A",
    marginTop: 4,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px !important"
    },
  },
  "& .buttonView": {
    "padding": "16px 34px",
    "borderRadius": "8px",
    "fontSize": "16px",
    "fontWeight": 700,
    "cursor": "pointer",
    "backgroundColor": "#204b9c",
    "color": "white",
    "textAlign": "center",
    "@media only screen and (max-width: 1024px)": {
      "padding": "12px 34px",
    },
  },
  "& .formContainerView": {
    padding: 32,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px"
  },
  
});
// Customizable Area End
