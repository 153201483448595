import React from "react";
// Customizable Area Start
import { TextField, Button, Select, MenuItem, Snackbar, Grid, Box } from "@material-ui/core";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

import AddAreaController, { Props, configJSON } from "./AddAreaController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import Alert from "@material-ui/lab/Alert";
import { HeaderText, InputLabel, SelectFieldCustom, SaveAndCancelButton, CustomStyledTextField } from "../../../components/src/customComponents/CustomTags.web";
import { CustomAutoCompleteSearchable } from "../../../components/src/customComponents/CustomAutoCompleteSearchable.web";

export default class AddArea extends AddAreaController {
  constructor(props: Props) {
    super(props);
  }

  renderTextInputArea = (
    title: string,
    options: {
      placeHolder?: string;
      value?: string;
      onChange?: (value: string) => void;
      required?: boolean;
    }
  ) => {
    const errors = this.state.formErrors;
    return (
      <Grid item xs={12} md={6}>
        {title && (
          <>
            <InputLabel >{title + (options.required ? "*" : "")}</InputLabel>
            <CustomStyledTextField
              error={!!errors[title]}
              value={options.value}
              onChange={(e) => {
                options.onChange && options.onChange(e.target.value);
              }}
              data-test-id={title}
              variant='outlined'
              placeholder={options.placeHolder || "Enter " + title}
              type={"text"}
            />
            {errors[title] && <div style={{...webStyle.errorText, }} >{errors[title]}</div>}
          </>
        )}
      </Grid>
    );
  };


  render() {
    return (
      <PageContainer navigation={this.props.navigation}>
        <CustomLoader loading={this.state.isLoading} />
        <div style={webStyle.mainContainerArea}>
          <HeaderText >{configJSON.areaInfoHeader}</HeaderText>
            <Grid container spacing={4} style={webStyle.formContainerArea}>
              {this.renderTextInputArea(configJSON.areaName, {
                value: this.state.areaInfo.attributes.area_name,
                onChange: (value) => {
                  this.setState({
                    areaInfo: {
                      ...this.state.areaInfo,
                      attributes: { ...this.state.areaInfo.attributes, area_name: value },
                    },
                  });
                },
              })}
              
               <Grid item xs={12} md={6}>
                <InputLabel>{configJSON.selectRegion }</InputLabel>
                <CustomAutoCompleteSearchable 
                    dataTestID="region"
                    value={this.state.areaInfo.attributes.region.data} 
                    optionList={this.state.regionList.map((region) => ({
                      option: region.attributes.branch_name,
                      id: region.attributes.id + "",
                    }))} 
                    debouncedFunction={()=>{}} 
                    handleScroll={this.handleScrollRegionDropdown} 
                    handleSelectOptions={(value:any)=>{
                      this.setState({
                        areaInfo: {
                          ...this.state.areaInfo,
                          attributes: {
                            ...this.state.areaInfo.attributes,
                            region: { data: { id: value.id, option:value.option } },
                          },
                        },
                      });
                    }} 
                    selectedItem={this.state.areaInfo.attributes.region.data} 
                    placeholder={"Select " + configJSON.selectRegion} 
                    isError={Boolean(this.state.formErrors && this.state.formErrors[configJSON.selectRegion])}
                />
                {this.state.formErrors[configJSON.selectRegion] && <div style={webStyle.errorText}>{this.state.formErrors[configJSON.selectRegion]}</div>}
               </Grid>
            </Grid>
        <div
          style={{
            display: "flex",
            gap: "32px",
            marginTop: '20px',
            padding:'20 0'
          }}>
          <SaveAndCancelButton
            data-test-id='CancelButton'
            variant='contained'
            onClick={this.onCancel}
            size='medium'
            style={{ ...webStyle.cancelButton, textTransform: "none", margin: '10px' }}
            disabled={!!this.state.snackBarMessage}
          >
            {configJSON.cancel}
          </SaveAndCancelButton>
          <SaveAndCancelButton
            data-test-id='SaveButton'
            variant='contained'
            onClick={this.onSave}
            size='medium'
            style={{ ...webStyle.saveButton, textTransform: "none", margin: '10px' }}
            color='secondary'
            disabled={!!this.state.snackBarMessage || this.state.isLoading}
          >
            {configJSON.add}
          </SaveAndCancelButton>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={!!this.state.snackBarMessage}
          autoHideDuration={4000}
          onClose={this.closeSnackBar}
        >
          <Alert severity='success'>{this.state.snackBarMessage}</Alert>
        </Snackbar>
       </div>

      </PageContainer>
    );
  }
}

const webStyle = {
  inputStyleArea: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
  },
  mainContainerArea: {
    fontFamily: "Montserrat",
    margin: 24,
    marginTop: 15,
  },
  formContainerArea: {
    marginTop: 10,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px",
  },
  inputField: {
    width: "100%",
    height: "56px",
    fontFamily: "Montserrat !important",
    marginTop: "4px",
  },
  rowContainer: {
    display: "flex",
    gap: "32px",
  },
  columnContainerArea: {
    width: "100%",
  },
  addButton: {
    marginTop: "8px",
    fontSize: "16px",
    color: "rgba(77, 77, 77, 1)",
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
  },
  cancelButton: {
    backgroundColor: "#F1F5F9",
    fontSize: "16px",
    width: "128px",
    fontWeight: 700,
    color: "#64748B",
  },
  saveButton: {
    backgroundColor: "#204B9C",
    width: "128px",
    fontSize: "16px",
    fontWeight: 700,
  },
  addModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFFFFF",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "60%",
    height: 417,
    borderRadius: "24px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop:'4px',
  },
  addModalCategory: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
  },
};
// Customizable Area End
