import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { getFiterList, makeApiMessage } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  CustomEnums,
  PermissionStatus,
  checkForImportExportPermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  getCustomEnumName,
  navigateTo,
  randomNumberGenerator
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import ImportExportWebAdapter from "../../../blocks/adapters/src/ImportExportWebAdapter";

export interface IStore {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    id: number | null;
    store_name: string;
    store_cr_number:string;
    created_at: string;
    updated_at: string;
    email: string;
    store_keeper_ids: string[];
    store_id: string;
    full_ip_phone_number: string;
    sub_cr_number?: string;
    ar_sub_cr_number?: string;
    telephone_number?: string;
    full_ip_phone_number_arabic?: string;
    gps_location?: string;
    ip_phone_prefix_ar?: string;
    ip_phone_prefix_en?: string;
    price_list_id: number | null;
    account_ids?: number[];
    is_closed?: boolean;
    company?: {
      id: number;
      name: string;
    };
    plants: {
      data: {
        id: number | null;
        name: string;
        region_id: number | null;
        total_capicity_in_kg: number | null;
        total_capicity_in_piece: number | null;
        created_at: string;
        updated_at: string;
        active: boolean;
        attributes:{
          name:string;
        }
      }[];
    };
    store_keepers: {
      data: {
        attributes: {
          id: number;
          first_name: string;
          full_name: string;
        };
        id: number;
      }[];
    };
    service_offers: {
      created_at: string;
      id: number | null;
      label: string;
      module_name: string;
      updated_at: string;
    }[];
    area: {
      id: number | null;
      area_name: string;
      region_id: number | null;
      created_at: string;
      updated_at: string;
      activated: boolean;
    };
    store_address: string;
    store_address_arabic?: string;
    shopkeeper_name?: string;
    area_id: number | null;
    store_times: {
      id: number | null;
      day: string;
      status: string;
      end_time: string;
      start_time: string;
      timeable_id: number | null;
      timeable_type: string;
      created_at: string;
      updated_at: string;
      label: string;
      is_weekend: boolean;
      end_day: string;
    }[];
    address: {
      id: number | null;
      country: null;
      latitude: number | null;
      longitude: number | null;
      address: string;
      addressble_id: number | null;
      addressble_type: string;
      address_type: null;
      created_at: string;
      updated_at: string;
      location: boolean;
      sub_cr?: string;
      street_name?: string;
      street_name_arabic?: string;
      additional_street_name?: string;
      additional_street_name_arabic?: string;
      building?: string;
      building_arabic?: string;
      plot?: string;
      plot_arabic?: string;
      city?: string;
      city_arabic?: string;
      city_subdivision?: string;
      city_subdivision_arabic?: string;
      postal_code?: string;
      postal_code_arabic?: string;
      country_sub_entity?: string;
      country_sub_entity_arabic?: string;
    };
    store_capacity: null;
    region_id: null;
    model_name: string;
    store_configuration?: {
      id: string;
      type: "store_configuration";
      attributes: {
        id: number;
        store_management_id: number;
        payment_method_screen: boolean;
        price_list_id: number;
        price_list: {
          data: {
            id: string,
            type: string,
            attributes: {
              id: number,
              name: string,
              price_list_type: string,
              is_master_price_list: boolean
            }
          }
        },
        item_preference_popup: boolean;
        sections: {
          id: number;
          name: string;
        }[] | undefined;
      };
    };
  };
}

export interface ICloseStoreData {
  reason: string;
  closed_reason_comment: string;
}

export interface IImportCSVResponses {
  status?: number;
  message: string;
  error?: string;
  errors: { [key: string]: string };
  url:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  popOverOpenedStore: boolean;
  popOverCloseStore: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTopStore: number;
  popOverLeftStore: number;
  hoveredButtonStore: string;
  stores: IStore[];
  clickedStore: IStore | undefined;
  page: number;
  pageSize: number;
  totalCounts: number;
  disableModalVisible: boolean;
  deactivatedStore: boolean;
  closedStore: boolean;
  reasonList: {
    id: number;
    label: string;
  }[];
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  filters: IFilter[];
  sortingData: ISortingData;
  query: string;
  suggestionFieldTitle: string;
  searchTextStore: string;
  isSuperAdmin?: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;
  permissionStatus: PermissionStatus;
  openProductImpExtPopover: EventTarget & HTMLButtonElement | null;
  customSnackBarOpen: boolean;
  customSnackBarMessage: string;
  customSnackBarSeverity: "error" | "warning" | "info" | "success";
  setFileLoaded: number;
  isLoading: boolean;
  uploadedFileState: File | null;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfStoreManagmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public RequestMessage = {
    StoreCloseRequestId: this.buildRequestMessageForStore("PUT"),
  };
  getStoresCallId: string = "";
  filterSuggestionApiCallId: string = "";
  // getAreaSuggestionListCallId: string = "";
  updateStoreCallId: string = "";
  reasonListApiCallId: string = "";
  tableRefsStore: HTMLElement | null = null;
  exportTemplateFileApiId: string = "";
  exportCsvFileApiId: string = "";
  importStoreCsvApiId: string = "";
  adapter: ImportExportWebAdapter;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),

      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getCustomEnumName(CustomEnums.ImportExportPopupFileMessage),
      getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      stores: [],
      page: 1,
      pageSize: 10,
      totalCounts: 0,
      popOverItemId: "",
      popOverItemStatus: "",
      disableModalVisible: false,
      deactivatedStore: false,
      closedStore: false,
      reasonList: [],
      popOverOpenedStore: false,
      popOverCloseStore: false,
      isLoading: false,
      popOverLeftStore: 0,
      popOverTopStore: 0,
      hoveredButtonStore: "",
      filterAnchor: undefined,
      clickedStore: undefined,
      filters: [
        {
          title: "Store Name",
          type: "autocompolete",
          value: "",
          apiKey: "store_name",
          options: [],
        },
        {
          title: "Area",
          type: "autocompolete",
          value: "",
          apiKey: "area",
          options: [],
        },
        {
          title: "Region",
          type: "autocompolete",
          value: "",
          options: [],
        },
      ],
      sortingData: {
        store_name: "",
        email: "",
        full_ip_phone_number: "",
        store_id: "",
        area_name: "",
      },
      query: "",
      searchTextStore: "",
      suggestionFieldTitle: "",
      snackbarOpen: false,
      snackbarMessage: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
        exportPermission: false
      },
      openProductImpExtPopover: null,
      customSnackBarOpen: false,
      customSnackBarMessage: "",
      customSnackBarSeverity: "success",
      setFileLoaded: 105,
      uploadedFileState: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.adapter = new ImportExportWebAdapter();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getStoresCallId) {
        if (responseJson) {
          const stores = responseJson.data as IStore[];
          this.setState({
            stores: stores,
            page: Number(responseJson.meta.current_page),
            totalCounts: Number(responseJson.meta.total_count),
            isLoading: false
          });
        }
      } else if (apiRequestCallId === this.updateStoreCallId) {
        this.getStores();
        if (responseJson.errors) {
          this.setState({snackbarOpen: true,popOverCloseStore: false,snackbarMessage: responseJson.errors})
        }
        if (responseJson && this.state.popOverItemStatus === "Active") {
          this.setState({ deactivatedStore: true });
        }
      } else if (apiRequestCallId === this.reasonListApiCallId) {
        this.checkReasonListResponse(responseJson);
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        this.checkFilterSuggestionResponse(responseJson);
      } else if(apiRequestCallId === this.exportTemplateFileApiId) {
        if(responseJson) {
          this.handleExportTemplateResponse(responseJson);
        }
      } else if(apiRequestCallId === this.importStoreCsvApiId) {
        if(responseJson) {
          this.handleImportCsvFileApiResponse(responseJson);
        }
      } else if(apiRequestCallId === this.exportCsvFileApiId) {
        if(responseJson) {
          this.handleExportCsvFileApiResponse(responseJson)
        }
      }
      this.handleStoreCloseResponse(apiRequestCallId, responseJson);
    } else {
      this.handleImportExportActions(from, message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let mesg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    mesg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(mesg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getStores();
  }
  
  checkReasonListResponse(responseJson: {
    status: number; data: {
      id: number;
      label: string
    }[]
  }) {
    if (responseJson && responseJson.data) {
      this.setState({ reasonList: responseJson.data });
    }
  }

  handleStoreCloseResponse(apiRequestCallId: string, responseJson: {responseJson: IStore, status: number, errors: string}) {
    if (
      apiRequestCallId === this.RequestMessage.StoreCloseRequestId.messageId
    ) {
      this.getStores();
      if (responseJson && responseJson.status == 422) {
        this.setState({
          snackbarOpen: true,
          popOverCloseStore: false,
          snackbarMessage: responseJson.errors,
        });
      } else {
        this.setState({ closedStore: true, popOverCloseStore: false });
      }
    }
  }

  checkFilterSuggestionResponse(responseJson: { filter_names: [] }) {
    if (responseJson) {
      const updatedFilters = getFiterList(
        responseJson,
        this.state.filters,
        this.state.suggestionFieldTitle
      );
      this.setState({ filters: updatedFilters });
    }
  }

  private buildRequestMessageForStore(method: string): Message {
    const requestMessageForStore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerTypeEndPoint
    );
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessageForStore;
  }

  getStatus(obj: IStore) {
    if (obj.attributes.activated) return "Active";
    else return obj.attributes.is_closed ? "Closed" : "Deactivated";
  }

  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  handleSearchStore = (value: string) => {
    this.setState(
      {
        page: 1,
        searchTextStore: value,
      },
      this.getStores
    );
  };

  createData(
    storeName: string,
    email: string,
    telephone: string,
    storeId: string,
    area: string,
    status: string,
    recid: string
  ) {
    return { storeName, email, telephone, storeId, area, status, recid };
  }

  handleStoreAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let ApiUrl;
    if (title === "Store Name") {
      ApiUrl = configJSON.getStoreSuggestionEndPoint;
    } else if (title === "Area") {
      ApiUrl = configJSON.getAreaSuggestionEndPoint;
    } else if (title === "Region") {
      ApiUrl = configJSON.getRegionSuggestionEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStores = (page: number = this.state.page) => {
    this.setState({isLoading:true})
    if(page == 0) {
      page = page + 1
    };

    const { filters, searchTextStore, pageSize, query } = this.state;

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const storeFilter = filters.find(
      (item: IFilter) => item.title === "Store Name"
    )?.value;

    const areaFilter = filters.find((item: IFilter) => item.title === "Area")
      ?.value;

    const reginFilter = filters.find((item: IFilter) => item.title === "Region")
      ?.value;

    const storeFilterQuery = storeFilter
      ? `&filter_by[store_name]=${storeFilter}`
      : "";
    const searchTextQuery = searchTextStore
      ? `&filter_by[query]=${searchTextStore}`
      : "";
    const areaTextQuery = areaFilter ? `&filter_by[area]=${areaFilter}` : "";
    const regionTextQuery = reginFilter ? `&filter_by[region_name]=${reginFilter}` : "";
    const storeApiEndPoint = `${configJSON.storeManagementApi}?page_no=${page}&per_page=${pageSize}${storeFilterQuery}${query}${searchTextQuery}${areaTextQuery}${regionTextQuery}`;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoresCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      storeApiEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleFilterChangeStore = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        page: 1,
      },
      this.getStores
    );
  };

  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
  };

  handleAddStore = () => {
    this.props.navigation.navigate("StoreAdd");
  };

  handlePageChange = (page: number) => {
    this.getStores(page);
    this.setState({ page });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getStores());
  };

  handleViewStore = (storeId: string) => {
    this.setState({ popOverOpenedStore: false });
    this.props.navigation.navigate("StoreView");
    window.localStorage.setItem("store_id", storeId);
  };

  handleEditStore = (storeId: string) => {
    this.setState({ popOverOpenedStore: false });
    this.props.navigation.navigate("StoreEdit");
    window.localStorage.setItem("store_id", storeId);
  };

  handleDeactivate = (clickedStoreId: string) => {
    this.setState({
      disableModalVisible: true,
      clickedStore: this.state.stores.find(
        (store) => store.id === clickedStoreId
      ),
    });
  };

  handleCloseButton = (storeId: string) => {
    this.setState({
      popOverCloseStore: true,
      popOverOpenedStore: false,
      clickedStore: this.state.stores.find((store) => store.id === storeId),
    });
    window.localStorage.setItem("store_id", storeId);

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reasonListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reasonListApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeActivateStore = () => {
    this.setState({ popOverOpenedStore: false, disableModalVisible: false });
    this.updateStore(
      this.state.popOverItemId,
      this.state.popOverItemStatus === "Active"
    );
  };

  handleDeactivateClose = () => {
    this.setState({ disableModalVisible: false, popOverOpenedStore: false });
  };

  handleDeactivatedStoreClose = () => {
    this.setState({ deactivatedStore: false });
  };

  handleGoList = () => {
    this.setState({ deactivatedStore: false, closedStore: false });
  };

  handleStoreCloseCancel() {
    this.setState({ popOverCloseStore: false });
  }

  updateStore = (storeId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateStoreCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.storeManagementApi}/${storeId}/${
        isActive ? "deactivate" : "activate"
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async handlePopStoreConfirm(data: ICloseStoreData) {
    let varid = await getStorageData("store_id")

    this.RequestMessage.StoreCloseRequestId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.storeManagementApi}/${varid}/deactivate`
    );

    this.RequestMessage.StoreCloseRequestId.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(
      this.RequestMessage.StoreCloseRequestId.id,
      this.RequestMessage.StoreCloseRequestId
    );
  }

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.storeManagementPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    
    const apiKeyImportExport = customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(apiKeyImportExport, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: {
        ...value,
        exportPermission: valueImportExportPermission.exportPermission
      }
    })
  };

  handleCloseCustomSnackbar = () => {
    this.setState({
      customSnackBarMessage: "",
      customSnackBarOpen: false
    });
  };

  handleOpenExportPopver = (events: EventTarget & HTMLButtonElement) => {
    this.setState({
      openProductImpExtPopover: events
    });
  };

  handleCloseImpExpPopover = () => {
    this.setState({
      openProductImpExtPopover: null
    });
  };

  handleExportTemplate = () => {
    const requestMessage = makeApiMessage({
      url: configJSON.exportCSVTemplateApiUrl,
      method: "GET",
    });

    this.exportTemplateFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  };

  handleOpenImportModal = () => {
    this.setState({ openProductImpExtPopover: null})

    let message = new Message(
      getCustomEnumName(CustomEnums.ImportExportPopupMeassage)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );
    this.send(message)
  };

  handleExportCsvFile = () => {
    this.setState({ openProductImpExtPopover: null });

    const { filters } = this.state;

    const storeFilter = filters.find(
      (item: IFilter) => item.title === "Store Name"
    )?.value;

    const areaFilter = filters.find((item: IFilter) => item.title === "Area")
      ?.value;

    const storeFilterQuery = `?filter_by[store_name]=${storeFilter}`

    const areaFilterQuery = areaFilter ? `&filter_by[area]=${areaFilter}` : "";
    
    const requestMessage = makeApiMessage({
      url: `${configJSON.exportCSVApiUrl}${storeFilterQuery}${areaFilterQuery}`,
      method: "GET",
    });

    this.exportCsvFileApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  };

  handleExportTemplateResponse = (response: {
    message: string;
    url: string
  }) => {
    this.setState({
      openProductImpExtPopover: null,
      customSnackBarOpen: true,
      customSnackBarSeverity: 'success',
      customSnackBarMessage: response.message
    });
    location.href = response.url;
  };

  handleImportCsvFileApiResponse = (response: IImportCSVResponses) => {
    const message = new Message(
      getCustomEnumName(CustomEnums.ImportExportAPIResponse)
    );
    message.addData('APIresponse', response)
    runEngine.sendMessage(message.id, message);

    if (response?.message) {
      this.setState({
        customSnackBarSeverity: 'success',
        customSnackBarOpen: true,
        customSnackBarMessage: response.message
      });
      navigateTo({ props: this.props, screenName: "StoreList" });
      this.handlePageChange(0);
    }
    else if (response?.status == 500) {
      this.setState({
        customSnackBarSeverity: 'error',
        customSnackBarOpen: true,
        customSnackBarMessage: response?.error || ''
      });
      this.props.navigation.goBack();
    }
    else {
      const message = new Message(
        getCustomEnumName(CustomEnums.ImportExportErrorPopupData)
      );
      message.addData('APIresponse', response);
      runEngine.sendMessage(message.id, message);
      navigateTo({ 
        props: this.props,
        screenName: "ImportExportErrorModal",
        raiseMessage: message
      })
    }
  }

  handleExportCsvFileApiResponse = (response: {
    file_url: string,
    message: string
  }) => {
    this.setState({
      customSnackBarSeverity: 'success',
      customSnackBarOpen: true,
      customSnackBarMessage: response.message
    });
    location.href = response.file_url;
  };

  handleImportExportActions(from: string, message: Message) {
    switch (from) {
      case getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked):
        this.handleImportExportModalClose();
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage):
        this.handleFileUpload(message.properties.fileEvent)        
        break;

      case getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage):
        this.handleImportFile()
        break;

      case getCustomEnumName(CustomEnums.ImportExportErrorPopupGoBack): 
        this.handleBackToListPage();
        break
        
    }    
  };

  handleImportExportModalClose = () => {
    const message = new Message(
      getCustomEnumName(CustomEnums.ImportExportPopupClose)
    );
    message.addData('ParentpageRoute', 'StoreList')
    runEngine.sendMessage(message.id, message)
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setFileLoaded: 0 });
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setFileLoaded: updateLoadingTime(this.state.setFileLoaded)
      }, () => {
        const message = new Message(
          getCustomEnumName(CustomEnums.ImportExportPopupFileMessage)
        );
        message.addData('returnValue', { 
            setLoaded: this.state.setFileLoaded,
            file: file && file[0]
          }
        );
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updateLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkFile = file && file[0];
    this.setState({ uploadedFileState: checkFile as File})
  };

  handleImportFile = () => {
    const { uploadedFileState } = this.state;

    const formData = new FormData();
    formData.append("data[file]", uploadedFileState as File);

    const requestMessage = makeApiMessage({
      url: configJSON.importCSVApiUrl,
      body: formData,
      method: "POST",
      isHeader: true,
    });

    this.importStoreCsvApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleBackToListPage = () => {
    navigateTo({ props: this.props, screenName: "StoreList" });
    this.setState({
      page: 1
    }, () => this.getStores());
  };

  // Customizable Area End
}
