import React from "react";
// Customizable Area Start
import { Box, Chip, styled } from "@material-ui/core";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import StoreGroupController, {Props,
  RegionData,
  StoreManagementData,
  AreaData
} from "./StoreGroupController";
import { colors } from "../../utilities/src/Colors";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

export default class StoreGroupView extends StoreGroupController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer
      navigation={this.props.navigation}
      onUserChange={(userContext) => this.handleUserChange(userContext)}
      >
        <MainContainer>
       <CustomLoader loading={this.state.isLoading} />
        <HeadingContainer>
            <Heading>Store Group</Heading>
            
                <BottomContainer>
                  <button
                    data-test-id='btn-edit'
                    onClick={() => this.handleEditGroup(this.props.navigation.getParam("id")) }
                    className='add'
                  >
                    Edit
                  </button>
                </BottomContainer>
          </HeadingContainer>
          <ShadowBox>
              <GridContainer>
                  <div>
                    <HeadingText>Group Name</HeadingText>
                    <ValueText>{this.state.storeGroupInfo?.attributes?.name}</ValueText>
                  </div>
                  <div>
                    <HeadingText>Group Arabic Name</HeadingText>
                    <ValueText>
                      {this.state.storeGroupInfo?.attributes?.second_name}</ValueText>
                  </div>
                  <div>
                    <HeadingText>Region</HeadingText>
                    <ChipContainer>

                      {this.state.storeGroupInfo?.attributes?.regions?.data?.map((item:RegionData) => item.attributes.name).map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>Area</HeadingText>
                    <ChipContainer>
                    {this.state.storeGroupInfo?.attributes?.areas?.data?.map((item:AreaData) => item.attributes.area_name).map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
                  <div>
                    <HeadingText>Store</HeadingText>
                    <ChipContainer>
                    {this.state.storeGroupInfo?.attributes?.store_managements?.data?.map((item:StoreManagementData) => item.attributes.store_name).map((name: string) => (
                        <StyledChip key={name} label={name} size='small' />
                      ))}
                    </ChipContainer>
                  </div>
              </GridContainer>
          </ShadowBox>

          <button data-test-id="goBackBtn" onClick={this.handleGoBack} className="backBtn">
            Back
          </button>

        </MainContainer>
        
      </PageContainer>
      // Customizable Area End
    );
  }
}
export const ValueText = styled("div")({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "22px",
  color: "var(--basic-black, #000)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,
    paddingBottom: 12,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});
export const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    marginBottom: "2px",
    color: "#204B9C",
    textTransform: "capitalize",
    backgroundColor: "#204B9C1A",
  },
});
export const HeadingText = styled("div")({
  fontSize: 18,
  fontWeight: 600,
  paddingBottom: 12,
  lineHeight: "22px",
  color: "var(--dark-grey, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 12,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});
const ShadowBox = styled(Box)({
  padding: 32,
  borderRadius: 12,
  marginBottom: 32,
  background: "#FFF",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});
export const Spacer32 = styled("div")({
  height: 32,
});
const MainContainer = styled('div')({
  paddingTop:'25px',
  "& .backBtn":{
    width:"126px",
    height:"56px",
    fontFamily: "Montserrat",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    border:"none",
    outline:"none",
    backgroundColor: "#204B9C",
    color: "white",
    "@media only screen and (max-width: 1024px)": {
      width: 126,
      height: 44,
      fontWeight: 600,
    }
  }
});
export const ChipContainer = styled("div")({
  "& > div": {
    marginRight: 8,
    "@media only screen and (max-width: 1024px)": {
      marginBottom: 12,
    },
  },
});

export const HeadingContainer = styled("div")({
  gap: 32,
  paddingBottom: 25,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > div, & > h2": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "@media only screen and (max-width: 550px)": {
    flexFlow: "flex-flow",
    justifyContent: "flex-start",
  },
});
export const Heading = styled("h2")({
  color: "#000",
  fontSize: 24,
  fontWeight: 600,
  paddingBottom: 25,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});
export const BottomContainer = styled("div")({
  gap: 32,
  display: "flex",
  paddingTop: 48,
  "& > button": {
    fontSize: 16,
    padding: 16,
    minWidth: 126,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  "& > .add": {
    color: "white",
    background: "var(--primary, #204B9C)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
  "& > .cancel": {
    color: "#64748B",
    background: "var(--primary, #F1F5F9)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
});
export const GridContainer = styled("div")({
  display: "grid",
  gridGap: 32,
  gridTemplateColumns: "1fr 1fr",
});
// Customizable Area End
