// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { ClassNameMap } from "@material-ui/styles";

export const configJSON = require("./config");
import { IStore } from "./CfStoreManagmentController";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { uniqBy, toString, debounce, concat } from "lodash";
import { getNewOptionsListFromSelection } from "../../../blocks/cfsalesdashboard3/src/utils";
import type { Color } from '@material-ui/lab/Alert'

export enum FormModeStore {
  Create,
  Edit,
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

interface ICompany {
  id: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface IPriceList {
  id: string;
  option: string;
}

interface IStoreKeeper {
  id: string;
  type: "account";
  attributes: {
    activated: boolean;
    first_name: string;
    last_name: string;
  };
}

export interface IFormStore {
  region_id: number | null;
  company_id: number | null;
  price_list: IPriceList | null;
  area_id: number | null;
  store_name: string;
  store_cr_number:string;
  store_id: string;
  store_address: string;
  store_address_arabic?: string;
  full_ip_phone_number: string;
  email: string;
  plant_ids: (string | number)[];
  service_offer_ids: (string | number)[];
  sections: IMultiSelectOption[];
  store_keeper_ids: string[] | null;
  store_capacity: number | null;
  latitude: number | null;
  gps_location: string;
  longitude: number | null;
  store_times_attributes: {
    _destroy?: boolean;
    id?: number;
    label: string;
    day: string;
    status: string;
    start_time: string;
    end_time: string;
    is_weekend: boolean;
    end_day: string;
  }[];
  selectAllPlant?: boolean;
  selectAllService?: boolean;
  selectAllSection?: boolean;
  selectAllStoreKeepers?: boolean;
  selectedSectionsForList: IMultiSelectOption[];
  paymentPopUpEnabled: boolean;
  preferencePopUpEnabled: boolean;
  store_keeper_name?: string;
  telephone_number?: string;
  full_ip_phone_number_arabic?: string;
  sub_cr_number?: string;
  sub_cr?: string;
  ar_sub_cr_number?: string;
  street_name?: string;
  street_name_arabic?: string;
  additional_street_name?: string;
  additional_street_name_arabic?: string;
  building?: string;
  building_arabic?: string;
  plot?: string;
  plot_arabic?: string;
  city?: string;
  city_arabic?: string;
  city_subdivision?: string;
  city_subdivision_arabic?: string;
  postal_code?: string;
  postal_code_arabic?: string;
  country_sub_entity?: string;
  country_sub_entity_arabic?: string;
  ip_phone_prefix_en?: string;
  ip_phone_prefix_ar?: string;
}

export interface ISentFormStore {
  region_id: number | null;
  area_id: number | null;
  store_name: string;
  store_id: string;
  store_address: string;
  full_ip_phone_number: string;
  telephone_number?: string;
  full_ip_phone_number_arabic?: string;
  email: string;
  plant_ids: (string | number)[];
  service_offer_ids: (string | number)[];
  store_capacity: number | null;
  ip_phone_prefix_en: string;
  ip_phone_prefix_ar: string;
  gps_location: string;
  ar_sub_cr_number: string;
  sub_cr_number: string;
  address_attributes: {
    latitude: string;
    longitude: string;
    sub_cr?: string;
    street_name: string;
    additional_street_name?: string;
    building: string;
    plot: string;
    city: string;
    city_subdivision: string;
    postal_code: string;
    country_sub_entity?: string;
  };
  store_times_attributes: {
    id?: number | null;
    day: string;
    status: string;
    start_time: string;
    end_time: string;
    is_weekend: boolean;
  }[];
}

export interface IRenderViewStore {
  total_capicity_in_kg: string;
  total_capicity_in_piece: string;
  name: string;
  region_id: number | null;
}

export interface IOptionListItem {
  id: string;
  option: string;
}

type IMultiSelectOption = {
  id: string;
  option: string;
}

export interface Props {
  classes: Record<string,string>;
  navigation: {
    navigate: (routeName: string) => void,
    goBack:()=>void,
  };
}

export interface TimeState {
  startDay: string;
  endDay: string;
  status: string;
  shifts: {
    id?: number | null;
    label: string;
    start_time: string;
    end_time: string;
  }[];
  closedDays: string[];
}

const getDummyTime = () => {
  const start_time = Date.now() - (Date.now() % (15 * 60 * 1000));
  const end_time = start_time + 15 * 60 * 1000;

  return {
    start_time: new Date(start_time).toString(),
    end_time: new Date(end_time).toString(),
  };
};

interface S {
  formErrors: { [key: string]: string };
  form: IFormStore;

  selectedPlantLinked:IOptionListItem[]
  plantLinkedSelectedOptionForList: IOptionListItem[];
  isPlantLinkedSelectAll: boolean;

  selectedServiceOffer:IOptionListItem[]
  serviceOfferSelectedOptionForList: IOptionListItem[];
  isServiceOfferSelectAll: boolean;

  selectedStoreKeeper:IOptionListItem[]
  storeKeeperSelectedOptionForList: IOptionListItem[];
  isStoreKeeperSelectAll: boolean;

  regionList: IOptionListItem[];
  areaList: IOptionListItem[];
  plantLinkedList: IOptionListItem[];
  serviceOfferList: IOptionListItem[];
  sectionsList: IMultiSelectOption[];
  sectionPage: number;
  sectionAutoCompleteValue: string;
  isLoading: boolean;
  isSubmitting: boolean;
  formMode: FormModeStore;
  snackbarOpenStore: boolean;
  snackbarMessageType: Color;
  snackbarMessageStore: string;
  serviceOfferIds: number[];
  openTimeModal: boolean;
  days: string[];
  isEditLoading: boolean;
  activeTimeTab: number;
  weekendTimes: TimeState;
  weekdaysTimes: TimeState;
  storeDetail: IStore;
  companies: ICompany[];
  priceList: IPriceList[];
  priceListPage: number;
  priceListSearchText: string;
  storeKeepers: IOptionListItem[];
  showReleaseStoreKeepersModal: boolean;
  permissionStatus: PermissionStatus;

}
export interface OptionType {
  id: number ;
  name: string;
}

interface Shift {
  id?: number | null;
  start_time: string;
  end_time: string;
  label: string;
}
interface SS {
  id: any;
}
// Customizable Area End

export default class StoreAddController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  itemsPerPage = 10;
  getRegionListCallId: string = "";
  getStoreKeeperListCallId: string = "";
  getPriceListCallId: string = "";
  getCompanyListCallId: string = "";
  getAreaListCallId: string = "";
  getPlantListCallId: string = "";
  checkStoreKeeperCallId: string = "";
  releaseStoreKeeperCallId: string = "";
  getServiceOfferListCallId: string = "";
  getSectionsListCallId: string = "";
  passwordText: string = "password";
  disableLoadMoreSection = false;
  disableLoadMorePriceList = false;

  private formInitialValues: IFormStore = {
    store_keeper_ids: [],
    price_list: null,
    company_id: null,
    region_id: null,
    area_id: null,
    store_name: "",
    store_cr_number:"",
    store_id: "",
    store_address: "",
    full_ip_phone_number: "",
    telephone_number: "",
    full_ip_phone_number_arabic: "",
    email: "",
    plant_ids: [],
    service_offer_ids: [],
    sections: [],
    store_capacity: null,
    latitude: null,
    longitude: null,
    gps_location: "",
    ip_phone_prefix_ar: '+966',
    ip_phone_prefix_en: '+966',
    store_times_attributes: [
      {
        label: "Shift",
        day: "Monday",
        status: "Open",
        start_time: getDummyTime().start_time,
        end_time: getDummyTime().end_time,
        is_weekend: false,
        end_day: "Thursday",
      },
      {
        label: "Shift",
        day: "Friday",
        status: "open",
        start_time: getDummyTime().start_time,
        end_time: getDummyTime().end_time,
        is_weekend: true,
        end_day: "Sunday"
      },
    ],
    selectAllPlant: false,
    selectAllService: false,
    selectAllSection: false,
    selectedSectionsForList: [],
    paymentPopUpEnabled: false,
    preferencePopUpEnabled: false,
  };

  public RequestMessage = {
    CreateStore: this.buildRequestMessage(Method.POST),
    UpdateStore: this.buildRequestMessage(Method.PATCH),
    GetRegionList: this.buildRequestMessage(Method.GET),
    getStoreKeeperList: this.buildRequestMessage(Method.GET),
    GetPriceList: this.buildRequestMessage(Method.GET),
    GetCompanyList: this.buildRequestMessage(Method.GET),
    GetAreaList: this.buildRequestMessage(Method.GET),
    GetServiceOfferList: this.buildRequestMessage(Method.GET),
    GetSectionsList: this.buildRequestMessage(Method.GET),
    GetPlantList: this.buildRequestMessage(Method.GET),
    CheckStoreKeeper: this.buildRequestMessage(Method.GET),
    GetStoreDetail: this.buildRequestMessage(Method.GET),
    GetStoreSuggestion: this.buildRequestMessage(Method.GET),
    PutReleaseStoreKeeper: this.buildRequestMessage(Method.PUT),
    Null: undefined ,
  };
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      plantLinkedSelectedOptionForList:[],
      selectedPlantLinked: [],
      isPlantLinkedSelectAll: false,

      serviceOfferSelectedOptionForList:[],
      selectedServiceOffer: [],
      isServiceOfferSelectAll: false,
      isEditLoading: false,
      storeKeeperSelectedOptionForList:[],
      selectedStoreKeeper: [],
      isStoreKeeperSelectAll: false,

      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      showReleaseStoreKeepersModal: false,
      storeKeepers: [],
      priceList: [],
      priceListPage: 1,
      priceListSearchText: "",
      companies: [],
      formErrors: {},
      form: { ...this.formInitialValues },
      regionList: [],
      areaList: [],
      serviceOfferList: [],
      sectionsList: [],
      sectionAutoCompleteValue: '',
      sectionPage: 1,
      plantLinkedList: [],
      isLoading: false,
      isSubmitting: false,
      formMode: FormModeStore.Create,
      snackbarOpenStore: false,
      snackbarMessageType: "info",
      snackbarMessageStore: "",
      serviceOfferIds: [],
      openTimeModal: false,
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      activeTimeTab: 0,
      weekendTimes: {
        startDay: "Friday",
        endDay: "Saturday",
        status: "close",
        shifts: [],
        closedDays: [],
      },
      weekdaysTimes: {
        startDay: "Sunday",
        endDay: "Thursday",
        status: "open",
        shifts: [
          {
            label: "Shift",
            start_time: getDummyTime().start_time,
            end_time: getDummyTime().end_time,
          },
        ],
        closedDays: [],
      },
      storeDetail: {
        id: "",
        type: "",
        attributes: {
          is_closed: false,
          store_keepers: { data: [] },
          price_list_id: null,
          company: undefined,
          activated: false,
          id: null,
          store_name: "",
          store_cr_number:"",
          created_at: "",
          updated_at: "",
          email: "",
          store_id: "",
          store_keeper_ids: [],
          full_ip_phone_number: "",
          gps_location: "",
          telephone_number: "",
          full_ip_phone_number_arabic: "",
          plants: {
            data: [
              {
                id: null,
                name: "",
                region_id: null,
                total_capicity_in_kg: null,
                total_capicity_in_piece: null,
                created_at: "",
                updated_at: "",
                active: false,
                attributes:{
                  name:''
                }
              },
            ],
          },
          service_offers: [],
          area: {
            id: null,
            area_name: "",
            region_id: null,
            created_at: "",
            updated_at: "",
            activated: false,
          },
          store_address: "",
          store_address_arabic: "",
          area_id: null,
          store_times: [],
          address: {
            id: null,
            country: null,
            latitude: null,
            longitude: null,
            address: "",
            addressble_id: null,
            addressble_type: "",
            address_type: null,
            created_at: "",
            updated_at: "",
            location: false,
          },
          store_capacity: null,
          region_id: null,
          model_name: "",
          store_configuration: undefined,
        },
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseStore = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (callID) {
      case this.RequestMessage.GetRegionList.messageId:
        this.handleGetRegionListResponse(responseStore);
        break;

      case this.RequestMessage.GetAreaList.messageId:
        this.handleGetAreaListResponse(responseStore);
        break;

      case this.RequestMessage.GetServiceOfferList.messageId:
        this.handleGetServiceOfferListResponse(responseStore);
        break;
      
      case this.RequestMessage.GetSectionsList.messageId:
        this.handleGetSectionsListResponse(responseStore);
        break;

      case this.RequestMessage.GetPlantList.messageId:
        this.handleGetPlantListResponse(responseStore);
        break;

      case this.RequestMessage.GetStoreDetail.messageId:
        this.handleGetStoreDetailResponse(responseStore);
        break;

      case this.RequestMessage.CreateStore.messageId:
        this.handleCreateStoreResponse(responseStore,"create");
        break;

      case this.RequestMessage.UpdateStore.messageId:
        this.handleCreateStoreResponse(responseStore,"update");
        break;

      case this.RequestMessage.GetStoreSuggestion.messageId:
        this.handleSuggestionResponse(responseStore);
        break;
      case this.getCompanyListCallId:
        this.handleCompanyResponse(responseStore);
        break;
      case this.getPriceListCallId:
        this.handlePriceListResponse(responseStore);
        break;
      case this.getStoreKeeperListCallId:
        this.handleStoreKeeperListResponse(responseStore);
        break;
      case this.checkStoreKeeperCallId:
        this.handleStoreKeeperCheckResponse(responseStore);
        break;
    }

    // Customizable Area End
  }
  // Customizable Area Start

  private handleGetRegionListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; branch_name: string };
    }[];
  }) {
    if (response && response.data) {
      const regionArray = response.data.map((item) => ({
        id: String(item.attributes.id),
        option: item.attributes.branch_name,
      }));

      this.setState({
        isLoading: false,
        regionList: regionArray || [],
      });
    }
  }

  private handleGetAreaListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; area_name: string; activated: boolean };
    }[];
  }) {
    if (response && response.data) {
      const areaArray = response.data
        .map((item) => ({
          id: String(item.attributes.id),
          option: item.attributes.area_name,
        }));

      this.setState({
        isLoading: false,
        areaList: areaArray || [],
      });
    }
  }

  private handleGetServiceOfferListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; label: string };
    }[];
  }) {
    if (response && response.data) {
      const serviceOfferArray = response.data.map((item) => ({
        id: String(item.attributes.id),
        option: item.attributes.label,
      }));

      this.setState({
        isLoading: false,
        serviceOfferList: serviceOfferArray || [],
      });
    }
  }

  private handleGetSectionsListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; section_name: string };
    }[];
  }) {
    this.disableLoadMoreSection = (response.data?.length || 0) < this.itemsPerPage
    if (response && response.data) {
      const {form, sectionPage, sectionsList} = this.state
      const sectionsArray = response.data.map((item) => ({
        id: toString(item.attributes.id),
        option: item.attributes.section_name,
      }));
      const currentSelectedSections = form.selectedSectionsForList.filter(item => item.id !== "-1")
      this.setState({
          sectionsList: uniqBy([...(sectionPage === 1 ? currentSelectedSections : sectionsList), ...sectionsArray],"id"),
          form: form.selectAllSection ? {
            ...form,
            selectedSectionsForList: uniqBy([
              ...form.selectedSectionsForList,
              ...sectionsArray
            ], "id")
          } : form
      })
    }
  }

  private handleGetPlantListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; name: string };
    }[];
  }) {
    if (response && response.data) {
      const plantArray = response.data.map((item) => ({
        id: String(item.attributes.id),
        option: item.attributes.name,
      }));

      this.setState({
        isLoading: false,
        plantLinkedList: plantArray || [],
      });
    }
  }

  private handleGetStoreDetailResponse(response: { data: IStore }) {
    if (response) {
      const {
        data: {
          attributes: {
            region_id,
            area,
            store_name,
            store_cr_number,
            store_id,
            store_address,
            store_address_arabic,
            gps_location="",
            full_ip_phone_number,
            ip_phone_prefix_ar,
            ip_phone_prefix_en,
            telephone_number,
            full_ip_phone_number_arabic,
            email,
            plants,
            service_offers,
            store_capacity,
            address,
            sub_cr_number,
            ar_sub_cr_number,
            store_times,
            company,
            store_keepers,
            store_configuration,
          },
        },
      } = response;

      const storeDetailFormdata = {
        store_keeper_ids: [],
        region_id,
        area_id: area.id,
        store_name,
        store_cr_number,
        ar_sub_cr_number,
        store_id,
        store_address,
        store_address_arabic,
        full_ip_phone_number,
        sub_cr_number,
        gps_location,
        telephone_number,
        full_ip_phone_number_arabic,
        ip_phone_prefix_ar : ip_phone_prefix_ar ? ip_phone_prefix_ar : "+966",
        ip_phone_prefix_en : ip_phone_prefix_en ? ip_phone_prefix_en : "+966",
        price_list: store_configuration?.attributes?.price_list ? {
          id: store_configuration.attributes.price_list.data.id,
          option: store_configuration.attributes.price_list.data.attributes.name
        } : null,
        company_id: company?.id || null,
        email,
        plant_ids:[],
        service_offer_ids: [],
        sections: store_configuration?.attributes?.sections?.map((item) => ({
          id: item.id.toString(),
          option: item.name
        })) || [],
        store_capacity,
        latitude: address?.latitude,
        longitude: address?.longitude,
        additional_street_name: address?.additional_street_name,
        additional_street_name_arabic: address?.additional_street_name_arabic,
        street_name: address?.street_name,
        street_name_arabic: address?.street_name_arabic,
        building: address?.building,
        building_arabic: address?.building_arabic,
        plot: address?.plot,
        plot_arabic: address?.plot_arabic,
        city: address?.city,
        city_arabic: address?.city_arabic,
        city_subdivision: address?.city_subdivision,
        city_subdivision_arabic: address?.city_subdivision_arabic,
        postal_code: address?.postal_code,
        country_sub_entity: address?.country_sub_entity, 
        postal_code_arabic: address?.postal_code_arabic,
        country_sub_entity_arabic: address?.country_sub_entity_arabic, 

        store_times_attributes: store_times?.map((item) => {
          return {
            id: item.id as number,
            day: item.day,
            status: item.status,
            start_time: item.start_time,
            end_time: item.end_time,
            label: item.label,
            is_weekend: item.is_weekend,
            end_day: item.end_day,
          };
        }),
        paymentPopUpEnabled: !!store_configuration?.attributes
          .payment_method_screen,
        preferencePopUpEnabled: !!store_configuration?.attributes
          .item_preference_popup,
      };

      this.setState(
        {
          form: {
            ...storeDetailFormdata,
            selectAllPlant:
              plants.data?.length === this.state?.plantLinkedList?.length,
            selectAllService:
              service_offers?.length === this.state?.serviceOfferList?.length,
            selectAllSection: false,
            selectedSectionsForList: storeDetailFormdata.sections,
            selectAllStoreKeepers:
              store_keepers.data.length === this.state?.storeKeepers?.length,
          },
          isLoading: false,
          storeDetail: response.data,
          sectionsList: storeDetailFormdata.sections,
          selectedPlantLinked: plants.data.map((item) => ({ id: String(item.id), option: item.attributes.name })),
          plantLinkedSelectedOptionForList: plants.data.map((item) => ({ id: String(item.id), option: item.attributes.name })),
          selectedServiceOffer: service_offers.map((item) => ({ id: String(item.id), option: item.label })),
          serviceOfferSelectedOptionForList: service_offers.map((item) => ({ id: String(item.id), option: item.label })),
          selectedStoreKeeper: store_keepers.data.map((item) => ({ id: String(item.id), option: item.attributes.full_name })),
          storeKeeperSelectedOptionForList:store_keepers.data.map((item) => ({ id: String(item.id), option: item.attributes.full_name }))
        },
        () => {
          if (this.state.formMode === FormModeStore.Edit) {
            this.getPriceListCall()
            this.getSectionsListCall()
          }
          this.processTimeData()
          this.setPlantLinkedIntoForm()
          this.setState({isEditLoading:false})
        }
      );
    }
  }

  private handleCreateStoreResponse(response: { data: { id: string }; full_messages: string[] },apicall:string) {
    if (response && response.data && response.data.id) {
      this.setState({
        isSubmitting: false,
        snackbarOpenStore: true,
        snackbarMessageType: 'success',
        snackbarMessageStore: apicall === "update" ? "Data updated successfully" : "Data added successfully",
      }, () => { 
        setTimeout(() => { this.setState({ isEditLoading: false})
       this.props.navigation.navigate("StoreList")}, 1000);  });
    } else {
      this.setState({
        isSubmitting: false,
        isLoading: false,
        isEditLoading: false,
        snackbarOpenStore: true,
        snackbarMessageType: 'error',
        snackbarMessageStore: response && response.full_messages[0],
      });
    }
  }

  private handleSuggestionResponse(response: { data: IStore; full_messages: string[] }) {
    if (response && response.data) {
      const suggestionData = response.data as IStore;
      const store = { ...this.state.form };
      store.store_id = suggestionData.attributes.store_id;
      this.setState({
        form: store,
      });
    }
  }

  handleCompanyResponse(response: { data: ICompany[]; full_messages: string[] }) {
    if (response && response.data) {
      this.setState({
        companies: response.data,
      });
    }
  }

  handlePriceListResponse(response: 
    { 
      data: {
        id: string;
        attributes: {
        id: number;
        name: string;
        }
      }[]; 
      full_messages: string[] 
    }) {
      
    if (response && response.data) {
      const {form, priceList, priceListPage} = this.state
      const receivedPriceLists = response.data.map(item => ({id: item.id, option: item.attributes.name}))
      this.disableLoadMorePriceList = receivedPriceLists.length < this.itemsPerPage
      const newPriceLists = priceListPage === 1 ? receivedPriceLists : concat(priceList, receivedPriceLists)
      form.price_list && newPriceLists.unshift(form.price_list)
      this.setState({
        priceList: uniqBy(newPriceLists,"id")
      });
      
    }
  }

  handleStoreKeeperListResponse(response: { data: IStoreKeeper[] }) {
    if (response && response.data) {
      const storeKeeperArray = response.data.map((item) => ({
        id: String(item.id),
        option: `${item.attributes.first_name} ${item.attributes.last_name}`,
      }));
      this.setState({
        storeKeepers: storeKeeperArray,
      });
    }
  }

  handleStoreKeeperCheckResponse(response: { message: string[] }) {
    if (response && response.message && response.message?.length > 0 && !response.message[0].includes(this.state.form.store_name)) {
      this.setState({
        showReleaseStoreKeepersModal: true,
      });
    }
  }

  processPath = (path: string, storeId: string) => {
    if (path === "/Settings-General-StoreListEdit") {
      this.setState({formMode: FormModeStore.Edit });
      this.getStoreDetailCall(storeId);
    } else if (path === "/Settings-General-StoreListView") {
      this.getStoreDetailCall(storeId);
    } else {
      this.getPriceListCall()
      this.getSectionsListCall()
    }
  };

  handleSnackbarStoreClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenStore: false });
  };

  onFormElementChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    property: string
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: event.target.value,
      },
    },()=>{
      if(property === "region_id"){
        this.setState({
          selectedPlantLinked:[],
          form:{
            ...this.state.form,
            area_id: null
        }})
      }
    });
  };

  onFormElementChange2 = (
    value: string | number,
    property: string
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: value,
      },
    },()=>{
      if(property === "region_id"){
        this.setState({
          selectedPlantLinked: [],
          form:{
            ...this.state.form,
            area_id: null
        }})
      }
    });
  };

  handleShiftTimeChange = (
    date: Date | null,
    index: number,
    is_weekend: boolean,
    startOrEnd: string
  ) => {
    if (is_weekend) {
      this.setState({
        weekendTimes: {
          ...this.state.weekendTimes,
          shifts: this.state.weekendTimes.shifts.map((storeTime, itemIndex) => {
            if (itemIndex === index) {
              return {
                ...storeTime,
                [startOrEnd]: date?.toString() || "",
              };
            }
            return storeTime;
          }),
        },
      });
    } else {
      this.setState({
        weekdaysTimes: {
          ...this.state.weekdaysTimes,
          shifts: this.state.weekdaysTimes.shifts.map((storeTime, itemIndex) => {
            if (itemIndex === index) {
              return {
                ...storeTime,
                [startOrEnd]: date?.toString() || "",
              };
            }
            return storeTime;
          }),
        },
      });
    }
  };

  handleDayChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    is_weekend: boolean,
    startOrEndDay: string
  ) => {
    if (!is_weekend) {
      this.setState({
        weekdaysTimes: {
          ...this.state.weekdaysTimes,
          [startOrEndDay]: event.target.value as string,
        },
      });
    } else {
      this.setState({
        weekendTimes: {
          ...this.state.weekendTimes,
          [startOrEndDay]: event.target.value as string,
        },
      });
    }
  };

  handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    is_weekend: boolean
  ) => {
    if (!is_weekend) {
      this.setState({
        weekdaysTimes: {
          ...this.state.weekdaysTimes,
          status: event.target.checked ? "open" : "close",
        },
      });
    } else {
      this.setState({
        weekendTimes: {
          ...this.state.weekendTimes,
          status: event.target.checked ? "open" : "close",
        },
      });
    }
  };

  handleCloseDayChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    dayString: string
  ) => {
    if (event.target.checked) {
      this.setState({
        weekendTimes: {
          ...this.state.weekendTimes,
          closedDays: [...this.state.weekendTimes.closedDays, dayString],
        },
      });
    } else {
      this.setState({
        weekendTimes: {
          ...this.state.weekendTimes,
          closedDays: this.state.weekendTimes.closedDays.filter(
            (item) => item !== dayString
          ),
        },
      });
    }
  };

  handleActiveTabClick = (activeTab: number) => {
    this.setState({
      activeTimeTab: activeTab,
    });
  };

  handleGetLocationClick = async () => {
    this.setState({ isLoading: true });

    if (!navigator.geolocation) {
      // Handle case where Geolocation API is not supported
      this.setState({ isLoading: false });
      return;
    }

    // SONARQUBE_EXCLUSION
    // Justification: Geolocation is necessary to provide location-based functionality.
    // This block handles obtaining the user's geolocation with their consent.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Get the latitude and longitude from the Geolocation API
          const { latitude, longitude } = position.coords;
          this.setState({
            form: {
              ...this.state.form,
              latitude,
              longitude,
            },
            isLoading: false,
          });
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleTimeModalOpen = () => {
    this.setState({
      openTimeModal: true,
    });
  };

  handleTimeModalClose = () => {
    this.processTimeData();
    this.setState({
      openTimeModal: false,
    });
  };

  handleTimeModalSave = () => {
    this.setState({
      openTimeModal: false,
    });
  };

  processTimeData = () => {
    const form = this.state.form;
    const newWeekendTimes = { ...this.state.weekendTimes };
    const newWeekdaysTimes = { ...this.state.weekdaysTimes };

    const weekDayData = form.store_times_attributes.filter(
      (item) => item.is_weekend === false
    );
    newWeekdaysTimes.startDay = weekDayData[0]?.day || "Sunday";
    newWeekdaysTimes.endDay =  weekDayData[0]?.end_day || "Thursday";

    if (newWeekdaysTimes.startDay === newWeekdaysTimes.endDay) {
      newWeekdaysTimes.endDay = "Thursday";
    }
    newWeekdaysTimes.status = weekDayData[0]?.status || "open";
    const firstDay = weekDayData[0]?.day || "Sunday";
    newWeekdaysTimes.shifts = weekDayData
      .filter((element) => element.day === firstDay)
      .map((item) => {
        return {
          id: item.id || null,
          start_time: item.start_time,
          end_time: item.end_time,
          label: item.label,
        };
      });

    const weekendDayData = form.store_times_attributes.filter(
      (item) => item.is_weekend === true
    );
    newWeekendTimes.startDay = weekendDayData[0]?.day|| "Friday";
    newWeekendTimes.endDay =
      weekendDayData[0]?.end_day || "Saturday";
    if (newWeekendTimes.startDay === newWeekendTimes.endDay) {
      newWeekendTimes.endDay = "Saturday";
    }

    newWeekendTimes.status = weekendDayData[0]?.status || "close";
    const weekendFirstDay = weekendDayData[0]?.day || "Friday";
    newWeekendTimes.shifts = weekendDayData
      .filter((element) => element.day === weekendFirstDay)
      .map((item) => {
        return {
          id: item.id || null,
          start_time: item.start_time,
          end_time: item.end_time,
          label: item.label,
        };
      });

    newWeekendTimes.closedDays = weekendDayData
      .filter((item) => item.status === "close")
      .map((item) => item.day);

    this.setState({
      weekendTimes: newWeekendTimes,
      weekdaysTimes: newWeekdaysTimes,
    });
  };

  handleCancelClick = () => {
    this.processTimeData();
    this.props.navigation.goBack();
  };

  handleBackClick = () => {
    this.props.navigation.navigate("StoreList");
  };

  handleEditStoreClick = () => {
    this.props.navigation.navigate("StoreEdit");
  };

  checkEntriesForValidation = (formErrors: { [keyOf: string]: string }) => {
    const newFormErrors = { ...formErrors };
    Object.entries(this.state.form).forEach(([keyOf, value]) => {
      const notRequiredFields = [
        "selectAllService",
        "store_cr_number",
        "selectAllPlant",
        "selectAllStoreKeepers",
        "store_keeper_ids",
        "sections",
        "selectAllSection",
        "selectedSectionsForList",
        "store_times_attributes",
        "service_offer_ids",
        "paymentPopUpEnabled",
        "preferencePopUpEnabled",
        "full_ip_phone_number_arabic",
        "telephone_number",
        "additional_street_name_arabic",
        "street_name_arabic",
        "building_arabic",
        "plot_arabic",
        "city_arabic",
        "city_subdivision_arabic",
        "postal_code_arabic",
        "country_sub_entity_arabic",
        "gps_location",
        "ip_phone_prefix_ar",
        "city",
        "city_subdivision",
        "plot",
        "building",
        "additional_street_name",
        "street_name",
        "postal_code",
        "country_sub_entity",
        "sub_cr_number",
        "ar_sub_cr_number",
        "store_address_arabic"
      ];
      if (notRequiredFields.includes(keyOf)) {
        return;
      }

      if (
        !value ||
        value === undefined ||
        value === "" ||
        value === "null" ||
        value.length === 0
      ) {
        newFormErrors[keyOf] = configJSON.fieldRequiredValidText;
      }
    });

    return newFormErrors;
  };

  getValue = (field = "") => {
    return field
  }
  handleOnSubmit = () => {
    let formErrors: { [keyOf: string]: string } = {};
    if (!this.state.form || this.state.isSubmitting) {
      return;
    }
    this.setState({isEditLoading:true})

    formErrors = this.checkEntriesForValidation(formErrors);

    if (Object.keys(formErrors).length > 0) {
      this.setState({isEditLoading:false, formErrors, isSubmitting: false });
      return;
    }

    const { form } = this.state;
    const {
      latitude,
      longitude, 
      gps_location,
      additional_street_name,
      additional_street_name_arabic,
      building,
      building_arabic,
      plot,
      plot_arabic,
      city,
      city_arabic,
      city_subdivision,
      city_subdivision_arabic,
      postal_code,
      postal_code_arabic,
      country_sub_entity,
      country_sub_entity_arabic,
      street_name,
      street_name_arabic, 
      sub_cr,
      ar_sub_cr_number,
      sub_cr_number,
      ip_phone_prefix_en,
      ip_phone_prefix_ar,
      ...rest } = form;
    const store_times_attributes = this.generateStoreTimesAttributes();

    const sentForm = {
      ...rest,
      plant_ids: this.extractPlantLinkedData(),
      service_offer_ids: this.extractServiceOfferData(),
      ip_phone_prefix_ar: this.getValue(ip_phone_prefix_ar),
      ip_phone_prefix_en:  this.getValue(ip_phone_prefix_en),
      sub_cr_number:   this.getValue(sub_cr_number),
      ar_sub_cr_number:  this.getValue(ar_sub_cr_number),
      gps_location: `${gps_location}`,
      address_attributes: {
        id: this.state.storeDetail?.attributes?.address?.id,
        latitude: `${latitude}`,
        longitude: `${longitude}`,
        sub_cr: "",
        street_name:  this.getValue(street_name),
        street_name_arabic:  this.getValue(street_name_arabic),
        additional_street_name:  this.getValue(additional_street_name),
        additional_street_name_arabic:  this.getValue(additional_street_name_arabic),
        building:  this.getValue(building),
        building_arabic:  this.getValue(building_arabic),
        plot:  this.getValue(plot),
        plot_arabic:  this.getValue(plot_arabic),
        city:  this.getValue(city),
        city_arabic:  this.getValue(city_arabic),
        city_subdivision:  this.getValue(city_subdivision),
        city_subdivision_arabic:   this.getValue(city_subdivision_arabic),
        postal_code:  this.getValue(postal_code),
        postal_code_arabic:  this.getValue(postal_code_arabic),
        country_sub_entity:  this.getValue(country_sub_entity),
        country_sub_entity_arabic:  this.getValue(country_sub_entity_arabic),
      },
      store_times_attributes,
      account_ids: this.extractStoreKeeperData(),
      store_configuration_attributes: {
        id: "",
        payment_method_screen: this.state.form.paymentPopUpEnabled,
        price_list_id: Number(this.state.form.price_list?.id),
        item_preference_popup: this.state.form.preferencePopUpEnabled,
        sections: form.selectAllSection ? ["All"] : form.selectedSectionsForList.filter(item => item.id !== "-1").map(item => Number(item.id))
      },
      store_management_accounts_attributes: [],
    };
    this.setState({isLoading: true})

    if (window.location.pathname === "/Settings-General-StoreListEdit") {
      sentForm.store_configuration_attributes.id = this.state.storeDetail
        .attributes.store_configuration?.id as string;
      this.updateStoreCall(
        { data: { attributes: sentForm } },
        window.localStorage.getItem("store_id") as string
      );
    } else {
      this.createStoreCall({ data: { attributes: sentForm } });
    }
  };

  generateStoreTimesAttributes = () => {
    const store_times_attributes: {
      id?: number | null;
      label: string;
      day: string;
      status: string;
      start_time: string;
      end_time: string;
      is_weekend: boolean;
      end_day: string;
    }[] = [];

    const { weekendTimes, weekdaysTimes } = this.state;

    const addShiftsToStoreTimes = (
      shifts: Shift[],
      isWeekend: boolean
    ) => {
        shifts.forEach((shift) => {
          store_times_attributes.push({
            id: shift.id ? shift.id : null,
            day: isWeekend ? weekendTimes.startDay : weekdaysTimes.startDay,
            start_time: shift.start_time,
            end_time: shift.end_time,
            label: shift.label,
            is_weekend: isWeekend,
            status: isWeekend ? weekendTimes.status : weekdaysTimes.status,
            end_day: isWeekend ? weekendTimes.endDay : weekdaysTimes.endDay
          })
        });
    };
    addShiftsToStoreTimes(weekdaysTimes.shifts, false);
    addShiftsToStoreTimes(weekendTimes.shifts, true);
    return store_times_attributes;
  };

  fillFormErrors = () => {
    this.setState({
      formErrors: {
        region_id: "error",
        area_id: "error",
        store_name: "error",
        store_id: "error",
        store_address: "error",
        full_ip_phone_number: "error",
        email: "error",
        plant_linked_id: "error",
        service_offer_ids: "error",
        store_capacity: "error",
      },
    });
  };

  changeFormMode = (formMode: FormModeStore) => {
    this.setState({
      formMode,
    });
  };

  createStoreCall = (form: { data: { attributes: ISentFormStore } }) => {
    this.setState({ isSubmitting: true, formErrors: {} });

    this.RequestMessage.CreateStore.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(
      this.RequestMessage.CreateStore.id,
      this.RequestMessage.CreateStore
    );
  };

  updateStoreCall = (
    form: { data: { attributes: ISentFormStore } },
    store_id: string
  ) => {
    this.setState({ isSubmitting: true, formErrors: {} });

    this.RequestMessage.UpdateStore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.storeManagementApi}/${store_id}`
    );

    this.RequestMessage.UpdateStore.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(
      this.RequestMessage.UpdateStore.id,
      this.RequestMessage.UpdateStore
    );
  };

  getCompanyListCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetCompanyList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyEndPoint
    );

    this.getCompanyListCallId = this.RequestMessage.GetCompanyList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetCompanyList.id,
      this.RequestMessage.GetCompanyList
    );
  };

  getPriceListCall = () => {
    const searchQuery = this.state.priceListSearchText ? `&filter_by[name]=${this.state.priceListSearchText}` : '';

    this.disableLoadMorePriceList = true;
    this.getPriceListCallId = this.RequestMessage.GetPriceList.messageId;

    this.RequestMessage.GetPriceList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.priceListEndPoint}&page_no=${this.state.priceListPage}&per_page=${this.itemsPerPage}${searchQuery}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetPriceList.id,
      this.RequestMessage.GetPriceList
    );
  };

  //filter_by[role_name]=Store Keeper

  getRegionListCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetRegionList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.regionListApiEndPoint}?dropdown=true&company_id=${this.state.form?.company_id}`
    );

    this.getRegionListCallId = this.RequestMessage.GetRegionList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetRegionList.id,
      this.RequestMessage.GetRegionList
    );
  };

  getStoreKeepers = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.getStoreKeeperList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeKeeperEndPoint
    );

    this.getStoreKeeperListCallId = this.RequestMessage.getStoreKeeperList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.getStoreKeeperList.id,
      this.RequestMessage.getStoreKeeperList
    );
  };

  getAreaListCall = (region_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetAreaList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.areaListApiEndPoint}&region_id=${region_id}`
    );

    this.getAreaListCallId = this.RequestMessage.GetAreaList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetAreaList.id,
      this.RequestMessage.GetAreaList
    );
  };

  checkStoreKeeperCall = () => {
    this.RequestMessage.CheckStoreKeeper.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkStoreKeeper +
        "/?" +
        this.state.selectedStoreKeeper
          ?.map((item) => `account_ids[]=${item.id}`)
          .join("&")
    );
    this.checkStoreKeeperCallId = this.RequestMessage.CheckStoreKeeper.messageId;

    runEngine.sendMessage(
      this.RequestMessage.CheckStoreKeeper.id,
      this.RequestMessage.CheckStoreKeeper
    );
  };

  releaseStoreKeeperCall = () => {
    this.RequestMessage.PutReleaseStoreKeeper.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.releaseStoreKeepers
    );
    this.releaseStoreKeeperCallId = this.RequestMessage.PutReleaseStoreKeeper.messageId;
    this.RequestMessage.PutReleaseStoreKeeper.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        account_ids: this.state.selectedStoreKeeper.map(data=>data.id),
      })
    );

    runEngine.sendMessage(
      this.RequestMessage.PutReleaseStoreKeeper.id,
      this.RequestMessage.PutReleaseStoreKeeper
    );
  };

  getServiceOfferListCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetServiceOfferList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serviceOfferListApiEndPoint
    );

    this.getServiceOfferListCallId = this.RequestMessage.GetServiceOfferList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetServiceOfferList.id,
      this.RequestMessage.GetServiceOfferList
    );
  };

  getSectionsListCall = () => {
    this.disableLoadMoreSection = true;
    const searchQuery = this.state.sectionAutoCompleteValue? `&filter_by[query]=${this.state.sectionAutoCompleteValue}` : ''
    this.RequestMessage.GetSectionsList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.getSectionListApiEndPoint}?dropdown=true${searchQuery}&page_no=${this.state.sectionPage}&per_page=${this.itemsPerPage}`
    );

    this.getSectionsListCallId = this.RequestMessage.GetSectionsList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetSectionsList.id,
      this.RequestMessage.GetSectionsList
    );
  };

  getPlantListCall = (region_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPlantList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.plantEndPoint}?region_id=${region_id}&dropdown=true`
    );

    this.getPlantListCallId = this.RequestMessage.GetPlantList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetPlantList.id,
      this.RequestMessage.GetPlantList
    );
  };

  getStoreDetailCall = (store_id: string) => {
    this.setState({isEditLoading:true, isLoading: true });

    this.RequestMessage.GetStoreDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.storeManagementApi}/${store_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetStoreDetail.id,
      this.RequestMessage.GetStoreDetail
    );
  };

  getStoreSuggestion = () => {
    this.RequestMessage.GetStoreSuggestion.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeSuggestionApi
    );

    runEngine.sendMessage(
      this.RequestMessage.GetStoreSuggestion.id,
      this.RequestMessage.GetStoreSuggestion
    );
  };

  private buildRequestMessage(method: Method): Message {
    const requestMessageForStore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeManagementApi
    );

    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getStoreHeaderMessage()
    );
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessageForStore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessageForStore;
  }

  getStoreHeaderMessage() {
    const headerStore = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(headerStore);
  }

  async componentDidMount() {
    super.componentDidMount();

    this.getServiceOfferListCall();
    this.getRegionListCall();
    this.getCompanyListCall();
    this.getStoreKeepers();

    const path = window.location.pathname;
    const storeId = window.localStorage.getItem("store_id");
    if (path === "/Settings-General-StoreListAdd") {
      this.getStoreSuggestion();
    }
    this.processPath(path, storeId as string);
  }

  addShift = (label: "Shift" | "Break", is_weekend: boolean) => {
    if (is_weekend) {
      this.setState({
        weekendTimes: {
          ...this.state.weekendTimes,
          shifts: [
            ...this.state.weekendTimes.shifts,
            {
              label,
              start_time: getDummyTime().start_time,
              end_time: getDummyTime().end_time,
            },
          ],
        },
      });
    } else {
      this.setState({
        weekdaysTimes: {
          ...this.state.weekdaysTimes,
          shifts: [
            ...this.state.weekdaysTimes.shifts,
            {
              label,
              start_time: getDummyTime().start_time,
              end_time: getDummyTime().end_time,
            },
          ],
        },
      });
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.weekdaysTimes.shifts.length === 0) {
      this.addShift("Shift", false);
    }

    if (this.state.form.region_id !== prevState.form.region_id) {
      this.getAreaListCall(this.state.form.region_id + "");
      this.getPlantListCall(this.state.form.region_id + "")
    }

    if (this.state.form.company_id !== prevState.form.company_id) {
      this.getRegionListCall();
    }
  }

  onChangePaymentPopUp = (value: boolean) => {
    this.setState({
      form: {
        ...this.state.form,
        paymentPopUpEnabled: value,
      },
    });
  };

  onChangePreferencePopup = (value: boolean) => {
    this.setState({
      form: {
        ...this.state.form,
        preferencePopUpEnabled: value,
      },
    });
  };

  handleReleaseModalClose = () => {
    this.setState({
      selectedStoreKeeper:[],
      storeKeeperSelectedOptionForList:[],
      showReleaseStoreKeepersModal: false,
    });
  };

  handleReleaseModalConfirm = () => {
    this.setState({
      showReleaseStoreKeepersModal: false,
    });
    this.releaseStoreKeeperCall();
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.storeManagementPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleSectionMultiSelect = (option: { id: string, option: string }) => {
    const {form, sectionsList} = this.state
    const {selectedSectionsForList, selectAllSection} = form
    if (option.id === "-1") {
        this.setState({
          form: {
            ...form,
            ...selectAllSection ? {
              selectAllSection: false,
              sections: [],
              selectedSectionsForList: []
            } : {
              selectAllSection: true,
              sections: [{ id: "-1", option: "Select All" }],
              selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...sectionsList]
            }
            
          }
        })
    } else {
      const isValueContained = selectedSectionsForList.some((item) => item.id == option.id)
      const currentSelectedSections = selectedSectionsForList.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? currentSelectedSections.filter((item) => item.id != option.id) : [...currentSelectedSections, option]
      const isAllItemSelected = newItems.length === this.state.sectionsList.length
      this.setState({
        form: {
          ...form,
          selectAllSection: isAllItemSelected,
          sections: getNewOptionsListFromSelection(isAllItemSelected, newItems).onlySelectedAllValue,
          selectedSectionsForList: getNewOptionsListFromSelection(isAllItemSelected, newItems).combineSelectedList
        }
      })
    }
  };

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionAutoCompleteValue) return;
    this.setState({ sectionAutoCompleteValue: getValue, sectionPage: 1 }, () => {
      if (getValue.length < 1 || getValue.length > 2) {
        this.getSectionsListCall()
      }
    });
  }

  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ sectionPage: prevState.sectionPage + 1 }), () => {
        this.getSectionsListCall()
      })
    }
  };

  handleScrollPricelistDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - position <= 1.3 &&
      !this.disableLoadMorePriceList
      ) {
      this.setState({
        priceListPage: this.state.priceListPage + 1
      },
        () => this.getPriceListCall()
      )
    }
  }

  handlePriceListSearch = (getValue: string) => {
    if (getValue === this.state.priceListSearchText) return;
    if(getValue.length < 1 || getValue.length > 2) {
      this.setState({
        priceListSearchText: getValue,
        priceListPage: 1,
      }, () => this.getPriceListCall());
    }
  }

  handleChangeSelectedPriceList = (newSelectedPriceList: {id: string, option: string} | null) => {
    this.setState((prev) => ({
      form: {
        ...prev.form,
        price_list: newSelectedPriceList
      },
    }))
    if (!newSelectedPriceList) {
      this.debouncedFunction("", this.handlePriceListSearch)
    }
  }

  capitalizeFirstCharacter = (sentence: string) => {
    if(sentence === 'sub_cr_number') {
      return 'Sub - Cr Number'
    }
    let words = sentence.split('_');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    let capitalizedSentence = words.join(' ');
    return capitalizedSentence;
  }

  generateAddressFieldsData = () => {
    return configJSON.addressFields.map((field: string) => {
      return {
        fieldName: field,
        label: this.capitalizeFirstCharacter(field),
        placeholder: `${field === 'sub_cr_number' ? 'Enter VAT Number' : 'Enter ' + this.capitalizeFirstCharacter(field)} English`,
        leftOne: true,
        fieldNameAr: `${field === 'sub_cr_number' ? 'ar_sub_cr_number' : field + '_arabic'}`,
        labelAr: `${this.capitalizeFirstCharacter(field)} (Arabic)`,
        placeholderAr: `${field === 'sub_cr_number' ? 'Enter VAT Number' : 'Enter ' + this.capitalizeFirstCharacter(field)} Arabic`,
        leftOneAr: false,
        sixColumnAr: false,
        isNumericAr: false,
        dirRtlAr: true
      }
    })
  }

  handlePlantLinkedMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData = {} as { isPlantLinkedSelectAll: boolean, selectedPlantLinked: IOptionListItem[], plantLinkedSelectedOptionForList: IOptionListItem[] }
    if (option.id == "-1") {
      updateStateData = this.state.isPlantLinkedSelectAll ?
        { ...updateStateData, isPlantLinkedSelectAll: false, selectedPlantLinked: [], plantLinkedSelectedOptionForList: [] }
        :
        {
          ...updateStateData,
          isPlantLinkedSelectAll: true,
          selectedPlantLinked: [{ id: "All", option: "Select All" }],
          plantLinkedSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.plantLinkedList]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isPlantLinkedSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedPlantLinked = newItems
      updateStateData.plantLinkedSelectedOptionForList = newItems
    }
    this.setState(updateStateData,()=>this.setPlantLinkedIntoForm())
  };

  extractPlantLinkedData(){
    let selectedIds = this.state.plantLinkedSelectedOptionForList.filter((item)=> item.id !== "-1").map((item) => item.id)
    let data = !this.state.isPlantLinkedSelectAll ? 
    selectedIds :
    this.state.plantLinkedList.map((data) => data.id)
    return data
  }

  setPlantLinkedIntoForm = () => {
    this.setState({form:{...this.state.form,plant_ids:this.extractPlantLinkedData()}})
  }

  handleServiceOfferMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData = {} as { isServiceOfferSelectAll: boolean, selectedServiceOffer: IOptionListItem[], serviceOfferSelectedOptionForList: IOptionListItem[] }
    if (option.id == "-1") {
      updateStateData = this.state.isServiceOfferSelectAll ?
        { ...updateStateData, isServiceOfferSelectAll: false, selectedServiceOffer: [], serviceOfferSelectedOptionForList: [] }
        :
        {
          ...updateStateData,
          isServiceOfferSelectAll: true,
          selectedServiceOffer: [{ id: "All", option: "Select All" }],
          serviceOfferSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.serviceOfferList]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isServiceOfferSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedServiceOffer = newItems
      updateStateData.serviceOfferSelectedOptionForList = newItems
    }
    this.setState(updateStateData)
  };

  extractServiceOfferData(){
    let selectedIds = this.state.serviceOfferSelectedOptionForList.filter((item)=> item.id !== "-1").map((item) => item.id)
    let data = !this.state.isServiceOfferSelectAll ? 
    selectedIds :
    this.state.serviceOfferList.map((data) => data.id)
    return data
  }

  handleStoreKeeperMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData = {} as { isStoreKeeperSelectAll: boolean, selectedStoreKeeper: IOptionListItem[], storeKeeperSelectedOptionForList: IOptionListItem[] }
    if (option.id == "-1") {
      updateStateData = this.state.isStoreKeeperSelectAll ?
        { ...updateStateData, isStoreKeeperSelectAll: false, selectedStoreKeeper: [], storeKeeperSelectedOptionForList: [] }
        :
        {
          ...updateStateData,
          isStoreKeeperSelectAll: true,
          selectedStoreKeeper: [{ id: "All", option: "Select All" }],
          storeKeeperSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.storeKeepers]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isStoreKeeperSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedStoreKeeper = newItems
      updateStateData.storeKeeperSelectedOptionForList = newItems
    }
    this.setState(updateStateData)
  };

  extractStoreKeeperData(){
    let selectedIds = this.state.storeKeeperSelectedOptionForList.filter((item)=> item.id !== "-1").map((item) => item.id)
    let data = !this.state.isStoreKeeperSelectAll ? 
    selectedIds :
    this.state.storeKeepers.map((data) => data.id)
    return data
  }

  // Customizable Area End
}
