import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

export const configJSON = require("./config");

import PlantViewController, { Props } from "./PlantViewController.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = () => ({
  pageContainerView: {
    fontFamily: "Montserrat",
    background: "white",

    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
    },
  },
  formContainerView: {
    padding: 32,
    borderRadius: 12,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.3px",
      color: "#1A1A1A",
    },
    position: "relative",
  },
  pageTitleView: {
    fontSize: 24,
    marginTop: 32,
    fontWeight: 600,
    paddingLeft:32,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px'
    }
  },
  sectionFormContainerView: {
    marginTop: 24,
    padding: 24,
    border: "1px solid #ECECEC",
    position: "relative",
    borderRadius: 12,
  },
  inputRowContainerView: {
    paddingRight: 32,
  },
  viewSectionHeaderView: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  buttonView: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor: "#204B9C",
    color: "white",
    textAlign: "center",
    "@media only screen and (max-width: 1024px)": {
      width: "128px !important",
      height: "44px !important",
      padding: "10px !important",
      fontSize: "16px !important"
    }
  },
  errorInputView: {
    "& fieldset": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  selectContainerView: {
    height: 56,
  },
  sectionTitleView: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px'
    }
  },
  buttonWithIconView: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    background: "rgba(32, 75, 156, 0.1)",
    borderRadius: 8,
    color: "#4D4D4D",
    fontWeight: 600,
    fontSize: 16,
  },
  fieldValue : {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 4,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px'
    }
  }
});
// Customizable Area End

export class PlantView extends PlantViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTitle = () => {
    const { classes = {} } = this.props;
    return <div className={classes.pageTitleView}>{configJSON.plant}</div>;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes = {} } = this.props;
    const { plantDetail } = this.state;

    return (
      <>
        <PageContainer navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
       <CustomLoader loading={this.state.isLoading} />
          <Box>
            <Grid container direction='column' className={classes.pageContainerView}>
              <Grid
                container
                direction='row'
                spacing={4}
                style={{
                  marginBottom: 32,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {this.renderTitle()}
                {sortCondition(this.state.permissionStatus.editPermission, <div
                  data-test-id={"editPlant"}
                  onClick={this.handleEditPlantClick}
                  className={classes.buttonView}
                  style={{
                    width: 184,
                    marginTop: 32,
                  }}
                >
                  Edit
                </div>,
                <></>)}
              </Grid>
              <Grid container direction='column' className={classes.formContainerView}>
                <Grid container spacing={4} direction='row'>
                  <Grid item xs={12} md={6} style={{ paddingRight: 32 }}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>Plant Name</FormLabel>
                      <Typography
                       className={classes.fieldValue}
                      >
                        {plantDetail.attributes.name || configJSON.blank}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>Region</FormLabel>
                      <Typography
                        className={classes.fieldValue}
                      >
                        {plantDetail.attributes.region.branch_name || configJSON.blank}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={4} direction='row' style={{ marginTop: 16 }}>
                  <Grid item xs={12} md={6} style={{ paddingRight: 32 }}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Total Plant Capacity in Kg
                      </FormLabel>
                      <Typography
                       className={classes.fieldValue}
                      >
                        {plantDetail.attributes.total_capicity_in_kg || configJSON.blank}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Total Plant Capacity in Pieces
                      </FormLabel>
                      <Typography
                       className={classes.fieldValue}
                      >
                        {plantDetail.attributes.total_capicity_in_piece || configJSON.blank}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginTop: 32 }}
                className={classes.formContainerView}
              >
                <Grid item xs={12} md={6}>
                  <div className={classes.sectionTitleView}>Plant Sections</div>
                </Grid>

                {plantDetail.attributes.plant_selections.data.map((item, index) => (
                  <Grid container direction='column' className={classes.sectionFormContainerView}>
                    <Grid container spacing={4} direction='row'>
                      <div className={classes.viewSectionHeaderView}>{item.attributes.name}</div>
                    </Grid>
                    <Grid container spacing={4} direction='row' style={{ marginTop: 66 }}>
                      <Grid item xs={12} md={6}>
                        <FormControl style={{ width: "100%" }}>
                          <FormLabel style={{ marginBottom: "8px" }}>Capacity in Kg</FormLabel>
                          <Typography
                           className={classes.fieldValue}
                          >
                            {item.attributes.capicity_in_kg || configJSON.blank}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl style={{ width: "100%" }}>
                          <FormLabel style={{ marginBottom: "8px" }}>Capacity in Pieces</FormLabel>
                          <Typography
                            className={classes.fieldValue}
                          >
                            {item.attributes.capicity_in_piece || configJSON.blank}
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid container direction='row' style={{ marginTop: 48 }}>
                <div
                  data-test-id={"cancelBtn"}
                  onClick={this.handleCancelClick}
                  className={classes.buttonView}
                  style={{
                    marginRight: 32,
                  }}
                >
                  {configJSON.backText}
                </div>
              </Grid>
            </Grid>
          </Box>
        </PageContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(useStyles)(PlantView);
// Customizable Area End
