import React from "react";

// Customizable Area Start
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import { Box, styled, Typography, StyledComponentProps, IconButton } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterListRounded';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import FilterPopover from "../../../components/src/FilterPopover";
import CustomDonutChart from "../../../components/src/CustomDonutChart";
import { colors } from "../../../blocks/utilities/src/Colors";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import Skeleton from '@material-ui/lab/Skeleton';
import ExportModal from "../../../components/src//ExportModal";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import { storeNameBasedOnGroup } from "./utils";
// Customizable Area End

import CustomerDashboardController, {
    Props,
    configJSON,
    // Customizable Area Start
    PieGraphData
    // Customizable Area End
} from "./CustomerDashboardController";

export default class CustomerDashboard extends CustomerDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderBanner = (label: string, dataSet: PieGraphData, bgColor: string, amountSpentInput: boolean = false) => {
        if (dataSet.male === "" && dataSet.female === "") {
            return (
                <Skeleton data-test-id="skeleton_customer" variant="rect" width={"32%"} height={164} />
            )
        }
        return (
            <Box className="banner" style={{ background: bgColor }}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <FlexiTypo FS="16px" Weight={400} ColorTxt={colors().coolgray}>{label}</FlexiTypo>
                    <FlexiTypo FS="19px" Weight={500} ColorTxt={colors().darkjunglegreen}>{dataSet.total}</FlexiTypo>
                </Box>
                <Box className="pieGraphBox">
                    <Box className="pieGraph">
                        <CustomDonutChart
                            holeSize={0}
                            height={100}
                            width={100}
                            data={[
                                { label: 'Male', value: Number(dataSet.male), color: colors().darkblue },
                                { label: 'Female', value: Number(dataSet.female), color: colors().carnationPink }
                            ]}
                        />
                    </Box>

                    <Box className="graphActionBox" style={{justifyContent:amountSpentInput ? "space-between" :"end"}}>
                        {amountSpentInput && <Box className="amountSpentContainer">
                            <FlexiTypo FS="12px" Weight={400} ColorTxt="#334155">Amount spent ≥</FlexiTypo>
                            <Box className="inputContainer">
                                <input data-test-id="amtSpentInput" defaultValue={this.state.amountSpendValue} onChange={this.handleAmountChange} type="number" className="amountInput" />
                                <StyledSpan className="sarTxt" Weight={400} FS="12px" ColorTxt="#64748B">SAR</StyledSpan>
                            </Box>
                        </Box>}
                        <Box className="annotationBox">
                            {this.renderBullet("Male", colors().darkblue)}
                            {this.renderBullet("Female", colors().carnationPink)}
                        </Box>
                    </Box>

                </Box>
            </Box>
        )
    }

    renderBullet = (label: string, bulletColor: string) => {
        return (
            <Box className="annotation">
                <Box className="bullet" style={{ background: bulletColor }} />
                <StyledSpan FS="10px" Weight={400}>{label}</StyledSpan>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {groupId, customerCountsInactive, customerCountsNew, customerCountsRepeated, customerCountsTop, customerCountsTotal, customerWithPricelistCount, storeNames, regionName } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <PageContainer
                navigation={this.props.navigation}
                onMultiRegionChange={() => true}
                onStoreAndGroupChange={() => true}
                onUserChange={this.handleUserChange}
            >
                <DashboardTabPanel navigation={this.props.navigation} id="CustomerDashboard" permissionStatus={this.state.permissionStatus} />
                <StyledWrapper
                    id={sortCondition(this.state.openExportModal, "print-wrapper", undefined) as string}
                >
                    <Box>

                        <Box className="sectionOne">
                            <Box className="headerRowOne">
                                <Box className="headerRowOneSubCont">
                                    <FlexiTypo FS={"16px"} Weight={700}>
                                        Region : {" "}
                                        <StyledSpan FS={"16px"} Weight={400} ColorTxt={colors().darkjunglegreen}>
                                            {regionName}
                                        </StyledSpan>
                                    </FlexiTypo>

                                    <FlexiTypo className="ellispseStore" FS={"16px"} Weight={700}>
                                        Store : {" "}
                                        <StyledSpan FS={"16px"} Weight={400} ColorTxt={colors().darkjunglegreen}>
                                           {storeNameBasedOnGroup(this.state.storeId)}
                                        </StyledSpan>
                                    </FlexiTypo>
                                    <FlexiTypo className="ellispseStore" FS={"16px"} Weight={700}>
                                        Group : {" "}
                                        <StyledSpan FS={"16px"} Weight={400} ColorTxt="#0F172A">
                                           {getGroupNameDisplay(groupId)}
                                        </StyledSpan>
                                    </FlexiTypo>
                                </Box>
                                <Box className="headerActionCont">
                                    <div data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                                        <IconButton className="filterBtn">
                                            <FilterIcon />
                                        </IconButton>
                                    </div>

                                    <button className="exportBtn" type="button" onClick={() => this.handleExportModal()}>
                                        {configJSON.exportAll}
                                    </button>

                                    <FilterPopover
                                        data-test-id="filterPopover"
                                        onClose={() => { this.handleCloseFilterPopover() }}
                                        anchor={this.state.filterAnchor}
                                        onFilterChange={this.handleFilterChangeAccount}
                                        filters={this.state.filters}
                                    />
                                </Box>
                            </Box>
                            <Box className="headerRowTwo">
                                <FlexiTypo FS={"16px"} Weight={700}>
                                    Period : {" "}
                                    <StyledSpan FS={"16px"} Weight={400} ColorTxt={colors().darkjunglegreen}>
                                        {this.handleDateFilterParam().startDateText} _ {this.handleDateFilterParam().endDateText}
                                    </StyledSpan>
                                </FlexiTypo>
                            </Box>
                        </Box>

                        <Box className="sectionTwo-Banner">
                            <Box className="bannerHeaderCont">
                                <PeopleOutlineOutlinedIcon />
                                <FlexiTypo Weight={500} FS={"19px"}>Customers</FlexiTypo>
                            </Box>

                            <Box className="bannerContainer">
                                {this.renderBanner("Total Customers", customerCountsTotal, "#D8F1F9")}
                                {this.renderBanner("New Customers", customerCountsNew, "#F7F6C0")}
                                {this.renderBanner("Inactive Customers", customerCountsInactive, "#FAE1BF")}
                                {this.renderBanner("Repeated Customers", customerCountsRepeated, "#D3E4EA")}
                                {this.renderBanner("Most spending customer", customerCountsTop, "#E0D9F5",true)}
                                
                                {this.renderBanner("Customers with pricelist", customerWithPricelistCount, "#F9D8EC")}
                            </Box>

                        </Box>
                    </Box>
                </StyledWrapper>

                {
                    this.state.openExportModal && <ExportModal isOpen onCancel={this.handleExportModal} onConfirm={this.handleExportConfirm} />
                }

            </PageContainer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .google-visualization-tooltip": {
        "backgroundColor": "white",
        "border": "1px solid #ccc",
        "padding": "10px",
        "fontFamily": "'Arial', sans-serif",
        "boxShadow": "2px 2px 10px rgba(0, 0, 0, 0.1)",
        "borderRadius": "8px"
    },
    "& svg > g > g > g:last-child g g:last-child": {
        filter: " none !important"
    },
    "& .pieGraphBox": {
        marginTop: "15px",
        height: 100,
        width: "100%",
        display: "flex",
        gap: "16px"
    },
    "& .pieGraph": {
        position: 'relative',
        width: "120px",
        minWidth: "100px"
    },
    "& .pieHoleText": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%"
    },
    "& .banner": {
        "width": "32%",
        "minHeight": "164px",
        height:"auto",
        "padding": "12px 16px",
        "borderRadius": "8px",
        "background": "#D8F1F9"
    },
    "& .bullet": {
        height: "12px",
        width: "12px",
        borderRadius: "4px"
    },
    "& .femaleDiv": {
        height: "12px",
        width: "12px",
        backgroundColor: "#FF92A5",
        border: "0px, 0px, 1px, 0px",
        borderRadius: "4px",
        padding: "6px",
        margin: '2px'
    },
    "& .bannerContainer": {
        marginTop: "15px",
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        justifyContent:"space-between"
    },
    "& .bannerHeaderCont": {
        display: "flex",
        height: "48px",
        gap: "10px",
        alignItems: "center",
        borderBottom: "1px solid #ECECEC"
    },
    "& .sectionTwo-Banner": {
        border: "1px solid #ECECEC",
        "width": "100%",
        "padding": "16px",
        "borderRadius": "12px",
        margin: "20px 0"
    },
    "& .filterBtn": {
        height: "44px",
        width: "44px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        border: "1px solid #ECECEC",
        "& .MuiSvgIcon-root": {
            color: colors().darkliver
        }
    },
    "& .sectionOne": {
        marginTop: "0px"
    },
    "& .headerRowOne": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    "& .headerRowOneSubCont": {
        display: "flex",
        gap: "30px"
    },
    "& .headerRowTwo": {
    },
    "& .label-16-400": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .label-23-500": {
        fontSize: "23px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        color: "#0F172A"
    },
    "& .amountSpentContainer":{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    "& .graphActionBox":{
        width: "30%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between"
    },
    "& .inputContainer":{
        position:"relative"
    },
    "& .sarTxt":{
        position:"absolute",
        top:"7px",
        left:"10px"
    },
    "& .amountInput":{
        height:"28px",
        boxSizing:"border-box",
        border: "1px solid #94A3B8",
        borderRadius:"6px",
        width:"100%",
        padding: "5px 10px 5px 38px",
        fontSize:"12px",
        fontWeight:400,
        "-moz-appearance": "textfield",
        "&:focus": {
            outline: "none"
        }
    },
    "& .annotationBox": {
        display: "flex",
        gap: "4px",
        "@media only screen and (max-width: 1024px)": {
            flexDirection:"column",
            marginTop:"0.25rem"
          },
    },
    "& .annotation": {
        display: "flex",
        gap: "4px"
    },
    "& .ellispseStore":{
        "maxWidth": "380px",
        "textOverflow": "ellipsis",
        "display": "block",
        "whiteSpace": "nowrap",
        "overflow": "hidden"
    },
    "& .headerActionCont":{
        display:"flex",
        gap:"18px"
    },
    "& .exportBtn": {
        display: "flex",
        fontSize:"16px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        background: colors().lightgray,
        fontWeight: 600,
        cursor: "pointer",
        height:"44px",
        border:0,
        padding:"0 16px",
        color: colors().darkliver
    },
    "&#print-wrapper": {
        "& .headerActionCont": {
            display: "none"
        },
        "& .amountInput": {
            paddingTop:"2.5px"
        }
    }
})

export const FlexiTypo = styled(Typography)((props: StyledComponentProps & { Weight: number, FS: string, ColorTxt?: string }) => ({
    fontSize: props.FS,
    fontFamily: "Montserrat",
    fontWeight: props.Weight,
    color: props.ColorTxt
}));

export const StyledSpan = styled('span')((props: StyledComponentProps & { Weight: number, FS: string, ColorTxt?: string }) => ({
    fontSize: props.FS,
    fontFamily: "Montserrat",
    fontWeight: props.Weight,
    color: props.ColorTxt
}));
// Customizable Area End
