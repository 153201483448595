import React from "react";

// Customizable Area Start
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Box,
  Button,
  Popover,
  IconButton,
  styled,
} from "@material-ui/core";
import { lazy, Suspense } from "react";

import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import AccountListController, {
  IUser,
  Props,
  configJSON,
} from "./AccountListController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import { handleHeaderClick } from "../../RolesPermissions2/src/utils";
import { Skeleton } from "@material-ui/lab";
import { colors } from "../../../blocks/utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";


// Customizable Area End

export default class AccountList extends AccountListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDeactivateDialog() {
    const { clickedAccount, disableModalVisible} = this.state;
    return (
      <ConfirmationModal
        open={disableModalVisible}
        handleClose={this.handleDeactivateClose}
        handleConfirm={this.changeActivateUser}
        title='Confirm Deactivate'
        message={{
          id: clickedAccount?.id,
          title: `${clickedAccount?.attributes.first_name} ${clickedAccount?.attributes.last_name}`,
        }}
        dynamic={true}
        confirmText='Deactivate'
      />
    );
  }

  getNameAndRolePermission(user: IUser) {
    const { attributes: { first_name, last_name, permission_count: permission, role: roleData }} = user;
    const name = `${first_name} ${last_name}`;
    const role = roleData ? roleData.name : "--";
    return { name, role, permission };
  }

  // Customizable Area End
  render() {
    // Customizable Area Start

    const {
      permissionStatus,
      filterAnchor,
      filters,
      users,
      page,
      pageSize,
      sortingData,
      isUsersListLoading,
      popOverOpened,
      popOverTop,
      popOverLeft,
      popOverItemId,
      popOverItemStatus,
      disableModalVisible,
      deactivatedAccount,
      clickedAccount
     } = this.state;
    const rows = users.map((user) => {
      const { name, role, permission } = this.getNameAndRolePermission(user);
      const { attributes: { employee_proifle: { data }, activated}, id: userId } = user;
      const region =  data.attributes.region_names.reduce((prev, current, index) => {
          return (
            prev +
            current +
            (index === data.attributes.region_names.length - 1 ? "" : ", ")
          );
        }, "") || "--";
      const status = activated ? "Active" : "Deactivated";

      return this.createData(
        name,
        role,
        permission + "",
        region,
        status,
        userId
      );
    });

    const filteredRows = rows.slice(
      page * pageSize,
      (page + 1) * pageSize
    );

    const emptyRows = pageSize - filteredRows.length;

    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyle.mainWrapper}>
        <PageContainer
          onSearchText={(value) => this.handleSearchAccount(value)}
          navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          <StyledBoxWrapper>
            <Box>
              <div style={webStyle.headerWrapper}>
                <div className={"pageHeaderTitle"} data-test-id='user_account'>
                  User Accounts
                </div>
                <div style={webStyle.headerButtonPartAccount}>
                  <div
                    data-test-id='filterIcon'
                    onClick={(event) => {
                      this.setState({
                        filterAnchor: event.currentTarget,
                      });
                    }}
                    className={"filterButtonAccount"}
                  >
                    <FilterIcon />
                    {renderBaseOnConditions(
                      this.state.isAppliedFilter,
                      <div className="filterActiveIcon"></div>,
                      <></>
                    )}
                  </div>
                  
                  {
                    filterAnchor && (
                      <FilterPopover
                      data-test-id='filterPopover'
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      anchor={filterAnchor}
                      onFilterChange={this.handleFilterChangeAccount}
                      onAutoCompleteChange={(title: string, value: string) => {
                        this.handleFilterAutoCompleteChange(title, value);
                      }}
                      filters={this.state.filters}
                    />
                    )
                  }
                  {renderBaseOnConditions(
                    permissionStatus.createPermission,
                    <Button
                      data-test-id='addAccount'
                      onClick={this.handleAddUser}
                      className={"addButton"}
                    >
                      Add Account
                    </Button>,
                    <></>
                  )}
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table style={webStyle.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow
                      style={{ background: "#204B9C", color: "#FFFFFF" }}
                    >
                      <SortingTableHeader
                        data-test-id='nameSortingHeader'
                        sortingData={sortingData}
                        title='Name'
                        sortingKey='name'
                        {...this.sortingProps}
                        type='left'
                        handleHeaderClick={() => {
                          handleHeaderClick(
                            filters,
                            (updatedFilters: any) => {
                              this.handleSetAccountFilterDataOnSortClick(
                                updatedFilters
                              );
                            }
                          );
                        }}
                      />
                      <SortingTableHeader
                        data-test-id='roleSortingHeader'
                        sortingData={sortingData}
                        title='Role'
                        sortingKey='role'
                        {...this.sortingProps}
                        handleHeaderClick={() => {
                          handleHeaderClick(
                            this.state.filters,
                            (updatedFilters: any) => {
                              this.handleSetAccountFilterDataOnSortClick(
                                updatedFilters
                              );
                            }
                          );
                        }}
                      />
                      <SortingTableHeader
                        data-test-id='permissionSortingHeader'
                        sortingData={sortingData}
                        title='Permission'
                        sortingKey='permission'
                        {...this.sortingProps}
                        handleHeaderClick={() => {
                          handleHeaderClick(
                            this.state.filters,
                            (updatedFilters: any) => {
                              this.handleSetAccountFilterDataOnSortClick(
                                updatedFilters
                              );
                            }
                          );
                        }}
                      />
                      <SortingTableHeader
                        data-test-id='regionSortingHeader'
                        sortingData={sortingData}
                        title='Region/Business Area'
                        sortingKey='region_name'
                        {...this.sortingProps}
                        handleHeaderClick={() => {
                          handleHeaderClick(
                            this.state.filters,
                            (updatedFilters: any) => {
                              this.handleSetAccountFilterDataOnSortClick(
                                updatedFilters
                              );
                            }
                          );
                        }}
                      />

                      <SortingTableHeader
                        sortingData={sortingData}
                        title='Status'
                        sortingKey=''
                        {...this.sortingProps}
                        sortingDisabled
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title=''
                        type='action'
                        sortingKey=''
                        {...this.sortingProps}
                        sortingDisabled
                      />
                    </TableRow>
                  </TableHead>
                  
                  {!isUsersListLoading && filteredRows.length>0&&<TableBody data-test-id="tableData">
                      {filteredRows.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell width='22vw' type='left' content={rowData.name} />
                          <SortingTableCell width='22vw' type='middle' content={rowData.role} />
                          <SortingTableCell width='22vw' type='middle' content={rowData.permission} />
                          <SortingTableCell width='22vw' type='middle' content={rowData.region} />
                          <SortingTableCell width='23vw' type='right' content={rowData.status} />
                          <SortingTableCell
                            data-test-id = "moreCell"
                            width='22vw'
                            type='action'
                            content={
                              <IconButton
                                data-test-id={`moreButton-${rowData.userId}`}
                                id={"IconButton" + index}
                                onClick={(event) => {
                                  const position = event.currentTarget.getBoundingClientRect();
                                  this.setState({
                                    popOverItemId: rowData.userId,
                                    popOverItemStatus: rowData.status,
                                    popOverOpened: true,
                                    popOverLeft: position.left + window.scrollX,
                                    popOverTop: position.top + window.scrollY,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>}
                    {isUsersListLoading && 
                    <>{Array(10)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(6)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}

                           
                          </TableRow>
                        </TableBody>
                      ))}</>
                  }
                </Table>
                {!isUsersListLoading && 
                  filteredRows.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 601,
                      fontSize: 16,
                    }}
                  >
                    No records found!!
                  </div>
                )}
              </TableContainer>
              {filteredRows.length > 0 && (
                <TablePagination
                  data-test-id='pagination'
                  rowsPerPageOptions={[10]}
                  component='div'
                  count={rows.length}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(event, page) => {
                    this.handlePageChange(page);
                  }}
                />
              )}
            </Box>
          </StyledBoxWrapper>
          
          {
            popOverOpened && (
              <Popover
            data-test-id={configJSON.popoverTest}
            style={{
              boxShadow: "none",
            }}
            anchorReference='anchorPosition'
            open={popOverOpened}
            onClose={() => this.setState({ popOverOpened: false })}
            anchorPosition={{
              top: popOverTop + 40,
              left: popOverLeft - 80,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {renderBaseOnConditions(
              permissionStatus.viewPermission ||
                permissionStatus.editPermission ||
                permissionStatus.activatePermission ||
                permissionStatus.deactivatePermission,
              <div style={webStyle.popoverContainer}>
                {renderBaseOnConditions(
                  permissionStatus.viewPermission,
                  <Box
                    data-test-id='viewButton'
                    onClick={() =>
                      this.handleViewUser(popOverItemId)
                    }
                    sx={webStyle.popoverButton}
                  >
                    View
                  </Box>,
                  <></>
                )}
                {renderBaseOnConditions(
                  permissionStatus.editPermission,
                  <Box
                    data-test-id='editButton'
                    onClick={() =>
                      this.handleEditUser(popOverItemId)
                    }
                    sx={webStyle.popoverButton}
                  >
                    Edit
                  </Box>,
                  <></>
                )}
          
                   {
                      renderBaseOnConditions(
                        permissionStatus.deactivatePermission && popOverItemStatus === "Active",
                        <Box
                         data-test-id='activateButton'
                          sx={webStyle.popoverButton}
                          onClick={() => {
                            this.handleDeactivate(popOverItemId);
                          }}
                        >
                          Deactivate
                        </Box>,
                         <>{!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          permissionStatus.activatePermission &&
                          popOverItemStatus === "Active"
                        && !permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </PopoverContainer1>}</>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        permissionStatus.activatePermission as boolean &&  popOverItemStatus !== "Active",
                        <Box
                           data-test-id='activateButton'
                          sx={webStyle.popoverButton}
                          onClick={() => {
                            this.changeActivateUser();
                          }}
                        >
                          Activate
                        </Box>,
                        <>{!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          !permissionStatus.activatePermission &&
                          popOverItemStatus !== "Active"
                        && permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </PopoverContainer1>}
                      </>
                      )
                    }
              </div>,
              <Box style={webStyle.popoverContainer}>
                <Box sx={webStyle.noPermissions}>No permissions</Box>
              </Box>
            )}
          </Popover>
            )
          }
        </PageContainer>
        {disableModalVisible && this.renderDeactivateDialog()}

        {deactivatedAccount && (
          <ConfirmationModal
            open={deactivatedAccount}
            handleClose={this.handleDeactivatedAccountClose}
            handleConfirm={this.handleGoList}
            title={"Account Deactivated"}
            message={{
              id: clickedAccount?.id,
              title: `${clickedAccount?.attributes.first_name} ${clickedAccount?.attributes.last_name}`,
            }}
            confirmText={"Back to Listing"}
            displayItem={true}
          />
        )}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  table: {
    minWidth: 700,
  },
  mainWrapper: {
    fontFamily: "Montserrat",
  },
  pageHeader: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapper: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  contentWrapper: {
    marginRight: 32,
    marginTop: 56,
  },

  headerButtonPartAccount: {
    display: "flex",
  },

  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  noPermissions: {
    padding: "0 8px",
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
};

const PopoverContainer1 = styled("div")({
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
  "& .noPermissions": {
    padding: "0 8px",
  }
})

const StyledBoxWrapper = styled(Box)({
  paddingTop: "25px",
  "& .pageHeaderTitle": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color: colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors().cyancobaltblue,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%"
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  },
});

// Customizable Area End
