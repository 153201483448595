import React from "react";
// Customizable Area Start
import PlantListController, { Props, configJSON } from "./PlantListController.web";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Box,
  Button,
  Popover,
  IconButton,
  styled
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterIcon from "@material-ui/icons/FilterList";

import PageContainer from "../../navigationmenu/src/PageContainer.web";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
// Customizable Area End

export class PlantList extends PlantListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTableHeader() {
    const sortingData = this.state.sortingData;
    return (
      <TableHead>
        <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
          <SortingTableHeader
            sortingData={sortingData}
            title='Plant Name'
            sortingKey='name'
            {...this.sortingProps}
            type='left'
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Region'
            sortingKey='region_name'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Plant Capacity (in KG)'
            sortingKey='total_capicity_in_kg'
            {...this.sortingProps}
          />
          <SortingTableHeader
            sortingData={sortingData}
            title='Plant Capacity (in Pieces)'
            sortingKey='total_capicity_in_piece'
            {...this.sortingProps}
          />

          <SortingTableHeader
            sortingData={sortingData}
            title='Status'
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
          />
          <SortingTableHeader
            sortingData={sortingData}
            type='action'
            title=''
            sortingKey=''
            {...this.sortingProps}
            sortingDisabled
          />
        </TableRow>
      </TableHead>
    )
  }

  render() {
    const { disableModalVisible, deactivatedPlant, plants } = this.state;
    const filteredRowData = plants?.map((plant) => {
      const plantName = plant.attributes.name;
      const region = plant.attributes?.region?.branch_name;
      const kgCapacity = plant.attributes.total_capicity_in_kg;
      const pieceCapacity = plant.attributes.total_capicity_in_piece;
      const plantId = plant.id;
      const status = plant.attributes.active ? "Active" : "Deactivated";

      return this.createData(plantName, region, kgCapacity, pieceCapacity, plantId, status);
    });
    const sortingData = this.state.sortingData;
    const emptyRowData = this.state.pageSize - filteredRowData.length;

    return (
      <>
        <Box style={webStyle.mainWrapperPlant}>
          <PageContainer
            onSearchText={(value) => this.handleSearchPlant(value)}
            navigation={this.props.navigation}
            onUserChange={(userContext) => this.handleUserChange(userContext)}
          >
            <StyledPlantListBoxWrapper>
              <Box>
                <div style={webStyle.headerWrapperPlant}>
                  <StyledPlantSettingHeading>{configJSON.plants}</StyledPlantSettingHeading>
                  <div style={webStyle.headerButtonPart}>
                    <div
                      data-test-id="filterBtn"
                      onClick={(event) => {
                        this.setState({
                          filterAnchor: event.currentTarget,
                        });
                      }}
                      className="filterButtonPlant"
                    >
                      <FilterIcon />
                    </div>
                    <FilterPopover
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      anchor={this.state.filterAnchor}
                      onAutoCompleteChange={(title: string, value: string) => {
                        this.setState({ suggestionFieldTitle: title });
                        this.handlePlantAutoComplete(title, value);
                      }}
                      onClearFilter={() => {
                        let updated = this.state.filters.map((item: IFilter) => {
                          item.value = ""; 
                          item.options=[];
                          return item;
                        });
                        this.setState({ filters: updated});
                      }}
                      onFilterChange={this.handleFilterChangePlant}
                      filters={this.state.filters}
                    />
                  <LoadingComponent
                  isLoading={this.state.isLoadingPermission}
                  height={56}
                  width={184}
                  isAllowed={this.state.permissionStatus.createPermission}
                  children = {
                    <Button
                      data-test-id='addPlant'
                      onClick={this.handleAddPlant}
                      className="addPlant" 
                    >
                      Add Plant
                    </Button>
                    }
                    />
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table style={webStyle.tablePlant} aria-label='customized table'>
                    {this.renderTableHeader()}

                    {!this.state.isLoading && filteredRowData.length>0&& <TableBody>
                      {filteredRowData.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell width='19vw' type='left' content={rowData.plantName} />
                          <SortingTableCell width='19vw' content={rowData.region} />
                          <SortingTableCell width='19vw' content={rowData.kgCapacity} />
                          <SortingTableCell width='19vw' content={rowData.pieceCapacity} />
                          <SortingTableCell width='19vw' type='right' content={rowData.status} />
                          <SortingTableCell
                            width='19vw'
                            type='action'
                            data-test-id = "moreCell"
                            content={
                              <IconButton
                                data-test-id='moreButton'
                                id={"IconButton" + index}
                                onClick={(event) => {
                                  const position = event.currentTarget.getBoundingClientRect();
                                  this.setState({
                                    popOverItemId: rowData.plantId,
                                    popOverItemStatus: rowData.status,
                                    popOverOpenedPlant: true,
                                    popOverLeftPlant: position.left + window.scrollX,
                                    popOverTopPlant: position.top + window.scrollY,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                      {emptyRowData > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRowData,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>}
                    {this.state.isLoading && 
                    <>{Array(5)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(5)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}

                           
                          </TableRow>
                        </TableBody>
                      ))}</>
                  }
                  </Table>
                  {!this.state.isLoading && filteredRowData.length === 0 && (
                    <div
                      style={{
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: 603,
                        width: "100%",
                      }}
                    >
                      No  records  found!!
                    </div>
                  )}
                </TableContainer>
                {filteredRowData.length > 0 && (
                  <TablePagination
                    component='div'
                    data-test-id='pagination'
                    rowsPerPageOptions={[10]}
                    page={this.state.page - 1}
                    count={this.state.totalCounts}
                    rowsPerPage={this.state.pageSize}
                    onPageChange={(_, page) =>
                      this.handlePageChange(page + 1)
                    }
                  />
                )}
              </Box>
            </StyledPlantListBoxWrapper>
            <Popover
              data-test-id={configJSON.popoverTest}
              style={{
                boxShadow: "none",
              }}
              anchorReference='anchorPosition'
              open={this.state.popOverOpenedPlant}
              onClose={() => this.setState({ popOverOpenedPlant: false })}
              anchorPosition={{
                top: this.state.popOverTopPlant + 40,
                left: this.state.popOverLeftPlant - 80,
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
                {
                  renderBaseOnConditions(
                    (this.state.permissionStatus.viewPermission ||
                      this.state.permissionStatus.editPermission ||
                      this.state.permissionStatus.activatePermission ||
                      this.state.permissionStatus.deactivatePermission),
                    <div style={webStyle.popoverContainerPlant}>
               
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.viewPermission,
                  <Box
                    data-test-id='viewButton'
                    onClick={() => this.handleViewPlant(this.state.popOverItemId)}
                    sx={webStyle.popoverButtonPlant}
                  >
                    View
                  </Box>,
                  <></>
                )
              }
               {renderBaseOnConditions(this.state.permissionStatus.editPermission,
                <Box
                  data-test-id='editButton'
                  onClick={() => this.handleEditPlant(this.state.popOverItemId)}
                  sx={webStyle.popoverButtonPlant}
                >
                  Edit
                </Box>,
                <></>)}

                      {
                      renderBaseOnConditions(
                        this.state.permissionStatus.deactivatePermission && this.state.popOverItemStatus === "Active",
                        <>
                        <Box
                          data-test-id='activateButton'
                          sx={webStyle.popoverButtonPlant}
                          onClick={() => {
                            this.handleDeactivate(this.state.popOverItemId);
                          }}
                        >
                          Deactivate
                        </Box>
                       </>
                        ,
                        <>
                        {!this.state.permissionStatus.viewPermission &&
                          !this.state.permissionStatus.editPermission &&
                          this.state.permissionStatus.activatePermission &&
                          this.state.popOverItemStatus === "Active"
                        && !this.state.permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions1">
                          No Permissions
                        </div>
                      </PopoverContainer1>}
                        </>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.activatePermission as boolean && this.state.popOverItemStatus !== "Active",
                        <>
                        <Box
                         data-test-id='activateButton'
                         sx={webStyle.popoverButtonPlant}
                        onClick={() => {
                          this.changeActivateUser();
                        }}
                        >
                          Activate
                        </Box>
                        </>,
                        <>{!this.state.permissionStatus.viewPermission &&
                          !this.state.permissionStatus.editPermission &&
                          !this.state.permissionStatus.activatePermission &&
                          this.state.popOverItemStatus !== "Active"
                        && this.state.permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions1">
                          No Permissions
                        </div>
                      </PopoverContainer1>}</>
                      )
                    }

              </div>,
                    <div className="popoverContainer">
                      <div className="noPermissions" style={{padding:'5px'}}>
                        No Permissions
                      </div>
                    </div>)}
            </Popover>
          </PageContainer>
          <ConfirmationModal
            open={disableModalVisible}
            handleClose={this.handleDeactivateClose}
            handleConfirm={this.changeActivateUser}
            title='Confirm Deactivate'
            message={{
              id: this.state.clickedPlant?.id,
              title: `${this.state.clickedPlant?.attributes.name}`,
            }}
            dynamic={true}
            confirmText='Deactivate'
          />

          {deactivatedPlant && (
            <ConfirmationModal
              open={deactivatedPlant}
              handleClose={this.handleDeactivatedPlantClose}
              handleConfirm={this.handleGoList}
              title={"Plant Deactivated"}
              message={{
                id: this.state.clickedPlant?.id,
                title: `${this.state.clickedPlant?.attributes.name}`,
              }}
              confirmText={"Back to Listing"}
              displayItem={true}
            />
          )}
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const StyledPlantListBoxWrapper = styled(Box)({
  "& .addPlant": {
    textTransform: "unset",
    background: "#204B9C",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
      padding: "10px !important"
    },
  },
  "& .filterButtonPlant": {
    background: "#FFFFFF",
    fontSize: "24px",
    color: "#4d4d4d",
    minHeight: "56px",
    minWidth: "56px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #204B9C",
    cursor: "pointer",
    marginRight: "24px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  }
});

export const StyledPlantSettingHeading = styled("div")({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const webStyle = {
  tablePlant: {
    minWidth: 700,
  },
  mainWrapperPlant: {
    fontFamily: "Montserrat",
  },
  pageHeaderPlant: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapperPlant: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    marginTop: 16,
    alignItems: "center",
    flexWrap: 'wrap' as 'wrap'
  },
  headerButtonPart: {
    display: "flex",
  },
  popoverButtonPlant: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  popoverContainerPlant: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
};

const PopoverContainer1 = styled("div")({
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
  "& .noPermissions1": {
    padding: "0 8px",
  }
})

export default PlantList
// Customizable Area End
