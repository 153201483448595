import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  AppBar,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  IconButton,
  Tooltip,
  styled,
  Select,
  Checkbox,
  StyledComponentProps,
  MenuProps as AcMenuProps,
} from "@material-ui/core";
import PageConatinerStoreGroupMultiSelect from "../../../components/src/PageConatinerStoreGroupMultiSelect.web"
import PageConatinerSearchableMultiSelect from "../../../components/src/PageConatinerSearchableMultiSelect.web"
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import SearchIcon from "@material-ui/icons/Search";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import Person from "@material-ui/icons/Person";
import {
  dashboardIcon,
  settingsIcon,
  activeUserIcon,
  passiveUserIcon,
  generalIcon,
  ProductsIcon,
  StoreIcon,
  pushPinIcon,
  marketingIcon,
  SearchIconDrawer,
  PlantOrderIcon,
  ExpandIcon,
  HomeCleanIcon,
  CashierIcon,
  ReportIcon,
  HardwareIcon,
  logoIcon,
} from "./assets";
import Snackbar from "@material-ui/core/Snackbar";
import Badge from "@material-ui/core/Badge";
import Alert from "@material-ui/lab/Alert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { isArray } from "lodash";
// Customizable Area End

// Customizable Area Start
import {
  IMenuItems,
  IMyUser,
  customerModuleMenuItems,
  generalModuleMenuItems,
  hardwareModuleMenuItems,
  homeCleanMenuItems,
  marketingModuleMenuItems,
  productsModuleMenuItems,
  settingMenuItems,
  storeModuleMenuItems,
} from "./utils";
import PageContainerController, {
  Props,
  configJSON,
} from "./PageContainerController.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import Notifications from "../../../blocks/notifications/src/Notifications.web";
const Strings = configJSON.Strings.pageContainer;
interface StyledDrawerResProps {
  responsive?: {
    "1024":string,
    "1280":string,
    "1366":string
  }
}

// Customizable Area End

export class PageContainer extends PageContainerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  expandLessIcon() {
    return <img src={ExpandIcon} width={24} height={24} className={"rotate-180"} />;
  }

  expandMoreIcon() {
    return <img src={ExpandIcon} width={24} height={24}/>;
  }

  renderProfilePopover = (user: IMyUser | undefined, onView: () => void) => {
    const logo =
      user && user.attributes.employee_proifle.data.attributes.profile_logo;
    const userEmail = user ? user.attributes.email : "";
    return (
      <div className={"mainContainer"} data-test-id={"profilePover"}>
        <div className={"userNameContainer"}>
          <div>
            {logo ? (
              <img
                data-test-id={"profile-logo"}
                className={"minLogo"}
                src={logo}
              />
            ) : (
              <AccountCircleSharpIcon className={"accountCircle"} />
            )}
          </div>
          <div>
            <div className={"userName"}>{this.renderUserName(user)}</div>
            <div className={"userEmail"}>{userEmail}</div>
          </div>
        </div>
        <div className={"buttonContainer"}>
          <Button
            disabled={!user}
            data-test-id={"profileBtn"}
            onClick={onView}
            className={"button"}
          >
            {Strings.viewProfileLabelValue}
          </Button>
        </div>
      </div>
    );
  };

  renderDrwaerTooltip(text: string, icon: string) {
    return (
      <DrawerBarTooltip title={text} placement={"right-start"} arrow>
        <MenuItemIconClose className={"menuItemIcononClose"}>
          <img className={"imageClose"} src={icon} width={24} height={24}/>
        </MenuItemIconClose>
      </DrawerBarTooltip>
    );
  }

  renderMenuItem(text: string, icon: string) {
    return (
      <>
        <MenuItemIcon className={"menuItemIcon"}>
          <img src={icon} width={24} height={24}/>
        </MenuItemIcon>
        <ListItemTextMain primary={text} className={"menuItemText"} />
      </>
    );
  }

  renderLevel3Menu = ({
    icon,
    text,
    route,
    outerMenuName,
    isRouteActive,
  }: {
    icon: string;
    text: string;
    route: string;
    outerMenuName: string;
    isRouteActive: boolean;
  }) => {
    const { open } = this.state;
    return (
      <ListItemLevel3
        className={this.getClassName(
          open,
          `menuItem3 ${isRouteActive && "activeRoute"}`,
          `closemenuItem3 ${isRouteActive && "activeRoute"}`
        )}
        button
        data-test-id={`listlevel3Menu-${route}`}
        onClick={() => {
          this.handleNavigateTo(route);
          this.handleOpenSidebarDropdown(outerMenuName);
        }}
      >
        {this.getConditionBased(
          open,
          this.renderMenuItem(text, icon),
          this.renderDrwaerTooltip(text, icon)
        )}
      </ListItemLevel3>
    );
  };

  renderLevel2Menu = ({
    icon,
    text,
    route,
    outerMenuName,
    isRouteActive,
  }: {
    icon: string;
    text: string;
    route: string;
    outerMenuName: string;
    isRouteActive: boolean;
  }) => {
    const { open } = this.state;
    return (
      <ListItemLevel2
        className={this.getClassName(
          open,
          `menuItem2 ${isRouteActive && "activeRoute"}`,
          `closeMenuItem2 ${isRouteActive && "activeRoute"}`
        )}
        button
        data-test-id={`level2ItemsMenu-${route}`}
        onClick={() => {
          this.handleNavigateTo(route);
          this.handleOpenSidebarDropdown(outerMenuName);
        }}
      >
        {this.getConditionBased(
          open,
          this.renderMenuItem(text, icon),
          this.renderDrwaerTooltip(text, icon)
        )}
      </ListItemLevel2>
    );
  };

  renderHomeClean(MenuItems: IMenuItems[]) {
    const {isAnyLeval2MenuItemIsPermitted} = this.handleGetMenuItemsPermission(MenuItems,[]);
    const { open, isHomeCleanProductOpen, activeMenu } = this.state;
    const isActive = activeMenu === "/HomeCleaningProductList";
    return (
      <>
        {
          this.getConditionBased(
            isAnyLeval2MenuItemIsPermitted as boolean,
            <RenderListItem
              button
              onClick={this.handleHomeCleanProduct}
              id={"homeCleanList"}
              data-test-id={"homeCleanList"}
              className={this.getClassName(
                open,
                `menuItem1 ${isActive && "activeRoute"}`,
                `closeMenuItem ${isActive && "activeRoute"}`
              )}
            >
              {this.getConditionBased(
                open,
                <>
                  {this.renderMenuItem("Home Cleaning", HomeCleanIcon)}
                  {isHomeCleanProductOpen
                    ? this.expandLessIcon()
                    : this.expandMoreIcon()}
                </>,
                this.renderDrwaerTooltip("Home Cleaning", HomeCleanIcon)
              )}
            </RenderListItem>,
            <></>
          )
        }

        {isHomeCleanProductOpen && (
          <>
            {MenuItems.map((item) =>
              this.withPermissionAndRouteCheck(this.renderLevel2Menu)(
                item[0] as string,
                item[1],
                item[2],
                item[3],
                item[4]
              )
            )}
          </>
        )}
      </>
    );
  }

  renderProducts = ({
    MenuItems,
    isActive,
    isAnyLeval2MenuItemIsPermitted,
    isAnyLeval3MenuItemIsPermitted
  }: {
    MenuItems: IMenuItems[];
    isActive: boolean;
    isAnyLeval2MenuItemIsPermitted: boolean;
    isAnyLeval3MenuItemIsPermitted: boolean;
  }) => {
    const { open, isInnerProductsOpen } = this.state;
    return (
      <>
        {
          this.getConditionBased(
            isAnyLeval2MenuItemIsPermitted,
            <RenderListItem
              button
              onClick={this.handleProductsInnerItems}
              data-test-id={"renderProducts"}
              className={this.getClassName(
                open,
                `menuItem1 ${isActive && "activeRoute"}`,
                `closeMenuItem ${isActive && "activeRoute"}`
              )}
            >
              {this.getConditionBased(
                open,
                <>
                  {this.renderMenuItem("Products", ProductsIcon)}
                  {isInnerProductsOpen
                    ? this.expandLessIcon()
                    : this.expandMoreIcon()}
                </>,
                this.renderDrwaerTooltip("Products", ProductsIcon)
              )}
            </RenderListItem>,
            <></>
          )
        }
        
        {isInnerProductsOpen && (
          <>
            {MenuItems.map((item) =>
              this.withPermissionAndRouteCheck(this.renderLevel2Menu)(
                item[0] as string,
                item[1],
                item[2],
                item[3],
                item[4]
              )
            )}
          </>
        )}
      </>
    );
  };

  renderOrders = ({
    storeMenuItems,
    isActive,
    isAnyLeval2MenuItemIsPermitted,
    isAnyLeval3MenuItemIsPermitted
  }: {
    storeMenuItems: IMenuItems[];
    isActive: boolean;
    isAnyLeval2MenuItemIsPermitted: boolean;
    isAnyLeval3MenuItemIsPermitted: boolean;
  }) => {
    const { open, isInnerOrderOpen } = this.state;
    return (
      <>
        {
          this.getConditionBased(
            isAnyLeval2MenuItemIsPermitted,
            <RenderListItem
              button
              onClick={this.handleOrdersInnerItems}
              id={"orderItemList"}
              data-test-id={"orderItemList"}
              className={this.getClassName(
                open,
                `menuItem1 ${isActive && "activeRoute"}`,
                `closeMenuItem ${isActive && "activeRoute"}`
              )}
            >
              {this.getConditionBased(
                open,
                <>
                  {this.renderMenuItem("Store", StoreIcon)}
                  {isInnerOrderOpen ? this.expandLessIcon() : this.expandMoreIcon()}
                </>,
                this.renderDrwaerTooltip("Store", ProductsIcon)
              )}
            </RenderListItem>,
            <></>
          )
        }
        {isInnerOrderOpen && (
          <>
            {storeMenuItems.map((item) =>
              this.withPermissionAndRouteCheck(this.renderLevel2Menu)(
                item[0] as string,
                item[1],
                item[2],
                item[3],
                item[4],
                item[5],
                item[6] as string
              )
            )}
          </>
        )}
      </>
    );
  };

  renderSettings = ({
    settingsMenuItems,
    generalMenuItems,
    isActive,
    isGeneralActive,
    isAnyLeval2MenuItemIsPermitted,
    isAnyLeval3MenuItemIsPermitted
  }: {
    settingsMenuItems: IMenuItems[];
    generalMenuItems: IMenuItems[];
    isActive: boolean;
    isGeneralActive: boolean;
    isAnyLeval2MenuItemIsPermitted: boolean;
    isAnyLeval3MenuItemIsPermitted: boolean;
  }) => {
    const { open, settingsOpened, generalSettingsOpened } = this.state;
    return (
      <>
        {
          this.getConditionBased(
            isAnyLeval2MenuItemIsPermitted,
            <RenderListItem
              button
              data-test-id={"settingList"}
              onClick={this.handleSettingsClick}
              className={this.getClassName(
                open,
                `menuItem1 ${isActive && "activeRoute"}`,
                `closeMenuItem ${isActive && "activeRoute"}`
              )}
            >
              {this.getConditionBased(
                open,
                <>
                  {this.renderMenuItem("Setting", settingsIcon)}
                  {settingsOpened ? this.expandLessIcon() : this.expandMoreIcon()}
                </>,
                this.renderDrwaerTooltip("Setting", settingsIcon)
              )}
            </RenderListItem>,
            <></>
          )
        }
        
        {this.getConditionBased(
          settingsOpened,
          <>
            {settingsMenuItems.map((item) =>
              this.withPermissionAndRouteCheck(this.renderLevel2Menu)(
                item[0] as string,
                item[1],
                item[2],
                item[3],
                item[4]
              )
            )}

            {
              this.getConditionBased(
                isAnyLeval3MenuItemIsPermitted,
                <RenderListItem
                  className={this.getClassName(
                    open,
                    `menuItem2 ${isGeneralActive && "activeRoute"}`,
                    `closeMenuItem2 ${isGeneralActive && "activeRoute"}`
                  )}
                  button
                  data-test-id={"generalMenuList"}
                  onClick={() => this.handleGeneralSettingsClick()}
                >
                  {this.getConditionBased(
                    open,
                    <>
                      {this.renderMenuItem("General", generalIcon)}
                      {generalSettingsOpened
                        ? this.expandLessIcon()
                        : this.expandMoreIcon()}
                    </>,
                    this.renderDrwaerTooltip("General", generalIcon)
                  )}
                </RenderListItem>,
                <></>
              )
            }
         
            {generalSettingsOpened && (
              <>
                {generalMenuItems.map((item) =>
                  this.withPermissionAndRouteCheck(this.renderLevel3Menu)(
                    item[0] as string,
                    item[1],
                    item[2],
                    item[3],
                    item[4],
                    item[5],
                    item[6],
                  )
                )}
              </>
            )}
          </>,
          <></>
        )}
      </>
    );
  };

  renderMarketing = ({
    marketingMenuItems,
    customerMenuItems,
    isActive,
    isCustomerActive,
    isAnyLeval2MenuItemIsPermitted,
    isAnyLeval3MenuItemIsPermitted
  }: {
    marketingMenuItems: IMenuItems[];
    customerMenuItems: IMenuItems[];
    isActive: boolean;
    isCustomerActive: boolean;
    isAnyLeval2MenuItemIsPermitted: boolean;
    isAnyLeval3MenuItemIsPermitted: boolean;
  }) => {
    const { open, marketingOpened, customerOpened } = this.state;
    return (
      <>
        {
          this.getConditionBased(
            isAnyLeval2MenuItemIsPermitted,
            <RenderListItem
              button
              onClick={this.handleMarketingClick}
              data-test-id={"marketingList"}
              className={this.getClassName(
                open,
                `menuItem1 ${isActive && "activeRoute"}`,
                `closeMenuItem ${isActive && "activeRoute"}`
              )}
            >
              {this.getConditionBased(
                open,
                <>
                  {this.renderMenuItem("Marketing", marketingIcon)}
                  {marketingOpened ? this.expandLessIcon() : this.expandMoreIcon()}
                </>,
                this.renderDrwaerTooltip("Marketing", marketingIcon)
              )}
            </RenderListItem>,
            <></>
          )
        }

        {this.getConditionBased(
          marketingOpened,
          <>
            {marketingMenuItems.map((item) =>
              this.withPermissionAndRouteCheck(this.renderLevel2Menu)(
                item[0] as string,
                item[1],
                item[2],
                item[3],
                item[4]
              )
            )}
            <>
            {
              this.getConditionBased(
                isAnyLeval3MenuItemIsPermitted,
                <RenderListItem
                  className={this.getClassName(
                    open,
                    `menuItem2 ${isCustomerActive && "activeRoute"}`,
                    `closeMenuItem2 ${isCustomerActive && "activeRoute"}`
                  )}
                  button
                  data-test-id={"customerMenuList"}
                  onClick={() => this.handleGeneralCustomerClick()}
                >
                  {this.getConditionBased(
                    open,
                    <>
                      <MenuItemIcon className={"menuItemIcon"}>
                        <Person />
                      </MenuItemIcon>
                      <ListItemTextMain
                        primary={"Customer"}
                        className={"menuItemText"}
                      />
                      {customerOpened
                        ? this.expandLessIcon()
                        : this.expandMoreIcon()}
                    </>,
                    <DrawerBarTooltip
                      title={"Customer"}
                      placement={"right-start"}
                      arrow
                    >
                      <Person />
                    </DrawerBarTooltip>
                  )}
                </RenderListItem>,
                <></>
              )
            }
             
              {customerOpened && (
                <>
                  {customerMenuItems.map((item) =>
                    this.withPermissionAndRouteCheck(this.renderLevel3Menu)(
                      item[0] as string,
                      item[1],
                      item[2],
                      item[3],
                      item[4]
                    )
                  )}
                </>
              )}
            </>
          </>,
          <></>
        )}
      </>
    );
  };

  renderPlant = () => {
    const { open, activeMenu } = this.state;
    const isPermissions = this.handlePermission("Plant management");
    if(!isPermissions) {
      return;
    } else {
      const isActive = activeMenu === "/Cfplantdashboard2";
      return (
        <RenderListItem
          button
          className={this.getClassName(
            open,
            `menuItem1 ${isActive && "activeRoute"}`,
            `closeMenuItem ${isActive && "activeRoute"}`
          )}
          data-test-id={`plant-dashboard`}
          onClick={() => this.handleNavigateTo("Cfplantdashboard2")}
        >
          {this.getConditionBased(
            open,
            this.renderMenuItem("Plant", PlantOrderIcon),
            this.renderDrwaerTooltip("Plant", PlantOrderIcon)
          )}
        </RenderListItem>
      );
    }
  };

  renderZatcaHistory = (isActive: boolean, title: string, route: string) => {
    const { open } = this.state;
    
    return (
      <RenderListItem
        button
        className={this.getClassName(
          open,
          `menuItem1 ${isActive && "activeRoute"}`,
          `closeMenuItem ${isActive && "activeRoute"}`
        )}
        data-test-id={`zatca-history`}
        onClick={() => this.handleNavigateTo(route)}
      >
        {this.getConditionBased(
          open,
          this.renderMenuItem(title, PlantOrderIcon),
          this.renderDrwaerTooltip(title, PlantOrderIcon)
        )}
      </RenderListItem>
    );
  };

  renderDashboard = () => {
    const isPermissions = this.handlePermission("Dashboard");
    if(!isPermissions) {
      return;
    } else {
      const { open } = this.state;
      const isActive = this.handleCheckIncluseRoute("Dashboard-")
      const activeClass = isActive ? " activeRoute" : ""

      return (
        <RenderListItem 
          data-test-id="dashboard-page"
          button 
          className={open ? ("menuItem1" + activeClass) : "closeMenuItem"} 
          onClick={() => this.handleNavigationForDashboard()}
        >
          {this.getConditionBased(
            open,
            this.renderMenuItem(configJSON.dashboard, dashboardIcon),
            this.renderDrwaerTooltip(configJSON.dashboard, dashboardIcon)
          )}
        </RenderListItem>
      );
    }
  };

  renderReports = (isActive: boolean) => {
    const isPermissions = this.handlePermission("Report Export");
    if(!isPermissions) {
      return;
    } else {
      const { open} = this.state;
      return (
        <RenderListItem button  onClick={() => this.handleNavigateTo('SalesReporting')} className={open ? `menuItem1 ${isActive && "activeRoute"}` : "closeMenuItem"}>
          {this.getConditionBased(
            open,
            this.renderMenuItem("Reports", ReportIcon),
            this.renderDrwaerTooltip("Reports", ReportIcon)
          )}
        </RenderListItem>
      );
    }
  };

  renderSearch = (isActive: boolean) => {
    const { open } = this.state;
    return (
      <RenderListItem
        button
        data-test-id={"searchPage"}
        className={this.getClassName(
          open,
          `menuItem1 ${isActive && "activeRoute"}`,
          `closeMenuItem ${isActive && "activeRoute"}`
        )}
        onClick={() => this.handleNavigateTo("AdvancedSearch")}
      >
        {this.getConditionBased(
          open,
          this.renderMenuItem("Advance Search", SearchIconDrawer),
          this.renderDrwaerTooltip("Advance Search", SearchIconDrawer)
        )}
      </RenderListItem>
    );
  };

  renderCashierStorekeeper = (isActive: boolean, title: string, route: string) => {
    const { open } = this.state;
    return (
      <RenderListItem
        button
        data-test-id={"cashierPage"}
        onClick={() => this.handleNavigateTo(route)}
        className={this.getClassName(
          open,
          `menuItem1 ${isActive && "activeRoute"}`,
          `closeMenuItem ${isActive && "activeRoute"}`
        )}
      >
        {this.getConditionBased(
          open,
          this.renderMenuItem(title, CashierIcon),
          this.renderDrwaerTooltip(title, CashierIcon)
        )}
      </RenderListItem>
    );
  };

  renderSelection(placeholder:string,fieldName:string,selectedValue:string| number,onChangeFunction:Function,optionArray:{ id: string; option: string; }[],noOptionText?: string) {
    return (
      <div style={{ minWidth: "200px" }}>
        <AutocompleteSelect
          placeholder={placeholder}
          fieldName={fieldName}
          disableClear
          onChange={(_item: React.ChangeEvent, value: { id: string; option: string }) => {
            onChangeFunction(value);
          }}
          value={selectedValue}
          options={optionArray}
          noOptionText={noOptionText}
        />
      </div>
      
    )
  }

  renderMutliRgionSelection(placeholder:string,fieldName:string,selectedValue:string []| number[],onChangeFunction:Function,optionArray:{ id: string; option: string; }[],noOptionText?: string) {
       const filteredData = optionArray.filter(item => this.state.regionMultiId.includes(item.id));
       let selectedRegionData
       if(this.state.selectAllRegionId !== undefined || (this.state.regionMultiId.length == this.state.regionListData.length)){
        selectedRegionData = [{ id: "-1", option: "Select All" }, ...filteredData]
       }else{
        selectedRegionData = filteredData
       }
    return (
      <div style={{ minWidth: "200px" }}>

          <PageConatinerSearchableMultiSelect
            id="region"
            name="region"
            dataTestId="regionDropdown"
            data-test-id="regionDropdown"
            checkSelectAllText={() => { }}
            value={filteredData}
            optionList={optionArray}
            placeHolderText="Select Region"
            handleScroll={()=>{}}
            handleClickOnSelectAll={() => { }}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, ()=>{}) }}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.onChangeMultiRegionSelect(option) }}
            emptyDataMessage="No Region Found"
            handleEmptyAutoSelectValue={() => { }}
            selectedOptionForList={selectedRegionData}
            isRadioButtonShow={true}
            isSelectAll = {this.state.regionMultiId.length == this.state.regionListData.length && this.state.regionListData.length > 1} 
        />
        {console.log(selectedRegionData,filteredData,"filteredData")}
      </div>
      
    )
  }

  getMultiSelectRegionRenderValue(items: unknown) {
    let updatedLabels = this.handleUpdatedRegionLabels(items);
    updatedLabels = updatedLabels || "Select Region"
    return isArray(items) && items.length === this.state.regionListData.length ? (
      <span className='color_4D4D4D'>
        {"All Region"}
      </span>
    ) : (
      updatedLabels
    );
  }

  getMultiSelectRenderValue(items: unknown) {
    let updatedLabels = this.handleUpdatedLabels(items);
    updatedLabels = updatedLabels || "Select Store"
    return isArray(items) && items.length === this.state.storeListData.length ? (
      <span className='color_4D4D4D'>
        {"All Stores"}
      </span>
    ) : (
      updatedLabels
    );
  }

  renderMultiSelectStore = () => {
    const optionArray =  this.state.storeListData.map((store) => ({
      id: store.id,
      option: store.attributes.store_name
    }))
    const filteredData = optionArray.filter(item => this.state.storeId.includes(item.id));
    let selectedStoreData
    if(this.state.selectAllStoreId !== undefined){
      selectedStoreData = [{ id: "-1", option: "Select All" }, ...filteredData]
    }else{
      selectedStoreData = filteredData
    }
    return (
      <div style={{ minWidth: "200px" }}>
          <PageConatinerSearchableMultiSelect
            id="store"
            name="store"
            dataTestId="storeDropdown"
            checkSelectAllText={() => { }}
            value={filteredData}
            optionList={optionArray}
            placeHolderText="Select Store"
            handleScroll={()=>{}}
            handleClickOnSelectAll={() => { }}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, ()=>{}) }}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.onChangeMultiSelect(option) }}
            emptyDataMessage="No Store Found"
            handleEmptyAutoSelectValue={() => { }}
            selectedOptionForList={selectedStoreData}
            isRadioButtonShow={true}  
            isSelectAll = {this.state.storeId.length == this.state.storeListData.length}       
        /> 
      </div>
    )
  }

  
  renderMultiSelectStoreAndGroup = () => {
    const optionStoreArray =  this.state.storeListData.map((store) => ({
      id: store.id,
      option: store.attributes.store_name,
      type: "store"
    }))

    const optionStoreByGroupArray =  this.state.storeListBygroupData.map((store) => ({
      id: store.id,
      option: store.attributes.store_name,
      type: "store"
    }))
    const mainStoreList = [...optionStoreArray,...optionStoreByGroupArray]

    const uniqueStoreList = mainStoreList.filter(
      (store, index, self) =>
        index === self.findIndex((s) => s.id === store.id)
    );

    const selectedStoreIds = new Set(this.state.selectedAllStoreId);

    const sortedStoreList = this.sortStoreList(uniqueStoreList,selectedStoreIds)
    const optionGroupArray =  this.state.groupListData.map((group) => ({
      id: group.id,
      option: group.attributes.name,
      type: "group",
      storecount:group.attributes.store_managements_count,
      selected_store: group.attributes.store_managements.data,
      stores:[{id:"-1",option:"Select All",type:"store"}, ...sortedStoreList]
    }))
    
    const filteredData = uniqueStoreList.filter(item => this.state.storeId.includes(item.id));
    const filteredDataGroup = optionGroupArray.filter(item => this.state.groupId == item.id);
    let selectedStoreData
    let selectedGroupData
    if(filteredData.length === uniqueStoreList.length){
      selectedStoreData = [ ...[{ id: "-1", option: "Select All",type:"store" },...filteredData]]
      selectedGroupData = filteredDataGroup
    }else{
      selectedStoreData = filteredData
      selectedGroupData = filteredDataGroup

    }
    return (
      <div style={{ minWidth: "200px" }}>
          <PageConatinerStoreGroupMultiSelect
            id="store"
            name="store"
            dataTestId="storeGroupDropdown"
            data-test-id="storeGroupDropdown"
            value={[...filteredData,...filteredDataGroup]}
            optionList={optionStoreArray}
            placeHolderText="Select Store"
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, ()=>{
              if(this.state.groupId){
                this.handleStoreFilter(getValue,uniqueStoreList)
              }
            }) 
            }}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string,type:string }) => { this.onChangeMulSelectStoreAndGroup(option) }}
            emptyDataMessage="No Group Found"
            selectedOptionForList={selectedStoreData}
            selectedGroupOptionForList={selectedGroupData}
            isRadioButtonShow={true}
            isSelectAll = {this.state.storeId.length == this.state.storeListData.length} 
            optionStoreList={[{id:"-1",option:"Select All",type:"store"}, ...sortedStoreList]}      
            optionGroupList={optionGroupArray as unknown as { id: string | number, option: string,type:string,storecount?:number }[]} 
            currentGroupId = {this.state.groupId}
            searchStoreValue = {this.state.searchValue}
            selectedGroupName = {this.state.selectedGroupName}
        />
      </div>
    )
  }


  renderHardware = () => {
    const isPermissions = this.handlePermission("Hardware");
    if(!isPermissions) {
      return;
    } else {
      const { open, isHardwareOpen } = this.state;
      const isActive = this.handleCheckIncluseRoute("Hardware-");
      return (
        <>
          <RenderListItem
            button
            onClick={this.handleHardwareClick}
            id={"hardwareItemList"}
            data-test-id={"hardwareItemList"}
            className={this.getClassName(
              open,
              `menuItem1 ${isActive && "activeRoute"}`,
              `closeMenuItem ${isActive && "activeRoute"}`
            )}
          >
            {this.getConditionBased(
              open,
              <>
                {this.renderMenuItem("Hardware", HardwareIcon)}
                {isHardwareOpen ? this.expandLessIcon() : this.expandMoreIcon()}
              </>,
              this.renderDrwaerTooltip("Hardware", HardwareIcon)
            )}
          </RenderListItem>
          {isHardwareOpen && (
            <>
            {hardwareModuleMenuItems.map((item) =>
              this.withPermissionAndRouteCheck(this.renderLevel2Menu)(
                item[0] as string,
                item[1],
                item[2],
                item[3],
                item[4],
                item[5],
                item[6]
              )
            )}
            </>
          )}
        </>
      );
    }
  };
  
  getRegionAndStoreRenders = () => {
    const { onStoreChange,onMultiRegionChange,onStoreAndGroupChange } = this.props;

    return (
      <>
       {onMultiRegionChange && this.renderMutliRgionSelection("Select Region", "region", this.state.regionMultiId, this.handleRegionSelection, this.state.regionListData.map((region) => ({
          id: region.id,
          option: region.attributes.branch_name
        }))
        )}
                  
        {onStoreChange && this.renderMultiSelectStore()}
        {onStoreAndGroupChange && this.renderMultiSelectStoreAndGroup()}
      </>
      
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, searchText, popoverOpen, pinned } = this.state;
    const { onRegionChange,resposniveDrawer } = this.props;
    const logo =
      this.state.myUser &&
      this.state.myUser.attributes.employee_proifle.data.attributes
        .profile_logo;
    const openPopoverAction = Boolean(this.state.isNotificationPopoverAnchor);
    const popOverId = openPopoverAction ? "simple-popover" : undefined;
    const logoStyle = open ? {
      dimension: 60,
      marginLeft: '0px',
    } : {
      dimension: 40,
      marginLeft: '-13px',
    };
    return (
      <NavigationWrapper className={"root"} responsive={resposniveDrawer}>
        <div>
          <Drawer
            variant={"permanent"}
            className={open ? "drawerOpen" : "drawerClose"}
            classes={{
              paper: open ? "drawerOpen" : "drawerClose",
            }}
            PaperProps={{ style: { backgroundColor: "#204B9C" } }}
          >
            <div className="pinnedWrap">
                <img data-test-id="company-logo" style={{ marginLeft: logoStyle.marginLeft}} src={logoIcon} alt={"pin icon"} width={logoStyle.dimension} height={logoStyle.dimension}/>
            </div>
            <div className={`pinnedWrap ${open ? "showPinned" : "hidePinned"}`}>
              <div
                className={pinned ? "pinnedSidebar" : "unpinnedSidebar"}
                data-test-id={"pinnedBar"}
                onClick={() => {
                  this.handlePinSidebar();
                }}
              >
                <img src={pushPinIcon} alt={"pin icon"} width={24} height={24}/>
              </div>
            </div>
            <div className={"listwrapper"}>
              <List className={"list"}>
                {this.beforeRenderSearch(this.renderSearch)()}
                {this.renderDashboard()}
                {this.beforeRenderReports(this.renderReports)()}
                {this.beforeRenderOrders(this.renderOrders)(
                  storeModuleMenuItems
                )}
                {this.beforeRenderCashier(this.renderCashierStorekeeper)()}
                {this.beforeRenderStoreKeeper(this.renderCashierStorekeeper)()}
                {this.withPlantPermission(this.renderPlant)()}
                {this.beforeRenderSettings(this.renderSettings)(
                  settingMenuItems,
                  generalModuleMenuItems
                )}
                {this.beforeRenderMarketing(this.renderMarketing)(
                  marketingModuleMenuItems,
                  customerModuleMenuItems
                )}
                {this.beforeRenderProducts(this.renderProducts)(
                  productsModuleMenuItems
                )}
                {this.renderHomeClean(homeCleanMenuItems)}
                {this.renderHardware()}
                {this.beforeRenderZatca(this.renderZatcaHistory)()}
              </List>
              <div className={open ? "btnwrapper" : "closeBtnWrapper"}>
                {this.getConditionBased(
                  open,
                  <Button
                    onClick={this.handleLogout}
                    className={"button"}
                    data-test-id={"logoutBtn"}
                    startIcon={<ExitToAppOutlinedIcon />}
                  >
                    {Strings.logoutLabel}
                  </Button>,
                  <DrawerBarTooltip
                    title={Strings.logoutLabel}
                    placement={"right-start"}
                    arrow
                  >
                    <MenuItemIconClose className={"menuItemIcononClose"}>
                      <ExitToAppOutlinedIcon
                        onClick={this.handleLogout}
                        className={"menuItemIconLogout"}
                      />
                    </MenuItemIconClose>
                  </DrawerBarTooltip>
                )}
              </div>
            </div>
          </Drawer>
          {this.getConditionBased(
            open,
            <IconButton
              className={`toggleMenuIcon opentoggleIcon`}
              data-test-id={"menuIconButton"}
              onClick={this.handleClose}
              disabled={pinned}
              >
            <ChevronLeftIcon />
            </IconButton>,
            <IconButton
            className={`toggleMenuIcon closeToggleIcon`}
            data-test-id={"menuIconButton"}
            disabled={pinned}
            onClick={this.handleOpen}
            >
            <ChevronRightIcon />
            </IconButton>
          )}
        </div>
        <div className={"wrapperBlock"}>
          <AppBar position={"sticky"} className={"appBar"}>
            <Toolbar className={"toolbar"}>
              <div className={"header"} id={"headerSearch"}>
                <OutlinedInput
                  className={`search ${
                    this.props.onSearchText ? "" : "hidden"
                  }`}
                  placeholder={"Search"}
                  value={searchText}
                  data-test-id={"commonSearch"}
                  onChange={this.handleSearchTextChange}
                  inputProps={{
                    "data-test-id": "pageContainerInput",
                    "data-testid": "pageContainerInput",
                  }}
                  startAdornment={
                    <InputAdornment position={"start"}>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                {this.props.afterSearchComponent}
                {!this.props.hideActivePassiveUsers && (
                  <div className={"userIcons"}>
                    <div className={"activeUserIcon"}>
                      <div>
                        <img
                          className={"activeUserIconImg"}
                          src={activeUserIcon}
                          width={24} height={24}
                        />
                      </div>
                    </div>
                    <div>
                      <img src={passiveUserIcon} width={24} height={24}/>
                    </div>
                  </div>
                )}
                <AfterPermissionComponent>
                  {this.props.afterPermissionComponent}
                  {onRegionChange && this.renderSelection("Select Region", "region", this.state.regionId, this.handleRegionSelection, this.state.regionListData.map((region) => ({
                    id: region.id,
                    option: region.attributes.branch_name
                  }))
                  )}

                  {this.getRegionAndStoreRenders()}

                 
                </AfterPermissionComponent>

                <div className={"msgIcons"}>
                  <div className={"msgIcon"}>
                    <MessageOutlinedIcon />
                  </div>
                  <div className={"msgIcon"}>
                    <LocalMallOutlinedIcon />
                  </div>

                  <div
                    data-test-id={"notificationIcon"}
                    className={"msgIcon"}
                    onClick={this.handleClickNotification}
                    aria-describedby={popOverId}
                  >
                    {this.state.notificationCount > 0 ? (
                      <Badge
                        classes={{ badge: "badgeColor" }}
                        className={"notificationIcon"}
                        variant='dot'
                      >
                        <NotificationsIcon
                          className={"notificationsIconStyle"}
                        />
                      </Badge>
                    ) : (
                      <NotificationsIcon className={"notificationsIconStyle"} />
                    )}
                  </div>
                  {this.state.isNotificationPopoverAnchor !== null && (
                    <Notifications
                      navigation={this.props.navigation}
                      id={""}
                      isOpenAnchor={this.state.isNotificationPopoverAnchor}
                      popOverId={popOverId}
                      onClose={() => {
                        this.handleCloseNotificationPopover();
                      }}
                      updateCount={() => {
                        this.getNotificationCount();
                      }}
                    />
                  )}

                  <div
                    ref={(newRef) => (this.popoverRef = newRef)}
                    className={`${"accountCircle"}`}
                    data-test-id={"popoverIcon"}
                    onClick={this.handlePopOverOpen}
                  >
                    {logo ? (
                      <img
                        className={`${"logo"} ${"accountUserLogo"}`}
                        src={logo}
                      />
                    ) : (
                      <div>{this.state.userShortName}</div>
                    )}
                  </div>
                  <ProfilePopover
                    open={popoverOpen}
                    anchorEl={this.popoverRef}
                    anchorReference={"anchorEl"}
                    onClose={this.handlePopOverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {this.renderProfilePopover(
                      this.state.myUser,
                      this.handleViewProfile
                    )}
                  </ProfilePopover>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          {this.state.tokenReady && (
            <div className={"childContainer"}>
              {this.handleChildrenWithProps()}
            </div>
          )}

          <Snackbar
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            autoHideDuration={3000}
            open={this.state.newOrderNotificationSnackbar}
            onClose={this.handleSnackbarClose}
            data-test-id='snackbar'
          >
            <Alert severity={"success"}>{"New Notification"}</Alert>
          </Snackbar>
        </div>
      </NavigationWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DrawerBarTooltip = withStyles({
  tooltip: {
    fontSize: "13px",
    color: "white",
    backgroundColor: colors().black,
  },
  arrow: {
    color: colors().black,
  },
})(Tooltip);

const NavigationWrapper = styled(Box)((props: StyledComponentProps & StyledDrawerResProps) => ({
  "& .pageContainerWrapper": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  "& .pinnedWrap": {
    margin: "30px 0 0px 30px",
  },
  "& .hidePinned": {
    opacity: 0,
  },
  "& .showPinned": {
    opacity: 1,
  },
  "& .wrapperBlock": {
    flex: "1 1 0",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  "& .toolbar": {
    width: "100%",
    margin: "0px",
    padding: "0px",
  },
  "& .menuItemIconLogout": {
    color: colors().white,
  },
  "& .childContainer": {
    padding: "0 32px",
    flex: "1 1 0",
    overflow: "auto",
  },
  "& .drawerOpen": {
    transition: "width 300ms",
    width: "252px",
    "@media only screen and (max-width: 1366px)": {
      width: props.responsive?.["1366"] ?  props?.responsive['1366'] : "252px",
    },
    "@media only screen and (max-width: 1280px)": {
      width: props.responsive?.["1280"]  ? props?.responsive['1280'] : "252px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: props.responsive?.["1024"] ?  props?.responsive['1024'] : "192px",
    },
  
  },
  "& .msgIcons": {
    color: "grey",
    display: "flex",
    alignItems: "center",
  },
  "& .activeUserIconImg": {
    marginTop: "7px",
  },
  "& .msgIcon": {
    marginRight: "32px",
  },
  badgeColor: {
    backgroundColor: colors().activeGreen,
    top: 5,
    right: 5,
    width: 14,
    height: 14,
    border: `3px solid ${colors().background}`,
    borderRadius: "50%",
  },
  "& .notificationIcon": {
    cursor: "pointer",
  },
  "& .notificationsIconStyle": {
    color: colors().cyancobaltblue,
    cursor: "pointer",
  },
  "& .search": {
    fontFamily:"Montserrat",
    width: "100%",
    maxWidth: "358px",
    marginRight: "16px",
    "& input": {
      padding: "18px 0",
    },
    "@media only screen and (max-width: 1024px)": {
      maxWidth: "280px",
      marginRight: "12px",
      height: "32px !important",
      "& input": {
        padding: "9px 0",
        paddingTop: 7,
        fontSize:13,
        
        "&::placeholder" :{
          fontFamily:"Montserrat !important",
          fontSize:"13px"
        },
      },
      "& .MuiSvgIcon-root":{
        height: "20px !important",
        width: "20px !important",

      }
    },
  },
  "& .hidden": {
    visibility: "hidden",
  },
  "& .drawerClose": {
    transition: "width 300ms",
    width: "72px",
    overflowX: "hidden",
  },
  "& .btnwrapper": {
    padding: 24,
    marginTop: 50,
    width: "100%",
  },
  "& .closeBtnWrapper": {
    padding: 12,
    marginTop: 50,
    width: "100%",
    textAlign: "center",
  },
  "& .rotate-180": {
    transform: "rotate(180deg)",
  },
  "& .appBar": {
    boxShadow: "none",
    padding: "0 32px 0 48px",
    marginBottom: "15px",
    background: colors().white,
    "@media only screen and (max-width: 1024px)": {
      padding: "0 16px 0 24px",
    },
  },
  "& .toggleMenuIcon": {
    padding: 4,
    background: colors().antiFlashWhite,
    position: "fixed",
    top: 155,
    bottom: "auto",
    right: "auto",
    zIndex: 1200,
    width: "32px",
    height: "32px",
    borderRadius: "10px",
  },
  "& .logo": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 500,
    borderRadius: 100,
    backgroundColor: colors().lightborder,
    height: 70,
    width: 70,
  },
  "& .userIcons": {
    display: "flex",
    boxsizing: "borderBox",
    width: "96px",
    marginRight: "12px",
    minWidth: "96px",
    height: "48px",
    background: colors().white,
    border: `1px solid ${colors().lightsilver}`,
    borderRadius: "22.5px",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "& .accountCircle": {
    width: "56px",
    height: "56px",
    background: colors().Cultured,
    border: `1px solid ${colors().cyancobaltblue}`,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().black,
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    textAlign: "center",
    "@media only screen and (max-width: 1024px)": {
      width: "32px",
      height: "32px",
    },
  },
  "& .accountUserLogo": {
    width: "100%",
    height: "100%",
  },
  "& .activeUserIcon": {
    width: "40px",
    height: "40px",
    backgroundColor: colors().Cultured,
    textAlign: "center",
    borderRadius: "20px",
  },
  "& .opentoggleIcon": {
    transition: "left 300ms ease 0s",
    left: "235px",
    "@media only screen and (max-width: 1366px)": {
      left: props.responsive?.["1366"] ?  "205px" : "235px",
    },
    "@media only screen and (max-width: 1280px)": {
      left: props.responsive?.["1280"] ?  "195px" : "235px",
    },
    "@media only screen and (max-width: 1024px)": {
      left: props.responsive?.["1024"] ?  "149px" : "176px",
    },
  },
  "& .closeToggleIcon": {
    transition: "left 300ms ease 0s",
    left: "56px",
  },
  "& .pinnedSidebar": {
    backgroundColor: colors().orange,
    width: "32px",
    minHeight: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${colors().orange}`,
    borderRadius: "32px",
    cursor: "pointer",
  },
  "& .unpinnedSidebar": {
    width: "32px",
    minHeight: "32px",
    backgroundColor: "transparent",
    border: `1px solid white`,
    borderRadius: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "32px 0",
    borderBottom: `1px solid ${colors().columbiaBlue}`,
    "@media only screen and (max-width: 1024px)": {
      padding: "16px 0",
    },
  },
  "& .listwrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  "& .aljabrIcon":{
    marginBottom:"30px"
  },
  "& .list": {
    width: "100%",
  },
  "& .userEmail": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    maxWidth: "200px",
    wordBreak: "break-all",
  },
  "&.root": {
    display: "flex",
    width: "100vw",
    height: "100vh",
  },
  "& .button": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "100%",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      lineHeight: "28px",
    },
  },
}));

const AfterPermissionComponent = styled(Box)({
  display: "flex",
  gap:"10px",
  marginRight: 12,
  "@media only screen and (max-width:1024px)": {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
  },
});

const ListItemLevel3 = styled(ListItem)({
  "&.menuItem3": {
    paddingLeft: "46px",
    opacity: 0.5,
  },
  "&.closemenuItem3": {
    paddingLeft: "28px",
    opacity: 0.5,
  },
  "&.activeRoute": {
    opacity: 1,
  },
});

const ListItemLevel2 = styled(ListItem)({
  "&.menuItem2": {
    paddingLeft: "40px",
    opacity: 0.5,
    color: colors().white,
    "@media only screen and (max-width: 1024px)": {
      paddingLeft: "32px",
    },
  },
  "&.closeMenuItem2": {
    paddingLeft: "22px",
    opacity: 0.5,
    color: colors().white,
  },
  "&.activeRoute": {
    opacity: 1,
  },
});

const RenderListItem = styled(ListItem)({
  "&.menuItem1": {
    paddingLeft: 32,
    paddingRight: 32,
    opacity: 0.5,
    color: colors().white,
    paddingTop: 6,
    paddingBottom: 6,
    marginBottom: 5,
    "@media only screen and (max-width: 1024px)": {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  "&.closeMenuItem": {
    paddingLeft: 16,
    paddingRight: 16,
    opacity: 0.5,
    color: colors().white,
    paddingTop: 6,
    paddingBottom: 6,
    marginBottom: 5,
  },
  "&.menuItem2": {
    paddingLeft: "40px",
    opacity: 0.5,
    color: colors().white,
    "@media only screen and (max-width: 1024px)": {
      paddingLeft: "32px",
    },
  },
  "&.closeMenuItem2": {
    paddingLeft: "22px",
    opacity: 0.5,
    color: colors().white,
  },
  "&.activeRoute": {
    opacity: 1,
  },
});

const MenuItemIconClose = styled(ListItemIcon)({
  "&.menuItemIcononClose": {
    minWidth: 24,
    maxWidth: 24,
    margin: "auto",
    color: colors().white,
    width: "8.5 rem",
  },
  "& .imageClose": {
    height: "24px",
    width: "24px",
  },
});

const MenuItemIcon = styled(ListItemIcon)({
  "&.menuItemIcon": {
    minWidth: 24,
    maxWidth: 24,
    color: colors().white,
  },
});

const ListItemTextMain = styled(ListItemText)({
  "&.menuItemText": {
    color: colors().white,
    marginLeft: 24,
    whiteSpace: "break-spaces",
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px",
      },
    },
    "@media only screen and (max-width: 1024px)": {
      marginLeft: 9,
    },
  },
});

const ProfilePopover = styled(Popover)({
  "& .mainContainer": {
    borderRadius: "12px",
  },
  "& .userNameContainer": {
    height: "92px",
    background: "rgba(32, 75, 156, 0.15)",
    padding: "24px",
    display: "flex",
    fontFamily: "Montserrat",
    alignItems: "center",
    gap: "12px",
  },
  "& .userName": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().black,
  },
  "& .userEmail": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    maxWidth: "200px",
    wordBreak: "break-all",
  },
  "& .accountCircle": {
    width: "56px",
    height: "56px",
    background: colors().Cultured,
    border: `1px solid ${colors().cyancobaltblue}`,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().black,
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    textAlign: "center",
  },
  "& .minLogo": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    margin: "auto",
    lineHeight: "24px",
    borderRadius: 100,
    fontWeight: 500,
    backgroundColor: colors().lightborder,
    height: 40,
    width: 40,
  },
  "& .buttonContainer": {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: "center",
  },
  "& .button": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  },
});

const StyledSelect1 = styled(Select)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: '12px',
      height:'48px'
  },
});

const BorderedSelect = styled(StyledSelect1)({
  width: 200,
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: 500,
  "@media only screen and (max-width: 1024px)": {
    height: 41,
    fontSize: 12
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "unset"
  },
  "& .MuiPaper-root": {
    overflow: "hidden",
  },
  "& .MuiOutlinedInput-input": {
    padding: "18.5px 14px",
    "@media only screen and (max-width: 1024px)": {
      padding: "12.5px 8px"
    },
  },
});

const GroupDiv = styled("div")({
  overflow: "scroll",
  maxHeight: "110px"
})

const StoreDiv = styled('span')({
  overflow: "scroll",
  maxHeight: "170px"
})

const StyledLabel = styled("span")({
  overflow: "hidden",
  textOverflow: "ellipsis"
})

const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "&.Mui-checked": {
    color: `${colors().cyancobaltblue} !important`,
  },
});

const StyleMenuProps: Partial<AcMenuProps> = {
  PaperProps: {
    style: {
      // overflow: "hidden",
      maxHeight: 80 * 4.5 + 8,
      width: 200,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export default PageContainer;
// Customizable Area End
