import React from "react";
// Customizable Area Start
import { lazy, Suspense } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Dialog,
  DialogContent,
  ListItemText,
  Snackbar,
  CircularProgress,
  styled,
  Box,
  Chip
} from "@material-ui/core"
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Alert from "@material-ui/lab/Alert";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { plusIcon, waitingForApprovalIcon } from "./assets";
export const configJSON = require("./config");
import { colors } from "../../../blocks/utilities/src/Colors";
import { Skeleton } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountCreationController, {
  FormMode,
  IForm,
  IRenderView,
  IStore,
  IStoreManagement,
  MenuProps,
  Props,
  RenderViewValue,
  ValueViewType,
} from "./AccountCreationController.web";
import ActionPermissionsModal, { 
  AddPermissionWrapper 
} from "../../../blocks/RolesPermissions2/src/ActionPermissionsModal.web";
import { renderBaseOnConditions, getOptionLabelByValue, safeExtraction } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { InputField, MultiSelectionBox } from "../../../components/src/customComponents/CustomTags.web";
import { ViewPermissionGroup } from "../../../blocks/RolesPermissions2/src/RolesPermissionsCreate2.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
// Customizable Area End

// Customizable Area Start
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#204B9C",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMultipleMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#204B9C",
      },
    },
  },
}))(MenuItem);

const useStyles: Styles<Theme, {}, string> = () => ({
  pageContainer: {
    padding: "32.5px 48px",
    fontFamily: "Montserrat",
    background: "white",
    
    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
    },
  },
  mainContainerWrapper: {
    paddingBottom:"30px",
    paddingTop:"30px"
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: 24,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  formContainer: {
    padding: 32,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.3px",
      color: "#1A1A1A",
    },
    position: "relative",
  },
  iconBtnContainer: {
    backgroundColor: "#204B9C",
    width: 56,
    height: 56,
    borderRadius: 8,
    marginLeft: 16,
    marginTop: 24,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (max-width: 1024px)": {
      width: 44,
      height: 42,
    }
  },
  button: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: 16,
      fontWeight: 600,
      width: 128,
      height: 44,
      padding: "12px 34px",
    }
  },
  errorInput: {
    "& fieldset": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  checkBoxContainer: {
    marginBottom: 32,

    "& span": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    "&:last-child": {
      marginBottom: 0,
    },
  },
  modalContainer: {
    width: "574px",
    height: "407px",
    borderRadius: 24,
  },
  modalContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalIconContainer: {
    marginBottom: 64,
    marginTop: 28,
  },
  modalBoldText: {
    marginBottom: 16,
    fontWeight: 600,
    fontSize: 24,
    fontFamily: "Montserrat",
  },
  modalBodyText: {
    fontSize: 20,
    marginBottom: 32,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  modalButton: {
    fontFamily: "Montserrat",
    width: 292,
    background: "#204B9C",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    paddingTop: 13,
    paddingBottom: 13,
    textAlign: "center",
    cursor: "pointer",
    marginBottom: 40,
  },
  selectContainer: {
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      height:"44px",
    }
  },
  viewSectionHeader: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 16,
    }
  },
  tag: {
    background: "rgba(32, 75, 156, 0.15)",
    borderRadius: 8,
    padding: "6px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "40px",
    color:colors().cyancobaltblue,
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
      fontWeight: 500,
    }
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px"
  },
  editButton : {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 600,
    cursor: "pointer",
    backgroundColor:  colors().cyancobaltblue,
    color: colors().background,
    textAlign: "center",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "155px",
      height:"44px",
      padding: "14px 34px",
    },
  },
  fieldColumns: {
    "& .MuiGrid-item:nth-child(2)": {
      "@media (max-width: 900px)": {
        marginTop: 20
      }
    }
  },
  PhoneFieldColumns: {
    "& .MuiGrid-item:nth-child(3)": {
      "@media (max-width: 900px)": {
        marginTop: 20
      }
    }
  },
  selectAllText: {
    color: "black",
    fontWeight: 400,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '14px',
    }
  },
  blueCheckbox: {
    "&$checked": {
      color: "#204B9C",
    },
  },
  checked: {},
  formErrors: {
    color: "red",
    marginBottom: "-20px",
  },
  chips: {
    gap: 4,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "Montserrat",
  },
  chip: {    
    color: "#204B9C",
    fontFamily: "Montserrat",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
  },
});

const StyledFormLable = styled('p')({
  marginBottom: "8px",
  marginTop: "0px",
  fontSize: "16px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
  color:colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    color:colors().charcoal,
  },
});

const StyledInputField = styled(InputField)({
  paddingTop:"0",
  "& .MuiOutlinedInput-root": {
    fontWeight: 400,
    fontSize: "1rem",
    border: "1px solid #ECECEC",
    fontFamily: "Montserrat",
    borderRadius: "0px",
    "& fieldset": {
      borderRadius: "0px",   
      borderWidth: 1
    },
  },
});

const StyledMobileLabel = styled('p')({
  marginBottom: "8px",
  marginTop: "0px",
  whiteSpace: 'nowrap',
  fontSize: "16px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
  color:colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    color:colors().charcoal,
  },
});
const StyledTextField = styled(TextField)({
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 14px'
    },
  },

});
const ContryCodeDiv = styled('div')({
  "@media only screen and (min-width: 1024px)": {
    width:"auto"
  },
  "@media only screen and (min-width: 1280px)": {
    width:"72px"
  },
  "@media only screen and (min-width: 1366px)": {
    width:"72px"
  },
});
const StylesFormControl = styled(FormControl)({
  width: "100%" ,
  paddingTop:"4px",
});
const StyledAutoSelect = styled(Autocomplete)({
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
     },
     '& .MuiListItemText-primary':{
      fontSize: '12px',
     }
 }
});

const StyledRoleTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: 16,
  fontWeight: 500,
  marginTop: 4,
  color:colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,
  }
});
const ViewFormLabel = styled(Typography)({
  marginBottom: "8px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontSize: "18px",
  fontWeight: 600,
  marginTop: 4,
  color:colors().darkliver,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color:colors().viewTextColor,
  }
});
const accountForDataSet = [{ id: "0", option: "Employee" }, { id: "1", option: "Sub - Contractor" }]
const departmentDataSet = [
  { id: "0", option: "Sales" }, 
  { id: "1", option: "IT" },
  { id: "2", option: "Production" },
  { id: "3", option: "Accounts" },
  { id: "4", option: "Management" },
]

// Customizable Area End

export class AccountCreation extends AccountCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderView = (value: RenderViewValue, label: string, color: string = "#1A1A1A") => (
    <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
      <FormControl style={{ width: "100%" }}>
        <ViewFormLabel style={{ marginBottom: "8px" }}>{label}</ViewFormLabel>
        <StyledRoleTypography>
          {value || configJSON.asterixPlaceholder}
        </StyledRoleTypography>
      </FormControl>
    </Grid>
  );

  renderRole = (roleName: string) => {
    return (
      <StyledRoleTypography>
        {roleName}
      </StyledRoleTypography>
    );
  };

  renderRoleView = (value: ValueViewType) => {
    const role = this.state.roleList.find((item) => item.id === value);
    const roleName = role ? role.name : "";
    return this.renderRole(roleName);
  };

  renderRegion = (regionName: string) => {
    return (
      <Typography
        style={{
          fontSize: 16,
          fontWeight: 500,
          marginTop: 4,
        }}
      >
        {regionName}
      </Typography>
    );
  };

  renderRegionView = () => {
    const regionName = this.state.selectedRegions.map(region => region.option).join(",");

    return (
      <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Business Area/Region</FormLabel>
          {this.renderRegion(regionName)}
        </FormControl>
      </Grid>
    );
  };

  renderAreaView = () => {
    const areaName = this.state.selectedAreas.map(area => area.option).join(",");

    return (
      <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Area</FormLabel>
          {this.renderRegion(areaName)}
        </FormControl>
      </Grid>
    );
  };

  renderAccountForView = (value: ValueViewType) => {
    return (
      <FormControl style={{ width: "100%" }}>
        <FormLabel style={{ marginBottom: "8px" }}>{configJSON.accountForView}</FormLabel>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginTop: 4,
          }}
        >
          {value === 1 ? configJSON.subContractor : configJSON.employee}
        </Typography>
      </FormControl>
    );
  };

  renderAccountText = () => {
    const { classes = {} } = this.props;

    return <div className={classes.viewSectionHeader}>{configJSON.accountText}</div>;
  };

  renderAccountSectionHeader = () => {
    if (this.state.formMode === FormMode.View) {
      return (
        <Grid container direction='row'>
          {this.renderAccountText()}
        </Grid>
      );
    }
    return null;
  };

  getFormContainerMarginTop() {
    if (this.state.formMode === FormMode.View) {
      return 66;
    }
    return 0;
  }

  renderTextField(key: keyof IForm, placeholder: string, inputDir?: string) {
    const { form, formErrors } = this.state;
    const { classes = {} } = this.props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;

      if (key === "phone_number") {
        inputValue = inputValue.replace(/[^\d+]/g, "");
      }
      
      this.onFormElementChange({ ...event, target: { ...event.target, value: inputValue } }, key);
    };

    return (
      <StyledTextField
        data-test-id={key}
        className={formErrors[key] ? classes.errorInput : "withoutError"}
        value={key === this.passwordText && this.state.formMode === FormMode.Edit ? "" : form[key]}
        color='primary'
        dir={inputDir}
        variant='outlined'
        type='text'
        name={key}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    );
  }

  renderNumberField(key: keyof IForm, placeholder: string) {
    const { form, formErrors } = this.state;
    const { classes = {} } = this.props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;

      if (key === "country_code") {
        inputValue = inputValue.replace(/[^\d+]/g, "");
      }

      this.onFormElementChange({ ...event, target: { ...event.target, value: inputValue } }, key);
    };

    return (
      <StyledTextField
        disabled={key === this.passwordText && this.state.formMode === FormMode.Edit}
        data-test-id={key}
        className={formErrors[key] ? classes.errorInput : "withoutError"}
        value={form[key]}
        color='primary'
        variant='outlined'
        type='text'
        name={key}
        placeholder={
          key === this.passwordText && this.state.formMode === FormMode.Edit
            ? configJSON.asterixPlaceholder
            : placeholder
        }
        onChange={handleInputChange}
      />
    );
  }

  renderInputTextField(
    key: keyof IRenderView,
    label: string,
    placeholder: string,
    viewLabel: string,
    inputDir?: string
  ) {
    const { form, formErrors, formMode } = this.state;

    if (formMode === FormMode.View) {
      return this.renderView(form[key], viewLabel);
    }

    return (
      <>
        <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }} >
          <FormControl style={{ width: "100%" }}>
            <StyledFormLable >{label}</StyledFormLable>
            {this.renderTextField(key, placeholder, inputDir)}
            {formErrors[key] && (
              <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>
                {formErrors[key]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }

  renderMobileNumberInputTextField(
    key: keyof IRenderView,
    label: string,
    placeholder: string,
    viewLabel: string
  ) {
    const { form, formErrors, formMode } = this.state;

    if (formMode === FormMode.View) {
      return this.renderView("+" + form["full_phone_number"], viewLabel);
    }

    return (
      <>
        <Grid item xs={4} sm={3} md={2} lg={1} style={{ paddingRight: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <StyledMobileLabel>{label}</StyledMobileLabel>
            <ContryCodeDiv>
              {this.renderNumberField("country_code", "")}
            </ContryCodeDiv>
            {(formErrors["country_code"] || formErrors["phone_number"]) && (
              <Typography className='errorMsg2' variant='caption' style={{ color: "red", whiteSpace: 'nowrap' }}>
                {formErrors["country_code"] || formErrors["phone_number"]}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={5} md={4} lg={5} style={{ paddingRight: 32 }}>
          <StylesFormControl style={{ width: "100%" }}>
            <FormLabel style={{ marginBottom: "8px", visibility: 'hidden', whiteSpace: 'nowrap' }}>{label}</FormLabel>
            {this.renderTextField(key, placeholder)}
          </StylesFormControl>
        </Grid>
      </>
    );
  }

  renderAccountSelect() {
    const { form, formErrors, formMode } = this.state;
    const { classes = {} } = this.props;

    if (formMode === FormMode.View) {
      return this.renderAccountForView(form.user_type);
    }

    return (
      <FormControl variant='outlined' style={{ width: "100%" }}>
        <StyledFormLable>{configJSON.accountFor}</StyledFormLable>

        <StyledAutoSelect
          clearOnEscape={false}
          clearOnBlur={false}
          ListboxProps={
            {
              style: {
                maxHeight: '150px',
              }
            }
          }
          data-test-id='user_type'
          id="accountFor"
          onInputChange={(event: unknown, value: unknown) => { this.onFormElementChange({ target: { value: value } }, "user_type") }}
          onChange={(event: React.ChangeEvent<{}>, value: unknown) => { 
            this.onFormElementChange({ target: { value: safeExtraction(value as { id: string }, "id") } }, "user_type")
          }}
          inputValue={getOptionLabelByValue(form.user_type as unknown as string, "id", accountForDataSet, "option")}
          renderInput={(params: object) => (
            <StyledInputField
              {...params}
              error={Boolean(formErrors.user_type)}
              variant="outlined"
              placeholder={configJSON.selectCategory}
            />
          )}
          options={accountForDataSet}
          getOptionLabel={(option: unknown) => (option as { option: string }).option}
        />

        {formErrors.user_type && (
          <Typography className={"errorMsg2"} variant='caption' style={{ color: "red" }}>
            {formErrors.user_type}
          </Typography>
        )}
      </FormControl>
    );
  }

  renderRoleSelect() {
    const { form, formErrors, formMode, roleList } = this.state;
    const { classes = {} } = this.props;
    

    if (formMode === FormMode.View) {
      return;
    }

    return (
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        style={{
          paddingRight: 32,
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <StyledFormLable>{configJSON.roleText}</StyledFormLable>

          <StyledAutoSelect
            clearOnEscape={false}
            clearOnBlur={false}
            ListboxProps={
              {
                style: {
                  maxHeight: '150px',
                }
              }
            }
            data-test-id='role_id'
            id="role_id"
            onInputChange={(event: unknown, value: unknown) => { this.onFormElementChange({ target: { value: value } }, "role_id") }}
            onChange={(event: React.ChangeEvent<{}>, value: unknown) => {
              let selectedValue =  safeExtraction(value as { id: string }, "id")
              this.onFormElementChange({ target: { value: Number(selectedValue) } }, "role_id")
            }}
            inputValue={getOptionLabelByValue(form.role_id as unknown as string, "id", roleList as unknown as { [key: string]: string }[], "name")}
            renderInput={(params: object) => (
              <StyledInputField
                {...params}
                error={Boolean(formErrors.role_id)}
                variant="outlined"
                placeholder={configJSON.selectRoleText}
              />
            )}
            options={roleList}
            getOptionLabel={(option: unknown) => (option as { name: string }).name}
          />

          {formErrors.role_id && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.role_id}
            </Typography>
          )}
        </FormControl>
        <div  data-test-id="redirect-role" onClick={this.handleAddRoleClick} className={classes.iconBtnContainer}>
          <AddCircleOutlineIcon style={{ color: "white", fontSize: 23 }} />
        </div>
      </Grid>
    );
  }

  renderRegionSelectAccount() {
    const { form, regionListAccount, formErrors } = this.state;
    const { classes = {} } = this.props;

    return (
      <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <StyledFormLable>Business Area/Region*</StyledFormLable>
        
          <CustomSearchableMultiSelect
            id="region"
            name="region"
            dataTestId="regionDropdown"
            checkSelectAllText={() => {}}
            value={this.state.selectedRegions}
            optionList={this.state.regionListAccount}
            placeHolderText="Select Region"
            changeInputOnClear
            handleScroll={() => {}}
            handleClickOnSelectAll={() => {}}
            isOnBlurFunction={() => this.debouncedFunction("", this.handleSearchRegionList)}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSearchRegionList) }}
            handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string , option: string }) => { this.handleSelectRegionOptions(option) }}
            emptyDataMessage="No Region Found"
            handleEmptyAutoSelectValue={() => {}}
            selectedOptionForList={this.state.selectedRegionsFromList}
            isError={Boolean(formErrors.region_ids)}
          />
          {formErrors.region_ids && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.region_ids}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderAreaSelectAccount() {
    return (
      <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <StyledFormLable>Area</StyledFormLable>
        
          <CustomSearchableMultiSelect
            id="area"
            name="area"
            dataTestId="areaDropdown"
            checkSelectAllText={() => {}}
            value={this.state.selectedAreas}
            optionList={this.state.areaListAccount}
            placeHolderText="Select Area"
            changeInputOnClear
            handleScroll={() => {}}
            handleClickOnSelectAll={() => {}}
            isOnBlurFunction={() => this.debouncedFunction("", this.handleSearchAreaList)}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSearchAreaList) }}
            handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string , option: string }) => { this.handleSelectAreaOptions(option) }}
            emptyDataMessage="No Area Found"
            handleEmptyAutoSelectValue={() => {}}
            selectedOptionForList={this.state.selectedAreasFromList}
          />
        </FormControl>
      </Grid>
    );
  }

  renderChips(items: Array<string>) {
    const { classes = {} } = this.props;
    const renderedJSX = 
      items?.length ? items
      .filter((item) => item)
      .map((name: string) => (
        <Chip label={name} key={name} className={classes.chip} />
      )): <></>;
    return <div className={classes.chips}>{renderedJSX}</div>;
  }

  renderStoreSelectAccount() {
    const { storeList, formErrors, formMode, selectedStore } = this.state;

    if (formMode === FormMode.View) {
      let store_names = "";
      if(selectedStore.length) {
        store_names = selectedStore.map((store)=> store.option).join(",")
      }
      return this.renderView(store_names || "--", "Store");
    }

    return (
      <Grid item xs={12} sm={6} style={{ paddingRight: 32, marginTop: 30 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Store</FormLabel>

          <CustomSearchableMultiSelect
            id="store"
            name="store"
            dataTestId="store_management_id"
            placeHolderText='Select Stores'
            changeInputOnClear
            emptyDataMessage="No Data Found"
            handleScroll={() => { }}
            checkSelectAllText={() => { }}
            handleClickOnSelectAll={() => { }}
            handleEmptyAutoSelectValue={() => { }}
            debouncedFunction={(getValue: string) => { }}
            optionList={storeList}
            value={this.state.selectedStore}
            selectedOptionForList={this.state.storeSelectedOptionForList}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleStoreMultiSelect(value, option) }}
            isError={Boolean(formErrors.store_management_ids)}
          />
          
          {formErrors.store_management_ids && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.store_management_ids}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderDropdownForRegionManagerSelect() {
    const {formMode, form, regionListAccount, formErrors } = this.state;
    const { classes = {} } = this.props;

    if (formMode === FormMode.View) {
      const regionName = regionListAccount.find((item) => String(item.id) === String(form["region_manager_id"]))?.option
      return this.renderView(regionName || "--", "Region of Region Manager");
    }
    
    return (
      <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Region of Region Manager</FormLabel>
          <Select
            data-test-id='region_manager_id'
            className={formErrors["region_manager_id"] ? classes.errorInput : classes.selectContainer}
            id='region_manager_id'
            displayEmpty
            renderValue={form.region_manager_id !== null ? undefined : () => "Select Region"}
            value={form.region_manager_id}
            onChange={(event) => this.onFormElementChange(event, "region_manager_id")}
            placeholder='Select Region'
            MenuProps={MenuProps}
          >
            {regionListAccount.map((region) => (
              <StyledMenuItem key={region.id} value={region.id}>
                <ListItemText primary={region.option} />
              </StyledMenuItem>
            ))}
          </Select>
          {formErrors.region_manager_id && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.region_manager_id}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderDropdownForPlantUsers() {
    const { form, formErrors, formMode } = this.state;
    const { classes = {} } = this.props;

    if (formMode === FormMode.View) {
      return this.renderView(form["plant_section"] || "--", "Plant Section");
    }

    return (
      <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Plant Section</FormLabel>
          <Select
            data-test-id='plant_section'
            className={formErrors["plant_section"] ? classes.errorInput : classes.selectContainer}
            id='plant_section'
            displayEmpty
            renderValue={form.plant_section !== null ? undefined : () => "Select Plant Section"}
            value={form.plant_section}
            onChange={(event) => this.onFormElementChange(event, "plant_section")}
            placeholder='Select Plant Section'
            MenuProps={MenuProps}
          >
            {this.getPlantSection().map((section: {
              value: string;
              label: string;
            }) => {
              return (
                <StyledMenuItem key={section.value} value={section.value}>
                  <ListItemText primary={section.label} />
                </StyledMenuItem>
              )
            })}
          </Select>
          {formErrors.plant_section && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.plant_section}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  getDestructureRender(){
    const { classes = {} } = this.props;

    const { infoModalVisible, form, isSubmitting, formMode, dynamicContent } = this.state;
    let pageTitle;
    let buttonText;

    if (formMode === FormMode.Create) {
      pageTitle = configJSON.accountCreation;
    } else if (formMode === FormMode.Edit) {
      pageTitle = configJSON.editUserAccount;
    } else {
      pageTitle = configJSON.userAccountText;
    }

    if (isSubmitting) {
      buttonText = <CircularProgress size={16} color='inherit' />;
    } else if (formMode === FormMode.Edit) {
      buttonText = configJSON.saveText;
    } else {
      buttonText = configJSON.createText;
    }
    
    const roleName = this.state.roleList.find(
      (role) => role.id === form.role_id
    )?.name;

    const isRegionManager = roleName === "Region Manager"    
    const isStoreKeeper = roleName === "Store Keeper"
    const isPlantUser = roleName === "Plant User" 
    
    return { form, formMode, classes, isRegionManager, isStoreKeeper, isPlantUser, pageTitle, buttonText, infoModalVisible, dynamicContent }

  }

  renderPermissonView() {
    const { selectedPermissions } = this.state;
    if(selectedPermissions.length == 0) {
      return (
        <ViewPermissionGroup>
          <Box className="viewPermissionBox">{configJSON.textNoPermission}</Box>
        </ViewPermissionGroup>
      )
    } else {
      return (
        <ViewPermissionGroup>
          {
            selectedPermissions.map((mainPermission) => {
              return (
                <Box className="viewPermissionBox">
                  <Typography className="permissionName">{mainPermission.name}</Typography>
                  <Box className="permissionViewWrapper">
                    {
                      mainPermission.permission_sub_groups.filter(
                        (subPermission) => subPermission.permitted).map(
                          (subItem) => {
                            return <Typography className="permissionSubName">{subItem.name}</Typography>
                      })
                    }
                  </Box>
                </Box>
              )
            })
          }
        </ViewPermissionGroup>
      )
    }
  };

  renderDirectManager() {
    const { form, managerListData, formErrors } = this.state;
    const { classes = {} } = this.props;

    return (
      <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32, marginTop: 30 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <StyledFormLable>Direct Manager Name*</StyledFormLable>

          <StyledAutoSelect
            clearOnEscape={false}
            clearOnBlur={false}
            ListboxProps={
              {
                style: {
                  maxHeight: '150px',
                }
              }
            }
            data-test-id='direct_manager_name'
            id="direct_manager_name"
            onInputChange={(event: unknown, value: unknown) => { this.onFormElementChange({ target: { value: value } }, "direct_manager_name") }}
            onChange={(event: React.ChangeEvent<{}>, value: unknown) => {
              this.onFormElementChange({ target: { value: safeExtraction(value as { id: string }, "id") } }, "direct_manager_name")
            }}
            inputValue={getOptionLabelByValue(form.direct_manager_name as unknown as string, "id", this.convertToIOptionsForManager(managerListData), "label")}
            renderInput={(params: object) => (
              <StyledInputField
                {...params}
                error={Boolean(formErrors.direct_manager_name)}
                variant="outlined"
                placeholder={"Select Direct Manager"}
              />
            )}
            options={this.convertToIOptionsForManager(managerListData)}
            getOptionLabel={(option: unknown) => (option as { label: string }).label}
          />

          {formErrors.direct_manager_name && (
            <Typography
              className={"errorMsg2"}
              variant='caption'
              style={{ color: "red", marginBottom: "-20px" }}
            >
              {formErrors.direct_manager_name}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderDirectManagerView = (value: ValueViewType) => {
    const region = this.state.managerListData.find((item) => item.id === value);
    const regionName = region ? `${region.attributes.first_name}` : "";

    return (
      <Grid item xs={12} sm={6} style={{ paddingRight: 32, marginTop: 30 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Direct Manager Name</FormLabel>
          {this.renderRegion(regionName)}
        </FormControl>
      </Grid>
    );
  };

  renderDepartmentSelect() {
    const { form, formErrors, formMode } = this.state;

    if (formMode === FormMode.View) {
      return this.renderDepartmentView(form["department_name"]);
    }

    return (
      <FormControl variant='outlined' style={{ width: "100%" }}>
        <StyledFormLable>{"Department Name*"}</StyledFormLable>

        <StyledAutoSelect
          clearOnBlur={false}
          clearOnEscape={false}
          ListboxProps={
            {
              style: {
                maxHeight: '150px',
              }
            }
          }
          data-test-id='department_name'
          id="department_name"
          onInputChange={(event: unknown, value: unknown) => { this.onFormElementChange({ target: { value: value } }, "department_name") }}
          onChange={(event: React.ChangeEvent<{}>, value: unknown) => {
            this.onFormElementChange({ target: { value: safeExtraction(value as { id: string }, "id") } }, "department_name")
          }}
          inputValue={getOptionLabelByValue(form.department_name as unknown as string, "id", departmentDataSet, "option")}
          renderInput={(params: object) => (
            <StyledInputField
              {...params}
              error={Boolean(formErrors.department_name)}
              variant="outlined"
              placeholder={"Enter Department Name"}
            />
          )}
          options={departmentDataSet}
          getOptionLabel={(option: unknown) => (option as { option: string }).option}
        />

        {formErrors.department_name && (
          <Typography className={"errorMsg2"} variant='caption' style={{ color: "red" }}>
            {formErrors.department_name}
          </Typography>
        )}
      </FormControl>
    );
  }

  renderDepartmentView = (value: ValueViewType) => {
    const department = departmentDataSet.find((item) => item.id == value);
    const departmentName = department ? `${department.option}` : "";

    return (
      <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
        <FormControl variant='outlined' style={{ width: "100%" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Department Name</FormLabel>
          {this.renderRegion(departmentName)}
        </FormControl>
      </Grid>
    );
  };

  renderRegionField = () => {
    const {formMode } = this.state;
    return (
      <>
        {formMode === FormMode.View
                    ? this.renderRegionView()
                    : this.renderRegionSelectAccount()}
      </>
    )
  }

  renderAreaField = () => {
    const {formMode } = this.state;
    return (
      <>
        {formMode === FormMode.View
                    ? this.renderAreaView()
                    : this.renderAreaSelectAccount()}
      </>
    )
  }

  renderSubmitButton = () => {
    const {formMode } = this.state;
    const { 
      classes,
      buttonText
    } = this.getDestructureRender();
    return (
      <>
        {formMode !== FormMode.View && (
          <div
            data-test-id={"submitBtn"}
            onClick={this.handleOnSubmit}
            className={classes.button}
            style={{ backgroundColor: "#204B9C", color: "white" }}
          >
            {buttonText}
          </div>
        )}
      </>
    )
  }

  renderCancelBackButton = () => {
    const {formMode } = this.state;
    return (
      <>
        {formMode === FormMode.View ? configJSON.backText : configJSON.cancelText}
      </>
    )
  }
  

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      form,
      formMode,
      classes,
      isRegionManager,
      isStoreKeeper,
      isPlantUser,
      pageTitle,
      buttonText,
      infoModalVisible,
      dynamicContent 
    } = this.getDestructureRender();

    const { permissionModalOpen, permissionStatus } = this.state;
    
    return (
      <Suspense fallback="Loading">
        <PageContainer
          navigation={this.props.navigation}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
          {this.state.isLoading ? <Skeleton variant="rect" width={2000} height={900} /> : 
          <Grid direction='column' className={classes.pageContainer}>
            <div className={classes.mainContainerWrapper}>
              <Grid
                container
                direction='row'
                style={{
                  marginBottom: 32,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                
                <div className={classes.pageTitle}>{pageTitle}</div>
                {formMode === FormMode.View && (
                  <>
                    {
                      renderBaseOnConditions(
                        permissionStatus.editPermission,
                        <div
                          data-test-id={"editAccount"}
                          onClick={() => this.handleEditAccountClick(this.state.currentUserId)}
                          className={classes.editButton}
                        >
                          Edit Account
                        </div>,
                        <></>
                      )
                    }
                  </>
                )}
              </Grid>
              <Grid container direction='column' className={classes.formContainer}>
                {this.renderAccountSectionHeader()}
                <Grid
                  container
                  direction='row'
                  style={{ marginTop: this.getFormContainerMarginTop() }}
                  className={classes.fieldColumns}
                >
                  {this.renderInputTextField(
                    "first_name",
                    "First Name*",
                    "Enter First Name",
                    "First Name"
                  )}
                  {this.renderInputTextField(
                    "last_name",
                    "Last Name*",
                    "Enter Last Name",
                    "Last Name"
                  )}
                </Grid>
                
                <Grid
                  container
                  direction='row'
                  style={{ marginTop: 30 }}
                  className={classes.fieldColumns}
                >
                  {this.renderInputTextField(
                    "first_name_arabic",
                    "First Name (Arabic)",
                    "Enter First Name (Arabic)",
                    "First Name (Arabic)",
                    "rtl"
                  )}
                  {this.renderInputTextField(
                    "last_name_arabic",
                    "Last Name (Arabic)",
                    "Enter Last Name (Arabic)",
                    "Last Name (Arabic)",
                    "rtl"
                  )}
                </Grid>

                <Grid container direction='row' style={{ marginTop: 30 }} className={classes.PhoneFieldColumns}>
                  {this.renderMobileNumberInputTextField(
                    "phone_number",
                    "Mobile Number*",
                    "Enter Mobile Number",
                    "Mobile Number"
                  )}
                  {this.renderInputTextField(
                    "email",
                    "Email Address*",
                    "Enter Email",
                    "Email Address"
                  )}
                </Grid>
                <Grid container direction='row' style={{ marginTop: 30 }} className={classes.fieldColumns}>
                  <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }}>
                    {this.renderAccountSelect()}
                  </Grid>
                  {this.renderInputTextField(
                    "user_login_id",
                    "Employee ID/Iqama ID*",
                    "Enter ID",
                    "Employee ID/Iqama ID"
                  )}
                </Grid>
                <Grid container direction='row' style={{ marginTop: 30 }} className={classes.fieldColumns}>
                    <Grid item xs={12} sm={8} md={6} style={{ paddingRight: 32 }}>
                      {this.renderDepartmentSelect()}
                    </Grid>
                  {this.renderInputTextField("position", "Position*", "Enter Position", "Position")}
                </Grid>
                <Grid container direction='row' style={{ marginTop: 30 }} className={classes.fieldColumns}>
                  {this.renderRegionField()}
                  {this.renderAreaField()}
                   {formMode === FormMode.View
                    ? this.renderDirectManagerView(form.direct_manager_name)
                    : this.renderDirectManager()}
                    {this.renderStoreSelectAccount()}
                </Grid>
                
                    <Grid
                      container
                      direction='row'
                      style={{ marginTop: 30 }}
                      className={classes.fieldColumns}
                    >
                      {this.renderRoleSelect()}
                      {isRegionManager && this.renderDropdownForRegionManagerSelect()}
                      {!isRegionManager && !isPlantUser &&
                        this.renderInputTextField(
                          "password",
                          "Password*",
                          "Enter Password",
                          "Password"
                        )}
                    </Grid>
                    {(isRegionManager || isPlantUser) && (
                      <Grid
                        container
                        direction='row'
                        style={{ marginTop: 30 }}
                        className={classes.fieldColumns}
                      >
                        {this.renderInputTextField(
                          "password",
                          "Password*",
                          "Enter Password",
                          "Password"
                        )}
                      </Grid>
                    )}
                  
                {(form.role_id && formMode !== FormMode.View) ? (
                  <>
                  {renderBaseOnConditions(
                    permissionStatus.addPermission as boolean,
                    <Box style={{margin: "24px 0 0 0"}}>
                      <AddPermissionWrapper 
                        className='permission-wrapper'
                        onClick={() => this.handleOpenPermissionModal()}
                      >
                        <img src={plusIcon} width={20} height={20} alt='plus-icon' />
                        <span className='add-permission-span'>{configJSON.addPermission}</span>
                      </AddPermissionWrapper>
                      <Typography
                        className='errorMsg2'
                        variant='caption'
                        style={{
                          color: "red",
                          margin: "6px",
                          display: "block"
                        }}
                      >
                        {this.state.formErrors.permission_sub_group_ids}
                      </Typography>
                    </Box>,
                    <></>
                  )}
                  <Box style={{margin: "24px 0 0 0"}}>
                    <StyledFormLable>{configJSON.permissionsSection}</StyledFormLable>
                    {this.renderPermissonView()}
                  </Box>
                  </>
                ): <></>}
              </Grid>
              {formMode === FormMode.View && (
                <Grid
                  style={{ marginTop: 30 }}
                  container
                  direction='column'
                  className={classes.formContainer}
                >
                  <Grid container direction='row'>
                    <div className={classes.viewSectionHeader}>
                      {configJSON.rolesAndPermissionText}
                    </div>
                  </Grid>
                  <Grid container direction='column' style={{ marginTop: 66 }}>
                    <Grid item style={{ margin: "0 0 24px 0"}}>
                      <FormControl style={{ width: "100%" }}>
                        <ViewFormLabel>
                          {configJSON.roleSection}
                        </ViewFormLabel>
                        {this.renderRoleView(form.role_id)}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl style={{ width: "100%" }}>
                        <ViewFormLabel>
                          {configJSON.permissionsSection}
                        </ViewFormLabel>
                        {this.renderPermissonView()}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid container direction='row' style={{ marginTop: 48 }}>
                <div
                  data-test-id={"cancelBtn"}
                  onClick={this.handleCancelClick}
                  className={classes.button}
                  style={{
                    backgroundColor: "#F1F5F9",
                    color: "#64748B",
                    marginRight: 32,
                  }}
                > 
                  {this.renderCancelBackButton()}
                </div>
                {this.renderSubmitButton()}
              </Grid>
            </div>
          </Grid>
          }
          <Dialog
            open={infoModalVisible}
            data-test-id="infoModal"
            onClose={this.handleInFoModalClose}
            fullWidth={true}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent>
              <div className={classes.modalContentContainer}>
                <div className={classes.modalIconContainer}>
                  <img src={waitingForApprovalIcon} />
                </div>
                <div className={classes.modalBoldText}>{configJSON.modalTitle}</div>
                <div className={classes.modalBodyText}>{dynamicContent}</div>
                <div onClick={this.handleInFoModalClose} className={classes.modalButton}>
                  {configJSON.gotoHome}
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={4000}
            onClose={this.handleSnackbarClose}
          >
            <Alert severity='error'>{this.state.snackbarMessage}</Alert>
          </Snackbar>
          {permissionModalOpen && 
            <ActionPermissionsModal 
              navigation={undefined}
              id={""}
              isOpenPermissionModal={permissionModalOpen}
              onClosePermissionModal={this.handleClosePermissionModal}
              onContinuePermissionChange={this.handleChangePermission}
              permissionGroup={this.state.mainPermissionGroups}
            />
          }
        </PageContainer>
      </Suspense>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(AccountCreation);
