// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment, {Moment} from "moment";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { navigateTo, customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { makeApiMessage } from "../../../components/src/common";
import { getYAxisValues } from "../../../components/src/customComponents/CustomColumnChart.web";
import html2pdf from "html2pdf.js";
import { handleDisplayRegion } from "./utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface OverallSalesData {
  stores_count: number,
  order_count: number,
  pieces_count: number,
  amount: string,
  discount: string,
  vat: string,
  used_credit: number,
  revenue_amount: string,
  rewash_order_count: number,
  unpaid_amount: string,
  start_date: string;
  end_date: string;
}

export type SaleDataItem = {
  name: string; 
  value: string;
}

export type SectionSale = {
  section: string;
  pieces: number;
  orders: number;
  total_price: string;
}

export type StoreDetails = {
  store_name: string,
  orders_count: number,
  pieces: number,
  amount: string
}

export const storeTableColumns = [
  {
    label: "Store Name",
    binding: "store_name"
  },
  {
    label: "Orders",
    binding: "orders_count"
  },
  {
    label: "Pieces",
    binding: "pieces"
  },
  {
    label: "Amount",
    binding: "amount"
  } 
] as {label: string; binding: keyof StoreDetails}[]

const initialOverallSales = {
  "stores_count":0,
  "order_count":0,
  "pieces_count":0,
  "amount":"0.00",
  "discount":"0.00",
  "vat":"0.00",
  "used_credit":0,
  "revenue_amount":"0.00",
  "rewash_order_count":0,
  "unpaid_amount":"0.00",
  "start_date": "",
  "end_date": ""
}

const initialStoreDetails = {
  stores_count: 0,
  active_stores_count: 0,
  new_stores_count: 0,
  closed_stores_count: 0,
  top_stores_count: 0,
  low_stores_count: 0
}

const initialFilter =
  {
    title: "Date",
    type: "dateselect",
    datevalue: { from: "", to: "" },
    value: "today",
    options: [
      {
        label: "Today",
        value: "today",
      },
      {
        label: "Last 7 days",
        value: "last_7_days",
      },
      {
        label: "Last 30 days",
        value: "last_30_days",
      },
      {
        label: "Specific Dates",
        value: "specific",
      },
    ],
  } as IFilterItem
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDate: string | null;
  filterAnchor: undefined | HTMLDivElement
  filters: IFilterItem[]
  overallSales: OverallSalesData,
  totalSales: {
    storeSales: string
    mobileSales: string
  },
  salesDetail: {
    today_sales_amount: string;
    yesterday_sales_amount: string;
  }
  storeDetails: {
    stores_count: number
    active_stores_count: number
    new_stores_count: number
    closed_stores_count: number
    top_stores_count: number
    low_stores_count: number
  }
  regionId: string
  storeId: string
  storeName: string
  groupId: string
  groupName: string
  regionName: string
  regionSales: {
    region_name: string; 
    total: string;
  }[]
  regionSalesDetails: Array<{
    region_name: string;
    data: SectionSale[]
    total: Array<number | string>
  }>
  sectionSales: SectionSale[]
  mobileSales: SectionSale[]
  newStoreDetails: StoreDetails[]
  showModal: "new_stores" | "region_sales" | ""
  loadingModal: boolean
  totalCustomer: number;
  topCustomer: number;
  newCustomer: number;
  inactiveCustomer: number;
  repeatedCustomer: number;
  openExportModal: boolean;
  isLoadingOverallSale: boolean;
  isLoadingTotalSale: boolean;
  isLoadingSaleDetail: boolean;
  isLoadingStoreDetail: boolean;
  isLoadingRegionSale: boolean;
  permissionStatus: DashboardPermissionStatus | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class MainDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOverallSalesCallId = "";
  getTotalSalesCallId = "";
  getRegionSalesCallId = "";
  getSalesDetailCallId= "";
  getStoreDetailsCallId = "";
  getSectionSalesCallId = "";
  getNewStoreDetailsCallId = "";
  getRegionSalesDetailsCallId = "";
  getTotalCustomerCountCallId = "";
  getTopCustomerCountCallId = "";
  getNewCustomerCountCallId = "";
  getInactiveCustomerCountCallId = "";
  getRepeatedCustomerCountCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      regionId: "",
      regionName: "",
      storeId: "",
      storeName: "",
      groupId: "",
      groupName: "",
      selectedDate: null,
      filterAnchor: undefined,
      filters: [initialFilter],
      overallSales: initialOverallSales,
      totalSales: {
        storeSales: "0.00",
        mobileSales: "0.00"
      },
      salesDetail: {
        today_sales_amount: "0.00",
        yesterday_sales_amount: "0.00"
      },
      storeDetails: initialStoreDetails,
      newStoreDetails: [],
      regionSales : [],
      regionSalesDetails: [],
      sectionSales: [],
      mobileSales: [],
      showModal: "",
      loadingModal: false,
      totalCustomer: 0,
      topCustomer: 0,
      newCustomer: 0,
      inactiveCustomer: 0,
      repeatedCustomer: 0,
      openExportModal: false,
      isLoadingOverallSale: true,
      isLoadingTotalSale: true,
      isLoadingSaleDetail: true,
      isLoadingStoreDetail: true,
      isLoadingRegionSale: true,
      permissionStatus: null
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {storeId,regionMultiId,groupId,regionNames,regionSelectAll} = receivedData
      this.setState({regionId: regionMultiId, storeId, groupId:groupId,
        regionName: handleDisplayRegion(regionMultiId,regionNames,regionSelectAll).textDisplayed
       }, () => {
        if (regionMultiId) this.fetchData()
      })
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ) || {};
      switch (apiRequestCallId) {
        case this.getOverallSalesCallId:
          this.setState({overallSales: {...initialOverallSales, ...responseJson}, isLoadingOverallSale: false})
          break;
        case this.getTotalSalesCallId: 
          this.setState({totalSales: {
            storeSales: responseJson.store_sale || "0.00",
            mobileSales: "0.00"
          },
          isLoadingTotalSale: false
        })
          break;
        case this.getSalesDetailCallId:
          const {today_sales_amount = "0.00", yesterday_sales_amount = "0.00"} = responseJson
          this.setState({
            salesDetail: {
              today_sales_amount,
              yesterday_sales_amount
            },
            isLoadingSaleDetail: false
          })
          break;
        case this.getRegionSalesCallId:
          this.setState({regionSales: responseJson?.data || [], isLoadingRegionSale: false})
          break;
        case this.getStoreDetailsCallId: {
          this.setState({storeDetails: {
            ...initialStoreDetails,
            ...responseJson
          }})
          break;
        }
        case this.getSectionSalesCallId:
          const newSectionSales = responseJson.data || []
          this.setState({
            sectionSales: newSectionSales,
          })
          break;
        case this.getRegionSalesDetailsCallId:
          this.setState({
            regionSalesDetails: responseJson.data || [],
            loadingModal: false
          })
          break;
        case this.getNewStoreDetailsCallId:
          this.setState({
            newStoreDetails: responseJson.data || [],
            loadingModal: false
          })
          break;
        case this.getTotalCustomerCountCallId:
          this.setState({totalCustomer: this.getCustomerCount(responseJson)})
          break;
        case this.getTopCustomerCountCallId:
          this.setState({topCustomer: this.getCustomerCount(responseJson)})
          break;
        case this.getNewCustomerCountCallId:
          this.setState({newCustomer: this.getCustomerCount(responseJson)})
          break;
        case this.getRepeatedCustomerCountCallId:
          this.setState({repeatedCustomer: this.getCustomerCount(responseJson)})
          break;
        case this.getInactiveCustomerCountCallId:
          this.setState({inactiveCustomer: this.getCustomerCount(responseJson)})
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  selectedDateValue = () => {
    const {filters, selectedDate, overallSales} = this.state
    const {value} = filters[0]
    if (!value) return selectedDate
    return (overallSales.start_date === overallSales.end_date) ? overallSales.start_date : null
  }

  handleChangeSelectedDate = (value: Moment | null) => {
    this.setState({
      selectedDate: value ? moment(value).format("YYYY-MM-DD") : null,
      filters: [{...initialFilter, value: ""}]
    }, () => this.fetchData())
  }

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterAnchor: event.currentTarget,
    });
  };

  handleCloseFilterButton = () => {
    this.setState({
      filterAnchor: undefined,
    });
  };

  handleFilterChange = (filters: IFilter[]) =>  {
    const  newFilters = filters.map(item => {
      if (!item.value  || (item.value === "specific" && (!item.datevalue?.from || !item.datevalue.to))) return ({
        ...item,
        value: "today",
        datevalue: {
          from: "",
          to: ""
        }
      })
      return item
    })
    this.setState({filters: newFilters}, () => this.fetchData())
  };
  

  handleStoreChange = (storeId: string, storeName: string, isAllSelected: boolean,groupId:string,groupName:string) => {

    this.setState({
      storeId, 
      groupId,
      groupName:groupName,
      storeName: isAllSelected ? "All Stores" : storeName
    })
  };

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }

  getFilterQueryString = () => {
    const {storeId, regionId, filters, selectedDate} = this.state
    const {datevalue, value} = filters[0] || {}

    const regionParam = regionId ? `?region_ids=${regionId}` : ""
    const storeParam = storeId  ? `&store_ids=${storeId}` : ""

    let dateTypeParam = `&date_type=${(value === "specific" || !value) ? "specific_dates" : value}`

    let startDateParam = datevalue?.from ? `&start_date=${moment(datevalue.from).format("YYYY-MM-DD")}` : ""
    let endDateParam = datevalue?.to ? `&end_date=${moment(datevalue.to).format("YYYY-MM-DD")}` : ""

    if (!value && selectedDate) {
      const specificDate = moment(selectedDate).format("YYYY-MM-DD")
      startDateParam = `&start_date=${specificDate}`
      endDateParam = `&end_date=${specificDate}`
    }
    return regionParam + storeParam + dateTypeParam + startDateParam + endDateParam
  }

  getBarChartYAxisValues = () => {
    const {regionSales} = this.state
    const maxValue = Math.max(
        ...regionSales.map(
           item => Number(item.total)
        )
    )
    if (!maxValue) return [4, 3, 2, 1].map(element => ({
      raw: element,
      formatted: element.toString(),
    }))
    return getYAxisValues(5, maxValue)
  }

  regionSalesChartData = () => (
    this.state.openExportModal ? 
    this.state.regionSales.slice(0, 5) 
    : 
    this.state.regionSales
  )
    .map(item => ({ label: item.region_name, value: Number(item.total) }))

  toggleModal = (modal: "new_stores" | "region_sales" | "") => {
    if (modal) {
      const apiCall = {
        new_stores: {
          apiKey: "new_store_details",
          apiCallIdField: "getNewStoreDetailsCallId"
        },
        region_sales: {
          apiKey: "region_sales_details",
          apiCallIdField: "getRegionSalesDetailsCallId"
        }
      }[modal] as {apiKey: string; apiCallIdField: "getNewStoreDetailsCallId" | "getRegionSalesDetailsCallId"}
      const apiMessage = makeApiMessage({
        url: configJSON.mainDashboardApiEndPoint + apiCall.apiKey + this.getFilterQueryString(),
        method: configJSON.dashboarApiMethodType
      })
      this[apiCall.apiCallIdField] = apiMessage.messageId
      this.send(apiMessage)
    }
    this.setState({showModal: modal, loadingModal: true})
  }  

  fetchData = () => {
    const queryString = this.getFilterQueryString()
    const apiCalls = [
      {
        apiKey: "overall_sales",
        apiCallIdField: "getOverallSalesCallId"
      },
      {
        apiKey: "total_sales",
        apiCallIdField: "getTotalSalesCallId"
      },
      {
        apiKey: "region_sales",
        apiCallIdField: "getRegionSalesCallId"
      },
      {
        apiKey: "sales_details",
        apiCallIdField: "getSalesDetailCallId"
      },
      {
        apiKey: "store_details",
        apiCallIdField: "getStoreDetailsCallId"
      },
      {
        apiKey: "section_sales",
        apiCallIdField: "getSectionSalesCallId"
      },
    ] as const
    apiCalls.forEach((apiCall) => {
      const requestMessage = makeApiMessage({
        url: configJSON.mainDashboardApiEndPoint + apiCall.apiKey + queryString,
        method: configJSON.dashboarApiMethodType
      })
      this[apiCall.apiCallIdField] = requestMessage.messageId
      this.send(requestMessage)
    })

    const customerApiCalls = [
      {
        flag: "total",
        apiCallIdField: "getTotalCustomerCountCallId"
      },
      {
        flag: "top",
        apiCallIdField: "getTopCustomerCountCallId"
      },
      {
        flag: "new",
        apiCallIdField: "getNewCustomerCountCallId"
      },
      {
        flag: "inactive",
        apiCallIdField: "getInactiveCustomerCountCallId"
      },
      {
        flag: "repeated",
        apiCallIdField: "getRepeatedCustomerCountCallId"
      },
    ] as const

    customerApiCalls.forEach((apiCall) => {
      const requestMessage = makeApiMessage({
        url: configJSON.customerCountEndpoint + queryString + `&flag=${apiCall.flag}`,
        method: configJSON.dashboarApiMethodType
      })
      this[apiCall.apiCallIdField] = requestMessage.messageId
      this.send(requestMessage)
    })
    this.setState({
      isLoadingOverallSale: true,
      isLoadingSaleDetail: true,
      isLoadingStoreDetail: true,
      isLoadingTotalSale: true,
      isLoadingRegionSale: true
    })
  }

  dateRange = () => {
    const {start_date, end_date} = this.state.overallSales
    const startDate = moment(start_date).format("DD-MM-YYYY")
    const endDate = moment(end_date).format("DD-MM-YYYY")
    if (!start_date && !end_date) return ""
    if (startDate === endDate) return startDate
    return `${startDate} to ${endDate}`
  }

  getCustomerCount = (responseJson?: {data?: {total: number}} ) => responseJson?.data?.total || 0

  navigateToScreen = (screenName: string) => navigateTo({props: this.props, screenName})

  handleOpenExportModal = () => {
    this.setState({openExportModal: !this.state.openExportModal})
  }

  handleExportConfirm = async (method: string) => {
    if (method === "pdf") {
      const wrapperElement = document.getElementById("print-wrapper") as HTMLElement
     await html2pdf(wrapperElement, {
        filename: "main-dashboard.pdf",
        jsPDF: {
          unit: "px",
          format: [1440 , 1440]
        },
        margin: 24,
        pagebreak: { mode: ['css'] },
      })
      this.setState({openExportModal: false})
    }
  }

  isPrintLayout = () => this.state.openExportModal && this.state.sectionSales.length > 7
  // Customizable Area End
}