import React from "react";
// Customizable Area Start
import { Box, Typography, Grid,styled } from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CustomTypography from "../../../components/src/customComponent/CustomTypography.web";

import ViewTaxController, { Props, configData } from "./ViewTaxController.web";

import PageContainer from "../../navigationmenu/src/PageContainer.web"
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { style } from "./TaxListStyle";

import CustomButton from "../../../components/src/customComponent/customButton.web";
import { StyledMainBox } from "./TaxList.web";
import { colors } from "../../utilities/src/Colors";

const theme = createTheme({});
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";

// Customizable Area End

class ViewTax extends ViewTaxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const classes = this.props.classes;

    return (
      <ThemeProvider theme={theme}>
        <PageContainer 
        navigation={this.props.navigation}
        onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
        <StyledMainBox>
          <Box>
            <CustomLoader loading={this.state.loading} />

            <Box className="titleContainer">
              <Typography className="titleContainerText">
                {configData.taxInformation}
              </Typography>
            {
                sortCondition(
                  this.state.permissionStatus.editPermission,
                  <EditOrganizationBtn
                      data-test-id='Edit'
                      onClick={() =>
                        this.handleEditNavigate()
                      }
                    >
                    {configData.edit}
                </EditOrganizationBtn>,
                  <></>
                )
              }
            </Box>

            <Box className={classes.addEditCard}>
              <Box className={classes.editInputBox}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box flexBasis={"42%"}>
                      <HeadingText>{configData.taxName}</HeadingText>
                      <ValueText>{this.state.viewTax.taxName}</ValueText>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box flexBasis={"42%"}>
                      <HeadingText>{configData.taxPercentage}</HeadingText>
                      <ValueText>{`${Number(this.state.viewTax.taxRate).toFixed(2) || 0}%`}</ValueText>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
        </Box>

            <Box className={classes.marginTop48}>
               <BackOrganizationBtn
                  data-test-id= {configData.back}
                  onClick={() => this.handleBack()}
                  color='secondary'
                >
                {configData.back}
              </BackOrganizationBtn>
            </Box>
          </Box>
        </StyledMainBox>
        </PageContainer>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(style)(ViewTax);

const HeadingText = styled("div")({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: "22px",
  color: "var(--dark-grey, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});


export const ValueText = styled("div")({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color:colors().viewTextColor,
  marginTop: "12px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    fontWeight: 500,
    color:colors().viewTextColor
  },
});

const EditOrganizationBtn = styled("button")({
  textTransform: "capitalize",
  padding: "16px 20px",
  borderRadius: "8px",
  fontWeight: 700,
  fontSize: "16px",
  "&.secondary": {
    color: colors().slateGray,
    background: colors().antiFlashWhite,
  },
  lineHeight: "24px",
  background: colors().cyancobaltblue,
  color: "rgb(255, 255, 255)",
  width:"184px",
  border: "none",
  cursor: "pointer",
  outline: "none",
  height:"56px",
  "@media only screen and (max-width: 1024px)": {
    padding: "10px 20px",
    width: "128px",
    fontSize: "13px",
    height: "44px",
  },
});

const BackOrganizationBtn = styled("button")({
  textTransform: "capitalize",
  fontSize: "16px",
  padding: "16px 20px",
  borderRadius: "8px",
  background: colors().cyancobaltblue,
  lineHeight: "24px",
  fontWeight: 700,
  color: "rgb(255, 255, 255)",
  outline: "none",
  border: "none",
  "&.secondary": {
    color: colors().slateGray,
    background: colors().antiFlashWhite
  },
  width:"128px",
  height:"56px",
  "@media only screen and (max-width: 1024px)": {
    width: "128px",
    fontSize: "13px",
    padding: "10px 20px",
    height: "44px",
  },
  cursor: "pointer",
});
// Customizable Area End
