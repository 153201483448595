import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import React from "react";
import {
  PaymentsIcon,
  LinkIcon,
  OldPhoneIcon,
  TodayIcon,
  WalletIcon,
  StoreIcon
} from "../../../components/src/customComponents/PaymentModalAssets";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { IOrder } from "../../OrderCreation/src/OrderCreationController.web";
import { makeApiMessage } from "../../../components/src/common";

export const configJSON = require("./config");

export interface IPaymentMethod {
  id: number;
  name: string;
  active: boolean;
  enableSecond?: boolean;
  attributes: {
    icon: { 
      data: { 
        attributes: { 
          image: string
        } 
      } 
    } 
  }
}

export interface ISidebarItems {
  key: number;
  title: string;
  apiKey?: string;
  secondKey: number;
  icon: React.ReactNode;
  enableSecond?: boolean;
}

export const initialSideBarItems: Array<ISidebarItems> = [
  {
    key: 2,
    secondKey: 1,
    title: "Cash",
    apiKey: "cash",
    enableSecond: true,
    icon: <PaymentsIcon />,
  },
  {
    title: "POS Machine",
    icon: <OldPhoneIcon />,
    key: 3,
    secondKey: 3,
  },
  {
    title: "Payment Link",
    icon: <LinkIcon />,
    key: 5,
    secondKey: 4,
  },
  {
    title: "Pay Later",
    icon: <TodayIcon />,
    key: 6,
    secondKey: 5,
    enableSecond: true,
  },
  {
    title: "Store Pay",
    icon: <StoreIcon />,
    key: 7,
    secondKey: 6,
  },
  {
    key: 4,
    secondKey: 2,
    title: "Wallets",
    apiKey: "wallets",
    enableSecond: true,
    icon: <WalletIcon />,
  },
];

// Customizable Area End

export interface Props {
  // Customizable Area Start
  open: boolean;
  handleClose: () => void;
  totalItems: number | undefined;
  taxPercentage: number | undefined | string | null;
  customerId: string;
  order?: IOrder;
  cleaningOrder?: boolean;
  onPromoCodeApply: () => void;
  onPaymentDone: () => void;
  paymentClicked: boolean;
  fromUnpaid?: boolean;
  isMainPayment?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: number | string;
  totalAmount: string;
  points: number;
  skipRedeemPoints: boolean;
  isRedeemAllPoints: boolean;
  isUsed: boolean;
  currency: string;
  successMessage: string;
  errorMessage: string;
  errorSnackbarOpen: boolean;
  showOtpScreen: boolean;
  otpToken: string;
  otpValue: string;
  paymentMethods: IPaymentMethod[];
  walletBalance: number;
  secondPaymentEnabled: boolean;
  customPaymentAmount: string;
  remainBalance: number;
  selectedSecondPaymentMethod: string;
  loading: boolean;
  isRemainingBalanceLoading: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PaymentModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    // Customizable Area Start

    // Customizable Area End
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start

      activeTab: 0,
      totalAmount: "",
      currency: "",
      points: 0,
      isRedeemAllPoints: false,
      isUsed: false,
      errorMessage: "",
      errorSnackbarOpen: false,
      showOtpScreen: false,
      otpToken: "",
      otpValue: "",
      paymentMethods: [],
      secondPaymentEnabled: false,
      selectedSecondPaymentMethod: "",
      walletBalance: 0,
      customPaymentAmount: "",
      skipRedeemPoints: false,
      remainBalance: 0,
      successMessage: "",
      loading: false,
      isRemainingBalanceLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  getRewardsCallId: string = "";
  getWalletBalanceCallId: string = "";
  getRemainBalanceCallId: string = "";
  getPaymentMethodsCallId: string = "";
  redeemAllPointsCallId: string = "";
  makePaymentCallId: string = "";
  sendOtpCallId: string = "";
  headerContentType = { "Content-Type": "application/json" };

  async getHeaders() {
    const token = await getStorageData("token");
    return {
      "Content-Type": "application/json",
      token,
    };
  }

  async getRewards() {
    const { customerId } = this.props;
    const headers = await this.getHeaders();

    const message = makeApiMessage({
      method: "GET",
      headers: JSON.stringify(headers),
      url: configJSON.getTotalRewardPointsEndpoint + customerId,
    });

    this.getRewardsCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  async getWalletBalance() {
    const { customerId } = this.props;

    const headers = await this.getHeaders();
    const message = makeApiMessage({
      url: configJSON.getWalletBalanceEndpoint + customerId,
      method: "GET",
      headers: JSON.stringify(headers),
    });

    this.getWalletBalanceCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  async getRemainBalance() {
    if (!this.props.order) return;
    this.setState({ isRemainingBalanceLoading: true });
    const { order, cleaningOrder } = this.props;

    const headers = await this.getHeaders();
    const isEditKey = this.props.fromUnpaid ? "&edit_order=true" : "";
    const cleaningOrderKeys = cleaningOrder ? "&unpaid_amount=true" : "";

    const endpoint = cleaningOrder ? configJSON.getRemainingBalanceEndPointForCleaningOrder : configJSON.getRemainingBalanceEndPoint;

    const message = makeApiMessage({
      url: endpoint + order?.id + isEditKey + cleaningOrderKeys,
      headers: headers,
      method: "GET",
    });

    this.getRemainBalanceCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  async sendOtp() {
    const { customerId } = this.props;
    const { activeTab, remainBalance, customPaymentAmount } = this.state;
    const walletId = this.getWalletPaymentId();

    const secondPaymentEnabled = activeTab !== walletId;
    const amount = secondPaymentEnabled
      ? Number(remainBalance) - Number(customPaymentAmount)
      : Number(customPaymentAmount);

    const apiUrl =
      configJSON.sendOtpForWallets +
      customerId +
      "&total_amount=" +
      String(amount);

    const headers = await this.getHeaders();
    const message = makeApiMessage({
      headers: JSON.stringify(headers),
      method: "GET",
      url: apiUrl,
    });

    this.sendOtpCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  async getPaymentMethods() {
    const headers = await this.getHeaders();

    const message = makeApiMessage({
      url: configJSON.paymentMethodsEndpoint,
      headers: JSON.stringify(headers),
      method: "GET",
    });

    this.getPaymentMethodsCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  async redeemAllPoints() {
    const { customerId } = this.props;
    const { points, remainBalance } = this.state;

    const headers = await this.getHeaders();
    const message = makeApiMessage({
      method: "PUT",
      url: configJSON.getRedeemPointsEndpoint + customerId,
      headers: JSON.stringify(headers),
      body: JSON.stringify({
        points: points,
        order_total: remainBalance,
      }),
    });

    this.redeemAllPointsCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  }

  async makePayment() {
    if (!this.props?.order) return;

    const { order, fromUnpaid, customerId, cleaningOrder } = this.props || {};
    const {
      activeTab,
      remainBalance,
      customPaymentAmount,
      secondPaymentEnabled,
    } = this.state;

    const payLaterId = this.getPaymentMethodId("Pay Later");
    const secondaryPaymentId = this.getSecondaryPaymentMethod()?.id;

    const isPayLater = payLaterId === activeTab;
    const isQuickDrop = Boolean(order?.is_quick_drop);
    const extraFields = fromUnpaid ? { edit_order: true } : {};

    let amount;
    if (!isPayLater) {
      amount = Number(customPaymentAmount);
    } else if (isQuickDrop) {
      amount = 0;
    } else {
      amount = Number(order?.total);
    }

    const paymentBody = [
      {
        amount,
        account_id: customerId,
        payment_method_id: activeTab,
      },
    ];

    secondPaymentEnabled &&
      paymentBody.push({
        account_id: customerId,
        payment_method_id: String(secondaryPaymentId),
        amount: Number((remainBalance - Number(customPaymentAmount)).toFixed(2)),
      });
    
    let transKey = cleaningOrder ? 'home_cleaning_order_transactions_attributes' : 'order_transactions_attributes'

    const headers = await this.getHeaders();
    const jsonBody = JSON.stringify({
      ...extraFields,
      id: this.props.order.id || 0,
      [transKey]: paymentBody.filter(
        (item) => item.amount >= 0
      ),      
    } as {});

    const message = makeApiMessage({
      method: "PUT",
      body: jsonBody,
      headers: headers,
      url: cleaningOrder ? 
        configJSON.addPaymentTransactionHomeCleaningEndPoint 
        : configJSON.addPaymentTransactionEndPoint,
    });

    this.makePaymentCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  }

  getSecondaryPaymentMethod() {
    const { selectedSecondPaymentMethod, paymentMethods } = this.state;
    return paymentMethods.find(
      (item: IPaymentMethod) => item.name === selectedSecondPaymentMethod
    );
  }

  getPrimaryPaymentMethod() {
    const { activeTab, paymentMethods } = this.state;
    return paymentMethods.find(
      (paymentMethod) => paymentMethod.id === activeTab
    );
  }

  getPaymentMethodId(keyName: string) {
    const cashPayment = this.state.paymentMethods.find(
      (paymentMethod) => paymentMethod.name.toLowerCase() === keyName
    );
    return cashPayment ? cashPayment.id : 0;
  }

  getCashPaymentId = () => {
    const cashPayment = this.state.paymentMethods.find(
      (paymentMethod) => paymentMethod.name.toLowerCase() === "cash"
    );
    return cashPayment ? cashPayment.id : 0;
  };

  getPayLaterPaymentId = () => {
    const payment = this.state.paymentMethods.find(
      (paymentMethod) => paymentMethod.name.toLowerCase() === "pay later"
    );
    return payment ? payment.id : 0;
  };

  getWalletPaymentId = () => {
    const cashPayment = this.state.paymentMethods.find(
      (paymentMethod) => paymentMethod.name.toLowerCase() === "wallets"
    );
    return cashPayment ? cashPayment.id : 0;
  };

  async componentDidMount() {
    this.handleGetPaymentMethods();
    this.getRemainBalance();
  }

  handleGetPaymentMethods = () => {
    let data = localStorage.getItem("payment_method_lists");

    if (data) {
      this.setState({ paymentMethods: JSON.parse(data) }, () =>
        this.handleAfterPaymentMethod()
      );
    } else {
      this.getPaymentMethods();
    };
  };

  handlePayAll = () => {
    if (this.state.activeTab === this.getWalletPaymentId()) {
      if (this.state.walletBalance < this.state.remainBalance) {
        this.setState({
          errorMessage: configJSON.label_notSufficientWalletBalance,
          errorSnackbarOpen: true,
        });
        return;
      }
    }
    this.setState({
      customPaymentAmount: this.state.remainBalance + "",
    });
  };

  checkForBalance = (prevProps: Props) => {
    if (
      this.props.order !== prevProps.order &&
      this.props.order &&
      this.props.order.id &&
      this.props.open
    ) {
      this.getRemainBalance();
    }
  };

  checkForTabChange = (prevProps: Props, prevState: S) => {
    const { activeTab } = this.state;
    if (activeTab !== prevState.activeTab) {
      this.setState({ showOtpScreen: false });
      (activeTab === this.getCashPaymentId()) &&
        this.setState({
          customPaymentAmount: this.state.remainBalance + "",
        });
    }
  };

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      this.props.order &&
      this.props.order.status === "placed" &&
      !this.props.fromUnpaid
    )
      return;
    if (
      prevProps.customerId !== this.props.customerId &&
      Number(this.props.customerId) &&
      this.props.open
    ) {
      this.getRewards();
      this.setState({
        isRedeemAllPoints: false,
        isUsed: false,
      });
    }
    if (
      this.state.remainBalance !== prevState.remainBalance ||
      this.state.walletBalance !== prevState.walletBalance ||
      this.state.customPaymentAmount !== prevState.customPaymentAmount
    ) {
      this.setState({
        secondPaymentEnabled:
          Number(this.state.customPaymentAmount) < this.state.remainBalance,
      });
    }

    if (
      Number(this.state.customPaymentAmount) > this.state.walletBalance &&
      this.state.activeTab === this.getWalletPaymentId()
    ) {
      this.setState({
        customPaymentAmount: this.state.walletBalance + "",
      });
    }

    if (
      this.props.order &&
      this.props.open !== prevProps.open &&
      this.props.open
    ) {
      this.getRemainBalance();
      this.setState({
        activeTab: this.props.order.is_quick_drop
          ? this.getPayLaterPaymentId()
          : this.getCashPaymentId(),
        showOtpScreen: false,
        skipRedeemPoints: false,
      });
    }

    this.checkForTabChange(prevProps, prevState);
    this.checkForBalance(prevProps);
  }

  useAmount = () => {
    this.setState({
      isUsed: true,
    });
  };

  onOtpSuccess = () => {
    this.makePayment();
  };

  handlePay() {
    const {
      points,
      skipRedeemPoints,
      secondPaymentEnabled,
      selectedSecondPaymentMethod,
    } = this.state;

    const { name } = (this.getPrimaryPaymentMethod() as IPaymentMethod) || {};
    const { name: secondName } =
      (this.getSecondaryPaymentMethod() as IPaymentMethod) || {};

    if (name === "Wallets") {
      const condition = points > 0 && !skipRedeemPoints;
      if (condition) {
        this.setState({ skipRedeemPoints: true });
      }
    }

    if (secondPaymentEnabled && !selectedSecondPaymentMethod) {
      this.setState({
        errorMessage: "Please select second payment method",
        errorSnackbarOpen: true,
      });
      return;
    }

    this.setState({loading : true});

    if (
      [name, secondName].includes("Wallets")
    ) {
      this.sendOtp();
    } else {
      this.makePayment();
    }
  }

  handleSnackbarClose = () => {
    this.setState({
      errorSnackbarOpen: false,
    });
  };

  handleSuccessSnackbarClose = () => {
    this.setState({
      successMessage: "",
    });
  };

  handleOtpError = (message: string) => {
    this.setState({
      errorMessage: message,
      errorSnackbarOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      showOtpScreen: false,
      selectedSecondPaymentMethod: ""
    });
    this.props.handleClose();
  };

  receiveOtpMessage = (responseJson: { message: string; errors: string }) => {
    if (responseJson.message) {
      this.setState({
        showOtpScreen: true,
      });
    } else if (responseJson.errors) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: responseJson.errors,
      });
    }
  };

  receivePaymentMethods = (responseJson: { data: IPaymentMethod[] }) => {

    this.setState({
      paymentMethods: responseJson.data,
    }, () => this.handleAfterPaymentMethod());
  };

  handleAfterPaymentMethod = () => {
    if (this.props.order) {
      this.setState({
        activeTab: this.props.order.is_quick_drop
          ? this.getPayLaterPaymentId()
          : this.getCashPaymentId(),
      });   
    }
  }

  receiveRewardCall = (responseJson: {
    total_amount: string;
    points: number;
    currency: string;
  }) => {
    if (responseJson) {
      this.setState({
        totalAmount: responseJson.total_amount || "0",
        points: responseJson.points || 0,
        currency: responseJson.currency || "SAR",
      });
    }
  };

  receiveRedeemAllPoints = (responseJson: {
    errors: string;
    wallet_balance: number;
  }) => {
    if (responseJson.errors) {
      this.setState({
        errorMessage: responseJson.errors,
        errorSnackbarOpen: true,
      });
    } else {
      this.setState(
        {
          isRedeemAllPoints: true,
          walletBalance: Number(responseJson.wallet_balance),
        },
        this.getRemainBalance
      );
    }
  };

  receiveRemainBalance = (responseJson: {
    payable_balance: number | string;
  }) => {
    this.setState({
      remainBalance: responseJson.payable_balance as number,
      customPaymentAmount: responseJson.payable_balance + "",
    });
    if (
      responseJson.payable_balance === 0 &&
      this.props.order &&
      (this.state.activeTab === this.getPayLaterPaymentId()
        ? this.props.order.status === "placed"
        : Number(this.props.order.total))
    )
      this.props.onPaymentDone();
  };

  receiveWalletBalance = (responseJson: { balance: string | number }) => {
    const previousTransaction =
      this.props.order &&
      this.props.order.order_transactions.find(
        (item) =>
          item.attributes.payment_method.id === this.getWalletPaymentId()
      );

    this.setState({
      walletBalance:
        Number(responseJson.balance) -
        (previousTransaction
          ? Number(previousTransaction.attributes.amount)
          : 0),
    });
  };

  receiveMakePayment = (responseJson: {
    status: number;
    errors: string;
    order_status: string;
    message: string;
  }) => {
    if (responseJson.order_status === "placed") {
      this.props.onPaymentDone();
    }

    if (responseJson?.status == 422) {
      this.setState({ errorSnackbarOpen: true, errorMessage: responseJson?.errors })
    }
    else {
      this.setState({
        successMessage: responseJson.message,
      });
    }

    setTimeout(() => {
      this.setState({
        selectedSecondPaymentMethod: ""
      })
    }, 1000);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getRewardsCallId) {
        this.receiveRewardCall(responseJson);
      } else if (apiRequestCallId === this.redeemAllPointsCallId) {
        this.receiveRedeemAllPoints(responseJson);
      } else if (apiRequestCallId === this.sendOtpCallId) {
        this.receiveOtpMessage(responseJson);
      } else if (apiRequestCallId === this.getPaymentMethodsCallId) {
        this.receivePaymentMethods(responseJson);
      } else if (apiRequestCallId === this.getRemainBalanceCallId) {
        this.receiveRemainBalance(responseJson);
      } else if (apiRequestCallId === this.getWalletBalanceCallId) {
        this.receiveWalletBalance(responseJson);
      } else if (apiRequestCallId === this.makePaymentCallId) {
        this.receiveMakePayment(responseJson);
      }

      this.setState({loading: false, isRemainingBalanceLoading: false})
    }
  }

  onChangeTab(keyID: string | number) {
    this.setState({ activeTab: keyID });
  }

  onSelectSecondPayments(keyID: string) {
    this.setState({ selectedSecondPaymentMethod: keyID });
  }

  onChangeAmountToPay(
    activeTab: number | string,
    originalValue: number,
    walletBalance: number,
    remainBalance: number
  ) {
    let value = "" + originalValue;
    if (
      Number(originalValue) > walletBalance &&
      activeTab === this.getWalletPaymentId()
    ) {
      value = walletBalance + "";
    }
    if (Number(originalValue) > remainBalance) {
      value = remainBalance + "";
    }
    this.setState({ customPaymentAmount: value });
  }

  onKeyPressInput(keyID: string, preventDefault: Function, prevented: boolean) {
    if (!((keyID >= "0" && keyID <= "9") || keyID === "." || keyID === "Backspace")) {
      !prevented && preventDefault && preventDefault();
    }
  }

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
