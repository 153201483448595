import React from "react";
// Customizable Area Start
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  TableContainer,
  styled,
  Typography,
  Button,
  Tooltip
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
const theme = createTheme({});
import { colors } from "../../../blocks/utilities/src/Colors";
import FilterIcon from '@material-ui/icons/FilterListRounded';
import FilterPopover from "../../../components/src/FilterPopover";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { printIconPng } from "./assets";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import { SingleHorizontalBarChart } from "../../../components/src/CustomCharts";
import CustomColumnChart from "../../../components/src/customComponents/CustomColumnChart.web";
import { getGroupNameDisplay, removeSarUnit  } from "./utils";
import ExportModal from "../../../components/src//ExportModal";
import { storeNameBasedOnGroup } from "../../../blocks/dashboard/src/utils";

const barItems = [
  {
    label: "Clothes",
    dataKey: "clothes",
    color: colors().cyancobaltblue
  },
  {
    label: "Carpet",
    dataKey: "carpet",
  }
]
// Customizable Area End

// Customizable Area Start
import SalesDashboardController, {
  Props,
  configJSON,
  clothesColumn, totalColumns, dateColumns, carpetColumn
} from "./SalesDashboardController";
import PrintSummaryDialog from "../../../components/src/customComponents/PrintSummaryDialog.web";
// Customizable Area End
export default class SaleDashboard extends SalesDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <PageContainer
        navigation={this.props.navigation}
        onMultiRegionChange={()=>true}
        onStoreAndGroupChange={this.handleStoreChange}
        onUserChange={this.handleUserChange}
      >
        <DashboardTabPanel navigation={this.props.navigation} id="SalesDashboard" permissionStatus={this.state.permissionStatus} />
        <ThemeProvider theme={theme}>
          <SaleDashboardWrapper id={this.state.openExportModal ? "print-wrapper" : undefined}>
            <Box className="headingContainer">
              <Box className="leftHeading">
                <Box className="rowHeading">
                  <Box className="fieldBox">
                    <Typography className="label">{configJSON.region}</Typography>
                    <Typography className="value">{this.state.regionName}</Typography>
                  </Box>
                  <Box className="fieldBox">
                    <Typography className="label">{configJSON.store}</Typography>
                    <Typography className="value">{storeNameBasedOnGroup(this.state.storeId)}</Typography>
                  </Box>
                  <Box className="fieldBox">
                    <Typography className="label">{configJSON.groups}</Typography>
                    <Typography className="value">{getGroupNameDisplay(this.state.groupId)}</Typography>
                  </Box>
                  <div></div>
                </Box>
                <Box className="rowHeading">
                  <Box className="fieldBox">
                    <Typography className="label">{configJSON.period}</Typography>
                    <Typography className="value">{this.handleDateFilterParam().startDateText} _ {this.handleDateFilterParam().endDateText}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="actionBtnContainer">
                <div data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                  <IconButton className="filterBtn">
                    <FilterIcon />
                  </IconButton>
                </div>
                <button
                  onClick={this.togglePrintSummaryDialog}
                  data-test-id='print-summary'
                  className='exportAll'
                >
                  <div className="print">
                    <img
                      src={printIconPng}
                      alt={"print"}
                      className="printIcon"
                      data-test-id='btn-print-top'
                      width={25}
                    />
                    <div>
                      {configJSON.printSummary}
                    </div>
                  </div>
                </button>

                <button
                  onClick={this.handleOpenExportModal}
                  data-test-id='export-all'
                  className='exportAll'
                >
                  {configJSON.exportAll}
                </button>
              </Box>
              <FilterPopover
                data-test-id="filterPopover"
                onClose={() => { this.handleCloseFilterPopover() }}
                anchor={this.state.filterAnchor}
                onFilterChange={this.handleFilterChangeAccount}
                filters={this.state.filters}
              />
            </Box>

            <Box className="chartWrapper">
              <Box className="horizontalChart">
                <SingleHorizontalBarChart height={220} data={this.state.clothesData} title="Clothes" color="#A666FF" isLoading={this.state.isLoadingClothes}/>
                <SingleHorizontalBarChart height={220} data={this.state.carpetData} title="Carpets" color="#F0714D" isLoading={this.state.isLoadingCarpet}/>
              </Box>
              <Box className="verticalChart">
                <CustomColumnChart
                  title="Total"
                  subTitle="Clothes + Carpet"
                  description={configJSON.totalChartDesc}
                  chartData={this.state.totalData}
                  barItems={barItems}
                  wrapperClassName="revenue-bar-chart"
                  isLoading={this.state.isLoadingTotal}
                />
              </Box>
            </Box>

            <StyledTable>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <p>Dates</p>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <p>Clothes</p>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <p>Carpet</p>
                    </TableCell>
                    <TableCell colSpan={7}>
                      <p>Total (Clothes + Carpet)</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      dateColumns.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                            {column.header}
                          </p>
                        </TableCell>
                      ))
                    }
                    {
                      clothesColumn.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                            {column.header}{column.unit}
                          </p>
                        </TableCell>
                      ))
                    }
                    {
                      carpetColumn.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                            {column.header}{column.unit}
                          </p>
                        </TableCell>
                      ))
                    }
                    {
                      totalColumns.map(column => (
                        <TableCell key={column.binding}>
                          <p className={"sub-header"}>
                            {column.header}{column.unit}
                          </p>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  {
                    this.state.saleOrdersTable.map((order, index) => (
                      <TableRow key={index}>
                        {
                          dateColumns.map(column => (
                            <TableCell key={column.binding}>
                              <p className={"dark-color dates"}>
                                {order.dates}
                              </p>
                            </TableCell>
                          ))
                        }
                        {
                          clothesColumn.map(column => (
                            <TableCell key={column.binding}>
                              <Tooltip title={order.clothes[column.binding]} arrow>
                                <p className={"dark-color"}>
                                  {removeSarUnit(order.clothes[column.binding])}
                                </p>
                              </Tooltip>
                            </TableCell>
                          ))
                        }
                        {
                          carpetColumn.map(column => (
                            <TableCell key={column.binding}>
                              <Tooltip title={order.carpet[column.binding]} arrow>
                                <p className={"dark-color"}>
                                  {removeSarUnit(order.carpet[column.binding])}
                                </p>
                              </Tooltip>
                            </TableCell>
                          ))
                        }
                        {
                          totalColumns.map(column => (
                            <TableCell key={column.binding}>
                              <Tooltip title={order.total[column.binding]} arrow>
                                <p className={"dark-color"}>
                                  {removeSarUnit(order.total[column.binding])}
                                </p>
                              </Tooltip>
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </StyledTable>

          </SaleDashboardWrapper>
          {
          this.state.openExportModal && <ExportModal isOpen onCancel={this.handleOpenExportModal} onConfirm={this.handleExportConfirm} />
        }
          {
            this.state.openPrintSummary && (
              <PrintSummaryDialog
                open
                loading={this.state.isLoadingSummaryReceipt}
                onCancel={this.togglePrintSummaryDialog}
                onChangeDate={this.getSummaryReceipt}
                receiptUrl={this.state.receiptUrl}
                isStoreSelected={Boolean(this.state.storeId)}
              />
            )
          }
        </ThemeProvider>
      </PageContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const SaleDashboardWrapper = styled("div")({
  fontFamily: "Montserrat",
  "& .headingContainer": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .leftHeading": {
    display: "flex",
    flexDirection: "column",
    marginBottom: '1rem',
    gap: "1rem",
  },
  "& .rowHeading": {
    display: "flex",
    gap: "1rem"
  },
  "& .fieldBox": {
    display: "flex",
    gap: "0.5rem",
    fontSize: "1rem",
    lineHeight: "24px",
    "& .value": {
      fontWeight: 400,
      fontFamily: "Montserrat",
      color: colors().darkjunglegreen
    },
    "& .label": {
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
  },
  "& .actionBtnContainer": {
    display: "flex",
    gap: "1rem"
  },
  "& .filterBtn": {
    height: "44px",
    width: "44px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    border: "1px solid #ECECEC",
    "& .MuiSvgIcon-root": {
      color: colors().darkliver
    }
  },
  "& .exportAll": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    padding: "10px 16px",
    lineHeight: 1.5,
    border: 0,
    borderRadius: 8,
    color: colors().darkliver,
    fontWeight: 600,
    background: colors().lightgray,
    fontSize: "16px",
    cursor: "pointer",
  },
  "& .print": {
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .chartWrapper": {
    display: "flex",
    width: "100%",
    gap: "1rem",
    margin:"1rem 0",
    "@media only screen and (max-width: 1400px)": {
      flexDirection: "column"
    }
  },
  "& .horizontalChart": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "1rem",
    minWidth: "55%",
    "@media only screen and (max-width: 1440px)": {
      minWidth: "550px",
    },
    "& .headerTitle": {
      padding: "11px 0"
    }
  },
  "& .verticalChart": {
    flex: 1,
    maxWidth: "100%"
  },
  "& .sub-title": {
    fontSize: "14px !important",
    marginLeft: "0.5rem"
  },

  "&#print-wrapper": {
    gap: 30,
   "& .actionBtnContainer": {
    display:"none"
   },
   "& .sub-header, .dark-color":{
    fontSize:"11px"
   }
  },
  "& .MuiTableContainer-root": {
    maxHeight:"100%",
  }
});

const StyledTable = styled(TableContainer)({
  maxHeight: 440,
  borderRadius: 12,
  backgroundColor: colors().brightgray,
  padding: "12px 0",
  margin: "1rem 0 4rem 0",
  maxWidth:"100%",
  overflowX:"auto",
  "& .MuiTableCell-root": {
    borderBottom: 0,
    padding: 10,
    color: colors().darkliver,
    fontSize: 14,
    lineHeight: 1.5,
    "&:nth-child(1)": {
      borderRight: `solid 1px ${colors().dividerColor}`,
    },
    "&:nth-child(4)": {
      borderRight: `solid 1px ${colors().dividerColor}`,
    },
    "&:nth-child(7)": {
      borderRight: `solid 1px ${colors().dividerColor}`,
    },
    "&.MuiTableCell-head": {
      fontSize: 13,
      lineHeight: "15.6px",
      fontWeight: 600,
      paddingTop: 0,
      paddingBottom: 12,
      borderBottom: `solid 1px ${colors().dividerColor}`,
      "&:first-child": {
        color: colors().darkjunglegreen,
        fontWeight: 600
      },
      "&:nth-child(2)": {
        borderRight: `solid 1px ${colors().dividerColor}`,
      },
      "&:nth-child(3)": {
        borderRight: `solid 1px ${colors().dividerColor}`
      },
      "&:nth-child(4)": {
        borderRight: `1px solid transparent`
      },
    },
    "&.MuiTableCell-body": {
      fontWeight: 500,
      paddingBottom: 0,
      "&:first-child": {
        fontWeight: 600
      },
    },
    "& .sub-header": {
      fontWeight: 400,
      fontSize:"16x",
      whiteSpace:"nowrap"
    },
    "& .dark-color": {
      color: colors().darkjunglegreen,
      maxWidth: "15ch",
      overflow: "hidden",
      textOverflow: 'ellipsis',
      whiteSpace: "nowrap",
      fontSize:"16x",
      fontWeight:500
    },
    "& .dates": {
      maxWidth: "10ch",
    }
  },
})

// Customizable Area End