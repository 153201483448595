Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.successPreferenceMessage = "Your preference was created successfully";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorDataResult = 105;

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.accountListApi = "account_block/employees/employee_list";
exports.accountUpdateApi = "account_block/employees/admin_update_employee/";
exports.plantListApi = "bx_block_plants/plants";
exports.plantNameSuggestionListApi =
  "bx_block_plants/plants/plant_filter?plant_name=";
exports.plantRegionSuggestionListApi =
  "bx_block_plants/plants/plant_filter?region_name=";

exports.validationApiMethodType = "GET";

exports.APIBaseURL = urlConfig.baseURL;
exports.employeesApiEndPoint = "account_block/employees";
exports.roleListApiEndPoint = "bx_block_roles_permissions/roles?dropdown=true";
exports.dynamicContentApiEndPoint = "bx_block_settings/dynamic_contents";
exports.userDetailEndPoint = "account_block/employees/admin_show_employee";
exports.getPasswordEndPoint = "account_block/employees/initialize";
exports.updateAccountEndPoint = "account_block/employees/admin_update_employee";
exports.permissionOnRoleEndpoint =
  "bx_block_roles_permissions/roles/permission_lists";
exports.accountSuggestionApiEndPoint =
  "account_block/employees/employee_suggestion_list";
exports.token = "token";
exports.asterixPlaceholder = "*********";
exports.accountForView = "Account For";
exports.accountFor = "Account For*";
exports.subContractor = "Sub - Contractor";
exports.employee = "Employee";
exports.editAccount = "Edit Account";
exports.accountText = "Account";
exports.selectCategory = "Select Category";
exports.roleText = "Role*";
exports.selectRoleText = "Select Role";
exports.accountCreation = "Account Creation ";
exports.editUserAccount = "Edit User Account ";
exports.userAccountText = "User Account";
exports.createProfession = "Create Profession ";
exports.editprofession = "Edit Profession ";
exports.userProfessionText = "Profession";
exports.professionText = "Profession";
exports.saveText = "Save";
exports.addText = "Add";
exports.createText = "Create";
exports.permissionText = "Permission* :";
exports.rolesAndPermissionText = "Roles and Permission ";
exports.roleSection = "Role";
exports.permissionsSection = "Permissions ";
exports.roleAssignment = "Role Assignment ";
exports.accessControl = "Access Control ";
exports.backText = "Back";
exports.cancelText = "Cancel";
exports.modalTitle = "Profile sent for the approval";
exports.modalText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry ";
exports.gotoHome = "Go to Home";
exports.emailValidText = "Please enter a valid Email Id";
exports.fieldRequiredValidText = "This field is required";
exports.requestType = "application/json";
exports.popoverTest = "popoverTest";
exports.addSuccessProfessionText = "Profession Added Successfully !!";
exports.editProfessionSuccess = "Profession Updated Successfully !!";
exports.severitySuccess = "success";
exports.typeError = "error";
exports.activeProfession = "Profession Activated !!";
exports.cityRequired = "City field is required.";
exports.postcodeRequired = "Postal code field is required.";
exports.postcodeInvalid = "Invalid postal code!";
exports.regionRequired = "Region field is required.";
exports.addressRequired = "Address field is required.";
exports.emailRequired = "Email is required.";
exports.emailInvalid = "Invalid email";
exports.mobileRequired = "Mobile number is required.";
exports.nameRequired = "Name is required.";
exports.invalidText = "Invalid";
exports.textNoPermission = "No Permissions";
exports.addPermission = "Add Permissions";
exports.test = "Test";
exports.test1 = "Test1";
exports.test2 = "Test2";
exports.test3 = "Test3";

exports.regionListApiEndPoint = "bx_block_regions/regions?dropdown=true";
exports.areaListDropDownApiEndPoint = "bx_block_regions/areas?dropdown=true";
exports.plantDetailEndPoint = "bx_block_plants/plants";
exports.plantEndPoint = "bx_block_plants/plants";

exports.plantInformation = "Plant Information";
exports.plant = "Plant";
exports.add = "Add";
exports.region = "Region";
exports.selectRegion = "Select Region";
exports.addPlantSection = "Add Plant Section";
exports.removeBtnText = "Remove";
exports.blank = "----";

exports.addCustomerTypeLabel = "Create Customer Type";
exports.editCustomerTypeLabel = "Edit Customer Type";
exports.customerType = "Customer Type";

exports.customerTypeEndPoint = "bx_block_account_groups/customer_types";
exports.customerTypeSuggestionEndPoint =
  "bx_block_account_groups/customer_types/customer_type_filter?customer_type=";

exports.iconsEndpoint = "bx_block_categories/gallery_icons/list_all_icons";

exports.pleaseSelectOneIcon = "Please select one Icon";

exports.storeManagementApi = "bx_block_store_management/store_managements";
exports.getStoreSuggestionEndPoint =
  "bx_block_store_management/store_managements/store_filter?store_name=";
exports.getAreaSuggestionEndPoint =
  "bx_block_store_management/store_managements/store_filter?area_name=";
exports.storeSuggestionApi =
  "bx_block_store_management/store_managements/initialize";
exports.areaListApiEndPoint = "bx_block_regions/areas";
exports.serviceOfferListApiEndPoint =
  "bx_block_drop_down/drop_down?module_name=Service Offer";
exports.companyEndPoint = "bx_block_company/companies?dropdown=true";
exports.priceListEndPoint =
  "bx_block_price_list/price_lists?dropdown=true&price_list_type=Store";
exports.storeKeeperEndPoint =
  "account_block/employees/employee_list?filter_by[role_name]=Store Keeper";
exports.checkStoreKeeper =
  "bx_block_store_management/store_managements/check_storekeeper";
exports.releaseStoreKeepers =
  "bx_block_store_management/store_managements/update_storekeepers";
exports.reasonListApi =
  "bx_block_store_management/store_managements/close_store_reasons";
exports.generateRandomProfessionId =
  "account_block/professions/generate_random_id";
exports.professionsApiEndPoint = "account_block/professions";
exports.professionsNameSuggestionApiEndPoint =
  "/account_block/professions/profession_filter?profession_name=";
exports.professionsIdSuggestionApiEndPoint =
  "/account_block/professions/profession_filter?profession_unique_id=";
exports.plantSectionsApiEndPoint =
  "bx_block_order_management/orders/status_plants";
// Customer List Page Content
exports.CustomerListLabels = {
  actions: {
    view: "View",
    edit: "Edit",
    activate: "Activate",
    deactivate: "Deactivate",
    addCustomer: "Add Customer",
    backToListing: "Back to Listing",
  },
  labels: {
    noRecordsFound: "No records found!!",
    confirmDeactivate: "Confirm Deactivate",
    accountDeactivated: "Account Deactivated",
    customerDeactivated: "Customer Deactivated",
  },
  tableHeader: {
    id: "ID",
    name: "Name",
    city: "City",
    profession: "Profession",
    organization: "Organization",
    customerType: "Customer Type",
    businessCategory: "Business Category",
  },
};

exports.txtCustomer = "Customer";
exports.txtCustomers = "Customers";
exports.txtCreateCustomer = "Create Customer";
exports.txtEditCustomer = "Edit Customer";
exports.txtLblName = "Name";
exports.txtLblMobileNumber = "Mobile Number";
exports.txtLblEmail = "Email ID";
exports.txtLblGender = "Gender";
exports.txtLblRegion = "Business Area/Region";
exports.txtLblBirthday = "Birthday";
exports.txtLblProfession = "Profession";
exports.txtLblOrganization = "Organization";
exports.txtLblOrganizationTax = "Organization Tax";
exports.txtLblAddress = "Address";
exports.txtLblCity = "City";
exports.txtLblPostalCode = "Postal Code";
exports.txtLblId = "Id";
exports.txtLblBusiness = "Business";
exports.txtLblPriceList = "Price List";
exports.txtLblPriceListExpiryDate = "Price List Expiry Date";
exports.txtLblPriceListRegionView = "Price List Region";
exports.txtLblPriceListAreaView = "Price List Area";
exports.txtLblPriceListStoreView = "Price List Store";
exports.txtLblPriceListExp = "Select Price list expiry date";
exports.txtLblPriceListRegion = "Select Price List Region";
exports.txtLblPriceListArea = "Select Price List Area";
exports.txtLblPriceListStore = "Select Price List Store";
exports.txtLblPaymentType = "Payment Type";
exports.txtLblNote = "Note";
exports.txtLblPrivateNote = "Private Note";
exports.btnTxtPreference = "Create Preference";
exports.btnTxtCancel = "Cancel";
exports.btnTxtAdd = "Add";
exports.btnTxtSave = "Save";
exports.btnTxtEdit = "Edit";

exports.getCustomerListApiEndPoint = "/account_block/customers";
exports.getCustomerListApiMethod = "GET";

exports.orgName = "Organization Name";
exports.createOrganization = "Create Organization";
exports.editOrganization = "Edit Organization ";
exports.organization = "Organization";
exports.addOrganization = "Add Organization ";
exports.orgTaxNumber = "Organization Tax Number";
exports.edit = "Edit";
exports.back = "Back";
exports.lblMobileNumber = "Mobile Number";
exports.createOrgApiEndPoint = "account_block/organisations";
exports.getAllOrgListEndPoint = "account_block/organisations";
exports.getOrgSuggestionListEndPoint =
  "/account_block/organisations/organisation_filter?organisation=";
exports.getTaxNumSuggestionListEndPoint =
  "/account_block/organisations/organisation_filter?tax_number=";
exports.orgApiEndPoint = "account_block/organisations";
exports.typeput = "PUT";
exports.textDeactivate = "deactivate";
exports.textActivate = "activate";
exports.confirmButtonText = "Back to Listing";
exports.organizationDeactivateMessage = "Organization Deactivated ";
exports.orgNamePlaceholder = "Enter Customer Type Name ";
exports.orgNumberPlaceholder = "Enter Number ";
exports.orgAddedSuccessMsg = "Organization has been added successfully ";
exports.orgEditedSuccessMsg = "Organization has been edited successfully ";
exports.orgNumberPlaceholder = "Enter Number";
exports.noRecordFound = "No  records  found! ";

exports.getProductListApiEndPoint = "/bx_block_catalogue/catalogues";
exports.addProductListApiMethod = "POST";
exports.getProductListApiMethod = "GET";
exports.editProductListApiMethod = "PUT";

exports.getSectionListApiEndPoint = "/bx_block_categories/categories";
exports.getSectionListApiMethod = "GET";

exports.getCityListApiEndPoint = "/bx_block_city/cities";
exports.getCityListApiMethod = "GET";

exports.getPriceListApiEndPoint = "/bx_block_price_list/price_lists";
exports.addPriceListApiMethod = "POST";
exports.getPriceListApiMethod = "GET";

exports.getPreferenceListApiEndPoint = "/bx_block_categories/preference_lists";
exports.getPreferenceListApiMethod = "GET";

exports.getServiceListApiEndPoint = "/bx_block_categories/services";
exports.getServiceListApiMethod = "GET";

exports.getParentListApiEndPoint =
  "/bx_block_catalogue/catalogues/parent_products";
exports.getParentListApiMethod = "GET";

exports.getImagesListApiEndPoint =
  "/bx_block_categories/gallery_icons/list_all_images";
exports.getImagesListApiMethod = "GET";

exports.dragAndDropProductApiEndPoint =
  "/bx_block_catalogue/catalogues/order_numbers";
exports.dragAndDropProductApiMethod = "PUT";

exports.getDropdownOptionApiEndPoint = "/bx_block_drop_down/drop_down";
exports.getProfessionDropdownOptionApiEndPoint = "/account_block/professions";
exports.addDropdownOptionApiMethod = "POST";
exports.getDropdownOptionApiMethod = "GET";

exports.addNewCustomerApiEndPoint = "/account_block/web_customers";
exports.getCustomerDetailsApiMethod = "GET";
exports.addNewCustomerApiMethod = "POST";
exports.EditCustomerApiMethod = "PUT";

exports.getOrganisationTaxApiEndPoint = "/account_block/organisations";
exports.getOrganisationTaxApiMethod = "GET";

exports.getPaymentTypeApiEndPoint = "/bx_block_settings/payment_methods";
exports.getPaymentTypeApiMethod = "GET";
exports.customerSuggestionApiEndPoint =
  "/account_block/web_customers/web_customer_filter";
exports.customerSuggestionApiMethod = "GET";
exports.coverageGeneration = "coverageGeneration"
exports.managerListEndPoint = "account_block/employees/employee_list?filter_by[role_name]=manager&dropdown=true"
exports.genCov = "genCov"
exports.PlanSelectionField = "PlanSelectionFieldRemove"
exports.right="right";
exports.left="left";
exports.top="top";
exports.center="center";
exports.title="title";
exports.subtitle="subtitle";


exports.storeListDropdownApiEndPoint = "bx_block_store_management/store_managements";
exports.areaListDropdownApiEndPoint = "bx_block_regions/areas";
exports.loading = "Loading......."
// Customizable Area End
