import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled
} from "@material-ui/core";

import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TaxListController, { Props, configJSON } from "./TaxListController.web";

import PageContainer from "../../navigationmenu/src/PageContainer.web"
import { style } from "./TaxListStyle";
import { filterIcon } from "./assets";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import { CustomPopover } from "../../../components/src/customComponents/CustomTags.web";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions, sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";

const theme = createTheme({});

// Customizable Area End

class TaxList extends TaxListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    const classes = this.props.classes;
    const sortingData = this.state.sortingData;
    const open = Boolean(this.state.openAction);
    const popOverId = open ? "simple-popover" : undefined;

    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        {/* istanbul-ignore-next */}

        <PageContainer
          navigation={this.props.navigation}
          onSearchText={(value) => this.handleSearchTax(value)}
          onUserChange={(userContext) => this.handleUserChange(userContext)}
        >
        <StyledMainBox>
          <Box>
            <Box className="titleContainer">
              <Typography className="titleContainerText">
                {configJSON.tax}
              </Typography>
              <Box className={classes.filtergroup}>
                <Box
                  data-test-id='filterBtn'
                  className="filterButton"
                  onClick={(event) => this.openTaxPopver(event)}
                >
                  <img src={filterIcon} />
                </Box>
                <FilterPopover
                  data-test-id='filterPopver'
                  anchor={this.state.filterAnchor}
                  onClose={() => this.closeTaxPopver()}
                  filters={this.state.filters}
                  onAutoCompleteChange={(title: string, value: string) => {
                    this.setState({ suggestionFieldTitle: title });
                    this.handleTaxAutoComplete(title, value);
                  }}
                  onClearFilter={() => {
                    let updated = this.state.filters.map((item: IFilter) => {
                      if (item.title === "Tax Name") {
                        item.value = "";
                        item.options = [];
                      } else {
                        item.value = "";
                      }
                      return item;
                    });
                    this.setState({ filters: updated });
                  }}
                  onFilterChange={this.handleFilterChange}
                />
                  {
                sortCondition(
                  this.state.permissionStatus.createPermission,
                  <Button
                    className="addBtnStyle"
                    data-test-id="addTaxBtn"
                    variant={configJSON.contained}
                    onClick={() =>
                      this.props.navigation.navigate(configJSON.addEditTax, {
                        id: configJSON.new,
                      })
                    }
                  >
                    {configJSON.addTax}
                  </Button>,
                  <></>
                )
              }
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label='customized table'>
                <TableHead>
                  <TableRow className={classes.TableRow}>
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={configJSON.taxName}
                      sortingKey='tax_name'
                      {...this.sortingProps}
                      width='46%'
                      type='left'
                      handleHeaderClick={() => this.handleFilterWithSorting()}
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title={configJSON.taxpercentage}
                      sortingKey='tax_rate'
                      {...this.sortingProps}
                      width='46%'
                      handleHeaderClick={() => this.handleFilterWithSorting()}
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title=''
                      sortingKey=''
                      {...this.sortingProps}
                      width='8%'
                      type='action'
                      sortingDisabled
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.isLoading && this.state.taxLists.map((item: any) => {
                    return (
                      <TableRow key={item?.id}>
                        <SortingTableCell
                          type='left'
                          width='46vw'
                          content={item.attributes.tax_name}
                        />
                        <SortingTableCell
                          width='46vw'
                          type='right'
                          content={`${
                            Number(item.attributes.tax_rate).toFixed(2) || 0
                          }%`}
                        />
                        <SortingTableCell
                          data-test-id='moreBtnTableCell'
                          width='8vw'
                          type='action'
                          content={
                            <IconButton
                              data-test-id={`moreButton-${item?.id}`}
                              className={classes.blackColor}
                              id={"IconButton" + item?.id}
                              aria-describedby={popOverId}
                              onClick={(event) =>
                                this.handlepopver(event, item)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </TableRow>
                    );
                  })}
                </TableBody>
                {this.state.isLoading && 
                    <>{Array(5)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(2)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}

                           
                          </TableRow>
                        </TableBody>
                      ))}</>
                  }
              </Table>
              {!this.state.isLoading && this.state.taxLists.length === 0 && (
                <Box className={classes.NoRecord}>{configJSON.noData}</Box>
              )}
            </TableContainer>
            <CustomPopover
              data-test-id='actionPopup'
              id={popOverId}
              anchorEl={this.state.openAction}
              open={open}
              onClose={() => this.handleCloseActionList()}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
               {
          renderBaseOnConditions(
            (this.state.permissionStatus.viewPermission ||
            this.state.permissionStatus.editPermission),
            <div className={classes.taxPopoverContainer}>
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.viewPermission,
                  <Box
                  data-test-id='viewButton'
                      onClick={() => this.handleViewTaxDetails()}
                      className={classes.taxPopoverButton}
                    >
                  View
                </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  this.state.permissionStatus.editPermission,
                  <Box
                  data-test-id='editButton'
                  onClick={() => this.handleEditTaxDetails()}
                  className={classes.taxPopoverButton}
                >
                  Edit
                </Box>,
                  <></>
                )
              }
            </div>,
            <StylesNoPermConatiner>
              <StylesNoPermission>
                No Permissions
              </StylesNoPermission>
            </StylesNoPermConatiner>
              )
            }
            </CustomPopover>
            <TablePagination
              onPageChange={(event, page) => {
                this.handlePageChange(page);
              }}
              rowsPerPageOptions={[10]}
              count={this.state.meta.total_count}
              component='div'
              page={this.state.page}
              rowsPerPage={this.state.pageSize}
            />
          </Box>
        </StyledMainBox>
        </PageContainer>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default withStyles(style)(TaxList);


export const StyledMainBox = styled(Box)({
  "& .titleContainer": {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "32px 0",
    flexWrap: 'wrap' as 'wrap'
  },
  "& .titleContainerText": {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    fontFamily: "Montserrat",
    color: colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: 600,
      color: colors().black,
    },
  },
  "& .filterButton": {
    padding: "16px",
    borderRadius: "15px",
    border: "1px solid rgba(32, 75, 156, 1)",
    cursor: "pointer",
    marginRight: "24px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "9px",
    },
  },
  "& .addBtnStyle": {
    boxShadow:"none",
    padding: "16px 20px",
    background: "#204B9C",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgb(255, 255, 255)",
    outline: "none",
    border: "none",
    cursor: "pointer",
    height: "56px",
    width: "184px",
    "&.secondary": {
      background: colors().antiFlashWhite,
      color: colors().slateGray
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
      padding: "10px 20px",
    },
  } 
})

const StylesNoPermission = styled('div')({
  padding: "0 8px",
})

const StylesNoPermConatiner = styled('div')({
  paddingTop: "16px",
  paddingBottom: "16px",
  boxShadow:
    "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
})

// Customizable Area End
