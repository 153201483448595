// Customizable Area Start
import React from "react";
import { toString } from "lodash";
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Checkbox from "@material-ui/core/Checkbox";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import HistoryIcon from "@material-ui/icons/History";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import {  HeaderText, InputLabel, CustomStyledTextField } from "../../../components/src/customComponents/CustomTags.web";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiBox from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Switch from "@material-ui/core/Switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chip from "@material-ui/core/Chip";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";

export const configJSON = require("./config");

import StoreAddController, {
  FormModeStore,
  IFormStore,
  IOptionListItem,
  OptionType,
  Props,
  TimeState
} from "./StoreAddController.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
// Customizable Area End
// Customizable Area Start
const StyledMenuItemStore = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const StyledMultipleMenuItemStore = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#204B9C",
      },
    },
  },
}))(MenuItem);

const ExampleCustomInput = ({
  value,
  onClick,
}: {
  value: string;
  onClick: () => void;
}) => (
  <div
    style={{
      padding: "10px 12px",
      border: "1px solid grey",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    {value}
    <ArrowDropDownIcon />
  </div>
);

const useStyles: Styles<Theme, {}, string> = () => ({
  chips: {
    gap: 4,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "Montserrat",
  },
  chip: {    
    color: "#204B9C",
    fontFamily: "Montserrat",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
  },
  pageContainerStore: {
    fontFamily: "Montserrat",
    background: "white",
    padding: "32.5px 48px",

    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
    },
  },
  pageTitleStore: {
    fontSize: 24,
    marginTop: 32,
    fontWeight: 600,
  },
  formContainerStore: {
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,
    padding: 32,

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "-0.3px",
      fontSize: 16,
      color: "#1A1A1A",
    },
    position: "relative",
  },
  paddingRightClass: {
    paddingRight: 32,
  },
  button: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px",
      height: "44px",
      padding: "13px 34px",

    },
  },
  errorInput: {
    "& fieldset": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  selectContainer: {
  },
  selectDayContainer: {
    height: 46,
    "&.MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  multiSelectContainer: {
    "& .MuiOutlinedInput-input": {
      padding: "4px 14px",
    },
    "& .MuiSelect-selectMenu": {
      whiteSpace: "break-spaces",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: '#fff'
    }
  },
  sectionTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  getLocationText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 16,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  blueCheckbox: {
    "&$checked": {
      color: "#204B9C",
    },
  },
  checked: {},
  modalContentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 104px 0 32px",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,
  },
  modalRowContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
  },
  modalTextButtonsContainer: {
    display: "flex",
    color: "#204B9C",
    fontWeight: 600,
    fontSize: 16,
  },
  modalRowTitle: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Montserrat",
    width: 90,
    marginRight: 15,
    color: "#000000",
  },
  modalRowTo: {
    margin: "0px 24px",
    fontWeight: 500,
    fontSize: 18,
  },
  timeDialog: {
    textAlign: "center",
  },
  customTabContainer: {
    width: 244,
    height: 44,
    padding: 2,
    backgroundColor: "#F8FAFC",
    borderRadius: 50,
    display: "flex",
  },
  tabText: {
    color: "#4D4D4D",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    padding: "10px 12px",
  },
  activeTab: {
    padding: "10px 12px",
    backgroundColor: "white",
    borderRadius: 50,
    color: "#204B9C",
  },
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: 1,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + $track": {
        backgroundColor: "#204B9C",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#204B9C",
      border: "6px solid grey",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    color: "white !important",
  },
  track: {
    borderRadius: 26 / 2,
    border: "1px solid grey",
    backgroundColor: "grey",
    opacity: 1,
    //transition: theme.transitions.create(['background-color', 'border']),
  },
  checkedSwitch: {},
  focusVisible: {},
  datepickerInput: {
    paddingRight: 30,

    "&:after": {
      content: "▼",
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
      pointerEvents: "none",
    },
  },
  closeText: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 32,
  },
  closeChecksContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
  },
  checkAndTextContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  sectionTitleView: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  sectionFormContainerViewAdd: {
    marginTop: 24,
    padding: 24,
    border: "1px solid #ECECEC",
    position: "relative",
    borderRadius: 12,
  },
  dropDown: {
    "& .MuiAutocomplete-root": {
      minWidth: "unset !important",
      maxWidth: "unset !important",
      marginTop: "4px",
      "& .MuiInputBase-root": {
        minHeight: "56px !important",
        padding: "4px 65px 4px 4px",
        "@media only screen and (max-width: 1024px)": {
          minHeight: "44px !important",
          maxHeight: "unset !important",
      }
    }
    }
  },
  viewSectionHeaderViewAdd: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  stichSelection: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    padding: "15px",
  },
  w_100: {
    width: "100%",
  },
  storeTimeText: {
    fontSize: 16,
    marginTop: 4,
    fontWeight: 500,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  mb_8: {
    marginBottom: 8,
  },
  mt_30: {
    marginTop: 30,
  },
  mr_10: {
    marginRight: 10,
  },
  mt_24: {
    marginTop: 24,
  },
  mt_32: {
    marginTop: 32,
  },
  ml_10: {
    marginLeft: 10,
  },
  pr_32: {
    paddingRight: 32,
  },
  mt_66: {
    marginTop: 66,
  },
  mt_48: {
    marginTop: 48,
  },
  formErrors: {
    color: "red",
    marginBottom: "-20px",
  },
  fontRed: {
    color: "red",
  },
  selectAllText: {
    color: "black",
    fontWeight: 400,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '14px',
    }
  },
  addBreakText: {
    marginRight: 34,
    display: "flex",
    alignItems: "Center",
  },
  addShiftText: {
    display: "flex",
    alignItems: "Center",
  },
  getLocationIcon: {
    fontSize: 20,
    marginRight: 10,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  pageTitle: {
    marginBottom: 32,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 32,
  },
  historyIcon: {
    fontSize: 24,
    color: "#4d4d4d",
  },
  actionButtons: {
    marginTop: 28,
    marginBottom: 48,
  },
  cancleButton: {
    backgroundColor: "#F1F5F9",
    marginRight: 32,
    color: "#64748B",
  },
  addSaveButton: {
    color: "white",
    backgroundColor: "#204B9C",
  },
  addStoreTimeText: {
    fontSize: 24,
    fontWeight: 600,
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
});
// Customizable Area End
export class StoreAdd extends StoreAddController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
  ];

  renderChips(items: Array<string>) {
    const { classes } = this.props;
    const renderedJSX = items
      .filter((item) => item)
      .map((name: string) => (
        <Chip label={name} key={name} className={classes.chip} />
      ));
    return <div className={classes.chips}>{renderedJSX}</div>;
  }

  renderTextField(
    keyOf: keyof IFormStore,
    placeholder: string,
    isNumeric?: boolean,
    dirRtl?: boolean
  ) {
    const { form, formErrors } = this.state;
    const { classes  } = this.props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!isNumeric || regex.test(inputValue)) {
        this.onFormElementChange(
          { ...event, target: { ...event.target, value: inputValue } },
          keyOf
        );
      }
    };

    return (
      <CustomStyledTextField
        data-test-id={keyOf}
        className={formErrors[keyOf] ? classes.errorInput : "withoutError"}
        value={form[keyOf]}
        color='primary'
        variant='outlined'
        dir={sortCondition(dirRtl as boolean,"rtl","ltr") as string}
        type='text'
        name={keyOf}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    );
  }

  renderInputTextField(
    keyOf: keyof IFormStore,
    label: string,
    placeholder: string,
    leftOne: boolean,
    sixColumn?: boolean,
    isNumeric?: boolean,
    dirRtl?: boolean
  ) {
    const { formErrors } = this.state;
    const { classes  } = this.props;

    const requireFieldKeys = [
                              "store_name","store_address","email","store_capacity",
                              "latitude","longitude"
                              ];
    const isRequired = requireFieldKeys.includes(keyOf);

    return (
      <>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ paddingRight: sixColumn && leftOne ? 24 : undefined }}
          className={leftOne ? classes.paddingRightClass : ""}
        >
          <FormControl className={classes.w_100}>
            <InputLabel className={classes.mb_8}>{label}{isRequired && "*"}</InputLabel>
            {this.renderTextField(keyOf, placeholder, isNumeric, dirRtl)}
            {formErrors[keyOf] && (
              <Typography
                variant='caption'
                className={`${classes.fontRed} errorMsg2`}
              >
                {formErrors[keyOf]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }

  renderSelect(
    keyOf: keyof IFormStore,
    label: string,
    placeholder: string,
    leftOne: boolean,
    options: IOptionListItem[],
    requiredField: boolean = false
  ) {
    const { form, formErrors } = this.state;
    const { classes  } = this.props;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        className={leftOne ? classes.paddingRightClass : ""}
      >
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>{label}{requiredField && "*" }</InputLabel>

          <AutocompleteSelect
            className={
              formErrors[keyOf] ? classes.errorInput : classes.selectContainer
            }
            data-test-id={keyOf}
            fieldName={keyOf}
            placeholder={placeholder}
            options={options}
            value={form[keyOf] as string}
            isRadioButtonShow={true}
            onChange={(
              _event: React.ChangeEvent,
              value: { id: string }
            ) => {
              if(!value) return;
              this.onFormElementChange2(value.id,keyOf)
            }}
          />

          {formErrors[keyOf] && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors[keyOf]}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderMultipleServiceSelect() {
    const { formErrors, serviceOfferList, form } = this.state;
    const { classes  } = this.props;

    return (
      <Grid item xs={12} sm={6} className={classes.paddingRightClass}>
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>Service Offer</InputLabel>
          
          <CustomSearchableMultiSelect
            id="service_offer_id"
            name="service_offer_id"
            dataTestId="field-service_offer_id"
            placeHolderText={'Select Service Offer'}
            changeInputOnClear
            emptyDataMessage="No Service Offer Found"
            handleScroll={() => { }}
            checkSelectAllText={() => { }}
            handleClickOnSelectAll={() => { }}
            handleEmptyAutoSelectValue={() => { }}
            debouncedFunction={() => { }}
            optionList={serviceOfferList}
            value={this.state.selectedServiceOffer}
            selectedOptionForList={this.state.serviceOfferSelectedOptionForList}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleServiceOfferMultiSelect(value, option) }}
          />
          
          {formErrors.service_offer_id && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors.service_offer_id}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderMultipleStoreKeeperSelect() {
    const { formErrors } = this.state;
    const { classes  } = this.props;

    return (
      <Grid item xs={12} sm={6} className={classes.paddingRightClass}>
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>Store Keeper</InputLabel>

          <CustomSearchableMultiSelect
            id="store_keeper_id"
            name="store_keeper"
            dataTestId="store_keeper_id"
            placeHolderText={configJSON.selectStoreKeeperTxt}
            changeInputOnClear
            emptyDataMessage="No Data Found"
            handleScroll={() => { }}
            checkSelectAllText={() => { }}
            handleClickOnSelectAll={() => { }}
            handleEmptyAutoSelectValue={() => { }}
            debouncedFunction={() => { }}
            isOnBlurFunction={()=>this.checkStoreKeeperCall()}
            optionList={this.state.storeKeepers}
            value={this.state.selectedStoreKeeper}
            selectedOptionForList={this.state.storeKeeperSelectedOptionForList}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleStoreKeeperMultiSelect(value, option) }}
          />

          {formErrors.store_keeper_id && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors.store_keeper_id}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  renderMultipleSectionsSelect() {
    const { sectionsList, form } = this.state;
    const { classes  } = this.props;

    return (
      <Grid item xs={12} sm={6} className={`${classes.paddingRightClass} ${classes.dropDown}`}>
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>Section</InputLabel>
          <CustomSearchableMultiSelect
              id="section"
              name="section"
              dataTestId="sectionDropdown"
              checkSelectAllText={() => {}}
              value={form.sections}
              optionList={sectionsList}
              placeHolderText="Select Section"
              emptyDataMessage="No Section Found"
              changeInputOnClear
              handleClickOnSelectAll={() => {}}
              debouncedFunction={(getValue: string) => this.debouncedFunction(getValue, this.handleSectionAutoCompleteChange)}
              handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => this.handleSectionMultiSelect(option)}
              isOnBlurFunction={() => this.debouncedFunction("", this.handleSectionAutoCompleteChange)}
              handleScroll={this.handleScrollSectionDropdown}
              handleEmptyAutoSelectValue={() => {}}
              selectedOptionForList={form.selectedSectionsForList}
          />
        </FormControl>
      </Grid>
    );
  }

  renderMultiplePlantSelect() {
    const { formErrors, form } = this.state;
    const { classes  } = this.props;

    return (
      <Grid item xs={12} sm={6}>
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>Plant Linked*</InputLabel>

          <CustomSearchableMultiSelect
            id="plant_linked_id"
            name="plant_linked_id"
            dataTestId="field-plant_linked_id"
            placeHolderText={'Select Plant Linked'}
            changeInputOnClear
            emptyDataMessage="No Plant Found"
            handleScroll={() => { }}
            checkSelectAllText={() => { }}
            handleClickOnSelectAll={() => { }}
            handleEmptyAutoSelectValue={() => { }}
            debouncedFunction={() => { }}
            optionList={this.state.plantLinkedList}
            value={this.state.selectedPlantLinked}
            selectedOptionForList={this.state.plantLinkedSelectedOptionForList}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handlePlantLinkedMultiSelect(value, option) }}
          />

          {formErrors.plant_ids && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors.plant_ids}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  

  renderStoreTimes = (is_weekend: boolean) => {
    const { classes  } = this.props;
    let shiftCount = 0;

    let shifts = is_weekend
      ? this.state.weekendTimes.shifts
      : this.state.weekdaysTimes.shifts;

    return shifts.map((storeTime, index) => {
      if (storeTime.label === "Shift") shiftCount++;
      return (
        <div className={classes.modalRowContainer}>
          <div className={classes.modalRowTitle}>
            {storeTime.label === "Shift" ? `Shift ${shiftCount}` : "Break"}
          </div>
          <div>
            <DatePicker
              selected={new Date(storeTime.start_time)}
              data-test-id={`startTime_${index}`}
              onChange={(date) => {
                this.handleShiftTimeChange(
                  date,
                  index,
                  is_weekend,
                  "start_time"
                );
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              dateFormat='h:mm aa'
              customInput={
                <ExampleCustomInput
                  value={storeTime.start_time}
                  onClick={() => {}}
                />
              }
            />
          </div>
          <div className={classes.modalRowTo}>To</div>
          <div>
            <DatePicker
              selected={new Date(storeTime.end_time)}
              data-test-id='endTime'
              onChange={(date) => {
                this.handleShiftTimeChange(date, index, is_weekend, "end_time");
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              dateFormat='h:mm aa'
              customInput={
                <ExampleCustomInput
                  value={storeTime.end_time}
                  onClick={() => {}}
                />
              }
            />
          </div>
        </div>
      );
    });
  };

  renderTimeModalContent = (is_weekend: boolean) => {
    const { classes  } = this.props;
    const { days } = this.state;

    return (
      <>
        <div className={classes.modalRowContainer}>
          <div className={classes.modalRowTitle}>Weekdays</div>
          <div>
            <FormControl variant='outlined'>
              <Select
                className={classes.selectDayContainer}
                data-test-id={"start-day"}
                id={"start-day"}
                value={
                  !is_weekend
                    ? this.state.weekdaysTimes.startDay
                    : this.state.weekendTimes.startDay
                }
                onChange={(event) => {
                  this.handleDayChange(event, is_weekend, "startDay");
                }}
                placeholder={"Sunday"}
              >
                {days.map((dayOf) => (
                  <StyledMenuItemStore key={dayOf} value={dayOf}>
                    <ListItemText primary={dayOf} />
                  </StyledMenuItemStore>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.modalRowTo}>To</div>
          <div>
            <FormControl variant='outlined'>
              <Select
                className={classes.selectDayContainer}
                data-test-id={"end-day"}
                id={"end-day"}
                value={
                  !is_weekend
                    ? this.state.weekdaysTimes.endDay
                    : this.state.weekendTimes.endDay
                }
                onChange={(event) => {
                  this.handleDayChange(event, is_weekend, "endDay");
                }}
                placeholder={"Sunday"}
              >
                {days.map((dayOf) => (
                  <StyledMenuItemStore key={dayOf} value={dayOf}>
                    <ListItemText primary={dayOf} />
                  </StyledMenuItemStore>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.modalRowContainer}>
          <div className={classes.modalRowTitle}>Status</div>
          <div>
            <Switch
              focusVisibleClassName={classes.focusVisible}
              disableRipple
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              data-test-id={"status"}
              checked={
                !is_weekend
                  ? this.state.weekdaysTimes.status === "open"
                  : this.state.weekendTimes.status === "open"
              }
              onChange={(event) => {
                this.handleStatusChange(event, is_weekend);
              }}
            />
          </div>
        </div>
        {this.renderStoreTimes(is_weekend)}
        <div className={classes.modalRowContainer}>
          <div className={classes.modalRowTitle} />
          <div className={classes.modalTextButtonsContainer}>
            <div
              data-test-id={"add-shift"}
              onClick={() => this.addShift("Break", is_weekend)}
              className={classes.addBreakText}
            >
              <AddCircleOutlineIcon className={classes.mr_10} />
              Add Break
            </div>
            <div
              data-test-id={"add-break"}
              onClick={() => this.addShift("Shift", is_weekend)}
              className={classes.addShiftText}
            >
              <AddCircleOutlineIcon className={classes.mr_10} />
              Add Shift
            </div>
          </div>
        </div>
      </>
    );
  };

  renderCloseOnDays = () => {
    const { startDay, endDay } = this.state.weekendTimes;
    const { days } = this.state;
    const { classes  } = this.props;

    let weekendDays: string[] = [];

    if (days.indexOf(startDay) < days.indexOf(endDay)) {
      weekendDays = days.filter((item, index) => {
        return index >= days.indexOf(startDay) && index <= days.indexOf(endDay);
      });
    } else {
      weekendDays = days.filter((item, index) => {
        return (
          (index >= days.indexOf(startDay) &&
            index <= days.indexOf(endDay) + 7) ||
          (index >= days.indexOf(startDay) - 7 && index <= days.indexOf(endDay))
        );
      });
    }

    return weekendDays.map((weekenDay) => {
      return (
        <div className={classes.checkAndTextContainer}>
          <Checkbox
            data-test-id={`closeDay_${weekenDay}`}
            checked={this.state.weekendTimes.closedDays.includes(weekenDay)}
            onChange={(event) => {
              this.handleCloseDayChange(event, weekenDay);
            }}
            name={weekenDay}
            color='primary'
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <span>{weekenDay}</span>
        </div>
      );
    });
  };

  renderTimesforDaysAddStore = (storeTimes: TimeState) => {
    const { classes  } = this.props;
    return (
      <Grid container>
        {storeTimes.shifts?.map((shift) => (
          <Grid item md={6} xs={12} className={classes.mt_32}>
            <FormControl className={classes.w_100}>
              <FormLabel className={classes.mb_8}>{shift.label}</FormLabel>
              <Typography className={classes.storeTimeText}>
                {`${new Date(shift.start_time).toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })} To ${new Date(shift.end_time).toLocaleString([], {
                  hour: "2-digit",
                  hour12: true,
                  minute: "2-digit",
                })}` || configJSON.blank}
              </Typography>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    );
  };

  renderPhoneTextField(
    fieldName: keyof IFormStore,
    placeholder: string,
    responsive: any,
    isNumeric: boolean,
    dirRtl: boolean
  ) {
    const { classes } = this.props;
    const { formErrors } = this.state;
    return (
      <Grid item xs={responsive}>
            <FormControl className={classes.w_100}>
              {this.renderTextField(fieldName, placeholder, isNumeric, dirRtl)}
              {formErrors[fieldName] && (
                <Typography
                  variant='caption'
                  className={`${classes.fontRed} errorMsg2`}
                >
                  {formErrors[fieldName]}
                </Typography>
              )}
            </FormControl>
          </Grid>
    )
  }
  renderPhoneNumberFieldLayout(
    prefixFieldName: keyof IFormStore,
    fieldName: keyof IFormStore,
    prefixPlaceholder: string,
    fieldPlaceholder : string,
    fieldLabel: string,
    requirePadding:  boolean,
    isNumeric: boolean,
    dirRtl: boolean,
  ) {
    const requireFieldKeys = ["full_ip_phone_number"];
    const isRequired = requireFieldKeys.includes(fieldName);
    return (
      <Grid container direction='row' style={ { ...( requirePadding &&   { paddingRight: 24} ) }} xs={12} sm={6}>
        <InputLabel>{fieldLabel}{isRequired && "*"}</InputLabel>
        <Grid container spacing={2}>
          { this.renderPhoneTextField(prefixFieldName, prefixPlaceholder, 2, false, false)}
          { this.renderPhoneTextField(fieldName, fieldPlaceholder, 10, isNumeric, dirRtl)}
        </Grid>

      </Grid>
    )
  }

  renderAddressFields() {
    const { classes } = this.props;
    return (
      <>
        {
         this.generateAddressFieldsData().map( (item: any) => (
            <Grid container direction='row' className={classes.mt_30}>
              {this.renderInputTextField(
                item.fieldName as keyof IFormStore,
                item.label,
                item.placeholder,
                item.leftOne
              )}
              {this.renderInputTextField(
                 item.fieldNameAr as keyof IFormStore,
                 item.labelAr,
                 item.placeholderAr,
                item.leftOneAr,
                item.sixColumnAr,
                item.isNumericAr,
                item.dirRtlAr
              )}
            </Grid>
          ))
        }
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes  } = this.props;
    const { weekendTimes, weekdaysTimes } = this.state;

    const {
      isSubmitting,
      formMode,
      regionList,
      areaList,
      plantLinkedList,
      isLoading,
      openTimeModal,
      activeTimeTab,
      formErrors
    } = this.state;
    let buttonText;

    if (isSubmitting) {
      buttonText = <CircularProgress size={16} color='inherit' />;
    } else if (formMode === FormModeStore.Edit) {
      buttonText = configJSON.saveText;
    } else {
      buttonText = configJSON.add;
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <PageContainer navigation={this.props.navigation}>
        <CustomLoader loading={this.state.isEditLoading} />
        <Grid
        >
          <Grid container direction='row' className={classes.pageTitle}>
            <HeaderText>Store Information</HeaderText>
          </Grid>
          <Grid
            container
            direction='column'
            className={classes.formContainerStore}
          >
            <Grid container direction='row'>
              {this.renderSelect(
                "company_id",
                "Company",
                "Select Company",
                true,
                this.state.companies.map((company) => ({
                  id: String(company.attributes.id),
                  option: company.attributes.name,
                })),
                true
              )}
            </Grid>
            <Grid container direction='row' className={classes.mt_30}>
              {this.renderSelect(
                "region_id",
                "Region",
                "Select Region",
                true,
                regionList,
                true
              )}
              {this.renderSelect(
                "area_id",
                "Area",
                "Select Area",
                false,
                areaList,
                true
              )}
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {this.renderInputTextField(
                "store_name",
                "Store Name",
                "Enter Store Name",
                true
              )}
              {this.renderInputTextField(
                "store_id",
                "Store ID",
                "Enter Store Name",
                false
              )}
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {this.renderInputTextField(
                "store_address",
                "Address",
                "Enter Store Address",
                true
              )}
              {this.renderInputTextField(
                "store_address_arabic",
                "Address (Arabic)",
                "Enter Store Address",
                false,
                false,
                false,
                true
              )}
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {
                this.renderPhoneNumberFieldLayout(
                  'ip_phone_prefix_en',
                  'full_ip_phone_number',
                  'Enter Prefix',
                  'Enter IP Phone Number',
                  'IP Phone No.',
                  true,
                  true,
                  false
                )
              }
               { this.renderPhoneNumberFieldLayout(
                  'ip_phone_prefix_ar',
                  'full_ip_phone_number_arabic',
                  'Enter Prefix',
                  'Enter IP Phone Number',
                  'IP Phone No. (Arabic)',
                  false,
                  false,
                  true
                )
              }
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {this.renderInputTextField(
                "email",
                "Email Address",
                "Select Email ID",
                true
              )}
              {this.renderMultiplePlantSelect()}
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {this.renderMultipleServiceSelect()}
              {this.renderInputTextField(
                "store_capacity",
                "Store Capacity",
                "Enter Store Capacity",
                false
              )}
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {this.renderMultipleStoreKeeperSelect()}
              <Grid
                item
                xs={6}
                sm={6}
              >
                <FormControl variant='outlined' className={classes.w_100}>
                  <InputLabel className={classes.mb_8}>Price List*</InputLabel>
                  <AutocompleteSelect 
                    data-test-id="priceListDropdown"
                    fieldName="price_list"
                    placeholder="Select Price List"
                    handleScroll={this.handleScrollPricelistDropdown.bind(this)}
                    debouncedFunction={(getValue: string) => { 
                      this.debouncedFunction(getValue, this.handlePriceListSearch)
                    }}
                    onChange={(_blank: React.ChangeEvent<HTMLInputElement>, value: {id: string, option: string} | null) => {
                      this.handleChangeSelectedPriceList(value)
                    }}
                    onBlur={(event: React.MouseEvent) => {
                      this.handlePriceListSearch(toString(this.state.form.price_list?.option))
                    }}
                    value={toString(this.state.form.price_list?.id)}
                    options={this.state.priceList}
                    listBoxStyle={{maxHeight: 270}}
                    error={Boolean(!this.state.form.price_list && formErrors.price_list)}
                  />
                  {
                    !this.state.form.price_list && formErrors.price_list && (
                      <Typography
                        variant='caption'
                        className={`${classes.formErrors} errorMsg2`}
                      >
                        {formErrors.price_list}
                      </Typography>
                    )
                  }
                  </FormControl>
                </Grid>
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              {this.renderMultipleSectionsSelect()}
              {this.renderInputTextField(
                "store_cr_number",
                "Store CR No.",
                "Enter Store CR number",
                false
              )}
            </Grid>

              { this.renderAddressFields()}

            <Grid container direction='row' className={classes.mt_30}>
              <Grid item xs={12} sm={6} className={classes.paddingRightClass}>
                <div className={classes.sectionTitle}>
                  GPS Co-ordinates of Store
                </div>
              </Grid>
              <div className={classes.stichSelection}>
                <InputLabel className={classes.mb_8}>Payment Popup</InputLabel>
                <CustomSwitch
                  data-test-id='customswitch'
                  checked={this.state.form.paymentPopUpEnabled}
                  onChange={this.onChangePaymentPopUp}
                />
              </div>
            </Grid>

            <Grid container direction='row' className={classes.mt_30}>
              <Grid
                container
                direction='row'
                xs={6}
                sm={6}
                className={classes.pr_32}
              >
                {this.renderInputTextField(
                  "latitude",
                  "Latitude",
                  "Enter Latitude",
                  true,
                  true,
                  true
                )}
                {this.renderInputTextField(
                  "longitude",
                  "Longitude",
                  "Enter Longitude",
                  false,
                  true,
                  true
                )}
              </Grid>
              <div className={`${classes.stichSelection} ${classes.mt_24}`}>
                <InputLabel className={classes.mb_8}>
                  Item Preference Popup
                </InputLabel>
                <CustomSwitch
                  data-test-id='customswitchPreference'
                  checked={this.state.form.preferencePopUpEnabled}
                  onChange={this.onChangePreferencePopup}
                />
              </div>
            </Grid>
            <Grid container direction='row' className={classes.mt_30}>
              <Grid
                container
                direction='row'
                xs={12}
                sm={12}
                className={classes.pr_32}
              >
                {this.renderInputTextField(
                  "gps_location",
                  "GPS Location",
                  "Enter GPS Location",
                  true,
                  true,
                  false
                )}
              </Grid>
              </Grid>
            <MuiBox className={classes.mt_32}>
              <div
                data-test-id='getLocation'
                className={classes.getLocationText}
                onClick={this.handleGetLocationClick}
              >
                <GpsFixedIcon className={classes.getLocationIcon} />
                Get Current Location
                {isLoading && (
                  <CircularProgress size={20} className={classes.ml_10} />
                )}
              </div>
            </MuiBox>

            <Grid container direction='row' className={classes.mt_30}>
              <Grid item xs={12} sm={6} className={classes.paddingRightClass}>
                <FormControl className={classes.w_100}>
                  <InputLabel className={classes.mb_8}>Store Time</InputLabel>
                  <TextField
                    onClick={this.handleTimeModalOpen}
                    disabled
                    data-test-id={"store-time-input"}
                    color='primary'
                    variant='outlined'
                    type='text'
                    placeholder={"Enter Store Time"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' data-test-id='timeIcon'>
                          <HistoryIcon className={classes.historyIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className={`${classes.formContainerStore} ${classes.mt_32}`}
          >
            <Grid item xs={12} sm={6}>
              <div className={classes.sectionTitleView}>Store Timings</div>
            </Grid>

            <Grid
              container
              direction='column'
              className={classes.sectionFormContainerViewAdd}
            >
              <Grid container direction='row'>
                <div className={classes.viewSectionHeaderViewAdd}>
                  Weekdays (
                  {`${weekdaysTimes.startDay} to ${weekdaysTimes.endDay}`})
                </div>
              </Grid>
              <Grid container direction='row' className={classes.mt_66}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.w_100}>
                    <FormLabel className={classes.mb_8}>Status</FormLabel>
                    <Typography className={classes.storeTimeText}>
                      {weekdaysTimes.status || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              {this.renderTimesforDaysAddStore(weekdaysTimes)}
            </Grid>

            <Grid
              className={classes.sectionFormContainerViewAdd}
              direction='column'
              container
            >
              <Grid container direction='row'>
                <div className={classes.viewSectionHeaderViewAdd}>
                  Weekend Days (
                  {`${weekendTimes.startDay} to ${weekendTimes.endDay}`})
                </div>
              </Grid>
              <Grid container direction='row' className={classes.mt_66}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.w_100}>
                    <FormLabel className={classes.mb_8}>Status</FormLabel>
                    <Typography className={classes.storeTimeText}>
                      {weekendTimes.status || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              {this.renderTimesforDaysAddStore(weekendTimes)}
            </Grid>
          </Grid>

          <Grid container direction='row' className={classes.mt_48} style={{paddingBottom:'46px'}}>
            <div
              onClick={this.handleCancelClick}
              data-test-id={"cancelBtn"}
              className={`${classes.button} ${classes.cancleButton}`}
            >
              Cancel
            </div>

            <div
              data-test-id={"submitBtn"}
              className={`${classes.button} ${classes.addSaveButton}`}
              onClick={this.handleOnSubmit}
            >
              {buttonText}
            </div>
          </Grid>
        </Grid>
        <Dialog
          className='main-dialog-container'
          open={openTimeModal}
          onClose={this.handleTimeModalClose}
        >
          <MuiBox className={classes.timeDialog}>
            <DialogTitle>
              <span className={classes.addStoreTimeText}>Add Store Time</span>
            </DialogTitle>
            <DialogContent>
              <div className={classes.modalContentContainer}>
                <div
                  className={`${classes.modalRowContainer} ${classes.justifyContentCenter}`}
                >
                  <div className={classes.customTabContainer}>
                    <div
                      data-test-id={"weekDaysTab"}
                      onClick={() => this.handleActiveTabClick(0)}
                      className={`${classes.tabText} ${
                        activeTimeTab === 0 ? classes.activeTab : ""
                      }`}
                    >
                      Week Days
                    </div>
                    <div
                      data-test-id={"weekendDaysTab"}
                      onClick={() => this.handleActiveTabClick(1)}
                      className={`${classes.tabText} ${
                        activeTimeTab === 1 ? classes.activeTab : ""
                      }`}
                    >
                      Weekend Days
                    </div>
                  </div>
                </div>
                {this.renderTimeModalContent(activeTimeTab === 1)}
              </div>
              {activeTimeTab === 1 &&
                this.state.weekendTimes.status === "open" && (
                  <div className={classes.closeChecksContainer}>
                    <span className={classes.closeText}>Closed on</span>
                    {this.renderCloseOnDays()}
                  </div>
                )}
            </DialogContent>
            <MuiBox className={`buttons ${classes.actionButtons}`}>
              <Button
                className={"btn-cancel"}
                data-test-id={"btn-cancel"}
                onClick={this.handleTimeModalClose}
                color='primary'
              >
                Cancel
              </Button>
              <Button
                className='btn-confirm'
                data-test-id='btn-confirm'
                onClick={this.handleTimeModalSave}
                color='primary'
              >
                Save
              </Button>
            </MuiBox>
          </MuiBox>
        </Dialog>

        <Dialog
          data-test-id='conflictWarningPopup'
          open={this.state.showReleaseStoreKeepersModal}
          onClose={this.handleReleaseModalClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Store Keeper Conflict
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Some of the store keepers are already assigned to other stores. Do
              you want to release them?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleReleaseModalClose}>Cancel</Button>
            <Button
              data-test-id='btnRelease'
              onClick={this.handleReleaseModalConfirm}
              autoFocus
            >
              Release
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
      <Snackbar
          data-test-id='snackBar'
          open={!!this.state.snackbarOpenStore}
          onClose={this.handleSnackbarStoreClose}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity={this.state.snackbarMessageType}>{this.state.snackbarMessageStore}</Alert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles)(StoreAdd);
