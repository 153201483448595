import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { getFiterList, makeApiMessage } from "../../../components/src/common";
// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface IArea {
  id: string;
  type: "area";
  attributes: {
    id: number;
    area_name: string;
    activated: boolean;
    region: {
      data: {
        attributes: {
          branch_name: string;
        };
      };
    };
    company: {
      data: {
        attributes: {
          name: string;
        };
      };
    };
    store_management_count: number;
    store_managements: {
      id: number;
      store_name: string;
    }[];
  };
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  areas: IArea[];
  page: number;
  pageSize: number;
  sortingData: ISortingData;
  query: string;
  filterAnchor: any;
  filters: IFilter[];
  searchTextArea: string;
  suggestionFieldTitle: string;
  permissionStatus: PermissionStatus;
  openStoreListDialog: boolean;
  isLoading: boolean;
  listingDataShow: string[];
}

export interface SS {
  id: any;
}

const emptyArea: IArea = {
  id: "",
  type: "area",
  attributes: {
    id: 0,
    area_name: "",
    activated: false,
    region: {
      data: {
        attributes: {
          branch_name: "",
        },
      },
    },
    company: {
      data: {
        attributes: {
          name: "",
        },
      },
    },
    store_managements: [],
    store_management_count: 0
  },
};

export default class AreaListController extends BlockComponent<Props, S, SS> {
  getAreasCallId: string = "";
  updateUserCallId: string = "";
  filterSuggestionApiCallId: string = "";
  tableRefs: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      popOverOpened: false,
      drawerWidth: 0,
      popOverItemStatus: "",
      popOverLeft: 0,
      hoveredButton: "",
      areas: [],
      popOverTop: 0,
      page: 0,
      popOverItemId: "",
      pageSize: 10,
      sortingData: {
        company_name: "",
        area_name: "",
        branch_name: "",
        store_count: "",
      },
      query: "",
      searchTextArea: "",
      suggestionFieldTitle: "",
      filters: [
        {
          title: "Company Name",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Area Name",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Region",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Store Count",
          type: "select",
          options: [
            {
              label: "Maximum To Minimum",
              value: "DESC",
            },
            {
              label: "Minimum To Maximum",
              value: "ASC",
            },
          ],
          value: "",
        },
      ],
      filterAnchor: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      },
      openStoreListDialog: false,
      listingDataShow: [],
      isLoading: false
    };
  }

  processMeta = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let newAreas = Array<IArea>(responseJson.meta.total_count);
    for (let i = 0; i < responseJson.meta.total_count; i++) {
      if (
        i >= (responseJson.meta.current_page - 1) * 10 &&
        i < responseJson.meta.current_page * 10
      ) {
        newAreas[i] = responseJson.data[i - (responseJson.meta.current_page - 1) * 10];
      } else {
        if (!this.state.areas[i] || !this.state.areas[i].id) newAreas[i] = { ...emptyArea };
      }
    }
    this.setState({ areas: newAreas });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getAreasCallId) {
        this.handleAreaListDataResp(responseJson, message);
      } else if (apiRequestCallId === this.updateUserCallId) {
        this.getAreas();
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        if (responseJson) {
          const updatedFilters = getFiterList(
            responseJson,
            this.state.filters,
            this.state.suggestionFieldTitle
          );
          this.setState({ filters: updatedFilters });
        }
      }
    }
  }

  createData(company: string, name: string, region: string, status: string, id: string, storeCount: number, storeList: { id: number, store_name: string}[]) {
    return { company, name, region, status, id, storeCount, storeList };
  }

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };

  getAreas = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.contentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAreasCallId = getDataMsg.messageId;

    const companyFilter = this.state.filters.find((item) => item.title === "Company Name")?.value;
    const areaFilter = this.state.filters.find((item) => item.title === "Area Name")?.value;
    const regionFilter = this.state.filters.find((item) => item.title === "Region")?.value;
    const storeManagementCountFilter = this.state.filters.find((item) => item.title === "Store Count")?.value;
    const searchTextArea = this.state.searchTextArea;

    let url =
      configJSON.areaListApi +
      "?page_no=" +
      (this.state.page + 1) +
      (companyFilter ? `&filter_by[company_name]=${companyFilter}` : "") +
      (areaFilter ? `&filter_by[area_name]=${areaFilter}` : "") +
      (regionFilter ? `&filter_by[region]=${regionFilter}` : "") +
      (searchTextArea ? `&filter_by[query]=${searchTextArea}` : "") +
      (storeManagementCountFilter ? `&filter_by[store_count]=${storeManagementCountFilter}`: "") +
      this.state.query;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleAreaListDataResp = (responseJson: any, message: Message) => {
    if (responseJson) {
      if (responseJson.meta) {
        this.setState({isLoading:false });
        this.processMeta(message);
      } else {
        let areas = responseJson.data as IArea[];
        areas = areas.filter((el) => el.attributes);
        this.setState({isLoading:false, areas: areas });
      }
    }
  };

  updateArea = (areaId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.areaListApi + "/" + areaId + "/" + (isActive ? "activate" : "deactivate")
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();

    this.getAreas();
  }

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    if (page !== this.state.page) {
      this.setState({ page }, () => this.getAreas());
    }
  };

  handleSearchArea = (value: string) => {	
    this.setState(	
      {	
        page: 0,
        searchTextArea: value,	
      },	
      this.getAreas	
    );
  };

  handleAddArea = () => {
    this.props.navigation.navigate("AddArea");
  };

  handleEditArea = (areaId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("/Settings-General-AreaListEdit?areaId=" + areaId);
    window.localStorage.setItem("areaId", areaId);
  };

  handleViewArea = (areaId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("/Settings-General-AreaListView?areaId=" + areaId);
    window.localStorage.setItem("areaId", areaId);
  };

  handleDeactivate = () => {
    this.setState({ popOverOpened: false });
    this.updateArea(this.state.popOverItemId, false);
  };

  handleActivateArea = () => {
    this.setState({ popOverOpened: false });
    this.updateArea(this.state.popOverItemId, true);
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getAreas());
  };

  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "22%",
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState({ filters }, this.getAreas);
  };

  handleAreaAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let ApiUrl;
    if(title === "Company Name") {
      ApiUrl = configJSON.areaCompanyNameFilterSuggEndPoint;
    } else if (title === "Area Name") {
      ApiUrl = configJSON.areaNameFilterSuggEndPoint;
    } else if (title === "Region") {
      ApiUrl = configJSON.areaRegionFilterSuggEndPoint;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.areaPermissions;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleCloseStoreList = () => {
    this.setState({
      openStoreListDialog: false,
      listingDataShow: []
    })
  }
  handleStoreList = (list: {id: number, store_name: string}[]) => {
    this.setState({
      openStoreListDialog: true,
      listingDataShow: list.map((item) => item.store_name)
    })
  }

  // Customizable Area End
}
