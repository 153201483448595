// Customizable Area Start
import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import PageContainer from "../../navigationmenu/src/PageContainer.web";
import {  HeaderText, InputLabel, CustomStyledTextField } from "../../../components/src/customComponents/CustomTags.web";
import Loader from "../../../components/src/Loader.web";
import FormControl from "@material-ui/core/FormControl";
export const configJSON = require("./config");
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import {
  Snackbar
} from "@material-ui/core";
import StoreGroupAddController, {
  IFormStore,
  IOptionListItem,
  Props,
} from "./StoreGroupAddController";
import { Alert } from "@material-ui/lab";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

type ROption = { id: string | number, option: string }
// Customizable Area End
// Customizable Area Start

const useStyles: Styles<Theme, {}, string> = () => ({
  chips: {
    gap: 4,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "Montserrat",
  },
  chip: {    
    color: "#204B9C",
    fontFamily: "Montserrat",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
  },
  pageContainerStore: {
    fontFamily: "Montserrat",
    background: "white",
    padding: "32.5px 48px",

    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
    },
  },
  pageTitleStore: {
    fontSize: 24,
    marginTop: 32,
    fontWeight: 600,
  },
  formContainerStore: {
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,
    padding: 32,

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "-0.3px",
      fontSize: 16,
      color: "#1A1A1A",
    },
    position: "relative",
  },
  paddingRightClass: {
    paddingRight: 32,
  },
  button: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px",
      height: "44px",
      padding: "13px 34px",

    },
  },
  errorInput: {
    "& fieldset": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  selectContainer: {
  },
  selectDayContainer: {
    height: 46,
    "&.MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  multiSelectContainer: {
    "& .MuiOutlinedInput-input": {
      padding: "4px 14px",
    },
    "& .MuiSelect-selectMenu": {
      whiteSpace: "break-spaces",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: '#fff'
    }
  },
  sectionTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  getLocationText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 16,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  blueCheckbox: {
    "&$checked": {
      color: "#204B9C",
    },
  },
  checked: {},
  modalContentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 104px 0 32px",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,
  },
  modalRowContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
  },
  modalTextButtonsContainer: {
    display: "flex",
    color: "#204B9C",
    fontWeight: 600,
    fontSize: 16,
  },
  modalRowTitle: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Montserrat",
    width: 90,
    marginRight: 15,
    color: "#000000",
  },
  modalRowTo: {
    margin: "0px 24px",
    fontWeight: 500,
    fontSize: 18,
  },
  timeDialog: {
    textAlign: "center",
  },
  customTabContainer: {
    width: 244,
    height: 44,
    padding: 2,
    backgroundColor: "#F8FAFC",
    borderRadius: 50,
    display: "flex",
  },
  tabText: {
    color: "#4D4D4D",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    padding: "10px 12px",
  },
  activeTab: {
    padding: "10px 12px",
    backgroundColor: "white",
    borderRadius: 50,
    color: "#204B9C",
  },
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: 1,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + $track": {
        backgroundColor: "#204B9C",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#204B9C",
      border: "6px solid grey",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    color: "white !important",
  },
  track: {
    borderRadius: 26 / 2,
    border: "1px solid grey",
    backgroundColor: "grey",
    opacity: 1,
    //transition: theme.transitions.create(['background-color', 'border']),
  },
  checkedSwitch: {},
  focusVisible: {},
  datepickerInput: {
    paddingRight: 30,

    "&:after": {
      content: "▼",
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
      pointerEvents: "none",
    },
  },
  closeText: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 32,
  },
  closeChecksContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
  },
  checkAndTextContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  sectionTitleView: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  sectionFormContainerViewAdd: {
    marginTop: 24,
    padding: 24,
    border: "1px solid #ECECEC",
    position: "relative",
    borderRadius: 12,
  },
  dropDown: {
    "& .MuiAutocomplete-root": {
      minWidth: "unset !important",
      maxWidth: "unset !important",
      marginTop: "4px",
      "& .MuiInputBase-root": {
        minHeight: "56px !important",
        padding: "4px 65px 4px 4px",
        "@media only screen and (max-width: 1024px)": {
          minHeight: "44px !important",
          maxHeight: "unset !important",
      }
    }
    }
  },
  viewSectionHeaderViewAdd: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  stichSelection: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    padding: "15px",
  },
  w_100: {
    width: "100%",
  },
  storeTimeText: {
    fontSize: 16,
    marginTop: 4,
    fontWeight: 500,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  mb_8: {
    marginBottom: 8,
  },
  mt_30: {
    marginTop: 30,
  },
  mr_10: {
    marginRight: 10,
  },
  mt_24: {
    marginTop: 24,
  },
  mt_32: {
    marginTop: 32,
  },
  ml_10: {
    marginLeft: 10,
  },
  pr_32: {
    paddingRight: 32,
  },
  mt_66: {
    marginTop: 66,
  },
  mt_48: {
    marginTop: 48,
  },
  formErrors: {
    color: "red",
    marginBottom: "-20px",
  },
  fontRed: {
    color: "red",
  },
  selectAllText: {
    color: "black",
    fontWeight: 400,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '14px',
    }
  },
  addBreakText: {
    marginRight: 34,
    display: "flex",
    alignItems: "Center",
  },
  addShiftText: {
    display: "flex",
    alignItems: "Center",
  },
  getLocationIcon: {
    fontSize: 20,
    marginRight: 10,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  pageTitle: {
    marginBottom: 32,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 32,
  },
  historyIcon: {
    fontSize: 24,
    color: "#4d4d4d",
  },
  actionButtons: {
    marginTop: 28,
    marginBottom: 48,
  },
  cancleButton: {
    backgroundColor: "#F1F5F9",
    marginRight: 32,
    color: "#64748B",
  },
  addSaveButton: {
    color: "white",
    backgroundColor: "#204B9C",
  },
  addStoreTimeText: {
    fontSize: 24,
    fontWeight: 600,
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
});
// Customizable Area End
export class StoreGroupAdd extends StoreGroupAddController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
  ];
  renderTextField(
    keyOf: keyof IFormStore,
    placeholder: string,
    isNumeric?: boolean,
    inputDir?: string
  ) {
    const { form, formErrors } = this.state;
    const { classes  } = this.props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;
      if (!isNumeric) {
        this.onFormElementChange(
          inputValue,
          keyOf
        );
      }
    };

    return (
      <CustomStyledTextField
        data-test-id={keyOf}
        className={formErrors[keyOf] ? classes.errorInput : "withoutError"}
        value={form[keyOf]}
        color='primary'
        variant='outlined'
        type='text'
        name={keyOf}
        placeholder={placeholder}
        onChange={handleInputChange}
        dir={inputDir}
      />
    );
  }

  renderInputTextField(
    keyOf: keyof IFormStore,
    label: string,
    placeholder: string,
    leftOne: boolean,
    sixColumn?: boolean,
    isNumeric?: boolean,
    inputDir?: string
  ) {
    const { formErrors } = this.state;
    const { classes  } = this.props;

    return (
      <>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ paddingRight: sixColumn && leftOne ? 24 : undefined }}
          className={leftOne ? classes.paddingRightClass : ""}
        >
          <FormControl className={classes.w_100}>
            <InputLabel className={classes.mb_8}>{label}</InputLabel>
            {this.renderTextField(keyOf, placeholder, isNumeric, inputDir)}
            {formErrors[keyOf] && (
              <Typography
                variant='caption'
                className={`${classes.fontRed} errorMsg2`}
              >
                {formErrors[keyOf]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }

  renderRegionSelect(
    keyOf: keyof IFormStore,
    label: string,
    leftOne: boolean,
    options: IOptionListItem[],
    value: ROption[],
    selectedOptionForList: ROption[],
  ) {
    const { formErrors } = this.state;
    const { classes  } = this.props;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        className={leftOne ? classes.paddingRightClass : ""}
      >
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>{label}</InputLabel>
          <CustomSearchableMultiSelect
            id="region"
            name="region"
            dataTestId="regionDropdown"
            checkSelectAllText={() => { }}
            value={value}
            optionList={options.map((item:IOptionListItem)=>{
              return {
                id: item.id,
                option: item.name}
            })}
            placeHolderText="Select Region"
            changeInputOnClear
            handleScroll={this.handleScrollRegionDropdown}
            handleClickOnSelectAll={() => { }}
            isOnBlurFunction={() => this.debouncedFunction("", this.handleRegionAutoCompleteChange)}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleRegionAutoCompleteChange) }}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleRegionMultiSelect(value, option) }}
            emptyDataMessage="No Region Found"
            handleEmptyAutoSelectValue={() => { }}
            selectedOptionForList={selectedOptionForList}
            isError={Boolean(formErrors[keyOf])}
          />
          {formErrors[keyOf] && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors[keyOf]}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }
  renderAreaSelect(
    value: ROption[],
    selectedOptionForList: ROption[],
    label: string,
    leftOne: boolean,
    options: IOptionListItem[],
    keyOf: keyof IFormStore,
  ) {
    const { formErrors } = this.state;
    const { classes  } = this.props;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        className={leftOne ? classes.paddingRightClass : ""}
      >
        <FormControl variant='outlined' className={classes.w_100}>
          <InputLabel className={classes.mb_8}>{label}</InputLabel>
          <CustomSearchableMultiSelect
            id="area"
            name="area"
            dataTestId="areaDropdown"
            checkSelectAllText={() => { }}
            value={value}
            optionList={options.map((item:IOptionListItem)=>{
              return {
                id: item.id,
                option: item.name}
            })}
            placeHolderText="Select Area"
            changeInputOnClear
            handleScroll={this.handleScrollRegionDropdown}
            handleClickOnSelectAll={() => { }}
            isOnBlurFunction={() => this.debouncedFunction("", this.handleRegionAutoCompleteChange)}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleRegionAutoCompleteChange) }}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleAreaMultiSelect(value, option) }}
            emptyDataMessage="No Area Found"
            handleEmptyAutoSelectValue={() => { }}
            selectedOptionForList={selectedOptionForList}
            isError={Boolean(formErrors[keyOf])}
          />
          {formErrors[keyOf] && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors[keyOf]}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }
  renderStoreSelect(
    leftOne: boolean,
    keyOf: keyof IFormStore,
    options: IOptionListItem[],
    value: ROption[],
    selectedOptionForList: ROption[]
  ) {
    const { classes} = this.props;
    const { formErrors    } = this.state;
    return (
      <Grid
      className={leftOne ? classes.paddingRightClass : ""}
        item
        sm={6}
        xs={12}
      >
        <FormControl
        className={classes.w_100}
         variant='outlined' >
          <InputLabel className={classes.mb_8}>
          Store
            </InputLabel>
          <CustomSearchableMultiSelect
            id="store"
            name="store"
            dataTestId="storeDropdown"
            checkSelectAllText={() => { }}
            value={value}
            optionList={options.map((item:IOptionListItem)=>{
              return {
                id: item.id,
                option: item.name}
            })}
            placeHolderText="Select Store"
            changeInputOnClear
            handleScroll={this.handleScrollRegionDropdown}
            handleClickOnSelectAll={() => { }}
            isOnBlurFunction={() => this.debouncedFunction("", this.handleRegionAutoCompleteChange)}
            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleRegionAutoCompleteChange) }}
            handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleStoreMultiSelect(value, option) }}
            emptyDataMessage="No Store Found"
            handleEmptyAutoSelectValue={() => { }}
            selectedOptionForList={selectedOptionForList}
            isError={Boolean(formErrors[keyOf])}
          />
          {formErrors[keyOf] && (
            <Typography
              variant='caption'
              className={`${classes.formErrors} errorMsg2`}
            >
              {formErrors[keyOf]}
            </Typography>
          )}
        </FormControl>
      </Grid>
    );
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes  } = this.props;

    const {
      regionList,
      areaList,
      storeList,
    } = this.state;
   
    // Customizable Area End
    return (
      // Customizable Area Start
      <PageContainer navigation={this.props.navigation}>
        <CustomLoader loading={this.state.isLoading} />
        <Grid
        >
          <Grid container direction='row' className={classes.pageTitle}>
            <HeaderText>{this.props.navigation.getParam("id") ? 'Edit Store Group' :'Add Store Group'}</HeaderText>
          </Grid>
          <Grid
            container
            direction='column'
            className={classes.formContainerStore}
          >
            <Grid container direction='row' className={classes.mt_30}>
              {this.renderInputTextField(
                "name",
                "Group Name",
                "Enter Group Name",
                true
              )}
              {this.renderInputTextField(
                "second_name",
                "Group Arabic Name",
                "Enter Group Arabic Name",
                false,
                false,
                false,"rtl"
              )}
            </Grid>
            <Grid container direction='row' className={classes.mt_30}>
              {this.renderRegionSelect(
                "region_ids",
                "Region",
                true,
                regionList,
                this.state.regionSelectedData,
                this.state.selectedRegionsForList,
              )}
              {this.renderAreaSelect(
                this.state.areaSelectedData,
                this.state.selectedAreasForList,
                "Area",
                true,
                areaList,
                "area_ids",
              )}
             
            </Grid>
             
            <Grid container direction='row' className={classes.mt_30}>
            {this.renderStoreSelect(
                true,
                "store_management_ids",
                storeList,
                this.state.storeSelectedData,
                this.state.selectedStoresForList
              )}
            </Grid>
            

          </Grid>
          <Grid container direction='row' className={classes.mt_48} style={{paddingBottom:'46px'}}>
            <div
              onClick={this.handleCancelClick}
              data-test-id={"cancelBtn"}
              className={`${classes.button} ${classes.cancleButton}`}
            >
              Cancel
            </div>

            <div
              data-test-id={"submitBtn"}
              className={`${classes.button} ${classes.addSaveButton}`}
              onClick={this.handleOnSubmit}
            >
              {this.props.navigation.getParam("id") ? configJSON.saveText : configJSON.add}
            </div>
          </Grid>
        <Snackbar
          open={this.state.saveStatus.length > 0}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => this.setState({ saveStatus: "" })}
            severity={
              this.state.saveStatus === configJSON.saveText ? "success" : "error"
            }
          >
            {this.state.saveStatus}
          </Alert>
        </Snackbar>
        </Grid>
        
     
      </PageContainer>
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles)(StoreGroupAdd);
